import React, { useEffect, useState } from "react";
import { conjugateActiveIndicativePresent, LATIN_VERB_KLASS_TITLE, LatinVerb } from "../../data/latin/LatinVerb.tsx";
import "../WordFull.scss";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface LatinVerbFullProps {
  verb: LatinVerb;
}

function LatinVerbFull({ verb }: LatinVerbFullProps) {
  const [ activeIndicativePresent, setActiveIndicativePresent ] = useState<boolean>(false);

  useEffect(() => {
    setActiveIndicativePresent(false);
  }, [ verb ]);

  const toggleActiveIndicativePresent: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();
    setActiveIndicativePresent(!activeIndicativePresent);
  }

  const { infinitive, klass, dutch } = verb;
  return (
    <div className="word-full">
      <div className="word-full-title">
        {infinitive}
        <span className="word-full-model float-end">
          {LATIN_VERB_KLASS_TITLE[klass]}
        </span>
      </div>
      <div className="word-full-conjugation">
        <table className="table table-sm">
          <tbody>
          <tr>
            <th colSpan={4} className="title">
              <a href="#" onClick={toggleActiveIndicativePresent}>
                {activeIndicativePresent ? "▼" : "▶"} Indicatief praesens
              </a>
            </th>
          </tr>
          {
            activeIndicativePresent ?
              <>
                <tr>
                  <th className="conjugation">1</th>
                  <td>{conjugateActiveIndicativePresent(verb, "s1")}</td>
                  <td>{conjugateActiveIndicativePresent(verb, "p1")}</td>
                </tr>
                <tr>
                  <th className="conjugation">2</th>
                  <td>{conjugateActiveIndicativePresent(verb, "s2")}</td>
                  <td>{conjugateActiveIndicativePresent(verb, "p2")}</td>
                </tr>
                <tr>
                  <th className="conjugation">3</th>
                  <td>{conjugateActiveIndicativePresent(verb, "s3")}</td>
                  <td>{conjugateActiveIndicativePresent(verb, "p3")}</td>
                </tr>
              </> : null
          }
          </tbody>
        </table>
      </div>
      <div className="word-full-translation">
        {dutch}
      </div>
    </div>
  );
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export default LatinVerbFull;