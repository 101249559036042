import { searchWord } from "../WordBase";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export interface GreekInterrogativePronoun {
  type: "interrogativePronoun";  // "vraagwoord"
  ground: string;
  dutch: string;
}

export const GREEK_INTERROGATIVE_PRONOUNS: GreekInterrogativePronoun[] = [
  {
    type: "interrogativePronoun",
    ground: "τί",
    dutch: "wat? waarom?",
  },
  // {
  //   type: "interrogativePronoun",
  //   ground: "",
  //   dutch: "",
  //   extras: {},
  // },
];

export const searchGreekInterrogativePronouns = searchWord(GREEK_INTERROGATIVE_PRONOUNS, "Greek interrogative pronoun", "ground");