import { searchWord } from "../WordBase";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export interface GreekImpersonalVerb {
  type: "impersonalVerb";  // "onpersoonlijk werkwoord"
  ground: string;
  dutch: string;
}

export const GREEK_IMPERSONAL_VERBS: GreekImpersonalVerb[] = [
  {
    type: "impersonalVerb",
    ground: "ἔξεστι(ν)",
    dutch: "het is mogelijk, het is toegelaten",
  },
  // {
  //   type: "impersonalVerb",
  //   ground: "",
  //   dutch: "",
  // },
];

export const searchGreekImpersonalVerb = searchWord(GREEK_IMPERSONAL_VERBS, "Greek impersonal verb", "ground");