import { useParams } from "react-router-dom";
import { Examples } from "../components/Examples";
import { isValidVocabularyKey, VOCABULARIES } from "../data/Data";
import { assertNever } from "../utils/assertNever";
import { fromCsr } from "../utils/csr";
import { GreekPractice } from "./GreekPractice";
import { LatinPractice } from "./LatinPractice";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export function Range() {
  const params = useParams();

  const selector = params?.selector ?? "";
  if (!isValidVocabularyKey(selector)) {
    // TODO: improve
    throw new Error("");
  }

  const csr = params?.csr ?? "";
  const words = fromCsr(csr);

  switch(selector) {
    case "2023-pegasus-novus-1": {
      const { vocabulary } = VOCABULARIES[selector];
      return <LatinPractice vocabulary={vocabulary}
                            answerBodyFn={(w) => <Examples examples={w._2023_pegasus_novus_1.examples} />}
                            words={words} />;
    }
    case "2024-pegasus-novus-2": {
      const { vocabulary } = VOCABULARIES[selector];
      return <LatinPractice vocabulary={vocabulary} words={words} />;
    }
    case "2024-nea-thalassa-1": {
      const { vocabulary } = VOCABULARIES[selector];
      return <GreekPractice vocabulary={vocabulary} words={words} />;
    }
    default:
      assertNever(selector);
  }
}