import { LatinConjunction } from "../LatinWord";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const LATIN_CONJUNCTIONS: Array<LatinConjunction> = [
  {
    type: "conjunction",
    latin: "itaque",
    dutch: "daarom; en zo",
  },
  {
    type: "conjunction",
    latin: "ac (of atque)",
    dutch: "en",
  },
  {
    type: "conjunction",
    latin: "at",
    dutch: "maar",
  },
  {
    type: "conjunction",
    latin: "aut",
    dutch: "of",
  },
  {
    type: "conjunction",
    latin: "autem",
    dutch: "maar",
  },
  {
    type: "conjunction",
    latin: "enim",
    dutch: "want",
  },
  {
    type: "conjunction",
    latin: "et",
    dutch: "en; ook",
  },
  {
    type: "conjunction",
    latin: "nam",
    dutch: "want",
  },
  {
    type: "conjunction",
    latin: "nec of neque",
    dutch: "en niet; ook niet",
  },
  {
    type: "conjunction",
    latin: "-que",
    dutch: "en",
  },
  {
    type: "conjunction",
    latin: "sed",
    dutch: "maar",
  },
  {
    type: "conjunction",
    latin: "cum",
    dutch: "toen; wanneer",
  },
  {
    type: "conjunction",
    latin: "dum",
    dutch: "terwijl",
  },
  {
    type: "conjunction",
    latin: "quamquam",
    dutch: "hoewel",
  },
  {
    type: "conjunction",
    latin: "quia",
    dutch: "omdat",
  },
  {
    type: "conjunction",
    latin: "quod",
    dutch: "omdat",
  },
  {
    type: "conjunction",
    latin: "sī",
    dutch: "als",
  },
  {
    type: "conjunction",
    latin: "ubi",
    dutch: "toen; wanneer",
  },
  {
    type: "conjunction",
    latin: "nōn solum ... sed etiam ...",
    dutch: "niet alleen ... maar ook ...",
  },
  {
    type: "conjunction",
    latin: "sīve",
    dutch: "hetzij; of als",
  },
  {
    type: "conjunction",
    latin: "postquam",
    dutch: "nadat",
  },
];

export function searchLatinConjunction(latin: string): LatinConjunction {
  const results = LATIN_CONJUNCTIONS.filter((conjunction) => conjunction.latin === latin);
  if (results.length !== 1) {
    console.error(results);
    throw new Error(`No single Latin conjunction found for: ${latin}`);
  }
  return results[0];
}