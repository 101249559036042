import React from "react";
import * as rt from "runtypes";
import { assertNever } from "../../utils/assertNever.tsx";
import { Optional } from "../../utils/Optional.tsx";
import { WordFinal } from "../WordBase.tsx";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export type LatinRegularVerbKlass =
  | "-āre"
  | "-ēre"
  | "-īre"
  | "īre"
  | "fīerī"
  | "capere"
  | "esse"
  | "posse"
  | "tegere"
  | "dīcere"  // Like "tegere", but different singular imperative (p. 149).
  | "dūcere"  // Like "tegere", but different singular imperative (p. 149).
  | "ferre"  // Irregular, see p. 222.
  ;

export type LatinIrregularVerbKlass =
  | "velle"  // Fully irregular, according to velle (p. 222).
  ;

export type LatinVerbKlass = LatinRegularVerbKlass | LatinIrregularVerbKlass;

export const LatinVerbVoice = rt.Union(
  rt.Literal("active"),
  rt.Literal("passive")
);
export type LatinVerbVoice = rt.Static<typeof LatinVerbVoice>;

export const LatinVerbMood = rt.Union(
  rt.Literal("indicative"),
  rt.Literal("subjunctive"),
  rt.Literal("imperative")
);
export type LatinVerbMood = rt.Static<typeof LatinVerbMood>;

export const LatinVerbTense = rt.Union(
  rt.Literal("present"),
  rt.Literal("future"),
  rt.Literal("futurePerfect"),
  rt.Literal("imperfect"),
  rt.Literal("perfect"),
  rt.Literal("pluperfect")
);
export type LatinVerbTense = rt.Static<typeof LatinVerbTense>;

export const LatinVerbNumberPerson = rt.Union(
  rt.Literal("s1"),
  rt.Literal("s2"),
  rt.Literal("s3"),
  rt.Literal("p1"),
  rt.Literal("p2"),
  rt.Literal("p3")
);
export type LatinVerbNumberPerson = rt.Static<typeof LatinVerbNumberPerson>;

export const LatinConjugatedTense = rt.Dictionary(WordFinal, LatinVerbNumberPerson);
export type LatinConjugatedTense = rt.Static<typeof LatinConjugatedTense>;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export interface LatinVerbBase {
  type: "verb",
  infinitive: string;
  s1Form: string;
  root: string;
  dutch: string;
}

export interface LatinRegularVerb extends LatinVerbBase {
  klass: LatinRegularVerbKlass;
  explicitConjugations?: {
    active?: {
      indicative?: {
        present?: Optional<LatinConjugatedTense>;
      }
    }
  }
}

export interface LatinIrregularVerb extends LatinVerbBase {
  klass: LatinIrregularVerbKlass;
  explicitConjugations: {
    active: {
      indicative: {
        present: LatinConjugatedTense;
      }
    }
  }
}

export type LatinVerb = LatinRegularVerb | LatinIrregularVerb;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const LATIN_VERB_KLASS_TITLE: Record<LatinVerbKlass, React.ReactNode> = {
  "-āre": "volgens amāre",
  "-ēre": "volgens monēre",
  "-īre": "volgens audīre",
  "īre": "volgens īre",
  "fīerī": "volgens fīerī",
  "capere": "volgens capere",
  "esse": "volgens esse",
  "posse": "volgens posse",
  "tegere": "volgens tegere",
  "dīcere": "volgens tegere (variant: dīcere)",
  "dūcere": "volgens tegere (variant: dūcere)",
  "ferre": "volgens ferre",
  "velle": "onregelmatig (velle)",
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const LATIN_VERB_ACTIVE_INDICATIVE_PRESENT_ENDINGS: Record<LatinRegularVerbKlass, LatinConjugatedTense> = {
  // http://latindictionary.wikidot.com/verb:clamare
  "-āre": {
    "s1": "ō",
    "s2": "as",
    "s3": "at",
    "p1": "āmus",
    "p2": "ātis",
    "p3": "ant",
  },
  // http://latindictionary.wikidot.com/verb:videre
  "-ēre": {
    "s1": "eō",
    "s2": "es",
    "s3": "et",
    "p1": "ēmus",
    "p2": "ētis",
    "p3": "ent",
  },
  // http://latindictionary.wikidot.com/verb:aperire
  "-īre": {
    "s1": "iō",
    "s2": "is",
    "s3": "it",
    "p1": "īmus",
    "p2": "ītis",
    "p3": "iunt",
  },
  // https://ancientlanguages.org/latin/dictionary/adeo-adire-adii-aditum
  "īre": {
    "s1": "eō",
    "s2": "īs",
    "s3": "it",
    "p1": "īmus",
    "p2": "ītis",
    "p3": "eunt",
  },
  "fīerī": {
    "s1": "fīō",
    "s2": "fīs",
    "s3": "fit",
    "p1": "fīmus",
    "p2": "fītis",
    "p3": "fīunt",
  },
  "capere": {
    "s1": "iō",
    "s2": "is",
    "s3": "it",
    "p1": "imus",
    "p2": "itis",
    "p3": "iunt",
  },
  // http://latindictionary.wikidot.com/verb:esse
  // Page 50.
  "esse": {
    "s1": "sum",
    "s2": "es",
    "s3": "est",
    "p1": "sumus",
    "p2": "estis",
    "p3": "sunt",
  },
  // http://latindictionary.wikidot.com/verb:posse
  // Page 50.
  "posse": {
    "s1": "possum",
    "s2": "potes",
    "s3": "potest",
    "p1": "possumus",
    "p2": "potestis",
    "p3": "possunt",
  },
  "tegere": {
    "s1": "ō",
    "s2": "is",
    "s3": "it",
    "p1": "imus",
    "p2": "itis",
    "p3": "unt",
  },
  "dīcere": {
    "s1": "ō",
    "s2": "is",
    "s3": "it",
    "p1": "imus",
    "p2": "itis",
    "p3": "unt",
  },
  "dūcere": {
    "s1": "ō",
    "s2": "is",
    "s3": "it",
    "p1": "imus",
    "p2": "itis",
    "p3": "unt",
  },
  "ferre": {
    "s1": "ferō",
    "s2": "fers",
    "s3": "fert",
    "p1": "ferimus",
    "p2": "fertis",
    "p3": "ferunt",
  },
};

export function conjugateActiveIndicativePresent(verb: LatinVerb, number: LatinVerbNumberPerson): WordFinal {
  const explicit = verb.explicitConjugations?.active?.indicative?.present?.[number];
  if (explicit) return explicit;
  const { root, klass, } = verb;
  switch (klass) {
    case "-āre":
      return root + LATIN_VERB_ACTIVE_INDICATIVE_PRESENT_ENDINGS["-āre"][number];
    case "-ēre":
      return root + LATIN_VERB_ACTIVE_INDICATIVE_PRESENT_ENDINGS["-ēre"][number];
    case "-īre":
      return root + LATIN_VERB_ACTIVE_INDICATIVE_PRESENT_ENDINGS["-īre"][number];
    case "īre":
      return root + LATIN_VERB_ACTIVE_INDICATIVE_PRESENT_ENDINGS["īre"][number];
    case "fīerī":
      return root + LATIN_VERB_ACTIVE_INDICATIVE_PRESENT_ENDINGS["fīerī"][number];
    case "capere":
      return root + LATIN_VERB_ACTIVE_INDICATIVE_PRESENT_ENDINGS["capere"][number];
    case "esse":
      return root + LATIN_VERB_ACTIVE_INDICATIVE_PRESENT_ENDINGS["esse"][number];
    case "posse":
      return root + LATIN_VERB_ACTIVE_INDICATIVE_PRESENT_ENDINGS["posse"][number];
    case "tegere":
      return root + LATIN_VERB_ACTIVE_INDICATIVE_PRESENT_ENDINGS["tegere"][number];
    case "dīcere":
      return root + LATIN_VERB_ACTIVE_INDICATIVE_PRESENT_ENDINGS["dīcere"][number];
    case "dūcere":
      return root + LATIN_VERB_ACTIVE_INDICATIVE_PRESENT_ENDINGS["dūcere"][number];
    case "ferre":
      return root + LATIN_VERB_ACTIVE_INDICATIVE_PRESENT_ENDINGS["ferre"][number];
    case "velle":
      return verb.explicitConjugations.active.indicative.present[number];
    default:
      assertNever(klass);
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export function conjugate(verb: LatinVerb): LatinConjugatedTense {
  return {
    "s1": conjugateActiveIndicativePresent(verb, "s1"),
    "s2": conjugateActiveIndicativePresent(verb, "s2"),
    "s3": conjugateActiveIndicativePresent(verb, "s3"),
    "p1": conjugateActiveIndicativePresent(verb, "p1"),
    "p2": conjugateActiveIndicativePresent(verb, "p2"),
    "p3": conjugateActiveIndicativePresent(verb, "p3"),
  };
}