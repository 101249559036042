import { useState } from "react";
import "./Examples.scss";
import { Example } from "../data/WordBase";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface ExampleProps {
  example: Example;
}

export function RenderedExample({ example }: ExampleProps) {
  return <span>{example.foreign}<br /><i>{example.dutch}</i></span>;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface ExamplesProps {
  examples: Example[];
}

export function Examples({ examples }: ExamplesProps) {
  const [ opened, setOpened ] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    setOpened(!opened);
  }

  if (examples.length === 0) return null;
  // We already checked the length earlier, so the typecast is safe.
  // const shownExamples: Example[] = opened ? examples : [_.sample(examples) as Example];
  const shownExamples: Example[] = opened ? examples : [];

  return (
    <div className="word-latin-examples">
      <div className="title">
        Voorbeelden
      </div>
      {
        <ul>
          {
            shownExamples.map((example, i) => (
              <li key={i}><RenderedExample example={example} /></li>
            ))
          }
        </ul>
      }
      {
        examples.length > 0 ?
          <a href="#" onClick={handleClick}>
            {opened ? "sluit" : "toon"}
          </a> :
          null
      }
    </div>
  );
}