import * as rt from "runtypes";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export type Sex = "m" | "f" | "n";

export const SEX_TITLE: Record<Sex, React.ReactNode> = {
  "m": "M",
  "f": "V",
  "n": "O",
};

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export interface Example {
  foreign: string;
  dutch: string;
  religious: boolean;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const searchWord = <T extends object>(list: T[], klassName: string, key: keyof T) => (value: string): T => {
  const results = list.filter((item) => item[key] === value);
  if (results.length !== 1) {
    console.error(results);
    throw new Error(`No single ${klassName} found for: ${value}`);
  }
  return results[0];
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const WordFinal = rt.Union(rt.Undefined, rt.String, rt.Array(rt.String));
export type WordFinal = rt.Static<typeof WordFinal>;