import { useEffect, useState } from "react";
import { Sex } from "../../data/WordBase.tsx";
import { LatinAdjective, toDutchTitle } from "../../data/latin/LatinWord.tsx";
import "../WordFull.scss";
import "./LatinAdjectiveFull.scss";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const LATIN_ADJECTIVE_MODELS: Record<LatinAdjective["klass"], string> = {
  1: "-us, -a, -um",
  "1r": "-er, -era, -erum",
  "1er": "-er, -ra, -rum",
  2: "tweede klasse",
};

const LATIN_ADJECTIVE_NOMINATIVE_PLURAL: Record<LatinAdjective["klass"], Record<Sex, string>> = {
  1: {
    "m": "ī",
    "f": "ae",
    "n": "a",
  },
  "1r": {
    "m": "ī",
    "f": "ae",
    "n": "a",
  },
  "1er": {
    "m": "ī",
    "f": "ae",
    "n": "a",
  },
  2: {
    "m": "ēs",
    "f": "ēs",
    "n": "ia",
  },
};

const LATIN_ADJECTIVE_ACCUSATIVE_SINGULAR: Record<LatinAdjective["klass"], Record<Sex, string>> = {
  1: {
    "m": "um",
    "f": "am",
    "n": "um",
  },
  "1r": {
    "m": "um",
    "f": "am",
    "n": "um",
  },
  "1er": {
    "m": "um",
    "f": "am",
    "n": "um",
  },
  2: {
    "m": "em",
    "f": "em",
    "n": "e",
  },
};

const LATIN_ADJECTIVE_ACCUSATIVE_PLURAL: Record<LatinAdjective["klass"], Record<Sex, string>> = {
  1: {
    "m": "ōs",
    "f": "ās",
    "n": "a",
  },
  "1r": {
    "m": "ōs",
    "f": "ās",
    "n": "a",
  },
  "1er": {
    "m": "ōs",
    "f": "ās",
    "n": "a",
  },
  2: {
    "m": "ēs",
    "f": "ēs",
    "n": "ia",
  },
};

const LATIN_ADJECTIVE_GENITIVE_SINGULAR: Record<LatinAdjective["klass"], Record<Sex, string>> = {
  1: {
    "m": "ī",
    "f": "ae",
    "n": "ī",
  },
  "1r": {
    "m": "ī",
    "f": "ae",
    "n": "ī",
  },
  "1er": {
    "m": "ī",
    "f": "ae",
    "n": "ī",
  },
  2: {
    "m": "is",
    "f": "is",
    "n": "is",
  },
};

const LATIN_ADJECTIVE_GENITIVE_PLURAL: Record<LatinAdjective["klass"], Record<Sex, string>> = {
  1: {
    "m": "ōrum",
    "f": "ārum",
    "n": "ōrum",
  },
  "1r": {
    "m": "ōrum",
    "f": "ārum",
    "n": "ōrum",
  },
  "1er": {
    "m": "ōrum",
    "f": "ārum",
    "n": "ōrum",
  },
  2: {
    "m": "ium",
    "f": "ium",
    "n": "ium",
  },
};

const LATIN_ADJECTIVE_DATIVE_SINGULAR: Record<LatinAdjective["klass"], Record<Sex, string>> = {
  1: {
    "m": "ō",
    "f": "ae",
    "n": "ō",
  },
  "1r": {
    "m": "ō",
    "f": "ae",
    "n": "ō",
  },
  "1er": {
    "m": "ō",
    "f": "ae",
    "n": "ō",
  },
  2: {
    "m": "ī",
    "f": "ī",
    "n": "ī",
  },
};

const LATIN_ADJECTIVE_DATIVE_PLURAL: Record<LatinAdjective["klass"], Record<Sex, string>> = {
  1: {
    "m": "īs",
    "f": "īs",
    "n": "īs",
  },
  "1r": {
    "m": "īs",
    "f": "īs",
    "n": "īs",
  },
  "1er": {
    "m": "īs",
    "f": "īs",
    "n": "īs",
  },
  2: {
    "m": "ibus",
    "f": "ibus",
    "n": "ibus",
  },
};

const LATIN_ADJECTIVE_ABLATIVE_SINGULAR: Record<LatinAdjective["klass"], Record<Sex, string>> = {
  1: {
    "m": "ō",
    "f": "ā",
    "n": "ō",
  },
  "1r": {
    "m": "ō",
    "f": "ā",
    "n": "ō",
  },
  "1er": {
    "m": "ō",
    "f": "ā",
    "n": "ō",
  },
  2: {
    "m": "ī",
    "f": "ī",
    "n": "ī",
  },
};

const LATIN_ADJECTIVE_ABLATIVE_PLURAL: Record<LatinAdjective["klass"], Record<Sex, string>> = {
  1: {
    "m": "īs",
    "f": "īs",
    "n": "īs",
  },
  "1r": {
    "m": "īs",
    "f": "īs",
    "n": "īs",
  },
  "1er": {
    "m": "īs",
    "f": "īs",
    "n": "īs",
  },
  2: {
    "m": "ibus",
    "f": "ibus",
    "n": "ibus",
  },
};

interface LatinAdjectiveFullProps {
  word: LatinAdjective;
}

function LatinAdjectiveFull({ word }: LatinAdjectiveFullProps) {
  const [ singularDeclension, setSingularDeclension ] = useState<boolean>(false);
  const [ pluralDeclension, setPluralDeclension ] = useState<boolean>(false);

  useEffect(() => {
    setSingularDeclension(false);
    setPluralDeclension(false);
  }, [ word ]);

  const toggleSingularDeclension: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();
    setSingularDeclension(!singularDeclension);
  }

  const togglePluralDeclension: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();
    setPluralDeclension(!pluralDeclension);
  }

  const { latinNS, klass, root } = word;
  return (
    <div className="word-full adjective">
      <div className="word-full-title">
        {latinNS.join(", ")}
        <span className="word-full-model float-end">{LATIN_ADJECTIVE_MODELS[klass]}</span>
      </div>
      <div className="word-full-conjugation adjective-full-conjugation">
        <table className="table table-sm">
          <tbody>
          <tr>
            <th colSpan={4} className="title">
              <a href="#" onClick={toggleSingularDeclension}>{singularDeclension ? "▼" : "▶"} Enkelvoud</a>
            </th>
          </tr>
          {
            singularDeclension ?
              <>
                <tr>
                  <th className="sex"></th>
                  <th className="sex">M.</th>
                  <th className="sex">V.</th>
                  <th className="sex">O.</th>
                </tr>
                <tr>
                  <th className="declension">Nominatief</th>
                  <td>{latinNS[0]}</td>
                  <td>{latinNS[1]}</td>
                  <td>{latinNS[2]}</td>
                </tr>
                <tr>
                  <th className="declension">Accusatief</th>
                  <td>{root + LATIN_ADJECTIVE_ACCUSATIVE_SINGULAR[klass]["m"]}</td>
                  <td>{root + LATIN_ADJECTIVE_ACCUSATIVE_SINGULAR[klass]["f"]}</td>
                  <td>{root + LATIN_ADJECTIVE_ACCUSATIVE_SINGULAR[klass]["n"]}</td>
                </tr>
                <tr>
                  <th className="declension">Genitief</th>
                  <td>{root + LATIN_ADJECTIVE_GENITIVE_SINGULAR[klass]["m"]}</td>
                  <td>{root + LATIN_ADJECTIVE_GENITIVE_SINGULAR[klass]["f"]}</td>
                  <td>{root + LATIN_ADJECTIVE_GENITIVE_SINGULAR[klass]["n"]}</td>
                </tr>
                <tr>
                  <th className="declension">Datief</th>
                  <td>{root + LATIN_ADJECTIVE_DATIVE_SINGULAR[klass]["m"]}</td>
                  <td>{root + LATIN_ADJECTIVE_DATIVE_SINGULAR[klass]["f"]}</td>
                  <td>{root + LATIN_ADJECTIVE_DATIVE_SINGULAR[klass]["n"]}</td>
                </tr>
                <tr>
                  <th className="declension">Ablatief</th>
                  <td>{root + LATIN_ADJECTIVE_ABLATIVE_SINGULAR[klass]["m"]}</td>
                  <td>{root + LATIN_ADJECTIVE_ABLATIVE_SINGULAR[klass]["f"]}</td>
                  <td>{root + LATIN_ADJECTIVE_ABLATIVE_SINGULAR[klass]["n"]}</td>
                </tr>
              </> : null
          }
          </tbody>
        </table>

        <table className="table table-sm">
          <tbody>
          <tr>
            <th colSpan={4} className="title">
              <a href="#" onClick={togglePluralDeclension}>{pluralDeclension ? "▼" : "▶"} Meervoud</a>
            </th>
          </tr>
          {
            pluralDeclension ?
              <>
                <tr>
                  <th className="sex"></th>
                  <th className="sex">M.</th>
                  <th className="sex">V.</th>
                  <th className="sex">O.</th>
                </tr>
                <tr>
                  <th className="declension">Nominatief</th>
                  <td>{root + LATIN_ADJECTIVE_NOMINATIVE_PLURAL[klass]["m"]}</td>
                  <td>{root + LATIN_ADJECTIVE_NOMINATIVE_PLURAL[klass]["f"]}</td>
                  <td>{root + LATIN_ADJECTIVE_NOMINATIVE_PLURAL[klass]["n"]}</td>
                </tr>
                <tr>
                  <th className="declension">Accusatief</th>
                  <td>{root + LATIN_ADJECTIVE_ACCUSATIVE_PLURAL[klass]["m"]}</td>
                  <td>{root + LATIN_ADJECTIVE_ACCUSATIVE_PLURAL[klass]["f"]}</td>
                  <td>{root + LATIN_ADJECTIVE_ACCUSATIVE_PLURAL[klass]["n"]}</td>
                </tr>
                <tr>
                  <th className="declension">Genitief</th>
                  <td>{root + LATIN_ADJECTIVE_GENITIVE_PLURAL[klass]["m"]}</td>
                  <td>{root + LATIN_ADJECTIVE_GENITIVE_PLURAL[klass]["f"]}</td>
                  <td>{root + LATIN_ADJECTIVE_GENITIVE_PLURAL[klass]["n"]}</td>
                </tr>
                <tr>
                  <th className="declension">Datief</th>
                  <td>{root + LATIN_ADJECTIVE_DATIVE_PLURAL[klass]["m"]}</td>
                  <td>{root + LATIN_ADJECTIVE_DATIVE_PLURAL[klass]["f"]}</td>
                  <td>{root + LATIN_ADJECTIVE_DATIVE_PLURAL[klass]["n"]}</td>
                </tr>
                <tr>
                  <th className="declension">Ablatief</th>
                  <td>{root + LATIN_ADJECTIVE_ABLATIVE_PLURAL[klass]["m"]}</td>
                  <td>{root + LATIN_ADJECTIVE_ABLATIVE_PLURAL[klass]["f"]}</td>
                  <td>{root + LATIN_ADJECTIVE_ABLATIVE_PLURAL[klass]["n"]}</td>
                </tr>
              </> : null
          }
          </tbody>
        </table>
      </div>
      <div className="word-full-translation">
        {toDutchTitle(word)}
      </div>
    </div>
  );
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export default LatinAdjectiveFull;