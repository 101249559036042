import { searchWord } from "../../WordBase";
import { LatinToFixIrregularWord } from "../LatinWord";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const LATIN_TO_FIXES: Array<LatinToFixIrregularWord> = [
  {
    type: "toFixIrregularWord",
    latin: "alius ..., alius ...",
    dutch: "sommige(n) ..., andere(n) ...",
  },
  {
    type: "toFixIrregularWord",
    latin: "aliī ..., aliī",
    dutch: "sommige(n) ..., andere(n) ...",
  },
  {
    type: "toFixIrregularWord",
    latin: "alter ..., alter ...",
    dutch: "de ene ..., de andere ...",
  },
  // {
  //   type: "toFixIrregularWord",
  //   latin: "",
  //   dutch: "",
  // },
];

export const searchLatinToFixIrregularWord = searchWord(LATIN_TO_FIXES, "Latin to-fix-word", "latin");