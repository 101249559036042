import { searchWord } from "../WordBase";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export interface GreekPrepositionDative {
  type: "prepositionDative";  // "voorzetsel (datief)"
  ground: string;
  dutch: string;
}

export const GREEK_PREPOSITION_DATIVE: GreekPrepositionDative[] = [
  {
    type: "prepositionDative",
    ground: "ἐν",
    dutch: "in, op",
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BD%91%CF%80%CF%8C
    type: "prepositionDative",
    ground: "ῠ̔πό",
    dutch: "onder, aan de voet van",
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%90%CF%80%CE%AF
    type: "prepositionDative",
    ground: "ἐπί",
    dutch: "op, bij",
  },
  {
    // https://en.wiktionary.org/wiki/%CF%80%CE%B1%CF%81%CE%AC
    type: "prepositionDative",
    ground: "παρά",
    dutch: "bij",
  },
  {
    // https://en.wiktionary.org/wiki/%CF%80%CF%81%CF%8C%CF%82
    type: "prepositionDative",
    ground: "πρός",
    dutch: "bij",
  },
  {
    // https://en.wiktionary.org/wiki/%CF%83%CF%8D%CE%BD
    type: "prepositionDative",
    ground: "σύν",
    dutch: "met",
  },
  // {
  //   type: "prepositionDative",
  //   ground: "",
  //   dutch: "",
  // },
];

export const searchGreekPrepositionDative = searchWord(GREEK_PREPOSITION_DATIVE, "Greek preposition (+ dat)", "ground");

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export interface GreekPrepositionGenitive {
  type: "prepositionGenitive";  // "voorzetsel (genitief)"
  ground: string;
  dutch: string;
}

export const GREEK_PREPOSITION_GENITIVE: GreekPrepositionGenitive[] = [
  {
    // https://en.wiktionary.org/wiki/%E1%BC%90%CE%BE
    type: "prepositionGenitive",
    ground: "ἐκ / ἐξ",
    dutch: "uit",
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%80%CE%BD%CF%84%CE%AF
    type: "prepositionGenitive",
    ground: "ἀντί",
    dutch: "tegenover, in ruil voor",
  },
  {
    // https://en.wiktionary.org/wiki/%CF%80%CE%B5%CF%81%CE%AF
    type: "prepositionGenitive",
    ground: "περῐ́",
    dutch: "over, in verband met",
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BD%91%CF%80%CF%8C
    type: "prepositionGenitive",
    ground: "ῠ̔πό",
    dutch: "door (handelende persoon)",
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%80%CF%80%CF%8C
    type: "prepositionGenitive",
    ground: "ἀπό",
    dutch: "vanaf",
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%90%CF%80%CE%AF
    type: "prepositionGenitive",
    ground: "ἐπί",
    dutch: "op",
  },
  {
    // https://en.wiktionary.org/wiki/%CF%80%CE%B1%CF%81%CE%AC
    type: "prepositionGenitive",
    ground: "παρά",
    dutch: "van bij",
  },
  {
    // https://en.wiktionary.org/wiki/%CF%80%CF%81%CF%8C%CF%82
    type: "prepositionGenitive",
    ground: "πρός",
    dutch: "van bij",
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BD%91%CF%80%CE%AD%CF%81
    type: "prepositionGenitive",
    ground: "ὑπέρ",
    dutch: "boven, ten voordele van",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BC%CE%B5%CF%84%CE%AC
    type: "prepositionGenitive",
    ground: "μετά",
    dutch: "met",
  },
  {
    // https://en.wiktionary.org/wiki/%CF%80%CF%81%CF%8C
    type: "prepositionGenitive",
    ground: "πρό",
    dutch: "voor",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B4%CE%B9%CE%AC
    type: "prepositionGenitive",
    ground: "διά",
    dutch: "door, gedurende",
  },
  // {
  //   type: "prepositionGenitive",
  //   ground: "",
  //   dutch: "",
  // },
];

export const searchGreekPrepositionGenitive = searchWord(GREEK_PREPOSITION_GENITIVE, "Greek preposition (+ gen)", "ground");

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export interface GreekPrepositionAccusative {
  type: "prepositionAccusative";  // "voorzetsel (accusatief)"
  ground: string;
  dutch: string;
}

export const GREEK_PREPOSITION_ACCUSATIVE: GreekPrepositionAccusative[] = [
  {
    type: "prepositionAccusative",
    ground: "εἰς",
    dutch: "naar, tot in",
  },
  {
    type: "prepositionAccusative",
    ground: "μετᾰ́",
    dutch: "na",
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%90%CF%80%CE%AF
    type: "prepositionAccusative",
    ground: "ἐπί",
    dutch: "naar, tegen",
  },
  {
    // https://en.wiktionary.org/wiki/%CF%80%CE%B1%CF%81%CE%AC
    type: "prepositionAccusative",
    ground: "παρά",
    dutch: "naar, tot bij, langs",
  },
  {
    // https://en.wiktionary.org/wiki/%CF%80%CF%81%CF%8C%CF%82
    type: "prepositionAccusative",
    ground: "πρός",
    dutch: "naar, tot bij",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B4%CE%B9%CE%AC
    type: "prepositionAccusative",
    ground: "διά",
    dutch: "wegens",
  },
  // {
  //   type: "prepositionAccusative",
  //   ground: "",
  //   dutch: "",
  // },
];

export const searchGreekPrepositionAccusative = searchWord(GREEK_PREPOSITION_ACCUSATIVE, "Greek preposition (+ acc)", "ground");