import { LatinNoun } from "../LatinNoun";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const LATIN_NOUNS: { [key: string]: LatinNoun } = {
// export const LATIN_NOUNS = {
  "avus": {
    "type": "noun",
    "root": "av",
    "dutch": "de grootvader",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "avus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "rosa": {
    "type": "noun",
    "root": "ros",
    "dutch": "de roos",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "rosa",
      "ablS": "rosā"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "dōnum": {
    "type": "noun",
    "root": "dōn",
    "dutch": "het geschenk",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "dōnum",
      "accS": "dōnum"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "amīcus": {
    "type": "noun",
    "root": "amīc",
    "dutch": "de vriend",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "amīcus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "deus": {
    "type": "noun",
    "root": "de",
    "dutch": "de god",
    "klass": 1,
    "declension": "2irr",
    "explicitDeclensions": {
      "nomS": "deus",
      "vocS": [
        "deus"
      ],
      "nomP": [
        "dī"
      ],
      "vocP": [
        "dī"
      ],
      "genP": [
        "deōrum"
      ],
      "datP": [
        "dīs"
      ],
      "ablP": [
        "dīs"
      ]
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "dominus": {
    "type": "noun",
    "root": "domin",
    "dutch": "de meester",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "dominus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "fīlius": {
    "type": "noun",
    "root": "fīli",
    "dutch": "de zoon",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "fīlius"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "locus": {
    "type": "noun",
    "root": "loc",
    "dutch": "de plaats; de gelegenheid",
    "klass": 1,
    "declension": "2irr",
    "explicitDeclensions": {
      "nomS": "locus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "populus": {
    "type": "noun",
    "root": "popul",
    "dutch": "het volk",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "populus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "puer": {
    "type": "noun",
    "root": "puer",
    "dutch": "de jongen",
    "klass": "1r",
    "declension": "2er",
    "explicitDeclensions": {
      "nomS": "puer"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "servus": {
    "type": "noun",
    "root": "serv",
    "dutch": "de slaaf",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "servus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "īnsula": {
    "type": "noun",
    "root": "īnsul",
    "dutch": "het eiland",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "īnsula"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "puella": {
    "type": "noun",
    "root": "puell",
    "dutch": "het meisje",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "puella"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "pugna": {
    "type": "noun",
    "root": "pugn",
    "dutch": "het gevecht",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "pugna"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "terra": {
    "type": "noun",
    "root": "terr",
    "dutch": "de aarde; het land",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "terra"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "vīta": {
    "type": "noun",
    "root": "vīt",
    "dutch": "het leven",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "vīta"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "castra": {
    "type": "noun",
    "root": "castr",
    "dutch": "het kamp",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomP": "castra"
    },
    "hasSingular": false,
    "hasPlural": true,
    "sex": "n"
  },
  "forum": {
    "type": "noun",
    "root": "for",
    "dutch": "het forum; het Romeinse marktplein",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "forum",
      "accS": "forum"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "templum": {
    "type": "noun",
    "root": "templ",
    "dutch": "de tempel",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "templum"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "vīnum": {
    "type": "noun",
    "root": "vīn",
    "dutch": "de wijn",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "vīnum"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "dux": {
    "type": "noun",
    "root": "duc",
    "dutch": "de leider",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "dux"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "māter": {
    "type": "noun",
    "root": "mātr",
    "dutch": "de moeder",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "māter"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "corpus": {
    "type": "noun",
    "root": "corpor",
    "dutch": "het lichaam",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "corpus",
      "accS": "corpus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "cīvis": {
    "type": "noun",
    "root": "cīv",
    "dutch": "de burger",
    "klass": 2,
    "declension": "3mixed",
    "explicitDeclensions": {
      "nomS": "cīvis",
      "ablS": [
        "cīve",
        "cīvī"
      ]
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "frāter": {
    "type": "noun",
    "root": "frātr",
    "dutch": "de broer",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "frāter"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "homō": {
    "type": "noun",
    "root": "homin",
    "dutch": "de mens",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "homō"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "hostis": {
    "type": "noun",
    "root": "host",
    "dutch": "de vijand",
    "klass": 2,
    "declension": "3mixed",
    "explicitDeclensions": {
      "nomS": "hostis"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "mīles": {
    "type": "noun",
    "root": "mīlit",
    "dutch": "de soldaat",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "mīles"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "mōns": {
    "type": "noun",
    "root": "mont",
    "dutch": "de berg",
    "klass": 2,
    "declension": "3mixed",
    "explicitDeclensions": {
      "nomS": "mōns"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "pater": {
    "type": "noun",
    "root": "patr",
    "dutch": "de vader",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "pater"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "mors": {
    "type": "noun",
    "root": "mort",
    "dutch": "de dood",
    "klass": 2,
    "declension": "3mixed",
    "explicitDeclensions": {
      "nomS": "mors",
      "genP": [
        "mortum",
        "mortium"
      ],
      "accP": [
        "mortēs",
        "mortīs"
      ]
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "mulier": {
    "type": "noun",
    "root": "mulier",
    "dutch": "de vrouw",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "mulier"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "nox": {
    "type": "noun",
    "root": "noct",
    "dutch": "de nacht",
    "klass": 2,
    "declension": "3mixed",
    "explicitDeclensions": {
      "nomS": "nox"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "pars": {
    "type": "noun",
    "root": "part",
    "dutch": "het deel; de kant",
    "klass": 2,
    "declension": "3mixed",
    "explicitDeclensions": {
      "nomS": "pars",
      "accS": [
        "partem",
        "partim"
      ],
      "ablS": [
        "parte",
        "partī"
      ],
      "accP": [
        "partēs",
        "partīs"
      ]
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "soror": {
    "type": "noun",
    "root": "sorōr",
    "dutch": "de zus",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "soror"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "urbs": {
    "type": "noun",
    "root": "urb",
    "dutch": "de stad",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "urbs"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "uxor": {
    "type": "noun",
    "root": "uxōr",
    "dutch": "de echtgenote",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "uxor"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "vōx": {
    "type": "noun",
    "root": "vōc",
    "dutch": "de stem; het woord",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "vōx"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "flūmen": {
    "type": "noun",
    "root": "flūmin",
    "dutch": "de rivier",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "flūmen",
      "accS": "flūmen"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "lītus": {
    "type": "noun",
    "root": "lītor",
    "dutch": "de kust",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "lītus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "nōmen": {
    "type": "noun",
    "root": "nōmin",
    "dutch": "de naam",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "nōmen"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "tempus": {
    "type": "noun",
    "root": "tempor",
    "dutch": "de tijd; het moment",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "tempus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "fructus": {
    "type": "noun",
    "root": "fruct",
    "dutch": "de vrucht",
    "klass": 3,
    "declension": "4",
    "explicitDeclensions": {
      "nomS": "fructus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "rēs": {
    "type": "noun",
    "root": "r",
    "dutch": "de zaak",
    "klass": 3,
    "declension": "5",
    "explicitDeclensions": {
      "nomS": "rēs"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "domus": {
    "type": "noun",
    "root": "dom",
    "dutch": "het huis",
    "klass": 3,
    "declension": "4",
    "explicitDeclensions": {
      "nomS": "domus",
      "genS": [
        "domūs",
        "domī"
      ],
      "genP": [
        "domuum",
        "domōrum"
      ],
      "datS": [
        "domuī",
        "domō",
        "domū"
      ],
      "datP": [
        "domibus"
      ],
      "ablS": [
        "domū",
        "domō"
      ],
      "ablP": [
        "domibus"
      ],
      "vocS": [
        "domus"
      ],
      "vocP": [
        "domūs"
      ]
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "manus": {
    "type": "noun",
    "root": "man",
    "dutch": "de hand; de groep",
    "klass": 3,
    "declension": "4",
    "explicitDeclensions": {
      "nomS": "manus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "diēs": {
    "type": "noun",
    "root": "di",
    "dutch": "de dag",
    "klass": 3,
    "declension": "5",
    "explicitDeclensions": {
      "nomS": "diēs",
      "genS": "diēī",
      "datS": "diēī"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "flamma": {
    "type": "noun",
    "root": "flamm",
    "dutch": "de vlam",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "flamma"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "rēgīna": {
    "type": "noun",
    "root": "rēgīn",
    "dutch": "de koningin",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "rēgīna"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "grex": {
    "type": "noun",
    "root": "greg",
    "dutch": "de kudde",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "grex"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "pāstor": {
    "type": "noun",
    "root": "pāstōr",
    "dutch": "de herder",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "pāstor"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "vātēs": {
    "type": "noun",
    "root": "vāt",
    "dutch": "de waarzegger; de dichter",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "vātēs"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "famēs": {
    "type": "noun",
    "root": "fam",
    "dutch": "de honger",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "famēs"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "campus": {
    "type": "noun",
    "root": "camp",
    "dutch": "de vlakte",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "campus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "silva": {
    "type": "noun",
    "root": "silv",
    "dutch": "het bos",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "silva"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "studium": {
    "type": "noun",
    "root": "studi",
    "dutch": "de studie; de sympathie; de ijver",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "studium"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "discordia": {
    "type": "noun",
    "root": "discordi",
    "dutch": "de ruzie",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "discordia"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "fōrma": {
    "type": "noun",
    "root": "fōrm",
    "dutch": "de vorm; de schoonheid",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "fōrma"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "nuptiae": {
    "type": "noun",
    "root": "nupti",
    "dutch": "de bruiloft; het huwelijk",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomP": "nuptiae"
    },
    "hasSingular": false,
    "hasPlural": true,
    "sex": "f"
  },
  "iūdicium": {
    "type": "noun",
    "root": "iūdici",
    "dutch": "het oordeel; het proces",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "iūdicium"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "officium": {
    "type": "noun",
    "root": "offici",
    "dutch": "de taak; de verplichting",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "officium"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "fraus": {
    "type": "noun",
    "root": "fraud",
    "dutch": "het bedrog",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "fraus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "ōs": {
    "type": "noun",
    "root": "ōr",
    "dutch": "de mond; het gelaat",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "ōs"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "dolus": {
    "type": "noun",
    "root": "dol",
    "dutch": "de list",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "dolus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "ventus": {
    "type": "noun",
    "root": "vent",
    "dutch": "de wind",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "ventus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "āra": {
    "type": "noun",
    "root": "ār",
    "dutch": "het altaar",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "āra"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "grātia": {
    "type": "noun",
    "root": "grāti",
    "dutch": "de charme; het aanzien; de drank",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "grātia"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "ōrāculum": {
    "type": "noun",
    "root": "ōrācul",
    "dutch": "het orakel",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "ōrāculum"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "auctor": {
    "type": "noun",
    "root": "auctōr",
    "dutch": "de dader",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "auctor"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "sacerdōs": {
    "type": "noun",
    "root": "sacerdōt",
    "dutch": "de priester",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "sacerdōs"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "condiciō": {
    "type": "noun",
    "root": "condiciōn",
    "dutch": "de voorwaarde; de toestand",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "condiciō"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "equus": {
    "type": "noun",
    "root": "equ",
    "dutch": "het paard",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "equus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "aqua": {
    "type": "noun",
    "root": "aqu",
    "dutch": "het water",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "aqua"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "familia": {
    "type": "noun",
    "root": "famili",
    "dutch": "het gezin; het personeel",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "familia"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "via": {
    "type": "noun",
    "root": "vi",
    "dutch": "de weg",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "via"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "monumentum": {
    "type": "noun",
    "root": "monument",
    "dutch": "het monument; het aandenken",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "monumentum"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "senex": {
    "type": "noun",
    "root": "sen",
    "dutch": "de oude man",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "senex"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "virgō": {
    "type": "noun",
    "root": "virgin",
    "dutch": "de maagd; het meisje",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "virgō"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "sīdus": {
    "type": "noun",
    "root": "sīder",
    "dutch": "de ster; het sterrenbeeld",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "sīdus",
      "accS": "sīdus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "fēmina": {
    "type": "noun",
    "root": "fēmin",
    "dutch": "de vrouw",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "fēmina",
      "ablS": "fēminā"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "canis": {
    "type": "noun",
    "root": "can",
    "dutch": "de hond",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "canis",
      "genS": "canis"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "iuvenis": {
    "type": "noun",
    "root": "iuven",
    "dutch": "de jongeman",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "iuvenis",
      "genS": "iuvenis"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "mercātor": {
    "type": "noun",
    "root": "mercātor",
    "dutch": "de handelaar",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "mercātor"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "senātor": {
    "type": "noun",
    "root": "senātōr",
    "dutch": "de senator",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "senātor"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "caput": {
    "type": "noun",
    "root": "capit",
    "dutch": "het hoofd; het hoofdstuk",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "caput"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "cūria": {
    "type": "noun",
    "root": "cūri",
    "dutch": "de curia; het Romeinse senaatsgebouw",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "cūria",
      "ablS": "cūriā"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "fābula": {
    "type": "noun",
    "root": "fābul",
    "dutch": "het verhaal; het toneelstuk",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "fābula"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "lūna": {
    "type": "noun",
    "root": "lūn",
    "dutch": "de maan",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "lūna",
      "ablS": "lūnā"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "iūdex": {
    "type": "noun",
    "root": "iūdic",
    "dutch": "de rechter",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "iūdex"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "onus": {
    "type": "noun",
    "root": "oner",
    "dutch": "de last",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "onus",
      "accS": "onus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "statua": {
    "type": "noun",
    "root": "statu",
    "dutch": "het standbeeld",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "statua",
      "ablS": "statuā"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "eques": {
    "type": "noun",
    "root": "equit",
    "dutch": "de ruiter; de ridder",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "eques"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "theātrum": {
    "type": "noun",
    "root": "theātr",
    "dutch": "het theater",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "theātrum"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "pecūnia": {
    "type": "noun",
    "root": "pecūni",
    "dutch": "het geld",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "pecūnia"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "aedificium": {
    "type": "noun",
    "root": "aedifici",
    "dutch": "het gebouw",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "aedificium",
      "accS": "aedificium"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "spectāculum": {
    "type": "noun",
    "root": "spectācul",
    "dutch": "het schouwspel",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "spectāculum",
      "accS": "spectāculum"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "fīnis": {
    "type": "noun",
    "root": "fīn",
    "dutch": "de grens; het einde; het doel",
    "klass": 2,
    "declension": "3mixed",
    "explicitDeclensions": {
      "nomS": "fīnis",
      "genS": "fīnis",
      "accP": [
        "fīnēs",
        "fīnīs"
      ]
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "iter": {
    "type": "noun",
    "root": "itiner",
    "dutch": "de reis; de weg",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "iter",
      "accS": "iter"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "mūrus": {
    "type": "noun",
    "root": "mūr",
    "dutch": "de muur",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "mūrus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "cūra": {
    "type": "noun",
    "root": "cūr",
    "dutch": "de zorg",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "cūra"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "tergum": {
    "type": "noun",
    "root": "terg",
    "dutch": "de rug",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "tergum",
      "accS": "tergum"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "prīnceps": {
    "type": "noun",
    "root": "prīncip",
    "dutch": "de voornaamste; de keizer",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "prīnceps"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "annus": {
    "type": "noun",
    "root": "ann",
    "dutch": "het jaar",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "annus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "hortus": {
    "type": "noun",
    "root": "hort",
    "dutch": "de tuin",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "hortus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "taurus": {
    "type": "noun",
    "root": "taur",
    "dutch": "de stier",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "taurus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "rēgia": {
    "type": "noun",
    "root": "rēgi",
    "dutch": "het paleis",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "rēgia",
      "ablS": "rēgiā"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "caelum": {
    "type": "noun",
    "root": "cael",
    "dutch": "de hemel",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "caelum",
      "accS": "caelum"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "mōnstrum": {
    "type": "noun",
    "root": "mōnstr",
    "dutch": "het wonder; het monster",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "mōnstrum",
      "accS": "mōnstrum"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "perīculum": {
    "type": "noun",
    "root": "perīcul",
    "dutch": "het gevaar",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "perīculum"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "rēx": {
    "type": "noun",
    "root": "rēg",
    "dutch": "de koning",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "rēx"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "capillus": {
    "type": "noun",
    "root": "capill",
    "dutch": "het haar",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "capillus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "porta": {
    "type": "noun",
    "root": "port",
    "dutch": "de poort",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "porta",
      "ablS": "portā"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "prōvincia": {
    "type": "noun",
    "root": "prōvinci",
    "dutch": "de provincie",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "prōvincia"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "fōns": {
    "type": "noun",
    "root": "fōnt",
    "dutch": "de bron",
    "klass": 2,
    "declension": "3mixed",
    "explicitDeclensions": {
      "nomS": "fōns"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "pēs": {
    "type": "noun",
    "root": "ped",
    "dutch": "de voet (als lengtemaat: 30cm)",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "pēs"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "nūntius": {
    "type": "noun",
    "root": "nūnti",
    "dutch": "de bode; het bericht",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "nūntius"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "sapientia": {
    "type": "noun",
    "root": "sapienti",
    "dutch": "de wijsheid",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "sapientia"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "unda": {
    "type": "noun",
    "root": "und",
    "dutch": "de golf",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "unda"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "bellum": {
    "type": "noun",
    "root": "bell",
    "dutch": "de oorlog",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "bellum"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "amor": {
    "type": "noun",
    "root": "amōr",
    "dutch": "de liefde",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "amor"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "ignis": {
    "type": "noun",
    "root": "ign",
    "dutch": "het vuur",
    "klass": 2,
    "declension": "3mixed",
    "explicitDeclensions": {
      "nomS": "ignis",
      "ablS": [
        "igne",
        "ignī"
      ],
      "accP": [
        "ignēs",
        "ignīs"
      ]
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "Iuppiter": {
    "type": "noun",
    "root": "Iov",
    "dutch": "Jupiter",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "Iuppiter"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "lūx": {
    "type": "noun",
    "root": "lūc",
    "dutch": "het licht",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "lūx"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "Venus": {
    "type": "noun",
    "root": "Vener",
    "dutch": "Venus",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "Venus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "scelus": {
    "type": "noun",
    "root": "sceler",
    "dutch": "de misdaad",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "scelus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "umbra": {
    "type": "noun",
    "root": "umbr",
    "dutch": "de schaduw; de schim",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "umbra"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "gladius": {
    "type": "noun",
    "root": "gladi",
    "dutch": "het zwaard",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "gladius"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "hasta": {
    "type": "noun",
    "root": "hast",
    "dutch": "de lans",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "hasta"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "sagitta": {
    "type": "noun",
    "root": "sagitt",
    "dutch": "de pijl",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "sagitta"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "comes": {
    "type": "noun",
    "root": "comit",
    "dutch": "de kameraad",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "comes"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "lapis": {
    "type": "noun",
    "root": "lapid",
    "dutch": "de steen",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "lapis"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "arma": {
    "type": "noun",
    "root": "arm",
    "dutch": "de wapens",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomP": "arma"
    },
    "hasSingular": false,
    "hasPlural": true,
    "sex": "n"
  },
  "parentēs": {
    "type": "noun",
    "root": "parent",
    "dutch": "de ouders",
    "klass": 2,
    "declension": "3mixed",
    "explicitDeclensions": {
      "nomP": "parentēs"
    },
    "hasSingular": false,
    "hasPlural": true,
    "sex": "m"
  },
  "leō": {
    "type": "noun",
    "root": "leōn",
    "dutch": "de leeuw",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "leō"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "lēgātus": {
    "type": "noun",
    "root": "lēgāt",
    "dutch": "de gezant; de onderbevelhebber",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "lēgātus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "lūdus": {
    "type": "noun",
    "root": "lūd",
    "dutch": "het spel; de school",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "lūdus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "imperātor": {
    "type": "noun",
    "root": "imperātōr",
    "dutch": "de opperbevelhebber",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "imperātor"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "sōl": {
    "type": "noun",
    "root": "sōl",
    "dutch": "de zon",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "sōl"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "legiō": {
    "type": "noun",
    "root": "legiōn",
    "dutch": "het legioen",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "legiō"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "pāx": {
    "type": "noun",
    "root": "pāc",
    "dutch": "de vrede",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "pāx"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "iūs": {
    "type": "noun",
    "root": "iūr",
    "dutch": "het recht",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "iūs"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "mūnus": {
    "type": "noun",
    "root": "mūner",
    "dutch": "de taak; het geschenk",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "mūnus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "līberī": {
    "type": "noun",
    "root": "līber",
    "dutch": "de kinderen",
    "klass": "1r",
    "declension": "2r",
    "explicitDeclensions": {
      "nomP": "līberī"
    },
    "hasSingular": false,
    "hasPlural": true,
    "sex": "m"
  },
  "vir": {
    "type": "noun",
    "root": "vir",
    "dutch": "de man",
    "klass": "1r",
    "declension": "2r",
    "explicitDeclensions": {
      "nomS": "vir"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "ager": {
    "type": "noun",
    "root": "agr",
    "dutch": "het veld",
    "klass": "1er",
    "declension": "2er",
    "explicitDeclensions": {
      "nomS": "ager"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "liber": {
    "type": "noun",
    "root": "libr",
    "dutch": "het boek",
    "klass": "1er",
    "declension": "2er",
    "explicitDeclensions": {
      "nomS": "liber"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "magister": {
    "type": "noun",
    "root": "magistr",
    "dutch": "de meester",
    "klass": "1er",
    "declension": "2er",
    "explicitDeclensions": {
      "nomS": "magister"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "cibus": {
    "type": "noun",
    "root": "cib",
    "dutch": "het voedsel",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "cibus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "glōria": {
    "type": "noun",
    "root": "glōri",
    "dutch": "de roem",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "glōria"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "victōria": {
    "type": "noun",
    "root": "victōr",
    "dutch": "de overwinning",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "victōria"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "beneficium": {
    "type": "noun",
    "root": "benefici",
    "dutch": "de weldaad",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "beneficium"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "praemium": {
    "type": "noun",
    "root": "praemi",
    "dutch": "de beloning",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "praemium"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "oculus": {
    "type": "noun",
    "root": "ocul",
    "dutch": "het oog",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "oculus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "auxilium": {
    "type": "noun",
    "root": "auxili",
    "dutch": "de hulp",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "auxilium"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "bracchium": {
    "type": "noun",
    "root": "bracchi",
    "dutch": "de arm",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "bracchium"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "verbum": {
    "type": "noun",
    "root": "verb",
    "dutch": "het woord",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "verbum"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "auris": {
    "type": "noun",
    "root": "aur",
    "dutch": "het oor",
    "klass": 2,
    "declension": "3mixed",
    "explicitDeclensions": {
      "nomS": "auris"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "rāmus": {
    "type": "noun",
    "root": "rām",
    "dutch": "de tak",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "rāmus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "causa": {
    "type": "noun",
    "root": "caus",
    "dutch": "de oorzaak; de reden; het proces",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "causa"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "cēna": {
    "type": "noun",
    "root": "cēn",
    "dutch": "het avondmaal",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "cēna"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "hōra": {
    "type": "noun",
    "root": "hōr",
    "dutch": "het uur",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "hōra"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "vīlla": {
    "type": "noun",
    "root": "vīll",
    "dutch": "de villa; het landgoed",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "vīlla"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "cōnsilium": {
    "type": "noun",
    "root": "cōnsili",
    "dutch": "het overleg; de raad; het plan",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "cōnsilium"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "pretium": {
    "type": "noun",
    "root": "preti",
    "dutch": "de prijs",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "pretium"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "flōs": {
    "type": "noun",
    "root": "flōr",
    "dutch": "de bloem",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "flōs"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "arbor": {
    "type": "noun",
    "root": "arbor",
    "dutch": "de boom",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "arbor"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "mēnsa": {
    "type": "noun",
    "root": "mēns",
    "dutch": "de tafel",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "mēnsa"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "nāvis": {
    "type": "noun",
    "root": "nāv",
    "dutch": "het schip",
    "klass": 2,
    "declension": "3mixed",
    "explicitDeclensions": {
      "nomS": "nāvis"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "hospes": {
    "type": "noun",
    "root": "hospit",
    "dutch": "de gast; de gastheer",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "hospes"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "pōns": {
    "type": "noun",
    "root": "pont",
    "dutch": "de brug",
    "klass": 2,
    "declension": "3mixed",
    "explicitDeclensions": {
      "nomS": "pōns"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "aestās": {
    "type": "noun",
    "root": "aestāt",
    "dutch": "de zomer",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "aestās"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "īra": {
    "type": "noun",
    "root": "īr",
    "dutch": "de woede",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "īra"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "poena": {
    "type": "noun",
    "root": "poen",
    "dutch": "de boete; de straf",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "poena"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "audācia": {
    "type": "noun",
    "root": "audāci",
    "dutch": "de moed",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "audācia"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "cōnstantia": {
    "type": "noun",
    "root": "cōnstanti",
    "dutch": "de volharding",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "cōnstantia"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "silentium": {
    "type": "noun",
    "root": "silenti",
    "dutch": "de stilte",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "silentium"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "hiems": {
    "type": "noun",
    "root": "hiem",
    "dutch": "de winter",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "hiems"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "virtūs": {
    "type": "noun",
    "root": "virtūt",
    "dutch": "de kwaliteit; de dapperheid",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "virtūs"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "saxum": {
    "type": "noun",
    "root": "sax",
    "dutch": "het rotsblok",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "saxum"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "celeritās": {
    "type": "noun",
    "root": "celeritāt",
    "dutch": "de snelheid",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "celeritās"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "cōpia": {
    "type": "noun",
    "root": "cōpi",
    "dutch": "de hoeveelheid; de gelegenheid",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "cōpia"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "sermō": {
    "type": "noun",
    "root": "sermōn",
    "dutch": "het gesprek; het taalgebruik",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "sermō"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "voluntās": {
    "type": "noun",
    "root": "voluntāt",
    "dutch": "de wil",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "voluntās"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "praeda": {
    "type": "noun",
    "root": "praed",
    "dutch": "de buit; de prooi",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "praeda"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "minister": {
    "type": "noun",
    "root": "ministr",
    "dutch": "de dienaar",
    "klass": "1er",
    "declension": "2er",
    "explicitDeclensions": {
      "nomS": "minister"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "fortūna": {
    "type": "noun",
    "root": "fortūn",
    "dutch": "het lot; de toestand",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "fortūna"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "ars": {
    "type": "noun",
    "root": "art",
    "dutch": "het vakmanschap; de kunst",
    "klass": 2,
    "declension": "3mixed",
    "explicitDeclensions": {
      "nomS": "ars"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "asinus": {
    "type": "noun",
    "root": "asin",
    "dutch": "de ezel",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "asinus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "aurum": {
    "type": "noun",
    "root": "aur",
    "dutch": "het goud",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "aurum"
    },
    "hasSingular": true,
    "hasPlural": false,
    "sex": "n"
  },
  "collis": {
    "type": "noun",
    "root": "coll",
    "dutch": "de heuvel",
    "klass": 2,
    "declension": "3mixed",
    "explicitDeclensions": {
      "nomS": "collis"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "lībertās": {
    "type": "noun",
    "root": "lībertāt",
    "dutch": "de vrijheid",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "lībertās"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "vestis": {
    "type": "noun",
    "root": "vest",
    "dutch": "de kleding",
    "klass": 2,
    "declension": "3mixed",
    "explicitDeclensions": {
      "nomS": "vestis"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "animal": {
    "type": "noun",
    "root": "animāl",
    "dutch": "het levend wezen; het dier",
    "klass": 2,
    "declension": "3mixed",
    "explicitDeclensions": {
      "nomS": "animal"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "calor": {
    "type": "noun",
    "root": "calōr",
    "dutch": "de warmte",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "calor"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "carmen": {
    "type": "noun",
    "root": "carmin",
    "dutch": "het lied; het gedicht",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "carmen"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "frīgus": {
    "type": "noun",
    "root": "frīgor",
    "dutch": "de koude",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "frīgus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "mare": {
    "type": "noun",
    "root": "mar",
    "dutch": "de zee",
    "klass": 2,
    "declension": "3mixed",
    "explicitDeclensions": {
      "nomS": "mare"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "moenia": {
    "type": "noun",
    "root": "moen",
    "dutch": "de stadsmuren",
    "klass": 2,
    "declension": "3mixed",
    "explicitDeclensions": {
      "nomP": "moenia"
    },
    "hasSingular": false,
    "hasPlural": true,
    "sex": "n"
  },
  "āla": {
    "type": "noun",
    "root": "āl",
    "dutch": "de vleugel",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "āla"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "cōpiae": {
    "type": "noun",
    "root": "cōpi",
    "dutch": "de troepen",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomP": "cōpiae"
    },
    "hasSingular": false,
    "hasPlural": true,
    "sex": "f"
  },
  "custōs": {
    "type": "noun",
    "root": "custōd",
    "dutch": "de bewaker",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "custōs"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "arx": {
    "type": "noun",
    "root": "arc",
    "dutch": "de burcht",
    "klass": 2,
    "declension": "3mixed",
    "explicitDeclensions": {
      "nomS": "arx"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "animus": {
    "type": "noun",
    "root": "anim",
    "dutch": "de geest; het gmoed; de moed",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "animus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "medicus": {
    "type": "noun",
    "root": "medic",
    "dutch": "de arts",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "medicus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "fera": {
    "type": "noun",
    "root": "fer",
    "dutch": "het wilde dier",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "fera"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "fuga": {
    "type": "noun",
    "root": "fug",
    "dutch": "de vlucht",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "fuga"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "lingua": {
    "type": "noun",
    "root": "lingu",
    "dutch": "de tong; de taal",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "lingua"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "vulnus": {
    "type": "noun",
    "root": "vulner",
    "dutch": "de wonde",
    "klass": 2,
    "declension": "3mixed",
    "explicitDeclensions": {
      "nomS": "vulnus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "exemplum": {
    "type": "noun",
    "root": "exempl",
    "dutch": "het voorbeeld",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "exemplum"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "tempestās": {
    "type": "noun",
    "root": "tempestāt",
    "dutch": "de tijd; de storm",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "tempestās"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "digitus": {
    "type": "noun",
    "root": "digit",
    "dutch": "de vinger",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "digitus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "dextra": {
    "type": "noun",
    "root": "dextr",
    "dutch": "de rechterhand",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "dextra"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "scūtum": {
    "type": "noun",
    "root": "scūt",
    "dutch": "het schild",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "scūtum"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "pellis": {
    "type": "noun",
    "root": "pell",
    "dutch": "de huid",
    "klass": 2,
    "declension": "3mixed",
    "explicitDeclensions": {
      "nomS": "pellis"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "regiō": {
    "type": "noun",
    "root": "regiōn",
    "dutch": "de streek",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "regiō"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "pecus": {
    "type": "noun",
    "root": "pecor",
    "dutch": "het vee",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "pecus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "victor": {
    "type": "noun",
    "root": "victōr",
    "dutch": "de overwinnaar",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "victor"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "initium": {
    "type": "noun",
    "root": "initi",
    "dutch": "het begin",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "initium"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "rēgnum": {
    "type": "noun",
    "root": "rēgn",
    "dutch": "het koninkrijk; de heerschappij",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "rēgnum"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "signum": {
    "type": "noun",
    "root": "sign",
    "dutch": "het teken",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "signum"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "gēns": {
    "type": "noun",
    "root": "gent",
    "dutch": "het geslacht; de volksstam",
    "klass": 2,
    "declension": "3mixed",
    "explicitDeclensions": {
      "nomS": "gēns"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "sēdēs": {
    "type": "noun",
    "root": "sēd",
    "dutch": "de zitplaats; de verblijfplaats",
    "klass": 2,
    "declension": "3mixed",
    "explicitDeclensions": {
      "nomS": "sēdēs"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "certāmen": {
    "type": "noun",
    "root": "certāmin",
    "dutch": "de wedstrijd",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "certāmen"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "pectus": {
    "type": "noun",
    "root": "pector",
    "dutch": "de borst; het hart; het verstand",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "pectus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "currus": {
    "type": "noun",
    "root": "curr",
    "dutch": "de renwagen",
    "klass": 3,
    "declension": "4",
    "explicitDeclensions": {
      "nomS": "currus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "cursus": {
    "type": "noun",
    "root": "curs",
    "dutch": "de loop; de koers",
    "klass": 3,
    "declension": "4",
    "explicitDeclensions": {
      "nomS": "cursus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "metus": {
    "type": "noun",
    "root": "met",
    "dutch": "de vrees",
    "klass": 3,
    "declension": "4",
    "explicitDeclensions": {
      "nomS": "metus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "fidēs": {
    "type": "noun",
    "root": "fid",
    "dutch": "de trouw; het vertrouwen; het gegeven woord",
    "klass": 3,
    "declension": "5",
    "explicitDeclensions": {
      "nomS": "fidēs"
    },
    "hasSingular": true,
    "hasPlural": false,
    "sex": "f"
  },
  "spēs": {
    "type": "noun",
    "root": "sp",
    "dutch": "de hoop",
    "klass": 3,
    "declension": "5",
    "explicitDeclensions": {
      "nomS": "spēs"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "cāsus": {
    "type": "noun",
    "root": "cās",
    "dutch": "de val; het voorval; het toeval",
    "klass": 3,
    "declension": "4",
    "explicitDeclensions": {
      "nomS": "cāsus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "ēventus": {
    "type": "noun",
    "root": "ēvent",
    "dutch": "de afloop",
    "klass": 3,
    "declension": "4",
    "explicitDeclensions": {
      "nomS": "ēventus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "exercitus": {
    "type": "noun",
    "root": "exercit",
    "dutch": "het leger",
    "klass": 3,
    "declension": "4",
    "explicitDeclensions": {
      "nomS": "exercitus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "magistrātus": {
    "type": "noun",
    "root": "magistrāt",
    "dutch": "het ambt; de magistraat",
    "klass": 3,
    "declension": "4",
    "explicitDeclensions": {
      "nomS": "magistrātus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "passus": {
    "type": "noun",
    "root": "pass",
    "dutch": "de pas (als lengtemaat: 1,5 m)",
    "klass": 3,
    "declension": "4",
    "explicitDeclensions": {
      "nomS": "passus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "senātus": {
    "type": "noun",
    "root": "senāt",
    "dutch": "de senaat",
    "klass": 3,
    "declension": "4",
    "explicitDeclensions": {
      "nomS": "senātus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "tumultus": {
    "type": "noun",
    "root": "tumult",
    "dutch": "het tumult",
    "klass": 3,
    "declension": "4",
    "explicitDeclensions": {
      "nomS": "tumultus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "vultus": {
    "type": "noun",
    "root": "vult",
    "dutch": "het gezicht",
    "klass": 3,
    "declension": "4",
    "explicitDeclensions": {
      "nomS": "vultus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "faciēs": {
    "type": "noun",
    "root": "faci",
    "dutch": "het gezicht; het uitzicht",
    "klass": 3,
    "declension": "5",
    "explicitDeclensions": {
      "nomS": "faciēs",
      "genS": "faciēī",
      "datS": "faciēī"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "īnferī": {
    "type": "noun",
    "root": "īnfer",
    "dutch": "de onderwereld",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomP": "īnferī"
    },
    "hasSingular": false,
    "hasPlural": true,
    "sex": "n"
  },
  "marītus": {
    "type": "noun",
    "root": "marīt",
    "dutch": "de echtgenoot",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "marītus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "morbus": {
    "type": "noun",
    "root": "morb",
    "dutch": "de ziekte",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "morbus"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "mēnsis": {
    "type": "noun",
    "root": "mēns",
    "dutch": "de maand",
    "klass": 2,
    "declension": "3mixed",
    "explicitDeclensions": {
      "nomS": "mēnsis"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "aetās": {
    "type": "noun",
    "root": "aetāt",
    "dutch": "de leeftijd; de periode",
    "klass": 2,
    "declension": "3cons",
    "explicitDeclensions": {
      "nomS": "aetās"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "somnium": {
    "type": "noun",
    "root": "somni",
    "dutch": "de droom",
    "klass": 1,
    "declension": "2",
    "explicitDeclensions": {
      "nomS": "somnium"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "n"
  },
  "imber": {
    "type": "noun",
    "root": "imbr",
    "dutch": "de regenbui",
    "klass": 2,
    "declension": "3mixed",
    "explicitDeclensions": {
      "nomS": "imber",
      "genP": "imbrium"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  "epistula": {
    "type": "noun",
    "root": "epistul",
    "dutch": "de brief",
    "klass": 1,
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "epistula"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "avis": {
    "type": "noun",
    "root": "av",
    "dutch": "de vogel",
    "klass": 2,
    "declension": "3mixed",
    "explicitDeclensions": {
      "nomS": "avis"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  "patria": {
    type: "noun",
    root: "patri",
    dutch: "het vaderland",
    klass: 1,
    declension: "1",
    explicitDeclensions: {
      nomS: "patria",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "f",
  },
  "membrum": {
    type: "noun",
    root: "membr",
    dutch: "het lid",
    klass: 1,
    declension: "2",
    explicitDeclensions: {
      nomS: "membrum",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "n",
  },
  "socius": {
    type: "noun",
    root: "soci",
    dutch: "de bondgenoot; de deelgenoot (m)",
    klass: 1,
    declension: "2",
    explicitDeclensions: {
      nomS: "socius",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "m",
  },
  "socia": {
    type: "noun",
    root: "soci",
    dutch: "de bondgenoot; de deelgenoot (v)",
    klass: 1,
    declension: "1",
    explicitDeclensions: {
      nomS: "socia",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "f",
  },
  "socium": {
    type: "noun",
    root: "soci",
    dutch: "de bondgenoot; de deelgenoot (n)",
    klass: 1,
    declension: "2",
    explicitDeclensions: {
      nomS: "socium",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "n",
  },
  "classis": {
    type: "noun",
    root: "class",
    dutch: "de vloot",
    klass: 2,
    declension: "3mixed",
    explicitDeclensions: {
      nomS: "classis",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "f",
  },
  "vīs": {
    type: "noun",
    root: "vīr",
    dutch: "de kracht; het geweld",
    klass: 2,
    declension: "3irr",
    explicitDeclensions: {
      nomS: "vīs",
      nomP: "vīrēs",
      genS: "vīs",
      genP: "vīrium",
      datS: "vī",
      datP: "vīribus",
      accS: "vim",
      accP: "vīrēs",
      ablS: "vī",
      ablP: "vīribus",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "f",
  },
  "invidia": {
    type: "noun",
    root: "invidi",
    dutch: "de afgunst",
    klass: 1,
    declension: "1",
    explicitDeclensions: {
      nomS: "invidia",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "f",
  },
  "rēgina": {
    type: "noun",
    root: "regin",
    dutch: "de koningin",
    klass: 1,
    declension: "1",
    explicitDeclensions: {
      nomS: "rēgina",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "f",
  },
  "numerus": {
    type: "noun",
    root: "numer",
    dutch: "het getal; het aantal",
    klass: 1,
    declension: "2",
    explicitDeclensions: {
      nomS: "numerus",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "m",
  },
  "fātum": {
    type: "noun",
    root: "fāt",
    dutch: "het lot",
    klass: 1,
    declension: "2",
    explicitDeclensions: {
      nomS: "fātum",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "n",
  },
  "coniūnx": {
    type: "noun",
    root: "coniug",
    dutch: "de echtgenoot; de echtgenote (M + V)",
    klass: 2,
    declension: "3cons",
    explicitDeclensions: {
      nomS: "coniūnx",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "m",
  },
  "salūs": {
    type: "noun",
    root: "salūt",
    dutch: "het welzijn; de redding",
    klass: 2,
    declension: "3cons",
    explicitDeclensions: {
      nomS: "salūs",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "f",
  },
  "imperium": {
    type: "noun",
    root: "imperi",
    dutch: "de heerschappij; het opperbevel; het rijk",
    klass: 1,
    declension: "2",
    explicitDeclensions: {
      nomS: "imperium",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "n",
  },
  // "": {
  //   type: "noun",
  //   root: "",
  //   dutch: "",
  //   klass: ,
  //   declension: "",
  //   explicitDeclensions: {
  //     nomS: "",
  //   },
  //   hasSingular: true,
  //   hasPlural: true,
  //   sex: "",
  // },
}

export function searchLatinNoun(key: keyof typeof LATIN_NOUNS): LatinNoun {
  if (!(key in LATIN_NOUNS)) {
    throw new Error(`No Latin noun found for key: ${key}`);
  }
  return LATIN_NOUNS[key];
}