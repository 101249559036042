import { searchWord } from "../WordBase";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export interface GreekAdverb {
  type: "adverb";  // "bijwoord"
  ground: string;
  dutch: string;
}

export const GREEK_ADVERBS: GreekAdverb[] = [
  {
    type: "adverb",
    ground: "τί",
    dutch: "waarom?",
  },
  {
    type: "adverb",
    ground: "τότε",
    dutch: "dan, toen",
  },
  {
    type: "adverb",
    ground: "καί",
    dutch: "ook, zelfs",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B5%E1%BD%96
    type: "adverb",
    ground: "εὖ",
    dutch: "goed",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BD%E1%BF%A6%CE%BD
    type: "adverb",
    ground: "νῦν",
    dutch: "nu",
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%90%CE%BA%CE%B5%E1%BF%96
    type: "adverb",
    ground: "ἐκεῖ",
    dutch: "daar, daarheen",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BC%CE%AC%CE%BB%CE%B9%CF%83%CF%84%CE%B1
    type: "adverb",
    ground: "μᾰ́λῐστᾰ",
    dutch: "zeer, vooral, het meest",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B1%E1%BD%90%CF%84%CE%AF%CE%BA%CE%B1
    type: "adverb",
    ground: "αὐτίκα",
    dutch: "onmiddellijk",
  },
  {
    // https://en.wiktionary.org/wiki/%CF%80%CE%BF%CE%BB%CF%8D
    type: "adverb",
    ground: "πολῠ́",
    dutch: "veel, zeer",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BC%CE%AC%CE%BB%CE%B1
    type: "adverb",
    ground: "μάλα",
    dutch: "zeer",
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%94%CE%BD%CE%B8%CE%B1
    type: "adverb",
    ground: "ἔνθα",
    dutch: "daar (aanw.), waar",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BF%E1%BD%90%CE%BA%CE%AD%CF%84%CE%B9
    type: "adverb",
    ground: "οὐκέτι",
    dutch: "niet meer",
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%80%CE%B5%CE%AF
    type: "adverb",
    ground: "ἀεί",
    dutch: "altijd",
  },
  {
    // https://en.wiktionary.org/wiki/%CF%80%CE%BF%E1%BF%A6
    type: "adverb",
    ground: "ποῦ",
    dutch: "waar?",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B5%E1%BC%B0%CE%BA%CF%8C%CF%84%CF%89%CF%82
    type: "adverb",
    ground: "εἰκότως",
    dutch: "natuurlijk",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BF%E1%BD%94%CF%80%CE%BF%CF%84%CE%B5
    type: "adverb",
    ground: "οὔποτε",
    dutch: "nooit",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BF%E1%BD%90%CE%B4%CE%AD%CF%80%CE%BF%CF%84%CE%B5
    type: "adverb",
    ground: "οὐδέποτε",
    dutch: "nooit",
  },
  {
    // https://en.wiktionary.org/wiki/%CF%80%CE%BF%CE%BB%CE%BB%CE%AC%CE%BA%CE%B9%CF%82
    type: "adverb",
    ground: "πολλάκις",
    dutch: "vaak, dikwijls",
  },
  // {
  //   type: "adverb",
  //   ground: "",
  //   dutch: "",
  // },
];

export const searchGreekAdverb = searchWord(GREEK_ADVERBS, "Greek adverb", "ground");