import { LATIN_2024_PEGASUS_NOVUS_2_VOCABULARY } from "../data/latin/2024/PegasusNovus2";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export function parseNumberOrRange(range: string): number[] {
  const trimmed = range.trim();
  const parts = trimmed.split("-").map(part => parseInt(part.trim(), 10));

  // Handle the special case of a single number.
  if (parts.length === 1) {
    const num = parts[0];
    return isNaN(num) ? [] : [ num - 1 ];
  }

  // For the rest, we expect a range, so we perform some sanity checks.
  if (
    (parts.length !== 2) ||
    (isNaN(parts[0])) ||
    (isNaN(parts[1])) ||
    (parts[0] <= 0) ||
    (parts[1] <= 0) ||
    (parts[0] > parts[1])
  ) {
    return [];
  }

  const from = parts[0] - 1;
  const to = parts[1] - 1;
  const length = to - from + 1;
  return Array.from({ length }, (_, i) => i + from);
}

export function fromCsr(csr: string, max: number = LATIN_2024_PEGASUS_NOVUS_2_VOCABULARY.vocabulary.length): number[] {
  const trimmed = csr.trim();

  // Case without range.
  if (trimmed === "") {
    return Array.from({ length: max }, (_, i) => i + 1);
  }

  const segments: number[][] = trimmed.split(",").map(parseNumberOrRange);
  return Array
    .from(new Set(segments.flatMap(subArray => subArray)))
    .sort((a, b) => a - b);
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export function toCsr(arr: number[]): string {
  const sortedArr = arr.map(i => i + 1).sort((a, b) => a - b);
  let csr = "";
  for (let i = 0; i < sortedArr.length; i++) {
    const start = sortedArr[i];
    while ((i + 1 < sortedArr.length) && (sortedArr[i + 1] === sortedArr[i] + 1)) {
      i++;
    }
    const end = sortedArr[i];
    if (start !== end) {
      csr += start + "-" + end;
    } else {
      csr += start;
    }
    if (i < sortedArr.length - 1) {
      csr += ",";
    }
  }
  return csr;
}