import { isRouteErrorResponse, useRouteError } from "react-router-dom";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      {
        isRouteErrorResponse(error) ?
          <p>{error.status} {error.statusText}</p> :
          <p>Unknown error.</p>
      }
    </div>
  );
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export default ErrorPage;