import { LatinPluralAdjective, toDutchTitle } from "../../data/latin/LatinWord";
import "../WordFull.scss";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface LatinPluralAdjectiveFullProps {
  word: LatinPluralAdjective;
}

function LatinPluralAdjectiveFull({ word }: LatinPluralAdjectiveFullProps) {
  const { latin, root } = word;
  return (
    <div className="word-full">
      <div className="word-full-title">
        {latin}, {root + "ae"}, {root + "a"}
      </div>
      <div className="word-full-translation">
        {toDutchTitle(word)}
      </div>
    </div>
  );
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export default LatinPluralAdjectiveFull;