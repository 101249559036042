import { LatinDemonstrativePronoun, LatinIndefinitePronoun, LatinPersonalPronoun, LatinPossessivePronoun } from "../LatinWord";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const searchLatinPronoun =
  <T extends { latin: string }>(type: string, pronouns: Array<T>) => (latin: string): T => {
  const results = pronouns.filter((pronoun) => pronoun.latin === latin);
  if (results.length !== 1) {
    console.error(results);
    throw new Error(`No single Latin ${type} pronoun found for: ${latin}`);
  }
  return results[0];
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const LATIN_PERSONAL_PRONOUNS: Array<LatinPersonalPronoun> = [
  {
    type: "personalPronoun",
    latin: "ego",
    dutch: "ik",
  }, {
    type: "personalPronoun",
    latin: "tū",
    dutch: "jij",
  }, {
    type: "personalPronoun",
    latin: "sē",
    dutch: "zich",
  }, {
    type: "personalPronoun",
    latin: "is, ea, id",
    dutch: "hij; zij; het",
  }, {
    type: "personalPronoun",
    latin: "nōs",
    dutch: "wij",
  }, {
    type: "personalPronoun",
    latin: "vōs",
    dutch: "jullie",
  }, {
    type: "personalPronoun",
    latin: "iī, eae, ea",
    dutch: "zij",
  },
];

export const searchLatinPersonalPronoun = searchLatinPronoun("personal", LATIN_PERSONAL_PRONOUNS);

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const LATIN_DEMONSTRATIVE_PRONOUNS: Array<LatinDemonstrativePronoun> = [
  {
    type: "demonstrativePronoun",
    latin: "is, ea, id",
    dutch: "die; dat",
  }, {
    type: "demonstrativePronoun",
    latin: "hic, haec, hoc; huius",
    dutch: "deze, dit",
  }, {
    type: "demonstrativePronoun",
    latin: "iste, ista, istud; istīus",
    dutch: "die, dat",
  }, {
    type: "demonstrativePronoun",
    latin: "ille, illa, illud; illīus",
    dutch: "die, dat; hij, zij",
  }, {
    type: "demonstrativePronoun",
    latin: "īdem, eadem, idem; eiusdem",
    dutch: "dezelfde; hetzelfde",
  }, {
    type: "demonstrativePronoun",
    latin: "ipse, ipsa, ipsum; ipsīus",
    dutch: "zelf; precies",
  },
  {
    // https://latin.cactus2000.de/pronom/showpronom_en.php?n=alius&form=alius
    type: "demonstrativePronoun",
    latin: "alius, alia, aliud",
    dutch: "een andere",
  },
  {
    // https://latin.cactus2000.de/pronom/showpronom_en.php?n=alter&form=alter
    type: "demonstrativePronoun",
    latin: "alter, altera, alterum",
    dutch: "de andere",
  },
  // {
  //   type: "demonstrativePronoun",
  //   latin: "",
  //   dutch: "",
  // },
];

export const searchLatinDemonstrativePronoun = searchLatinPronoun("demonstrative", LATIN_DEMONSTRATIVE_PRONOUNS);

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const LATIN_POSSESSIVE_PRONOUNS: Array<LatinPossessivePronoun> = [
  {
    type: "possessivePronoun",
    latin: "meus, ~a, ~um",
    dutch: "mijn",
  }, {
    type: "possessivePronoun",
    latin: "tuus, ~a, ~um",
    dutch: "jouw",
  }, {
    type: "possessivePronoun",
    latin: "suus, ~a, ~um",
    dutch: "zijn, haar, hun (eigen)",
  }, {
    type: "possessivePronoun",
    latin: "noster, nostra, nostrum",
    dutch: "ons; onze",
  }, {
    type: "possessivePronoun",
    latin: "vester, vestra, vestrum",
    dutch: "jullie",
  },
];

export const searchLatinPossessivePronoun = searchLatinPronoun("possessive", LATIN_POSSESSIVE_PRONOUNS);


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const LATIN_INDEFINITE_PRONOUNS: Array<LatinIndefinitePronoun> = [
  {
    type: "indefinitePronoun",
    latin: "nēmō",
    dutch: "niemand",
    sex: "m",
  }, {
    type: "indefinitePronoun",
    latin: "nihil",
    dutch: "niets",
    sex: "n",
  },
];

export const searchLatinIndefinitePronoun = searchLatinPronoun("indefinite", LATIN_INDEFINITE_PRONOUNS);