import { searchWord } from "../WordBase";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export interface GreekSuffix {
  type: "suffix";  // "achtervoegsel"
  ground: string;
  dutch: string;
}

export const GREEK_SUFFIXES: GreekSuffix[] = [
  {
    type: "suffix",
    ground: "-περ",
    dutch: "precies ..., juist ...",
  },
  // {
  //   type: "suffix",
  //   ground: "",
  //   dutch: "",
  // },
];

export const searchGreekSuffix = searchWord(GREEK_SUFFIXES, "Greek suffix", "ground");