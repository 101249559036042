import _ from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GreekDefaultFull from "../components/greek/GreekDefaultFull.tsx";
import { GreekWord, toDutchType } from "../data/greek/GreekWord.tsx";
import { assertNever } from "../utils/assertNever.tsx";
import "./Practice.scss";
import { Gr } from "../components/T.tsx";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

function GreekQuestionBody({ word }: { word: GreekWord }): React.ReactNode {
  switch (word.type) {
    case "adjective":
    case "adverb":
    case "conjunction":
    case "noun":
    case "numeral":
    case "impersonalVerb":
    case "interrogativePronoun":
    case "prefix":
    case "prepositionAccusative":
    case "prepositionDative":
    case "prepositionGenitive":
    case "particle":
    case "suffix":
    case "verb": {
      return <div><Gr>{word.ground}</Gr></div>;
    }
    default:
      return assertNever(word);
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

function GreekAnswerBody({ word }: { word: GreekWord }): React.ReactNode {
  switch (word.type) {
    case "adjective":
    case "adverb":
    case "conjunction":
    case "noun":
    case "numeral":
    case "impersonalVerb":
    case "interrogativePronoun":
    case "prefix":
    case "prepositionAccusative":
    case "prepositionDative":
    case "prepositionGenitive":
    case "particle":
    case "suffix":
    case "verb": {
      return <GreekDefaultFull word={word} />;
    }
    default:
      return assertNever(word);
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface GreekResultsBodyProps {
  correct: number;
  total: number;
}

function GreekResultsBody({ correct, total }: GreekResultsBodyProps): React.ReactNode {
  if (total === 0) {
    return "Je deed niets, maar wel zonder fouten!"
  }
  if (correct / total < 0.5) {
    return "Jaaaa hier is nog veel werk aan de winkel!";
  }
  if (correct !== total) {
    return "Goed maar niet perfect!";
  }
  return "Aristoteles!"
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type GreekPracticeState = "swirlIn" | "question" | "answer" | "swirlOut";

// const isSwirling = (state: CardState) => (state === "swirlIn") || (state === "swirlOut");
const isNotSwirling = (state: GreekPracticeState) =>
  (state === "question") || (state === "answer");
// const showQuestionSide = (state: CardState) => (state === "swirlIn") || (state === "question");
const showAnswerSide = (state: GreekPracticeState) =>
  (state === "answer") || (state === "swirlOut");

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// Transitions take 250 msec.
const FLIP_CARD_TRANSITION_DURATION = 250;

interface GreekPracticeProps {
  vocabulary: GreekWord[];
  words: number[];
}

export function GreekPractice({ vocabulary, words, }: GreekPracticeProps) {
  const params = useParams();
  const [ state, setState ] = useState<GreekPracticeState>("swirlIn");
  const [ testWords, setTestWords ] = useState<GreekWord[]>([]);
  const [ currentIndex, setCurrentIndex ] = useState<number>(0);
  const [ wrongIndices, setWrongIndices ] = useState<number[]>([]);

  // Reshuffle all words that need to be trained and start from scratch.
  const reshuffleAll = () => {
    // TODO: safety!
    // console.log("reshuffleAll");
    setTestWords(_(vocabulary).slice().pullAt(words).shuffle().value());
    setCurrentIndex(0);
    setWrongIndices([]);
    setState("swirlIn");
  }

  // Reshuffle all wrong words and start from scratch.
  const reshuffleWrong = () => {
    // console.log("reshuffleWrong");
    setTestWords(_(testWords).at(wrongIndices).shuffle().value());
    setCurrentIndex(0);
    setWrongIndices([]);
    setState("swirlIn");
  }

  const bumpIndex = () => {
    // console.log("bumpIndex");
    setCurrentIndex(currentIndex + 1);
  }

  useEffect(reshuffleAll, [ params ]);

  // Swirl in effect.
  useEffect(() => {
    if (state !== "swirlIn") return;
    // console.log("Swirl in effect");
    const timer = setTimeout(() => {
      // console.log("Swirl in effect: timed code");
      setState("question");
    }, FLIP_CARD_TRANSITION_DURATION);
    return () => {
      clearTimeout(timer);
    };
  }, [ state ]);

  // Swirl out effect.
  useEffect(() => {
    if (state !== "swirlOut") return;
    // console.log("Swirl out effect");
    const timer = setTimeout(() => {
      // console.log("Swirl out effect: timed code");
      setState("swirlIn");
    }, FLIP_CARD_TRANSITION_DURATION);
    return () => {
      clearTimeout(timer);
    };
  }, [ state ]);

  if (currentIndex > testWords.length) {
    return <div>Dit zou nooit mogen gebeuren...</div>;
  }

  if (currentIndex === testWords.length) {
    const correct = testWords.length - wrongIndices.length;
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="results">
          <div className="card">
            <div className="card-header">
              Resultaat: {correct}/{testWords.length}
            </div>
            <div className="card-body">
              <GreekResultsBody correct={correct} total={testWords.length} />
            </div>
            <div className="card-footer">
              <div className="d-flex justify-content-between">
                <button className={`btn btn-outline-warning ${wrongIndices.length === 0 ? "disabled" : ""}`}
                        onClick={reshuffleWrong}>
                  Enkel fouten
                </button>
                <button className="btn btn-outline-success"
                        onClick={reshuffleAll}>
                  Alles opnieuw
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const handleQuestionCardClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    if (isNotSwirling(state) && (state === "question")) {
      setState("answer");
    }
  }

  const handleAnswerCardCorrectClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    if (isNotSwirling(state) && (state === "answer")) {
      bumpIndex();
      setState("swirlOut");
    }
  }

  const handleAnswerCardWrongClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    if (isNotSwirling(state) && (state === "answer")) {
      setWrongIndices([ ...wrongIndices, currentIndex ]);
      bumpIndex();
      setState("swirlOut");
    }
  }

  const swirl = state === "swirlOut" ? "swirl-out" : state === "swirlIn" ? "swirl-in" : "swirl-none";
  const flip = showAnswerSide(state) ? "flipped" : "";
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className={`flip-card ${swirl}`}>
        <div className={`inner ${flip}`}>
          <div className="front">
            <div className="card shadow-lg"
                 onClick={handleQuestionCardClick}>
              <div className="card-header">
                <div className="d-flex justify-content-between align-items-baseline">
                  <span className="word-type" />
                  <span className="word-index">{currentIndex + 1}/{testWords.length}</span>
                </div>
              </div>
              <div className="card-body">
                <GreekQuestionBody word={testWords[currentIndex]} />
              </div>
            </div>
          </div>
          <div className="back">
            <div className="card shadow-lg">
              <div className="card-header">
                <div className="d-flex justify-content-between align-items-baseline">
                  <span className="word-type">{toDutchType(testWords[currentIndex])}</span>
                  <span className="word-index">{currentIndex + 1}/{testWords.length}</span>
                </div>
              </div>
              <div className="card-body">
                <GreekAnswerBody word={testWords[currentIndex]} />
                {/* <GreekExamples word={testWords[currentIndex]} /> */}
              </div>
              <div className="card-footer">
                <div className="d-flex justify-content-between">
                  <button className="btn btn-outline-danger"
                          onClick={handleAnswerCardWrongClick}>
                    Falsus
                  </button>
                  <button className="btn btn-outline-success"
                          onClick={handleAnswerCardCorrectClick}>
                    Verus
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}