import { SEX_TITLE } from "../../data/WordBase.tsx";
import { LatinIndefinitePronoun } from "../../data/latin/LatinWord.tsx";
import "../WordFull.scss";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface LatinIndefinitePronounFullProps {
  pronoun: LatinIndefinitePronoun;
}

function LatinIndefinitePronounFull({ pronoun }: LatinIndefinitePronounFullProps) {
  const { latin, dutch, sex, } = pronoun;
  return (
    <div className="word-full">
      <div className="word-full-title">
        {latin}
        <span className="word-full-model float-end">
          {SEX_TITLE[sex]}
        </span>
      </div>
      <div className="word-full-translation">
        {dutch}
      </div>
    </div>
  );
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export default LatinIndefinitePronounFull;