import { searchWord } from "../../WordBase";
import { LatinInterjection } from "../LatinWord";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const LATIN_INTERJECTIONS: Array<LatinInterjection> = [
  {
    type: "interjection",
    latin: "ecce!",
    dutch: "kijk!",
  },
  // {
  //   type: "toFixIrregularWord",
  //   latin: "",
  //   dutch: "",
  // },
];

export const searchLatinInterjection = searchWord(LATIN_INTERJECTIONS, "Latin interjection", "latin");