import { searchWord } from "../WordBase";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export interface GreekAdjective {
  type: "adjective";  // "Adjectief"
  ground: string;
  passport: string,
  dutch: string;
  declension: "1/2";
  explicitDeclensions?: {
    nomS?: { m?: string, f?: string, n?: string, },
    genS?: { m?: string, f?: string, n?: string, },
    datS?: { m?: string, f?: string, n?: string, },
    accS?: { m?: string, f?: string, n?: string, },
    vocS?: { m?: string, f?: string, n?: string, },
    nomD?: { m?: string, f?: string, n?: string, },
    genD?: { m?: string, f?: string, n?: string, },
    datD?: { m?: string, f?: string, n?: string, },
    accD?: { m?: string, f?: string, n?: string, },
    vocD?: { m?: string, f?: string, n?: string, },
    nomP?: { m?: string, f?: string, n?: string, },
    genP?: { m?: string, f?: string, n?: string, },
    datP?: { m?: string, f?: string, n?: string, },
    accP?: { m?: string, f?: string, n?: string, },
    vocP?: { m?: string, f?: string, n?: string, },
  },
  hasSingular?: boolean;
  hasDual?: boolean;
  hasPlural?: boolean;
}

export const GREEK_ADJECTIVES: GreekAdjective[] = [
  {
    // https://en.wiktionary.org/wiki/%E1%BC%80%CE%B3%CE%B1%CE%B8%CF%8C%CF%82
    type: "adjective",
    ground: "ᾰ̓γᾰθός",
    passport: "ᾰ̓γᾰθή, ᾰ̓γᾰθόν",
    dutch: "goed, edel",
    declension: "1/2",
    explicitDeclensions: {
      nomS: { m: "ᾰ̓γᾰθός", f: "ᾰ̓γᾰθή", n: "̓ᾰ̓γᾰθόν", },
      genS: { m: "ᾰ̓γᾰθοῦ", f: "ᾰ̓γᾰθῆς", n: "ᾰ̓γᾰθοῦ", },
      datS: { m: "ᾰ̓γᾰθῷ", f: "ᾰ̓γᾰθῇ", n: "ᾰ̓γᾰθῷ", },
      accS: { m: "ᾰ̓γᾰθόν", f: "ᾰ̓γᾰθήν", n: "ᾰ̓γᾰθόν", },
      vocS: { m: "ᾰ̓γᾰθέ", f: "ᾰ̓γᾰθή", n: "ᾰ̓γᾰθόν", },
      nomD: { m: "ᾰ̓γᾰθώ", f: "ᾰ̓γᾰθᾱ́", n: "ᾰ̓γᾰθώ", },
      genD: { m: "ᾰ̓γᾰθοῖν", f: "ᾰ̓γᾰθαῖν", n: "ᾰ̓γᾰθοῖν", },
      datD: { m: "ᾰ̓γᾰθοῖν", f: "ᾰ̓γᾰθαῖν", n: "̓ᾰ̓γᾰθοῖν", },
      accD: { m: "ᾰ̓γᾰθώ", f: "ᾰ̓γᾰθᾱ́", n: "ᾰ̓γᾰθώ", },
      vocD: { m: "ᾰ̓γᾰθώ", f: "ᾰ̓γᾰθᾱ́", n: "ᾰ̓γᾰθώ", },
      nomP: { m: "ᾰ̓γᾰθοί", f: "ᾰ̓γᾰθαί", n: "ᾰ̓γᾰθᾰ́", },
      genP: { m: "ᾰ̓γᾰθῶν", f: "ᾰ̓γᾰθῶν", n: "ᾰ̓γᾰθῶν", },
      datP: { m: "ᾰ̓γᾰθοῖς", f: "ᾰ̓γᾰθαῖς", n: "ᾰ̓γᾰθοῖς", },
      accP: { m: "ᾰ̓γᾰθούς", f: "ᾰ̓γᾰθᾱ́ς", n: "ᾰ̓γᾰθᾰ́", },
      vocP: { m: "ᾰ̓γᾰθοί", f: "ᾰ̓γᾰθαί", n: "ᾰ̓γᾰθᾰ́", },
    }
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BA%CE%B1%CE%BB%CF%8C%CF%82
    type: "adjective",
    ground: "κᾰλός",
    passport: "κᾰλή, κᾰλόν",
    dutch: "mooi",
    declension: "1/2",
    explicitDeclensions: {
      nomS: { m: "κᾰλός", f: "κᾰλή", n: "κᾰλόν", },
      genS: { m: "κᾰλοῦ", f: "κᾰλῆς", n: "κᾰλοῦ", },
      datS: { m: "κᾰλῷ", f: "κᾰλῇ", n: "κᾰλῷ", },
      accS: { m: "κᾰλόν", f: "κᾰλήν", n: "κᾰλόν", },
      vocS: { m: "κᾰλέ", f: "κᾰλή", n: "κᾰλόν", },
      nomD: { m: "κᾰλώ", f: "κᾰλᾱ́", n: "κᾰλώ", },
      genD: { m: "κᾰλοῖν", f: "κᾰλαῖν", n: "κᾰλοῖν", },
      datD: { m: "κᾰλοῖν", f: "κᾰλαῖν", n: "κᾰλοῖν", },
      accD: { m: "κᾰλώ", f: "κᾰλᾱ́", n: "κᾰλώ", },
      vocD: { m: "κᾰλώ", f: "κᾰλᾱ́", n: "κᾰλώ", },
      nomP: { m: "κᾰλοί", f: "κᾰλαί", n: "κᾰλᾰ́", },
      genP: { m: "κᾰλῶν", f: "κᾰλῶν", n: "κᾰλῶν", },
      datP: { m: "κᾰλοῖς", f: "κᾰλαῖς", n: "κᾰλοῖς", },
      accP: { m: "κᾰλούς", f: "κᾰλᾱ́ς", n: "κᾰλᾰ́", },
      vocP: { m: "κᾰλοί", f: "κᾰλαί", n: "κᾰλᾰ́", },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CF%83%CE%BF%CF%86%CF%8C%CF%82
    type: "adjective",
    ground: "σοφός",
    passport: "σοφή, σοφόν",
    dutch: "wijs, verstandig",
    declension: "1/2",
    explicitDeclensions: {
      nomS: { m: "σοφός", f: "σοφή", n: "σοφόν", },
      genS: { m: "σοφοῦ", f: "σοφῆς", n: "σοφοῦ", },
      datS: { m: "σοφῷ", f: "σοφῇ", n: "σοφῷ", },
      accS: { m: "σοφόν", f: "σοφήν", n: "σοφόν", },
      vocS: { m: "σοφέ", f: "σοφή", n: "σοφόν", },
      nomD: { m: "σοφώ", f: "σοφᾱ́", n: "σοφώ", },
      genD: { m: "σοφοῖν", f: "σοφαῖν", n: "σοφοῖν", },
      datD: { m: "σοφοῖν", f: "σοφαῖν", n: "σοφοῖν", },
      accD: { m: "σοφώ", f: "σοφᾱ́", n:"σοφώ", },
      vocD: { m: "σοφώ", f: "σοφᾱ́", n: "σοφώ", },
      nomP: { m: "σοφοί", f: "σοφαί", n: "σοφᾰ́", },
      genP: { m: "σοφῶν", f: "σοφῶν", n: "σοφῶν", },
      datP: { m: "σοφοῖς", f: "σοφαῖς", n: "σοφοῖς", },
      accP: { m: "σοφούς", f: "σοφᾱ́ς", n: "σοφᾰ́", },
      vocP: { m: "σοφοί", f: "σοφαί", n: "σοφᾰ́", },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B4%CF%85%CE%BD%CE%B1%CF%84%CF%8C%CF%82
    type: "adjective",
    ground: "δῠνᾰτός",
    passport: "δῠνᾰτή, δῠνᾰτόν",
    dutch: "machtig, mogelijk",
    declension: "1/2",
    explicitDeclensions: {
      nomS: { m: "δῠνᾰτός", f: "δῠνᾰτή", n: "δῠνᾰτόν", },
      genS: { m: "δῠνᾰτοῦ", f: "δῠνᾰτῆς", n: "δῠνᾰτοῦ", },
      datS: { m: "δῠνᾰτῷ", f: "δῠνᾰτῇ", n: "δῠνᾰτῷ", },
      accS: { m: "δῠνᾰτόν", f: "δῠνᾰτήν", n: "δῠνᾰτόν", },
      vocS: { m: "δῠνᾰτέ", f: "δῠνᾰτή", n: "δῠνᾰτόν", },
      nomD: { m: "δῠνᾰτώ", f: "δῠνᾰτᾱ́", n: "δῠνᾰτώ", },
      genD: { m: "δῠνᾰτοῖν", f: "δῠνᾰταῖν", n: "δῠνᾰτοῖν", },
      datD: { m: "δῠνᾰτοῖν", f: "δῠνᾰταῖν", n: "δῠνᾰτοῖν", },
      accD: { m: "δῠνᾰτώ", f: "δῠνᾰτᾱ́", n: "δῠνᾰτώ", },
      vocD: { m: "δῠνᾰτώ", f: "δῠνᾰτᾱ́", n: "δῠνᾰτώ", },
      nomP: { m: "δῠνᾰτοί", f: "δῠνᾰταί", n: "δῠνᾰτᾰ́", },
      genP: { m: "δῠνᾰτῶν", f: "δῠνᾰτῶν", n: "δῠνᾰτῶν", },
      datP: { m: "δῠνᾰτοῖς", f: "δῠνᾰταῖς", n: "δῠνᾰτοῖς", },
      accP: { m: "δῠνᾰτούς", f: "δῠνᾰτᾱ́ς", n: "δῠνᾰτᾰ́", },
      vocP: { m: "δῠνᾰτοί", f: "δῠνᾰταί", n: "δῠνᾰτᾰ́", },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%95%CE%BA%CE%B1%CF%83%CF%84%CE%BF%CF%82
    type: "adjective",
    ground: "ἕκᾰστος",
    passport: "ἑκᾰ́στη, ἕκᾰστον",
    dutch: "elk, ieder",
    declension: "1/2",
    explicitDeclensions: {
      nomS: { m: "ἕκᾰστος", f: "ἑκᾰ́στη", n: "ἕκᾰστον", },
      genS: { m: "ἑκᾰ́στου", f: "ἑκᾰ́στης", n: "ἑκᾰ́στου", },
      datS: { m: "ἑκᾰ́στῳ", f: "ἑκᾰ́στῃ", n: "ἑκᾰ́στῳ", },
      accS: { m: "ἕκᾰστον", f: "ἑκᾰ́στην", n: "ἕκᾰστον", },
      vocS: { m: "ἕκᾰστε", f: "ἑκᾰ́στη", n: "ἕκᾰστον", },
      nomD: { m: "ἑκᾰ́στω", f: "ἑκᾰ́στᾱ", n: "ἑκᾰ́στω", },
      genD: { m: "ἑκᾰ́στοιν", f: "ἑκᾰ́σταιν", n: "ἑκᾰ́στοιν", },
      datD: { m: "ἑκᾰ́στοιν", f: "ἑκᾰ́σταιν", n: "ἑκᾰ́στοιν", },
      accD: { m: "ἑκᾰ́στω", f: "ἑκᾰ́στᾱ", n: "ἑκᾰ́στω", },
      vocD: { m: "ἑκᾰ́στω", f: "ἑκᾰ́στᾱ", n: "ἑκᾰ́στω", },
      nomP: { m: "ἕκᾰστοι", f: "ἕκᾰσται", n: "ἕκᾰστᾰ", },
      genP: { m: "ἑκᾰ́στων", f: "ἑκᾰ́στων", n: "ἑκᾰ́στων", },
      datP: { m: "ἑκᾰ́στοις", f: "ἑκᾰ́σταις", n: "ἑκᾰ́στοις", },
      accP: { m: "ἑκᾰ́στους", f: "ἑκᾰ́στᾱς", n: "ἕκᾰστᾰ", },
      vocP: { m: "ἕκᾰστοι", f: "ἕκᾰσται", n: "ἕκᾰστᾰ", },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BC%CE%AD%CE%B3%CE%B9%CF%83%CF%84%CE%BF%CF%82
    type: "adjective",
    ground: "μέγῐστος",
    passport: "μεγῐ́στη, μέγῐστον",
    dutch: "zeer groot, grootst",
    declension: "1/2",
    explicitDeclensions: {
      nomS: { m: "μέγῐστος", f: "μεγῐ́στη", n: "μέγῐστον", },
      genS: { m: "μεγῐ́στου", f: "μεγῐ́στης", n: "μεγῐ́στου", },
      datS: { m: "μεγῐ́στῳ", f: "μεγῐ́στῃ", n: "μεγῐ́στῳ", },
      accS: { m: "μέγῐστον", f: "μεγῐ́στην", n: "μέγῐστον", },
      vocS: { m: "μέγῐστε", f: "μεγῐ́στη", n: "μέγῐστον", },
      nomD: { m: "μεγῐ́στω", f: "μεγῐ́στᾱ", n: "μεγῐ́στω", },
      genD: { m: "μεγῐ́στοιν", f: "μεγῐ́σταιν", n: "μεγῐ́στοιν", },
      datD: { m: "μεγῐ́στοιν", f: "μεγῐ́σταιν", n: "μεγῐ́στοιν", },
      accD: { m: "μεγῐ́στω", f: "μεγῐ́στᾱ", n: "μεγῐ́στω", },
      vocD: { m: "μεγῐ́στω", f: "μεγῐ́στᾱ", n: "μεγῐ́στω", },
      nomP: { m: "μέγῐστοι", f: "μέγῐσται", n: "μέγῐστᾰ", },
      genP: { m: "μεγῐ́στων", f: "μεγῐ́στων", n: "μεγῐ́στων", },
      datP: { m: "μεγῐ́στοις", f: "μεγῐ́σταις", n: "μεγῐ́στοις", },
      accP: { m: "μεγῐ́στους", f: "μεγῐ́στᾱς", n: "μέγῐστᾰ", },
      vocP: { m: "μέγῐστοι", f: "μέγῐσται", n: "μέγῐστᾰ", },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BE%CE%AD%CE%BD%CE%BF%CF%82
    type: "adjective",
    ground: "ξένος",
    passport: "ξένη, ξένον",
    dutch: "vreemd",
    declension: "1/2",
    explicitDeclensions: {
      nomS: { m: "ξένος", f: "ξένη", n: "ξένον", },
      genS: { m: "ξένου", f: "ξένης", n: "ξένου", },
      datS: { m: "ξένῳ", f: "ξένῃ", n: "ξένῳ", },
      accS: { m: "ξένον", f: "ξένην", n: "ξένον", },
      vocS: { m: "ξένε", f: "ξένη", n: "ξένον", },
      nomD: { m: "ξένω", f: "ξένᾱ", n: "ξένω", },
      genD: { m: "ξένοιν", f: "ξέναιν", n: "ξένοιν", },
      datD: { m: "ξένοιν", f: "ξέναιν", n: "ξένοιν", },
      accD: { m: "ξένω", f: "ξένᾱ", n: "ξένω", },
      vocD: { m: "ξένω", f: "ξένᾱ", n: "ξένω", },
      nomP: { m: "ξένοι", f: "ξέναι", n: "ξένᾰ", },
      genP: { m: "ξένων", f: "ξένων", n: "ξένων", },
      datP: { m: "ξένοις", f: "ξέναις", n: "ξένοις", },
      accP: { m: "ξένους", f: "ξένᾱς", n: "ξένᾰ", },
      vocP: { m: "ξένοι", f: "ξέναι", n: "ξένᾰ", },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BD%80%CE%BB%CE%AF%CE%B3%CE%BF%CF%82
    type: "adjective",
    ground: "ὀλῐ́γος",
    passport: "ὀλῐ́γη, ὀλῐ́γον",
    dutch: "weinig",
    declension: "1/2",
    explicitDeclensions: {
      nomS: { m: "ὀλῐ́γος", f: "ὀλῐ́γη", n: "ὀλῐ́γον", },
      genS: { m: "ὀλῐ́γου", f: "ὀλῐ́γης", n: "ὀλῐ́γου", },
      datS: { m: "ὀλῐ́γῳ", f: "ὀλῐ́γῃ", n: "ὀλῐ́γῳ", },
      accS: { m: "ὀλῐ́γον", f: "ὀλῐ́γην", n: "ὀλῐ́γον", },
      vocS: { m: "ὀλῐ́γε", f: "ὀλῐ́γη", n: "ὀλῐ́γον", },
      nomD: { m: "ὀλῐ́γω", f: "ὀλῐ́γᾱ", n: "ὀλῐ́γω", },
      genD: { m: "ὀλῐ́γοιν", f: "ὀλῐ́γαιν", n: "ὀλῐ́γοιν", },
      datD: { m: "ὀλῐ́γοιν", f: "ὀλῐ́γαιν", n: "ὀλῐ́γοιν", },
      accD: { m: "ὀλῐ́γω", f: "ὀλῐ́γᾱ", n: "ὀλῐ́γω", },
      vocD: { m: "ὀλῐ́γω", f: "ὀλῐ́γᾱ", n: "ὀλῐ́γω", },
      nomP: { m: "ὀλῐ́γοι", f: "ὀλῐ́γαι", n: "ὀλῐ́γᾰ", },
      genP: { m: "ὀλῐ́γων", f: "ὀλῐ́γων", n: "ὀλῐ́γων", },
      datP: { m: "ὀλῐ́γοις", f: "ὀλῐ́γαις", n: "ὀλῐ́γοις", },
      accP: { m: "ὀλῐ́γους", f: "ὀλῐ́γᾱς", n: "ὀλῐ́γᾰ", },
      vocP: { m: "ὀλῐ́γοι", f: "ὀλῐ́γαι", n: "ὀλῐ́γᾰ", },
    },
  },
  {
    type: "adjective",
    ground: "πολλοί",
    passport: "πολλαί, πολλᾰ́",
    dutch: "veel, talrijk",
    declension: "1/2",
    explicitDeclensions: {
      nomS: { m: "πολῠ́ς", f: "πολλή", n: "πολῠ́", },
      genS: { m: "πολλοῦ", f: "πολλῆς", n: "πολλοῦ", },
      datS: { m: "πολλῷ", f: "πολλῇ", n: "πολλῷ", },
      accS: { m: "πολῠ́ν", f: "πολλήν", n: "πολῠ́", },
      vocS: { m: "πολλέ", f: "πολλή", n: "πολῠ́", },
      nomP: { m: "πολλοί", f: "πολλαί", n: "πολλᾰ́", },
      genP: { m: "πολλῶν", f: "πολλῶν", n: "πολλῶν", },
      datP: { m: "πολλοῖς", f: "πολλαῖς", n: "πολλοῖς", },
      accP: { m: "πολλούς", f: "πολλᾱ́ς", n: "πολλᾰ́", },
      vocP: { m: "πολλοί", f: "πολλαί", n: "πολλᾰ́", },
    },
    // Not entirely true, but ok.
    hasSingular: false,
    hasDual: false,
  },
  {
    // https://en.wiktionary.org/wiki/%CF%87%CE%B1%CE%BB%CE%B5%CF%80%CF%8C%CF%82
    type: "adjective",
    ground: "χᾰλεπός",
    passport: "χᾰλεπή, χᾰλεπόν",
    dutch: "moeilijk, lastig",
    declension: "1/2",
    explicitDeclensions: {
      nomS: { m: "χᾰλεπός", f: "χᾰλεπή", n: "χᾰλεπόν", },
      genS: { m: "χᾰλεποῦ", f: "χᾰλεπῆς", n: "χᾰλεποῦ", },
      datS: { m: "χᾰλεπῷ", f: "χᾰλεπῇ", n: "χᾰλεπῷ", },
      accS: { m: "χᾰλεπόν", f: "χᾰλεπήν", n: "χᾰλεπόν", },
      vocS: { m: "χᾰλεπέ", f: "χᾰλεπή", n: "χᾰλεπόν", },
      nomD: { m: "χᾰλεπώ", f: "χᾰλεπᾱ́", n: "χᾰλεπώ", },
      genD: { m: "χᾰλεποῖν", f: "χᾰλεπαῖν", n: "χᾰλεποῖν", },
      datD: { m: "χᾰλεποῖν", f: "χᾰλεπαῖν", n: "χᾰλεποῖν", },
      accD: { m: "χᾰλεπώ", f: "χᾰλεπᾱ́", n: "χᾰλεπώ", },
      vocD: { m: "χᾰλεπώ", f: "χᾰλεπᾱ́", n: "χᾰλεπώ", },
      nomP: { m: "χᾰλεποί", f: "χᾰλεπαί", n: "χᾰλεπᾰ́", },
      genP: { m: "χᾰλεπῶν", f: "χᾰλεπῶν", n: "χᾰλεπῶν", },
      datP: { m: "χᾰλεποῖς", f: "χᾰλεπαῖς", n: "χᾰλεποῖς", },
      accP: { m: "χᾰλεπούς", f: "χᾰλεπᾱ́ς", n: "χᾰλεπᾰ́", },
      vocP: { m: "χᾰλεποί", f: "χᾰλεπαί", n: "χᾰλεπᾰ́", },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CF%87%CF%81%CE%AE%CF%83%CE%B9%CE%BC%CE%BF%CF%82
    type: "adjective",
    ground: "χρήσῐμος",
    passport: "χρησῐ́μη, χρήσῐμον",
    dutch: "nuttig",
    declension: "1/2",
    explicitDeclensions: {
      nomS: { m: "χρήσῐμος", f: "χρησῐ́μη", n: "χρήσῐμον", },
      genS: { m: "χρησῐ́μου", f: "χρησῐ́μης", n: "χρησῐ́μου", },
      datS: { m: "χρησῐ́μῳ", f: "χρησῐ́μῃ", n: "χρησῐ́μῳ", },
      accS: { m: "χρήσῐμον", f: "χρησῐ́μην", n: "χρήσῐμον", },
      vocS: { m: "χρήσῐμε", f: "χρησῐ́μη", n: "χρήσῐμον", },
      nomD: { m: "χρησῐ́μω", f: "χρησῐ́μᾱ", n: "χρησῐ́μω", },
      genD: { m: "χρησῐ́μοιν", f: "χρησῐ́μαιν", n: "χρησῐ́μοιν", },
      datD: { m: "χρησῐ́μοιν", f: "χρησῐ́μαιν", n: "χρησῐ́μοιν", },
      accD: { m: "χρησῐ́μω", f: "χρησῐ́μᾱ", n: "χρησῐ́μω", },
      vocD: { m: "χρησῐ́μω", f: "χρησῐ́μᾱ", n: "χρησῐ́μω", },
      nomP: { m: "χρήσῐμοι", f: "χρήσῐμαι", n: "χρήσῐμᾰ", },
      genP: { m: "χρησῐ́μων", f: "χρησῐ́μων", n: "χρησῐ́μων", },
      datP: { m: "χρησῐ́μοις", f: "χρησῐ́μαις", n: "χρησῐ́μοις", },
      accP: { m: "χρησῐ́μους", f: "χρησῐ́μᾱς", n: "χρήσῐμᾰ", },
      vocP: { m: "χρήσῐμοι", f: "χρήσῐμαι", n: "χρήσῐμᾰ", },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CF%80%CF%81%E1%BF%B6%CF%84%CE%BF%CF%82
    type: "adjective",
    ground: "πρῶτος",
    passport: "πρώτη, πρῶτον",
    dutch: "eerste",
    declension: "1/2",
    explicitDeclensions: {
      nomS: { m: "πρῶτος", f: "πρώτη", n: "πρῶτον", },
      nomD: { m: "πρώτω", f: "πρώτᾱ", n: "πρώτω", },
      nomP: { m: "πρῶτοι", f: "πρῶται", n: "πρῶτᾰ", },
      genS: { m: "πρώτου", f: "πρώτης", n: "πρώτου", },
      genD: { m: "πρώτοιν", f: "πρώταιν", n: "πρώτοιν", },
      genP: { m: "πρώτων", f: "πρώτων", n: "πρώτων", },
      datS: { m: "πρώτῳ", f: "πρώτῃ", n: "πρώτῳ", },
      datD: { m: "πρώτοιν", f: "πρώταιν", n: "πρώτοιν", },
      datP: { m: "πρώτοις", f: "πρώταις", n: "πρώτοις", },
      accS: { m: "πρῶτον", f: "πρώτην", n: "πρῶτον", },
      accD: { m: "πρώτω", f: "πρώτᾱ", n: "πρώτω", },
      accP: { m: "πρώτους", f: "πρώτᾱς", n: "πρῶτᾰ", },
      vocS: { m: "πρῶτε", f: "πρώτη", n: "πρῶτον", },
      vocD: { m: "πρώτω", f: "πρώτᾱ", n: "πρώτω", },
      vocP: { m: "πρῶτοι", f: "πρῶται", n: "πρῶτᾰ", },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BA%CE%B1%CE%BA%CF%8C%CF%82
    type: "adjective",
    ground: "κᾰκός",
    passport: "κᾰκή, κᾰκόν",
    dutch: "slecht",
    declension: "1/2",
    explicitDeclensions: {
      nomS: { m: "κᾰκός", f: "κᾰκή", n: "κᾰκόν", },
      nomD: { m: "κᾰκώ", f: "κᾰκᾱ́", n: "κᾰκώ", },
      nomP: { m: "κᾰκοί", f: "κᾰκαί", n: "κᾰκᾰ́", },
      genS: { m: "κᾰκοῦ", f: "κᾰκῆς", n: "κᾰκοῦ", },
      genD: { m: "κᾰκοῖν", f: "κᾰκαῖν", n: "κᾰκοῖν", },
      genP: { m: "κᾰκῶν", f: "κᾰκῶν", n: "κᾰκῶν", },
      datS: { m: "κᾰκῷ", f: "κᾰκῇ", n: "κᾰκῷ", },
      datD: { m: "κᾰκοῖν", f: "κᾰκαῖν", n: "κᾰκοῖν", },
      datP: { m: "κᾰκοῖς", f: "κᾰκαῖς", n: "κᾰκοῖς", },
      accS: { m: "κᾰκόν", f: "κᾰκήν", n: "κᾰκόν", },
      accD: { m: "κᾰκώ", f: "κᾰκᾱ́", n: "κᾰκώ", },
      accP: { m: "κᾰκούς", f: "κᾰκᾱ́ς", n: "κᾰκᾰ́", },
      vocS: { m: "κᾰκέ", f: "κᾰκή", n: "κᾰκόν", },
      vocD: { m: "κᾰκώ", f: "κᾰκᾱ́", n: "κᾰκώ", },
      vocP: { m: "κᾰκοί", f: "κᾰκαί", n: "κᾰκᾰ́", },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BD%CE%AD%CE%BF%CF%82
    type: "adjective",
    ground: "νέος",
    passport: "νέᾱ, νέον",
    dutch: "nieuw, jong",
    declension: "1/2",
    explicitDeclensions: {
      nomS: { m: "νέος", f: "νέᾱ", n: "νέον", },
      nomD: { m: "νέω", f: "νέᾱ", n: "νέω", },
      nomP: { m: "νέοι", f: "νέαι", n: "νέᾰ", },
      genS: { m: "νέου", f: "νέᾱς", n: "νέου", },
      genD: { m: "νέοιν", f: "νέαιν", n: "νέοιν", },
      genP: { m: "νέων", f: "νέων", n: "νέων", },
      datS: { m: "νέῳ", f: "νέᾳ", n: "νέῳ", },
      datD: { m: "νέοιν", f: "νέαιν", n: "νέοιν", },
      datP: { m: "νέοις", f: "νέαις", n: "νέοις", },
      accS: { m: "νέον", f: "νέᾱν", n: "νέον", },
      accD: { m: "νέω", f: "νέᾱ", n: "νέω", },
      accP: { m: "νέους", f: "νέᾱς", n: "νέᾰ", },
      vocS: { m: "νέε", f: "νέᾱ", n: "νέον", },
      vocD: { m: "νέω", f: "νέᾱ", n: "νέω", },
      vocP: { m: "νέοι", f: "νέαι", n: "νέᾰ", },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B4%CE%AF%CE%BA%CE%B1%CE%B9%CE%BF%CF%82
    type: "adjective",
    ground: "δῐ́καιος",
    passport: "δῐκαίᾱ, δῐ́καιον",
    dutch: "rechtvaardig",
    declension: "1/2",
    explicitDeclensions: {
      nomS: { m: "δῐ́καιος", f: "δῐκαίᾱ", n: "δῐ́καιον", },
      nomD: { m: "δῐκαίω", f: "δῐκαίᾱ", n: "δῐκαίω", },
      nomP: { m: "δῐ́καιοι", f: "δῐ́καιαι", n: "δῐ́καιᾰ", },
      genS: { m: "δῐκαίου", f: "δῐκαίᾱς", n: "δῐκαίου", },
      genD: { m: "δῐκαίοιν", f: "δῐκαίαιν", n: "δῐκαίοιν", },
      genP: { m: "δῐκαίων", f: "δῐκαίων", n: "δῐκαίων", },
      datS: { m: "δῐκαίῳ", f: "δῐκαίᾳ", n: "δῐκαίῳ", },
      datD: { m: "δῐκαίοιν", f: "δῐκαίαιν", n: "δῐκαίοιν", },
      datP: { m: "δῐκαίοις", f: "δῐκαίαις", n: "δῐκαίοις", },
      accS: { m: "δῐ́καιον", f: "δῐκαίᾱν", n: "δῐ́καιον", },
      accD: { m: "δῐκαίω", f: "δῐκαίᾱ", n: "δῐκαίω", },
      accP: { m: "δῐκαίους", f: "δῐκαίᾱς", n: "δῐ́καιᾰ", },
      vocS: { m: "δῐ́καιε", f: "δῐκαίᾱ", n: "δῐ́καιον", },
      vocD: { m: "δῐκαίω", f: "δῐκαίᾱ", n: "δῐκαίω", },
      vocP: { m: "δῐ́καιοι", f: "δῐ́καιαι", n: "δῐ́καιᾰ", },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BC%CE%B1%CE%BA%CF%81%CF%8C%CF%82
    type: "adjective",
    ground: "μᾰκρός",
    passport: "μᾰκρᾱ́, μᾰκρόν",
    dutch: "lang, groot",
    declension: "1/2",
    explicitDeclensions: {
      nomS: { m: "μᾰκρός", f: "μᾰκρᾱ́", n: "μᾰκρόν", },
      nomD: { m: "μᾰκρώ", f: "μᾰκρᾱ́", n: "μᾰκρώ", },
      nomP: { m: "μᾰκροί", f: "μᾰκραί", n: "μᾰκρᾰ́", },
      genS: { m: "μᾰκροῦ", f: "μᾰκρᾶς", n: "μᾰκροῦ", },
      genD: { m: "μᾰκροῖν", f: "μᾰκραῖν", n: "μᾰκροῖν", },
      genP: { m: "μᾰκρῶν", f: "μᾰκρῶν", n: "μᾰκρῶν", },
      datS: { m: "μᾰκρῷ", f: "μᾰκρᾷ", n: "μᾰκρῷ", },
      datD: { m: "μᾰκροῖν", f: "μᾰκραῖν", n: "μᾰκροῖν", },
      datP: { m: "μᾰκροῖς", f: "μᾰκραῖς", n: "μᾰκροῖς", },
      accS: { m: "μᾰκρόν", f: "μᾰκρᾱ́ν", n: "μᾰκρόν", },
      accD: { m: "μᾰκρώ", f: "μᾰκρᾱ́", n: "μᾰκρώ", },
      accP: { m: "μᾰκρούς", f: "μᾰκρᾱ́ς", n: "μᾰκρᾰ́", },
      vocS: { m: "μᾰκρέ", f: "μᾰκρᾱ́", n: "μᾰκρόν", },
      vocD: { m: "μᾰκρώ", f: "μᾰκρᾱ́", n: "μᾰκρώ", },
      vocP: { m: "μᾰκροί", f: "μᾰκραί", n: "μᾰκρᾰ́", },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BC%CE%B9%CE%BA%CF%81%CF%8C%CF%82
    type: "adjective",
    ground: "μῑκρός",
    passport: "μῑκρᾱ́, μῑκρόν",
    dutch: "kort, klein",
    declension: "1/2",
    explicitDeclensions: {
      nomS: { m: "μῑκρός", f: "μῑκρᾱ́", n: "μῑκρόν", },
      nomD: { m: "μῑκρώ", f: "μῑκρᾱ́", n: "μῑκρώ", },
      nomP: { m: "μῑκροί", f: "μῑκραί", n: "μῑκρᾰ́", },
      genS: { m: "μῑκροῦ", f: "μῑκρᾶς", n: "μῑκροῦ", },
      genD: { m: "μῑκροῖν", f: "μῑκραῖν", n: "μῑκροῖν", },
      genP: { m: "μῑκρῶν", f: "μῑκρῶν", n: "μῑκρῶν", },
      datS: { m: "μῑκρῷ", f: "μῑκρᾷ", n: "μῑκρῷ", },
      datD: { m: "μῑκροῖν", f: "μῑκραῖν", n: "μῑκροῖν", },
      datP: { m: "μῑκροῖς", f: "μῑκραῖς", n: "μῑκροῖς", },
      accS: { m: "μῑκρόν", f: "μῑκρᾱ́ν", n: "μῑκρόν", },
      accD: { m: "μῑκρώ", f: "μῑκρᾱ́", n: "μῑκρώ", },
      accP: { m: "μῑκρούς", f: "μῑκρᾱ́ς", n: "μῑκρᾰ́", },
      vocS: { m: "μῑκρέ", f: "μῑκρᾱ́", n: "μῑκρόν", },
      vocD: { m: "μῑκρώ", f: "μῑκρᾱ́", n: "μῑκρώ", },
      vocP: { m: "μῑκροί", f: "μῑκραί", n: "μῑκρᾰ́", },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%84%CE%BB%CE%BB%CE%BF%CF%82
    type: "adjective",
    ground: "ᾰ̓́λλος",
    passport: "ᾰ̓́λλη, ᾰ̓́λλο",
    dutch: "(een) ander",
    declension: "1/2",
    explicitDeclensions: {
      nomS: { m: "ᾰ̓́λλος", f: "ᾰ̓́λλη", n: "ᾰ̓́λλο", },
      nomD: { m: "ᾰ̓́λλω", f: "ᾰ̓́λλᾱ", n: "ᾰ̓́λλω", },
      nomP: { m: "ᾰ̓́λλοι", f: "ᾰ̓́λλαι", n: "ᾰ̓́λλᾰ", },
      genS: { m: "ᾰ̓́λλου", f: "ᾰ̓́λλης", n: "ᾰ̓́λλου", },
      genD: { m: "ᾰ̓́λλοιν", f: "ᾰ̓́λλαιν", n: "ᾰ̓́λλοιν", },
      genP: { m: "ᾰ̓́λλων", f: "ᾰ̓́λλων", n: "ᾰ̓́λλων", },
      datS: { m: "ᾰ̓́λλῳ", f: "ᾰ̓́λλῃ", n: "ᾰ̓́λλῳ", },
      datD: { m: "ᾰ̓́λλοιν", f: "ᾰ̓́λλαιν", n: "ᾰ̓́λλοιν", },
      datP: { m: "ᾰ̓́λλοις", f: "ᾰ̓́λλαις", n: "ᾰ̓́λλοις", },
      accS: { m: "ᾰ̓́λλον", f: "ᾰ̓́λλην", n: "ᾰ̓́λλο", },
      accD: { m: "ᾰ̓́λλω", f: "ᾰ̓́λλᾱ", n: "ᾰ̓́λλω", },
      accP: { m: "ᾰ̓́λλους", f: "ᾰ̓́λλᾱς", n: "ᾰ̓́λλᾰ", },
      vocS: { m: "ᾰ̓́λλε", f: "ᾰ̓́λλη", n: "ᾰ̓́λλο", },
      vocD: { m: "ᾰ̓́λλω", f: "ᾰ̓́λλᾱ", n: "ᾰ̓́λλω", },
      vocP: { m: "ᾰ̓́λλοι", f: "ᾰ̓́λλαι", n: "ᾰ̓́λλᾰ", },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%84%CE%BE%CE%B9%CE%BF%CF%82
    type: "adjective",
    ground: "ᾰ̓́ξῐος",
    passport: "ᾰ̓ξῐ́ᾱ, ᾰ̓́ξῐον",
    dutch: "waard, waardig (+ gen)",
    declension: "1/2",
    explicitDeclensions: {
      nomS: { m: "ᾰ̓́ξῐος", f: "ᾰ̓ξῐ́ᾱ", n: "ᾰ̓́ξῐον", },
      nomD: { m: "ᾰ̓ξῐ́ω", f: "ᾰ̓ξῐ́ᾱ", n: "ᾰ̓ξῐ́ω", },
      nomP: { m: "ᾰ̓́ξῐοι", f: "ᾰ̓́ξῐαι", n: "ᾰ̓́ξῐᾰ", },
      genS: { m: "ᾰ̓ξῐ́ου", f: "ᾰ̓ξῐ́ᾱς", n: "ᾰ̓ξῐ́ου", },
      genD: { m: "ᾰ̓ξῐ́οιν", f: "ᾰ̓ξῐ́αιν", n: "ᾰ̓ξῐ́οιν", },
      genP: { m: "ᾰ̓ξῐ́ων", f: "ᾰ̓ξῐ́ων", n: "ᾰ̓ξῐ́ων", },
      datS: { m: "ᾰ̓ξῐ́ῳ", f: "ᾰ̓ξῐ́ᾳ", n: "ᾰ̓ξῐ́ῳ", },
      datD: { m: "ᾰ̓ξῐ́οιν", f: "ᾰ̓ξῐ́αιν", n: "ᾰ̓ξῐ́οιν", },
      datP: { m: "ᾰ̓ξῐ́οις", f: "ᾰ̓ξῐ́αις", n: "ᾰ̓ξῐ́οις", },
      accS: { m: "ᾰ̓́ξῐον", f: "ᾰ̓ξῐ́ᾱν", n: "ᾰ̓́ξῐον", },
      accD: { m: "ᾰ̓ξῐ́ω", f: "ᾰ̓ξῐ́ᾱ", n: "ᾰ̓ξῐ́ω", },
      accP: { m: "ᾰ̓ξῐ́ους", f: "ᾰ̓ξῐ́ᾱς", n: "ᾰ̓́ξῐᾰ", },
      vocS: { m: "ᾰ̓́ξῐε", f: "ᾰ̓ξῐ́ᾱ", n: "ᾰ̓́ξῐον", },
      vocD: { m: "ᾰ̓ξῐ́ω", f: "ᾰ̓ξῐ́ᾱ", n: "ᾰ̓ξῐ́ω", },
      vocP: { m: "ᾰ̓́ξῐοι", f: "ᾰ̓́ξῐαι", n: "ᾰ̓́ξῐᾰ", },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%90%CE%BB%CE%B5%CF%8D%CE%B8%CE%B5%CF%81%CE%BF%CF%82
    type: "adjective",
    ground: "ἐλεύθερος",
    passport: "ἐλευθέρᾱ, ἐλεύθερον",
    dutch: "vrij",
    declension: "1/2",
    explicitDeclensions: {
      nomS: { m: "ἐλεύθερος", f: "ἐλευθέρᾱ", n: "ἐλεύθερον", },
      nomD: { m: "ἐλευθέρω", f: "ἐλευθέρᾱ", n: "ἐλευθέρω", },
      nomP: { m: "ἐλεύθεροι", f: "ἐλεύθεραι", n: "ἐλεύθερᾰ", },
      genS: { m: "ἐλευθέρου", f: "ἐλευθέρᾱς", n: "ἐλευθέρου", },
      genD: { m: "ἐλευθέροιν", f: "ἐλευθέραιν", n: "ἐλευθέροιν", },
      genP: { m: "ἐλευθέρων", f: "ἐλευθέρων", n: "ἐλευθέρων", },
      datS: { m: "ἐλευθέρῳ", f: "ἐλευθέρᾳ", n: "ἐλευθέρῳ", },
      datD: { m: "ἐλευθέροιν", f: "ἐλευθέραιν", n: "ἐλευθέροιν", },
      datP: { m: "ἐλευθέροις", f: "ἐλευθέραις", n: "ἐλευθέροις", },
      accS: { m: "ἐλεύθερον", f: "ἐλευθέρᾱν", n: "ἐλεύθερον", },
      accD: { m: "ἐλευθέρω", f: "ἐλευθέρᾱ", n: "ἐλευθέρω", },
      accP: { m: "ἐλευθέρους", f: "ἐλευθέρᾱς", n: "ἐλεύθερᾰ", },
      vocS: { m: "ἐλεύθερε", f: "ἐλευθέρᾱ", n: "ἐλεύθερον", },
      vocD: { m: "ἐλευθέρω", f: "ἐλευθέρᾱ", n: "ἐλευθέρω", },
      vocP: { m: "ἐλεύθεροι", f: "ἐλεύθεραι", n: "ἐλεύθερᾰ", },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%B1%CE%B5%CF%81%CF%8C%CF%82
    type: "adjective",
    ground: "ἱερός",
    passport: "ἱερᾱ́, ἱερόν",
    dutch: "heilig",
    declension: "1/2",
    explicitDeclensions: {
      nomS: { m: "ἱερός", f: "ἱερᾱ́", n: "ἱερόν", },
      nomD: { m: "ἱερώ", f: "ἱερᾱ́", n: "ἱερώ", },
      nomP: { m: "ἱεροί", f: "ἱεραί", n: "ἱερᾰ́", },
      genS: { m: "ἱεροῦ", f: "ἱερᾶς", n: "ἱεροῦ", },
      genD: { m: "ἱεροῖν", f: "ἱεραῖν", n: "ἱεροῖν", },
      genP: { m: "ἱερῶν", f: "ἱερῶν", n: "ἱερῶν", },
      datS: { m: "ἱερῷ", f: "ἱερᾷ", n: "ἱερῷ", },
      datD: { m: "ἱεροῖν", f: "ἱεραῖν", n: "ἱεροῖν", },
      datP: { m: "ἱεροῖς", f: "ἱεραῖς", n: "ἱεροῖς", },
      accS: { m: "ἱερόν", f: "ἱερᾱ́ν", n: "ἱερόν", },
      accD: { m: "ἱερώ", f: "ἱερᾱ́", n: "ἱερώ", },
      accP: { m: "ἱερούς", f: "ἱερᾱ́ς", n: "ἱερᾰ́", },
      vocS: { m: "ἱερέ", f: "ἱερᾱ́", n: "ἱερόν", },
      vocD: { m: "ἱερώ", f: "ἱερᾱ́", n: "ἱερώ", },
      vocP: { m: "ἱεροί", f: "ἱεραί", n: "ἱερᾰ́", },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BF%A5%E1%BE%B4%CE%B4%CE%B9%CE%BF%CF%82
    type: "adjective",
    ground: "ῥᾴδῐος",
    passport: "ῥᾳδῐ́ᾱ, ῥᾴδῐον",
    dutch: "gemakkelijk",
    declension: "1/2",
    explicitDeclensions: {
      nomS: { m: "ῥᾴδῐος", f: "ῥᾳδῐ́ᾱ", n: "ῥᾴδῐον", },
      nomD: { m: "ῥᾳδῐ́ω", f: "ῥᾳδῐ́ᾱ", n: "ῥᾳδῐ́ω", },
      nomP: { m: "ῥᾴδῐοι", f: "ῥᾴδῐαι", n: "ῥᾴδῐᾰ", },
      genS: { m: "ῥᾳδῐ́ου", f: "ῥᾳδῐ́ᾱς", n: "ῥᾳδῐ́ου", },
      genD: { m: "ῥᾳδῐ́οιν", f: "ῥᾳδῐ́αιν", n: "ῥᾳδῐ́οιν", },
      genP: { m: "ῥᾳδῐ́ων", f: "ῥᾳδῐ́ων", n: "ῥᾳδῐ́ων", },
      datS: { m: "ῥᾳδῐ́ῳ", f: "ῥᾳδῐ́ᾳ", n: "ῥᾳδῐ́ῳ", },
      datD: { m: "ῥᾳδῐ́οιν", f: "ῥᾳδῐ́αιν", n: "ῥᾳδῐ́οιν", },
      datP: { m: "ῥᾳδῐ́οις", f: "ῥᾳδῐ́αις", n: "ῥᾳδῐ́οις", },
      accS: { m: "ῥᾴδῐον", f: "ῥᾳδῐ́ᾱν", n: "ῥᾴδῐον", },
      accD: { m: "ῥᾳδῐ́ω", f: "ῥᾳδῐ́ᾱ", n: "ῥᾳδῐ́ω", },
      accP: { m: "ῥᾳδῐ́ους", f: "ῥᾳδῐ́ᾱς", n: "ῥᾴδῐᾰ", },
      vocS: { m: "ῥᾴδῐε", f: "ῥᾳδῐ́ᾱ", n: "ῥᾴδῐον", },
      vocD: { m: "ῥᾳδῐ́ω", f: "ῥᾳδῐ́ᾱ", n: "ῥᾳδῐ́ω", },
      vocP: { m: "ῥᾴδῐοι", f: "ῥᾴδῐαι", n: "ῥᾴδῐᾰ", },
    },
  },
  {
    type: "adjective",
    ground: "μόνος",
    passport: "μόνη, μόνον",
    dutch: "alleen, enig",
    declension: "1/2",
    explicitDeclensions: {
      nomS: { m: "μόνος", f: "μόνη", n: "μόνον", },
      nomD: { m: "μόνω", f: "μόνᾱ", n: "μόνω", },
      nomP: { m: "μόνοι", f: "μόναι", n: "μόνᾰ", },
      genS: { m: "μόνου", f: "μόνης", n: "μόνου", },
      genD: { m: "μόνοιν", f: "μόναιν", n: "μόνοιν", },
      genP: { m: "μόνων", f: "μόνων", n: "μόνων", },
      datS: { m: "μόνῳ", f: "μόνῃ", n: "μόνῳ", },
      datD: { m: "μόνοιν", f: "μόναιν", n: "μόνοιν", },
      datP: { m: "μόνοις", f: "μόναις", n: "μόνοις", },
      accS: { m: "μόνον", f: "μόνην", n: "μόνον", },
      accD: { m: "μόνω", f: "μόνᾱ", n: "μόνω", },
      accP: { m: "μόνους", f: "μόνᾱς", n: "μόνᾰ", },
      vocS: { m: "μόνε", f: "μόνη", n: "μόνον", },
      vocD: { m: "μόνω", f: "μόνᾱ", n: "μόνω", },
      vocP: { m: "μόνοι", f: "μόναι", n: "μόνᾰ", },
    },
  },
  // {
  //   type: "adjective",
  //   ground: "",
  //   passport: "",
  //   dutch: "",
  //   declension: "1/2",
  //   explicitDeclensions: {
  //     nomS: { m: "", f: "", n: "", },
  //     nomD: { m: "", f: "", n: "", },
  //     nomP: { m: "", f: "", n: "", },
  //     genS: { m: "", f: "", n: "", },
  //     genD: { m: "", f: "", n: "", },
  //     genP: { m: "", f: "", n: "", },
  //     datS: { m: "", f: "", n: "", },
  //     datD: { m: "", f: "", n: "", },
  //     datP: { m: "", f: "", n: "", },
  //     accS: { m: "", f: "", n: "", },
  //     accD: { m: "", f: "", n: "", },
  //     accP: { m: "", f: "", n: "", },
  //     vocS: { m: "", f: "", n: "", },
  //     vocD: { m: "", f: "", n: "", },
  //     vocP: { m: "", f: "", n: "", },
  //   },
  // },
];

export const searchGreekAdjective = searchWord(GREEK_ADJECTIVES, "Greek adjective", "ground");