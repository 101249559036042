import { Optional } from "../../utils/Optional";
import { searchWord, WordFinal } from "../WordBase";
import * as rt from "runtypes";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const GreekVerbNumberPerson = rt.Union(
  rt.Literal("s1"),
  rt.Literal("s2"),
  rt.Literal("s3"),
  rt.Literal("d2"),
  rt.Literal("d3"),
  rt.Literal("p1"),
  rt.Literal("p2"),
  rt.Literal("p3")
);
export type GreekVerbNumberPerson = rt.Static<typeof GreekVerbNumberPerson>;

export const GreekConjugatedTense = rt.Dictionary(WordFinal, GreekVerbNumberPerson);
export type GreekConjugatedTense = rt.Static<typeof GreekConjugatedTense>;

export const GreekInfinitiveAM = rt.Record({
  active: rt.String,
  middle: rt.String,
});
export type GreekInfinitiveAM = rt.Static<typeof GreekInfinitiveAM>;

export const GreekParticipleAM = rt.Record({
  active: rt.String,
  middle: rt.String,
});
export type GreekParticipleAM = rt.Static<typeof GreekParticipleAM>;

export const GreekInfinitiveAMP = rt.Record({
  active: rt.String,
  middle: rt.String,
  passive: rt.String,
});
export type GreekInfinitiveAMP = rt.Static<typeof GreekInfinitiveAMP>;

export const GreekParticipleAMP = rt.Record({
  active: rt.String,
  middle: rt.String,
  passive: rt.String,
});
export type GreekParticipleAMP = rt.Static<typeof GreekParticipleAMP>;

export interface GreekVerb {
  type: "verb";
  ground: string;
  passport: string;
  dutch: string;
  explicitConjugations?: {
    present?: {
      active?: {
        indicative?: Optional<GreekConjugatedTense>;
        subjunctive?: Optional<GreekConjugatedTense>;
        optative?: Optional<GreekConjugatedTense>;
        imperative?: Optional<GreekConjugatedTense>;
      },
      middlePassive?: {
        indicative?: Optional<GreekConjugatedTense>;
        subjunctive?: Optional<GreekConjugatedTense>;
        optative?: Optional<GreekConjugatedTense>;
        imperative?: Optional<GreekConjugatedTense>;
      },
      infinitive?: Optional<GreekInfinitiveAM>,
      participle?: {
        m?: Optional<GreekParticipleAM>,
        f?: Optional<GreekParticipleAM>,
        n?: Optional<GreekParticipleAM>,
      },
    },
    imperfect?: {
      active?: {
        indicative?: Optional<GreekConjugatedTense>;
      },
      middlePassive?: {
        indicative?: Optional<GreekConjugatedTense>;
      },
    },
    future?: {
      active?: {
        indicative?: Optional<GreekConjugatedTense>;
        optative?: Optional<GreekConjugatedTense>;
      },
      middle?: {
        indicative?: Optional<GreekConjugatedTense>;
        optative?: Optional<GreekConjugatedTense>;
      },
      passive?: {
        indicative?: Optional<GreekConjugatedTense>;
        optative?: Optional<GreekConjugatedTense>;
      },
      infinitive?: Optional<GreekInfinitiveAMP>,
      participle?: {
        m?: Optional<GreekParticipleAMP>,
        f?: Optional<GreekParticipleAMP>,
        n?: Optional<GreekParticipleAMP>,
      },
    },
  },


}

export const GREEK_VERBS: GreekVerb[] = [
  {
    // https://en.wiktionary.org/wiki/%CE%B3%CF%81%CE%AC%CF%86%CF%89
    type: "verb",
    ground: "γρἀφειν",
    passport: "γρἀφω",
    dutch: "schrijven",
    explicitConjugations: {
      present: {
        active: {
          indicative: {
            "s1": "γρᾰ́φω",
            "s2": "γρᾰ́φεις",
            "s3": "γρᾰ́φει",
            "d2": "γρᾰ́φετον",
            "d3": "γρᾰ́φετον",
            "p1": "γρᾰ́φομεν",
            "p2": "γρᾰ́φετε",
            "p3": "γρᾰ́φουσῐ(ν)",
          },
        },
      },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BB%CF%8D%CF%89
    type: "verb",
    ground: "λὐειν",
    passport: "λὐω",
    dutch: "losmaken",
    explicitConjugations: {
      present: {
        active: {
          indicative: {
            "s1": "λῡ́ω",
            "s2": "λῡ́ω",
            "s3": "λῡ́ει",
            "d2": "λῡ́ετον",
            "d3": "λῡ́ετον",
            "p1": "λῡ́ομεν",
            "p2": "λῡ́ετε",
            "p3": "λῡ́ουσῐ(ν)",
          },
        },
      },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B5%E1%BC%B0%CE%BC%CE%AF
    type: "verb",
    ground: "εἶναι",
    passport: "εἰμί",
    dutch: "zijn, zich bevinden, bestaan",
    explicitConjugations: {
      present: {
        active: {
          indicative: {
            "s1": "εἰμί",
            "s2": "εἶ",
            "s3": ["ἐστί", "ἐστίν"],
            "d2": "ἐστόν",
            "d3": "ἐστόν",
            "p1": "ἐσμέν",
            "p2": "ἐστέ",
            "p3": ["εἰσί", "εἰσίν"],
          },
        },
      },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%80%CE%BA%CE%BF%CF%8D%CF%89
    type: "verb",
    dutch: "horen, luisteren",
    ground: "ᾰ̓κούειν",
    passport: "ᾰ̓κούω",
    explicitConjugations: {
      present: {
        active: {
          indicative: {
            "s1": "ᾰ̓κούω",
            "s2": "ᾰ̓κούεις",
            "s3": "ᾰ̓κούει",
            "d2": "ᾰ̓κούετον",
            "d3": "ᾰ̓κούετον",
            "p1": "ᾰ̓κούομεν",
            "p2": "ᾰ̓κούετε",
            "p3": "ᾰ̓κούουσῐ(ν)",
          },
        },
      },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B2%CE%BB%CE%AD%CF%80%CF%89
    type: "verb",
    dutch: "kijken",
    ground: "βλέπειν",
    passport: "βλέπω",
    explicitConjugations: {
      present: {
        active: {
          indicative: {
            "s1": "βλέπω",
            "s2": "βλέπεις",
            "s3": "βλέπει",
            "d2": "βλέπετον",
            "d3": "βλέπετον",
            "p1": "βλέπομεν",
            "p2": "βλέπετε",
            "p3": "βλέπουσῐ(ν)",
          },
        },
      },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%90%CF%83%CE%B8%CE%AF%CF%89
    type: "verb",
    dutch: "eten",
    ground: "ἐσθῐ́ειν",
    passport: "ἐσθῐ́ω",
    explicitConjugations: {
      present: {
        active: {
          indicative: {
            "s1": "ἐσθῐ́ω",
            "s2": "ἐσθῐ́εις",
            "s3": "ἐσθῐ́ει",
            "d2": "ἐσθῐ́ετον",
            "d3": "ἐσθῐ́ετον",
            "p1": "ἐσθῐ́ομεν",
            "p2": "ἐσθῐ́ετε",
            "p3": "ἐσθῐ́ουσῐ(ν)",
          },
        },
      },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%94%CF%87%CF%89
    type: "verb",
    dutch: "hebben, houden",
    ground: "ἔχειν",
    passport: "ἔχω",
    explicitConjugations: {
      present: {
        active: {
          indicative: {
            "s1": "ἔχω",
            "s2": "ἔχεις",
            "s3": "ἔχει",
            "d2": "ἔχετον",
            "d3": "ἔχετον",
            "p1": "ἔχομεν",
            "p2": "ἔχετε",
            "p3": "ἔχουσῐ(ν)",
          },
        },
      },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BB%CE%AD%CE%B3%CF%89
    type: "verb",
    dutch: "zeggen, spreken",
    ground: "λέγειν",
    passport: "λέγω",
    explicitConjugations: {
      present: {
        active: {
          indicative: {
            "s1": "λέγω",
            "s2": "λέγεις",
            "s3": "λέγει",
            "d2": "λέγετον",
            "d3": "λέγετον",
            "p1": "λέγομεν",
            "p2": "λέγετε",
            "p3": "λέγουσῐ(ν)",
          },
        },
      },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CF%80%CE%AD%CE%BC%CF%80%CF%89
    type: "verb",
    dutch: "zenden",
    ground: "πέμπειν",
    passport: "πέμπω",
    explicitConjugations: {
      present: {
        active: {
          indicative: {
            "s1": "πέμπω",
            "s2": "πέμπεις",
            "s3": "πέμπει",
            "d2": "πέμπετον",
            "d3": "πέμπετον",
            "p1": "πέμπομεν",
            "p2": "πέμπετε",
            "p3": "πέμπουσῐ(ν)",
          },
        },
      },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CF%80%CE%AF%CE%BD%CF%89
    type: "verb",
    dutch: "drinken",
    ground: "πῑ́νειν",
    passport: "πῑ́νω",
    explicitConjugations: {
      present: {
        active: {
          indicative: {
            "s1": "πῑ́νω",
            "s2": "πῑ́νεις",
            "s3": "πῑ́νει",
            "d2": "πῑ́νετον",
            "d3": "πῑ́νετον",
            "p1": "πῑ́νομεν",
            "p2": "πῑ́νετε",
            "p3": "πῑ́νουσῐ(ν)",
          },
        },
      },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CF%84%CF%81%CE%AD%CF%86%CF%89
    type: "verb",
    dutch: "voeden, opvoeden",
    ground: "τρέφειν",
    passport: "τρέφω",
    explicitConjugations: {
      present: {
        active: {
          indicative: {
            "s1": "τρέφω",
            "s2": "τρέφεις",
            "s3": "τρέφει",
            "d2": "τρέφετον",
            "d3": "τρέφετον",
            "p1": "τρέφομεν",
            "p2": "τρέφετε",
            "p3": "τρέφουσῐ(ν)",
          },
        },
      },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%84%CE%B3%CF%89
    type: "verb",
    dutch: "voeren, brengen",
    ground: "ᾰ̓́γειν",
    passport: "ᾰ̓́γω",
    explicitConjugations: {
      present: {
        active: {
          indicative: {
            "s1": "ᾰ̓́γω",
            "s2": "ᾰ̓́γεις",
            "s3": "ᾰ̓́γει",
            "d2": "ᾰ̓́γετον",
            "d3": "ᾰ̓́γετον",
            "p1": "ᾰ̓́γομεν",
            "p2": "ᾰ̓́γετε",
            "p3": "ᾰ̓́γουσῐ(ν)",
          },
        },
      },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B5%E1%BD%91%CF%81%CE%AF%CF%83%CE%BA%CF%89
    type: "verb",
    dutch: "vinden",
    ground: "εὑρῐ́σκειν",
    passport: "εὑρῐ́σκω",
    explicitConjugations: {
      present: {
        active: {
          indicative: {
            "s1": "εὑρῐ́σκω",
            "s2": "εὑρῐ́σκεις",
            "s3": "εὑρῐ́σκει",
            "d2": "εὑρῐ́σκετον",
            "d3": "εὑρῐ́σκετον",
            "p1": "εὑρῐ́σκομεν",
            "p2": "εὑρῐ́σκετε",
            "p3": "εὑρῐ́σκουσῐ(ν)",
          },
        },
      },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CF%87%CE%B1%CE%AF%CF%81%CF%89
    type: "verb",
    dutch: "zich verheugen (over), blij zijn (met)",
    ground: "χαίρειν",
    passport: "χαίρω (+ datief)",
    explicitConjugations: {
      present: {
        active: {
          indicative: {
            "s1": "χαίρω",
            "s2": "χαίρεις",
            "s3": "χαίρει",
            "d2": "χαίρετον",
            "d3": "χαίρετον",
            "p1": "χαίρομεν",
            "p2": "χαίρετε",
            "p3": "χαίρουσῐ(ν)",
          },
        },
      },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%80%CF%80%CE%BF%CE%BA%CF%84%CE%B5%CE%AF%CE%BD%CF%89
    type: "verb",
    dutch: "doden",
    ground: "ἀποκτείνειν",
    passport: "ἀποκτείνω",
    explicitConjugations: {
      present: {
        active: {
          indicative: {
            "s1": "ἀποκτείνω",
            "s2": "ἀποκτείνεις",
            "s3": "ἀποκτείνει",
            "d2": "ἀποκτείνετον",
            "d3": "ἀποκτείνετον",
            "p1": "ἀποκτείνομεν",
            "p2": "ἀποκτείνετε",
            "p3": "ἀποκτείνουσῐ(ν)",
          },
        },
      },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B2%CE%B1%CE%AF%CE%BD%CF%89
    type: "verb",
    dutch: "gaan",
    ground: "βαίνειν",
    passport: "βαίνω",
    explicitConjugations: {
      present: {
        active: {
          indicative: {
            "s1": "βαίνω",
            "s2": "βαίνεις",
            "s3": "βαίνει",
            "d2": "βαίνετον",
            "d3": "βαίνετον",
            "p1": "βαίνομεν",
            "p2": "βαίνετε",
            "p3": "βαίνουσῐ(ν)",
          },
        },
      },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B8%CE%B1%CF%85%CE%BC%CE%AC%CE%B6%CF%89
    type: "verb",
    dutch: "verwonderd zijn, bewonderen",
    ground: "θαυμᾰ́ζειν",
    passport: "θαυμᾰ́ζω",
    explicitConjugations: {
      present: {
        active: {
          indicative: {
            "s1": "θαυμᾰ́ζω",
            "s2": "θαυμᾰ́ζεις",
            "s3": "θαυμᾰ́ζει",
            "d2": "θαυμᾰ́ζετον",
            "d3": "θαυμᾰ́ζετον",
            "p1": "θαυμᾰ́ζομεν",
            "p2": "θαυμᾰ́ζετε",
            "p3": "θαυμᾰ́ζουσῐ(ν)",
          },
        },
      },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CF%80%CE%B1%CF%81%CE%AD%CF%87%CF%89
    type: "verb",
    dutch: "geven, verschaffen",
    ground: "πᾰρέχειν",
    passport: "πᾰρέχω",
    explicitConjugations: {
      present: {
        active: {
          indicative: {
            "s1": "πᾰρέχω",
            "s2": "πᾰρέχεις",
            "s3": "πᾰρέχει",
            "d2": "πᾰρέχετον",
            "d3": "πᾰρέχετον",
            "p1": "πᾰρέχομεν",
            "p2": "πᾰρέχετε",
            "p3": "πᾰρέχουσῐ(ν)",
          },
        },
      },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CF%86%CE%AD%CF%81%CF%89
    type: "verb",
    dutch: "dragen, verdragen",
    ground: "φέρειν",
    passport: "φέρω",
    explicitConjugations: {
      present: {
        active: {
          indicative: {
            "s1": "φέρω",
            "s2": "φέρεις",
            "s3": "φέρει",
            "d2": "φέρετον",
            "d3": "φέρετον",
            "p1": "φέρομεν",
            "p2": "φέρετε",
            "p3": "φέρουσῐ(ν)",
          },
        },
      },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CF%86%CE%B5%CF%8D%CE%B3%CF%89
    type: "verb",
    dutch: "vluchten",
    ground: "φεύγειν",
    passport: "φεύγω",
    explicitConjugations: {
      present: {
        active: {
          indicative: {
            "s1": "φεύγω",
            "s2": "φεύγεις",
            "s3": "φεύγει",
            "d2": "φεύγετον",
            "d3": "φεύγετον",
            "p1": "φεύγομεν",
            "p2": "φεύγετε",
            "p3": "φεύγουσῐ(ν)",
          },
        },
      },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%90%CE%B8%CE%AD%CE%BB%CF%89
    type: "verb",
    dutch: "willen",
    ground: "ἐθέλειν",
    passport: "ἐθέλω / θέλω",
    explicitConjugations: {
      present: {
        active: {
          indicative: {
            "s1": "ἐθέλω",
            "s2": "ἐθέλεις",
            "s3": "ἐθέλει",
            "d2": "ἐθέλετον",
            "d3": "ἐθέλετον",
            "p1": "ἐθέλομεν",
            "p2": "ἐθέλετε",
            "p3": "ἐθέλουσῐ(ν)",
          },
        },
      },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BD%80%CE%BD%CE%BF%CE%BC%CE%AC%CE%B6%CF%89
    type: "verb",
    dutch: "noemen; pass.: heten",
    ground: "ὀνομᾰ́ζειν",
    passport: "ὀνομᾰ́ζω",
    explicitConjugations: {
      present: {
        active: {
          indicative: {
            "s1": "ὀνομᾰ́ζω",
            "s2": "ὀνομᾰ́ζεις",
            "s3": "ὀνομᾰ́ζει",
            "d2": "ὀνομᾰ́ζετον",
            "d3": "ὀνομᾰ́ζετον",
            "p1": "ὀνομᾰ́ζομεν",
            "p2": "ὀνομᾰ́ζετε",
            "p3": "ὀνομᾰ́ζουσῐ(ν)",
          },
        },
      },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CF%83%E1%BF%B4%CE%B6%CF%89
    type: "verb",
    dutch: "redden, bewaren",
    ground: "σῴζειν",
    passport: "σῴζω",
    explicitConjugations: {
      present: {
        active: {
          indicative: {
            "s1": "σῴζω",
            "s2": "σῴζεις",
            "s3": "σῴζει",
            "d2": "σῴζετον",
            "d3": "σῴζετον",
            "p1": "σῴζομεν",
            "p2": "σῴζετε",
            "p3": "σῴζουσῐ(ν)",
          },
        },
      },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%A5%CE%BA%CF%89
    type: "verb",
    dutch: "komen, gekomen zijn",
    ground: "ἥκειν",
    passport: "ἥκω",
    explicitConjugations: {
      present: {
        active: {
          indicative: {
            "s1": "ἥκω",
            "s2": "ἥκεις",
            "s3": "ἥκει",
            "d2": "ἥκετον",
            "d3": "ἥκετον",
            "p1": "ἥκομεν",
            "p2": "ἥκετε",
            "p3": "ἥκουσῐ(ν)",
          },
        },
      },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B2%CE%AC%CE%BB%CE%BB%CF%89
    type: "verb",
    dutch: "werpen, treffen",
    ground: "βᾰ́λλειν",
    passport: "βᾰ́λλω",
    explicitConjugations: {
      present: {
        active: {
          indicative: {
            "s1": "βᾰ́λλω",
            "s2": "βᾰ́λλεις",
            "s3": "βᾰ́λλει",
            "d2": "βᾰ́λλετον",
            "d3": "βᾰ́λλετον",
            "p1": "βᾰ́λλομεν",
            "p2": "βᾰ́λλετε",
            "p3": "βᾰ́λλουσῐ(ν)",
          },
        },
      },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BB%CE%B5%CE%AF%CF%80%CF%89
    type: "verb",
    dutch: "verlaten, achterlaten",
    ground: "λείπειν",
    passport: "λείπω",
    explicitConjugations: {
      present: {
        active: {
          indicative: {
            "s1": "λείπω",
            "s2": "λείπεις",
            "s3": "λείπει",
            "d2": "λείπετον",
            "d3": "λείπετον",
            "p1": "λείπομεν",
            "p2": "λείπετε",
            "p3": "λείπουσῐ(ν)",
          },
        },
      },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CF%84%CE%AF%CE%BA%CF%84%CF%89
    type: "verb",
    dutch: "baren, ter wereld brengen",
    ground: "τῐ́κτειν",
    passport: "τῐ́κτω",
    explicitConjugations: {
      present: {
        active: {
          indicative: {
            "s1": "τῐ́κτω",
            "s2": "τῐ́κτεις",
            "s3": "τῐ́κτει",
            "d2": "τῐ́κτετον",
            "d3": "τῐ́κτετον",
            "p1": "τῐ́κτομεν",
            "p2": "τῐ́κτετε",
            "p3": "τῐ́κτουσῐ(ν)",
          },
        },
      },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B4%CE%B9%CE%B1%CF%86%CE%AD%CF%81%CF%89
    type: "verb",
    dutch: "tot het einde dragen, verschillen",
    ground: "δῐᾰφέρειν",
    passport: "δῐᾰφέρω",
    explicitConjugations: {
      present: {
        active: {
          indicative: {
            "s1": "δῐᾰφέρω",
            "s2": "δῐᾰφέρεις",
            "s3": "δῐᾰφέρει",
            "d2": "δῐᾰφέρετον",
            "d3": "δῐᾰφέρετον",
            "p1": "δῐᾰφέρομεν",
            "p2": "δῐᾰφέρετε",
            "p3": "δῐᾰφέρουσῐ(ν)",
          },
        },
      },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%80%CE%BD%CE%B1%CE%B3%CE%B9%CE%B3%CE%BD%CF%8E%CF%83%CE%BA%CF%89
    type: "verb",
    dutch: "herkennen, lezen",
    ground: "ᾰ̓νᾰγῐγνώσκειν",
    passport: "ᾰ̓νᾰγῐγνώσκω",
    explicitConjugations: {
      present: {
        active: {
          indicative: {
            "s1": "ᾰ̓νᾰγῐγνώσκω",
            "s2": "ᾰ̓νᾰγῐγνώσκεις",
            "s3": "ᾰ̓νᾰγῐγνώσκει",
            "d2": "ᾰ̓νᾰγῐγνώσκετον",
            "d3": "ᾰ̓νᾰγῐγνώσκετον",
            "p1": "ᾰ̓νᾰγῐγνώσκομεν",
            "p2": "ᾰ̓νᾰγῐγνώσκετε",
            "p3": "ᾰ̓νᾰγῐγνώσκουσῐ(ν)",
          },
        },
      },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BB%CE%B1%CE%BC%CE%B2%CE%AC%CE%BD%CF%89
    type: "verb",
    dutch: "nemen, krijgen",
    ground: "λᾰμβᾰ́νειν",
    passport: "λᾰμβᾰ́νω",
    explicitConjugations: {
      present: {
        active: {
          indicative: {
            "s1": "λᾰμβᾰ́νω",
            "s2": "λᾰμβᾰ́νεις",
            "s3": "λᾰμβᾰ́νει",
            "d2": "λᾰμβᾰ́νετον",
            "d3": "λᾰμβᾰ́νετον",
            "p1": "λᾰμβᾰ́νομεν",
            "p2": "λᾰμβᾰ́νετε",
            "p3": "λᾰμβᾰ́νουσῐ(ν)",
          },
        },
      },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CF%80%CF%81%CE%AC%CF%83%CF%83%CF%89
    type: "verb",
    dutch: "verrichten, tot stand brengen",
    ground: "πρᾱ́ττειν",
    passport: "πρᾱ́ττω",
    explicitConjugations: {
      present: {
        active: {
          indicative: {
            "s1": "πρᾱ́ττω",
            "s2": "πρᾱ́ττεις",
            "s3": "πρᾱ́ττει",
            "d2": "πρᾱ́ττετον",
            "d3": "πρᾱ́ττετον",
            "p1": "πρᾱ́ττομεν",
            "p2": "πρᾱ́ττετε",
            "p3": "πρᾱ́ττουσῐ(ν)",
          },
        },
      },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B3%CE%B9%CE%B3%CE%BD%CF%8E%CF%83%CE%BA%CF%89
    type: "verb",
    dutch: "leren kennen, begrijpen",
    ground: "γιγνώσκειν",
    passport: "γιγνώσκω",
    explicitConjugations: {
      present: {
        active: {
          indicative: {
            "s1": "γιγνώσκω",
            "s2": "γιγνώσκεις",
            "s3": "γιγνώσκει",
            "d2": "γιγνώσκετον",
            "d3": "γιγνώσκετον",
            "p1": "γιγνώσκομεν",
            "p2": "γιγνώσκετε",
            "p3": "γιγνώσκουσῐ(ν)",
          },
        },
      },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BC%CE%AD%CE%BD%CF%89
    type: "verb",
    dutch: "blijven, verblijven",
    ground: "μένειν",
    passport: "μένω",
    explicitConjugations: {
      present: {
        active: {
          indicative: {
            "s1": "μένω",
            "s2": "μένεις",
            "s3": "μένει",
            "d2": "μένετον",
            "d3": "μένετον",
            "p1": "μένομεν",
            "p2": "μένετε",
            "p3": "μένουσῐ(ν)",
          },
        },
      },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B4%CE%B9%CE%B1%CF%86%CE%B8%CE%B5%CE%AF%CF%81%CF%89
    type: "verb",
    dutch: "vernielen, bederven",
    ground: "δῐᾰφθείρειν",
    passport: "δῐᾰφθείρω",
    explicitConjugations: {
      present: {
        active: {
          indicative: {
            "s1": "δῐᾰφθείρω",
            "s2": "δῐᾰφθείρεις",
            "s3": "δῐᾰφθείρει",
            "d2": "δῐᾰφθείρετον",
            "d3": "δῐᾰφθείρετον",
            "p1": "δῐᾰφθείρομεν",
            "p2": "δῐᾰφθείρετε",
            "p3": "δῐᾰφθείρουσῐ(ν)",
          },
        },
      },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B1%E1%BC%B0%CF%83%CE%B8%CE%AC%CE%BD%CE%BF%CE%BC%CE%B1%CE%B9
    "type": "verb",
    "ground": "αἰσθάνεσθαι",
    "passport": "αἰσθάνομαι",
    "dutch": "merken, gewaarworden",
    "explicitConjugations": {
      "present": {
        "middlePassive": {
          "indicative": {
            "s1": "αἰσθάνομαι",
            "s2": ["αἰσθάνῃ", "αἰσθάνει"],
            "s3": "αἰσθάνεται",
            "d2": "αἰσθάνεσθον",
            "d3": "αἰσθάνεσθον",
            "p1": "αἰσθανόμεθᾰ",
            "p2": "αἰσθάνεσθε",
            "p3": "αἰσθάνονται"
          },
          "subjunctive": {
            "s1": "αἰσθάνωμαι",
            "s2": "αἰσθάνῃ",
            "s3": "αἰσθάνηται",
            "d2": "αἰσθάνησθον",
            "d3": "αἰσθάνησθον",
            "p1": "αἰσθανώμεθᾰ",
            "p2": "αἰσθάνησθε",
            "p3": "αἰσθάνωνται"
          },
          "optative": {
            "s1": "αἰσθανοίμην",
            "s2": "αἰσθάνοιο",
            "s3": "αἰσθάνοιτο",
            "d2": "αἰσθάνοισθον",
            "d3": "αἰσθανοίσθην",
            "p1": "αἰσθανοίμεθᾰ",
            "p2": "αἰσθάνοισθε",
            "p3": "αἰσθάνοιντο"
          },
          "imperative": {
            "s1": "",
            "s2": "αἰσθάνου",
            "s3": "αἰσθανέσθω",
            "d2": "αἰσθάνεσθον",
            "d3": "αἰσθανέσθων",
            "p1": "",
            "p2": "αἰσθάνεσθε",
            "p3": "αἰσθανέσθων"
          }
        },
        "infinitive": {
          "active": "",
          "middle": "αἰσθάνεσθαι"
        },
        "participle": {
          "m": {
            "active": "",
            "middle": "αἰσθανόμενος"
          },
          "f": {
            "active": "",
            "middle": "αἰσθανομένη"
          },
          "n": {
            "active": "",
            "middle": "αἰσθανόμενον"
          }
        }
      },
      "imperfect": {
        "middlePassive": {
          "indicative": {
            "s1": "ᾐσθανόμην",
            "s2": "ᾐσθάνου",
            "s3": "ᾐσθάνετο",
            "d2": "ᾐσθάνεσθον",
            "d3": "ᾐσθανέσθην",
            "p1": "ᾐσθανόμεθᾰ",
            "p2": "ᾐσθάνεσθε",
            "p3": "ᾐσθάνοντο"
          }
        }
      }
    }
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%80%CF%80%CE%BF%CE%BA%CF%81%CE%AF%CE%BD%CF%89
    "type": "verb",
    "ground": "ἀποκρίνεσθαι",
    "passport": "ἀπο-κρίνομαι",
    "dutch": "antwoorden",
    "explicitConjugations": {
      "present": {
        "active": {
          "indicative": {
            "s1": "ᾰ̓ποκρῑνω",
            "s2": "ᾰ̓ποκρῑνεις",
            "s3": "ᾰ̓ποκρῑνει",
            "d2": "ᾰ̓ποκρῑνετον",
            "d3": "ᾰ̓ποκρῑνετον",
            "p1": "ᾰ̓ποκρῑνομεν",
            "p2": "ᾰ̓ποκρῑνετε",
            "p3": "ᾰ̓ποκρῑνουσῐ(ν)"
          },
          "subjunctive": {
            "s1": "ᾰ̓ποκρῑνω",
            "s2": "ᾰ̓ποκρῑνῃς",
            "s3": "ᾰ̓ποκρῑνῃ",
            "d2": "ᾰ̓ποκρῑνητον",
            "d3": "ᾰ̓ποκρῑνητον",
            "p1": "ᾰ̓ποκρῑνωμεν",
            "p2": "ᾰ̓ποκρῑνητε",
            "p3": "ᾰ̓ποκρῑνωσῐ(ν)"
          },
          "optative": {
            "s1": "ᾰ̓ποκρῑνοιμῐ",
            "s2": "ᾰ̓ποκρῑνοις",
            "s3": "ᾰ̓ποκρῑνοι",
            "d2": "ᾰ̓ποκρῑνοιτον",
            "d3": "ᾰ̓ποκρῑνοίτην",
            "p1": "ᾰ̓ποκρῑνοιμεν",
            "p2": "ᾰ̓ποκρῑνοιτε",
            "p3": "ᾰ̓ποκρῑνοιεν"
          },
          "imperative": {
            "s1": "",
            "s2": "ᾰ̓πόκρῑνε",
            "s3": "ᾰ̓ποκρῑνέτω",
            "d2": "ᾰ̓ποκρῑνετον",
            "d3": "ᾰ̓ποκρῑνέτων",
            "p1": "",
            "p2": "ᾰ̓ποκρῑνετε",
            "p3": "ᾰ̓ποκρῑνόντων"
          }
        },
        "middlePassive": {
          "indicative": {
            "s1": "ᾰ̓ποκρῑνομαι",
            "s2": ["ᾰ̓ποκρῑνῃ", "ᾰ̓ποκρῑνει"],
            "s3": "ᾰ̓ποκρῑνεται",
            "d2": "ᾰ̓ποκρῑνεσθον",
            "d3": "ᾰ̓ποκρῑνεσθον",
            "p1": "ᾰ̓ποκρῑνόμεθᾰ",
            "p2": "ᾰ̓ποκρῑνεσθε",
            "p3": "ᾰ̓ποκρῑνονται"
          },
          "subjunctive": {
            "s1": "ᾰ̓ποκρῑνωμαι",
            "s2": "ᾰ̓ποκρῑνῃ",
            "s3": "ᾰ̓ποκρῑνηται",
            "d2": "ᾰ̓ποκρῑνησθον",
            "d3": "ᾰ̓ποκρῑνησθον",
            "p1": "ᾰ̓ποκρῑνώμεθᾰ",
            "p2": "ᾰ̓ποκρῑνησθε",
            "p3": "ᾰ̓ποκρῑνωνται"
          },
          "optative": {
            "s1": "ᾰ̓ποκρῑνοίμην",
            "s2": "ᾰ̓ποκρῑνοιο",
            "s3": "ᾰ̓ποκρῑνοιτο",
            "d2": "ᾰ̓ποκρῑνοισθον",
            "d3": "ᾰ̓ποκρῑνοίσθην",
            "p1": "ᾰ̓ποκρῑνοίμεθᾰ",
            "p2": "ᾰ̓ποκρῑνοισθε",
            "p3": "ᾰ̓ποκρῑνοιντο"
          },
          "imperative": {
            "s1": "",
            "s2": "ᾰ̓ποκρῑνου",
            "s3": "ᾰ̓ποκρῑνέσθω",
            "d2": "ᾰ̓ποκρῑνεσθον",
            "d3": "ᾰ̓ποκρῑνέσθων",
            "p1": "",
            "p2": "ᾰ̓ποκρῑνεσθε",
            "p3": "ᾰ̓ποκρῑνέσθων"
          }
        },
        "infinitive": {
          "active": "ᾰ̓ποκρῑνειν",
          "middle": "ᾰ̓ποκρῑνεσθαι"
        },
        "participle": {
          "m": {
            "active": "ᾰ̓ποκρῑνων",
            "middle": "ᾰ̓ποκρῑνόμενος"
          },
          "f": {
            "active": "ᾰ̓ποκρῑνουσᾰ",
            "middle": "ᾰ̓ποκρῑνομένη"
          },
          "n": {
            "active": "ᾰ̓ποκρῖνον",
            "middle": "ᾰ̓ποκρῑνόμενον"
          }
        }
      },
      "imperfect": {
        "active": {
          "indicative": {
            "s1": "ᾰ̓πέκρῑνον",
            "s2": "ᾰ̓πέκρῑνες",
            "s3": "ᾰ̓πέκρῑνε(ν)",
            "d2": "ᾰ̓πεκρῑνετον",
            "d3": "ᾰ̓πεκρῑνέτην",
            "p1": "ᾰ̓πεκρῑνομεν",
            "p2": "ᾰ̓πεκρῑνετε",
            "p3": "ᾰ̓πέκρῑνον"
          }
        },
        "middlePassive": {
          "indicative": {
            "s1": "ᾰ̓πεκρῑνόμην",
            "s2": "ᾰ̓πεκρῑνου",
            "s3": "ᾰ̓πεκρῑνετο",
            "d2": "ᾰ̓πεκρῑνεσθον",
            "d3": "ᾰ̓πεκρῑνέσθην",
            "p1": "ᾰ̓πεκρῑνόμεθᾰ",
            "p2": "ᾰ̓πεκρῑνεσθε",
            "p3": "ᾰ̓πεκρῑνοντο"
          }
        }
      }
    }
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B2%CE%BF%CF%8D%CE%BB%CE%BF%CE%BC%CE%B1%CE%B9
    "type": "verb",
    "ground": "βούλεσθαι",
    "passport": "βούλομαι",
    "dutch": "willen",
    "explicitConjugations": {
      "present": {
        "middlePassive": {
          "indicative": {
            "s1": "βούλομαι",
            "s2": "βούλῃ,βούλει",
            "s3": "βούλεται",
            "d2": "βούλεσθον",
            "d3": "βούλεσθον",
            "p1": "βουλόμεθᾰ",
            "p2": "βούλεσθε",
            "p3": "βούλονται"
          },
          "subjunctive": {
            "s1": "βούλωμαι",
            "s2": "βούλῃ",
            "s3": "βούληται",
            "d2": "βούλησθον",
            "d3": "βούλησθον",
            "p1": "βουλώμεθᾰ",
            "p2": "βούλησθε",
            "p3": "βούλωνται"
          },
          "optative": {
            "s1": "βουλοίμην",
            "s2": "βούλοιο",
            "s3": "βούλοιτο",
            "d2": "βούλοισθον",
            "d3": "βουλοίσθην",
            "p1": "βουλοίμεθᾰ",
            "p2": "βούλοισθε",
            "p3": "βούλοιντο"
          },
          "imperative": {
            "s1": "",
            "s2": "βούλου",
            "s3": "βουλέσθω",
            "d2": "βούλεσθον",
            "d3": "βουλέσθων",
            "p1": "",
            "p2": "βούλεσθε",
            "p3": "βουλέσθων"
          }
        },
        "infinitive": {
          "active": "",
          "middle": "βούλεσθαι"
        },
        "participle": {
          "m": {
            "active": "",
            "middle": "βουλόμενος"
          },
          "f": {
            "active": "",
            "middle": "βουλομένη"
          },
          "n": {
            "active": "",
            "middle": "βουλόμενον"
          }
        }
      }
    }
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B3%CE%AF%CE%B3%CE%BD%CE%BF%CE%BC%CE%B1%CE%B9
    "type": "verb",
    "ground": "γίγνεσθαι",
    "passport": "γίγνομαι",
    "dutch": "worden, ontstaan, gebeuren",
    "explicitConjugations": {
      "present": {
        "middlePassive": {
          "indicative": {
            "s1": "γίγνομαι",
            "s2": "γίγνῃ,γίγνει",
            "s3": "γίγνεται",
            "d2": "γίγνεσθον",
            "d3": "γίγνεσθον",
            "p1": "γιγνόμεθᾰ",
            "p2": "γίγνεσθε",
            "p3": "γίγνονται"
          },
          "subjunctive": {
            "s1": "γίγνωμαι",
            "s2": "γίγνῃ",
            "s3": "γίγνηται",
            "d2": "γίγνησθον",
            "d3": "γίγνησθον",
            "p1": "γιγνώμεθᾰ",
            "p2": "γίγνησθε",
            "p3": "γίγνωνται"
          },
          "optative": {
            "s1": "γιγνοίμην",
            "s2": "γίγνοιο",
            "s3": "γίγνοιτο",
            "d2": "γίγνοισθον",
            "d3": "γιγνοίσθην",
            "p1": "γιγνοίμεθᾰ",
            "p2": "γίγνοισθε",
            "p3": "γίγνοιντο"
          },
          "imperative": {
            "s1": "",
            "s2": "γίγνου",
            "s3": "γιγνέσθω",
            "d2": "γίγνεσθον",
            "d3": "γιγνέσθων",
            "p1": "",
            "p2": "γίγνεσθε",
            "p3": "γιγνέσθων"
          }
        },
        "infinitive": {
          "active": "",
          "middle": "γίγνεσθαι"
        },
        "participle": {
          "m": {
            "active": "",
            "middle": "γιγνόμενος"
          },
          "f": {
            "active": "",
            "middle": "γιγνομένη"
          },
          "n": {
            "active": "",
            "middle": "γιγνόμενον"
          }
        }
      },
      "future": {
        "middle": {
          "indicative": {
            "s1": "γενήσομαι",
            "s2": "γενήσῃ,γενήσει",
            "s3": "γενήσεται",
            "d2": "γενήσεσθον",
            "d3": "γενήσεσθον",
            "p1": "γενησόμεθᾰ",
            "p2": "γενήσεσθε",
            "p3": "γενήσονται"
          },
          "optative": {
            "s1": "γενησοίμην",
            "s2": "γενήσοιο",
            "s3": "γενήσοιτο",
            "d2": "γενήσοισθον",
            "d3": "γενησοίσθην",
            "p1": "γενησοίμεθᾰ",
            "p2": "γενήσοισθε",
            "p3": "γενήσοιντο"
          }
        },
        "passive": {
          "indicative": {
            "s1": "γενηθήσομαι",
            "s2": "γενηθήσῃ",
            "s3": "γενηθήσεται",
            "d2": "γενηθήσεσθον",
            "d3": "γενηθήσεσθον",
            "p1": "γενηθησόμεθᾰ",
            "p2": "γενηθήσεσθε",
            "p3": "γενηθήσονται"
          },
          "optative": {
            "s1": "γενηθησοίμην",
            "s2": "γενηθήσοιο",
            "s3": "γενηθήσοιτο",
            "d2": "γενηθήσοισθον",
            "d3": "γενηθησοίσθην",
            "p1": "γενηθησοίμεθᾰ",
            "p2": "γενηθήσοισθε",
            "p3": "γενηθήσοιντο"
          }
        },
        "infinitive": {
          "active": "",
          "middle": "γενήσεσθαι",
          "passive": "γενηθήσεσθαι"
        },
        "participle": {
          "m": {
            "active": "",
            "middle": "γενησόμενος",
            "passive": "γενηθησόμενος"
          },
          "f": {
            "active": "",
            "middle": "γενησομένη",
            "passive": "γενηθησομένη"
          },
          "n": {
            "active": "",
            "middle": "γενησόμενον",
            "passive": "γενηθησόμενον"
          }
        }
      }
    }
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BC%CE%AC%CF%87%CE%BF%CE%BC%CE%B1%CE%B9
    "type": "verb",
    "ground": "μᾰχεσθαι",
    "passport": "μᾰχομαι",
    "dutch": "strijden, vechten",
    "explicitConjugations": {
      "present": {
        "middlePassive": {
          "indicative": {
            "s1": "μᾰχομαι",
            "s2": "μᾰχῃ,μᾰχει",
            "s3": "μᾰχεται",
            "d2": "μᾰχεσθον",
            "d3": "μᾰχεσθον",
            "p1": "μᾰχόμεθᾰ",
            "p2": "μᾰχεσθε",
            "p3": "μᾰχονται"
          },
          "subjunctive": {
            "s1": "μᾰχωμαι",
            "s2": "μᾰχῃ",
            "s3": "μᾰχηται",
            "d2": "μᾰχησθον",
            "d3": "μᾰχησθον",
            "p1": "μᾰχώμεθᾰ",
            "p2": "μᾰχησθε",
            "p3": "μᾰχωνται"
          },
          "optative": {
            "s1": "μᾰχοίμην",
            "s2": "μᾰχοιο",
            "s3": "μᾰχοιτο",
            "d2": "μᾰχοισθον",
            "d3": "μᾰχοίσθην",
            "p1": "μᾰχοίμεθᾰ",
            "p2": "μᾰχοισθε",
            "p3": "μᾰχοιντο"
          },
          "imperative": {
            "s1": "",
            "s2": "μᾰχου",
            "s3": "μᾰχέσθω",
            "d2": "μᾰχεσθον",
            "d3": "μᾰχέσθων",
            "p1": "",
            "p2": "μᾰχεσθε",
            "p3": "μᾰχέσθων"
          }
        },
        "infinitive": {
          "active": "",
          "middle": "μᾰχεσθαι"
        },
        "participle": {
          "m": {
            "active": "",
            "middle": "μᾰχόμενος"
          },
          "f": {
            "active": "",
            "middle": "μᾰχομένη"
          },
          "n": {
            "active": "",
            "middle": "μᾰχόμενον"
          }
        }
      }
    }
  },
  {
    // https://en.wiktionary.org/wiki/%CF%80%CE%B1%CF%8D%CF%89
    "type": "verb",
    "ground": "παύειν",
    "passport": "παύω",
    "dutch": "doen ophouden, doen stoppen",
    "explicitConjugations": {
      "present": {
        "active": {
          "indicative": {
            "s1": "παύω",
            "s2": "παύεις",
            "s3": "παύει",
            "d2": "παύετον",
            "d3": "παύετον",
            "p1": "παύομεν",
            "p2": "παύετε",
            "p3": "παύουσῐ(ν)"
          },
          "subjunctive": {
            "s1": "παύω",
            "s2": "παύῃς",
            "s3": "παύῃ",
            "d2": "παύητον",
            "d3": "παύητον",
            "p1": "παύωμεν",
            "p2": "παύητε",
            "p3": "παύωσῐ(ν)"
          },
          "optative": {
            "s1": "παύοιμῐ",
            "s2": "παύοις",
            "s3": "παύοι",
            "d2": "παύοιτον",
            "d3": "παυοίτην",
            "p1": "παύοιμεν",
            "p2": "παύοιτε",
            "p3": "παύοιεν"
          },
          "imperative": {
            "s1": "",
            "s2": "παῦε",
            "s3": "παυέτω",
            "d2": "παύετον",
            "d3": "παυέτων",
            "p1": "",
            "p2": "παύετε",
            "p3": "παυόντων"
          }
        },
        "middlePassive": {
          "indicative": {
            "s1": "παύομαι",
            "s2": "παύῃ,παύει",
            "s3": "παύεται",
            "d2": "παύεσθον",
            "d3": "παύεσθον",
            "p1": "παυόμεθᾰ",
            "p2": "παύεσθε",
            "p3": "παύονται"
          },
          "subjunctive": {
            "s1": "παύωμαι",
            "s2": "παύῃ",
            "s3": "παύηται",
            "d2": "παύησθον",
            "d3": "παύησθον",
            "p1": "παυώμεθᾰ",
            "p2": "παύησθε",
            "p3": "παύωνται"
          },
          "optative": {
            "s1": "παυοίμην",
            "s2": "παύοιο",
            "s3": "παύοιτο",
            "d2": "παύοισθον",
            "d3": "παυοίσθην",
            "p1": "παυοίμεθᾰ",
            "p2": "παύοισθε",
            "p3": "παύοιντο"
          },
          "imperative": {
            "s1": "",
            "s2": "παύου",
            "s3": "παυέσθω",
            "d2": "παύεσθον",
            "d3": "παυέσθων",
            "p1": "",
            "p2": "παύεσθε",
            "p3": "παυέσθων"
          }
        },
        "infinitive": {
          "active": "παύειν",
          "middle": "παύεσθαι"
        },
        "participle": {
          "m": {
            "active": "παύων",
            "middle": "παυόμενος"
          },
          "f": {
            "active": "παύουσᾰ",
            "middle": "παυομένη"
          },
          "n": {
            "active": "παῦον",
            "middle": "παυόμενον"
          }
        }
      }
    },
  },
  {
    // https://en.wiktionary.org/wiki/%CF%80%CE%B5%CE%AF%CE%B8%CF%89
    "type": "verb",
    "ground": "πείθειν",
    "passport": "πείθω",
    "dutch": "overtuigen",
    "explicitConjugations": {
      "present": {
        "active": {
          "indicative": {
            "s1": "πείθω",
            "s2": "πείθεις",
            "s3": "πείθει",
            "d2": "πείθετον",
            "d3": "πείθετον",
            "p1": "πείθομεν",
            "p2": "πείθετε",
            "p3": "πείθουσῐ(ν)"
          },
          "subjunctive": {
            "s1": "πείθω",
            "s2": "πείθῃς",
            "s3": "πείθῃ",
            "d2": "πείθητον",
            "d3": "πείθητον",
            "p1": "πείθωμεν",
            "p2": "πείθητε",
            "p3": "πείθωσῐ(ν)"
          },
          "optative": {
            "s1": "πείθοιμῐ",
            "s2": "πείθοις",
            "s3": "πείθοι",
            "d2": "πείθοιτον",
            "d3": "πειθοίτην",
            "p1": "πείθοιμεν",
            "p2": "πείθοιτε",
            "p3": "πείθοιεν"
          },
          "imperative": {
            "s1": "",
            "s2": "πεῖθε",
            "s3": "πειθέτω",
            "d2": "πείθετον",
            "d3": "πειθέτων",
            "p1": "",
            "p2": "πείθετε",
            "p3": "πειθόντων"
          }
        },
        "middlePassive": {
          "indicative": {
            "s1": "πείθομαι",
            "s2": "πείθῃ,πείθει",
            "s3": "πείθεται",
            "d2": "πείθεσθον",
            "d3": "πείθεσθον",
            "p1": "πειθόμεθᾰ",
            "p2": "πείθεσθε",
            "p3": "πείθονται"
          },
          "subjunctive": {
            "s1": "πείθωμαι",
            "s2": "πείθῃ",
            "s3": "πείθηται",
            "d2": "πείθησθον",
            "d3": "πείθησθον",
            "p1": "πειθώμεθᾰ",
            "p2": "πείθησθε",
            "p3": "πείθωνται"
          },
          "optative": {
            "s1": "πειθοίμην",
            "s2": "πείθοιο",
            "s3": "πείθοιτο",
            "d2": "πείθοισθον",
            "d3": "πειθοίσθην",
            "p1": "πειθοίμεθᾰ",
            "p2": "πείθοισθε",
            "p3": "πείθοιντο"
          },
          "imperative": {
            "s1": "",
            "s2": "πείθου",
            "s3": "πειθέσθω",
            "d2": "πείθεσθον",
            "d3": "πειθέσθων",
            "p1": "",
            "p2": "πείθεσθε",
            "p3": "πειθέσθων"
          }
        },
        "infinitive": {
          "active": "πείθειν",
          "middle": "πείθεσθαι"
        },
        "participle": {
          "m": {
            "active": "πείθων",
            "middle": "πειθόμενος"
          },
          "f": {
            "active": "πείθουσᾰ",
            "middle": "πειθομένη"
          },
          "n": {
            "active": "πεῖθον",
            "middle": "πειθόμενον"
          }
        }
      }
    }
  },
  {
    // https://en.wiktionary.org/wiki/%CF%86%CE%B1%CE%AF%CE%BD%CF%89
    "type": "verb",
    "ground": "φαίνειν",
    "passport": "φαίνω",
    "dutch": "laten zien",
    "explicitConjugations": {
      "present": {
        "active": {
          "indicative": {
            "s1": "φαίνω",
            "s2": "φαίνεις",
            "s3": "φαίνει",
            "d2": "φαίνετον",
            "d3": "φαίνετον",
            "p1": "φαίνομεν",
            "p2": "φαίνετε",
            "p3": "φαίνουσῐ(ν)"
          },
          "subjunctive": {
            "s1": "φαίνω",
            "s2": "φαίνῃς",
            "s3": "φαίνῃ",
            "d2": "φαίνητον",
            "d3": "φαίνητον",
            "p1": "φαίνωμεν",
            "p2": "φαίνητε",
            "p3": "φαίνωσῐ(ν)"
          },
          "optative": {
            "s1": "φαίνοιμῐ",
            "s2": "φαίνοις",
            "s3": "φαίνοι",
            "d2": "φαίνοιτον",
            "d3": "φαινοίτην",
            "p1": "φαίνοιμεν",
            "p2": "φαίνοιτε",
            "p3": "φαίνοιεν"
          },
          "imperative": {
            "s1": "",
            "s2": "φαῖνε",
            "s3": "φαινέτω",
            "d2": "φαίνετον",
            "d3": "φαινέτων",
            "p1": "",
            "p2": "φαίνετε",
            "p3": "φαινόντων"
          }
        },
        "middlePassive": {
          "indicative": {
            "s1": "φαίνομαι",
            "s2": "φαίνῃ,φαίνει",
            "s3": "φαίνεται",
            "d2": "φαίνεσθον",
            "d3": "φαίνεσθον",
            "p1": "φαινόμεθᾰ",
            "p2": "φαίνεσθε",
            "p3": "φαίνονται"
          },
          "subjunctive": {
            "s1": "φαίνωμαι",
            "s2": "φαίνῃ",
            "s3": "φαίνηται",
            "d2": "φαίνησθον",
            "d3": "φαίνησθον",
            "p1": "φαινώμεθᾰ",
            "p2": "φαίνησθε",
            "p3": "φαίνωνται"
          },
          "optative": {
            "s1": "φαινοίμην",
            "s2": "φαίνοιο",
            "s3": "φαίνοιτο",
            "d2": "φαίνοισθον",
            "d3": "φαινοίσθην",
            "p1": "φαινοίμεθᾰ",
            "p2": "φαίνοισθε",
            "p3": "φαίνοιντο"
          },
          "imperative": {
            "s1": "",
            "s2": "φαίνου",
            "s3": "φαινέσθω",
            "d2": "φαίνεσθον",
            "d3": "φαινέσθων",
            "p1": "",
            "p2": "φαίνεσθε",
            "p3": "φαινέσθων"
          }
        },
        "infinitive": {
          "active": "φαίνειν",
          "middle": "φαίνεσθαι"
        },
        "participle": {
          "m": {
            "active": "φαίνων",
            "middle": "φαινόμενος"
          },
          "f": {
            "active": "φαίνουσᾰ",
            "middle": "φαινομένη"
          },
          "n": {
            "active": "φαῖνον",
            "middle": "φαινόμενον"
          }
        }
      }
    }
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%94%CF%81%CF%87%CE%BF%CE%BC%CE%B1%CE%B9
    type: "verb",
    dutch: "gaan",
    ground: "ἰέναι",
    passport: "ἔρχομαι",
    explicitConjugations: {
      present: {
        active: {
          indicative: {
            s1: "ἔρχομαι",
            s2: ["ἔρχῃ", "ἔρχει"],
            s3: "ἔρχεται",
            d2: "ἔρχεσθον",
            d3: "ἔρχεσθον",
            p1: "ἐρχόμεθᾰ",
            p2: "ἔρχεσθε",
            p3: "ἔρχονται",
          },
        },
      },
    },
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%84%CF%81%CF%87%CF%89
    "type": "verb",
    "ground": "ᾰ̓ρχειν",
    "passport": "ᾰ̓ρχω",
    "dutch": "heersen (over)",
    "explicitConjugations": {
      "present": {
        "active": {
          "indicative": {
            "s1": "ᾰ̓ρχω",
            "s2": "ᾰ̓ρχεις",
            "s3": "ᾰ̓ρχει",
            "d2": "ᾰ̓ρχετον",
            "d3": "ᾰ̓ρχετον",
            "p1": "ᾰ̓ρχομεν",
            "p2": "ᾰ̓ρχετε",
            "p3": "ᾰ̓ρχουσῐ(ν)"
          },
          "subjunctive": {
            "s1": "ᾰ̓ρχω",
            "s2": "ᾰ̓ρχῃς",
            "s3": "ᾰ̓ρχῃ",
            "d2": "ᾰ̓ρχητον",
            "d3": "ᾰ̓ρχητον",
            "p1": "ᾰ̓ρχωμεν",
            "p2": "ᾰ̓ρχητε",
            "p3": "ᾰ̓ρχωσῐ(ν)"
          },
          "optative": {
            "s1": "ᾰ̓ρχοιμῐ",
            "s2": "ᾰ̓ρχοις",
            "s3": "ᾰ̓ρχοι",
            "d2": "ᾰ̓ρχοιτον",
            "d3": "ᾰ̓ρχοίτην",
            "p1": "ᾰ̓ρχοιμεν",
            "p2": "ᾰ̓ρχοιτε",
            "p3": "ᾰ̓ρχοιεν"
          },
          "imperative": {
            "s1": "",
            "s2": "ᾰ̓ρχε",
            "s3": "ᾰ̓ρχέτω",
            "d2": "ᾰ̓ρχετον",
            "d3": "ᾰ̓ρχέτων",
            "p1": "",
            "p2": "ᾰ̓ρχετε",
            "p3": "ᾰ̓ρχόντων"
          }
        },
        "middlePassive": {
          "indicative": {
            "s1": "ᾰ̓ρχομαι",
            "s2": "ᾰ̓ρχῃ,ᾰ̓ρχει",
            "s3": "ᾰ̓ρχεται",
            "d2": "ᾰ̓ρχεσθον",
            "d3": "ᾰ̓ρχεσθον",
            "p1": "ᾰ̓ρχόμεθᾰ",
            "p2": "ᾰ̓ρχεσθε",
            "p3": "ᾰ̓ρχονται"
          },
          "subjunctive": {
            "s1": "ᾰ̓ρχωμαι",
            "s2": "ᾰ̓ρχῃ",
            "s3": "ᾰ̓ρχηται",
            "d2": "ᾰ̓ρχησθον",
            "d3": "ᾰ̓ρχησθον",
            "p1": "ᾰ̓ρχώμεθᾰ",
            "p2": "ᾰ̓ρχησθε",
            "p3": "ᾰ̓ρχωνται"
          },
          "optative": {
            "s1": "ᾰ̓ρχοίμην",
            "s2": "ᾰ̓ρχοιο",
            "s3": "ᾰ̓ρχοιτο",
            "d2": "ᾰ̓ρχοισθον",
            "d3": "ᾰ̓ρχοίσθην",
            "p1": "ᾰ̓ρχοίμεθᾰ",
            "p2": "ᾰ̓ρχοισθε",
            "p3": "ᾰ̓ρχοιντο"
          },
          "imperative": {
            "s1": "",
            "s2": "ᾰ̓ρχου",
            "s3": "ᾰ̓ρχέσθω",
            "d2": "ᾰ̓ρχεσθον",
            "d3": "ᾰ̓ρχέσθων",
            "p1": "",
            "p2": "ᾰ̓ρχεσθε",
            "p3": "ᾰ̓ρχέσθων"
          }
        },
        "infinitive": {
          "active": "ᾰ̓ρχειν",
          "middle": "ᾰ̓ρχεσθαι"
        },
        "participle": {
          "m": {
            "active": "ᾰ̓ρχων",
            "middle": "ᾰ̓ρχόμενος"
          },
          "f": {
            "active": "ᾰ̓ρχουσᾰ",
            "middle": "ᾰ̓ρχομένη"
          },
          "n": {
            "active": "ᾰ̓ρχον",
            "middle": "ᾰ̓ρχόμενον"
          }
        }
      },
      "future": {
        "active": {
          "indicative": {
            "s1": "ᾰ̓ρξω",
            "s2": "ᾰ̓ρξεις",
            "s3": "ᾰ̓ρξει",
            "d2": "ᾰ̓ρξετον",
            "d3": "ᾰ̓ρξετον",
            "p1": "ᾰ̓ρξομεν",
            "p2": "ᾰ̓ρξετε",
            "p3": "ᾰ̓ρξουσῐ(ν)"
          },
          "optative": {
            "s1": "ᾰ̓ρξοιμῐ",
            "s2": "ᾰ̓ρξοις",
            "s3": "ᾰ̓ρξοι",
            "d2": "ᾰ̓ρξοιτον",
            "d3": "ᾰ̓ρξοίτην",
            "p1": "ᾰ̓ρξοιμεν",
            "p2": "ᾰ̓ρξοιτε",
            "p3": "ᾰ̓ρξοιεν"
          }
        },
        "middle": {
          "indicative": {
            "s1": "ᾰ̓ρξομαι",
            "s2": "ᾰ̓ρξῃ,ᾰ̓ρξει",
            "s3": "ᾰ̓ρξεται",
            "d2": "ᾰ̓ρξεσθον",
            "d3": "ᾰ̓ρξεσθον",
            "p1": "ᾰ̓ρξόμεθᾰ",
            "p2": "ᾰ̓ρξεσθε",
            "p3": "ᾰ̓ρξονται"
          },
          "optative": {
            "s1": "ᾰ̓ρξοίμην",
            "s2": "ᾰ̓ρξοιο",
            "s3": "ᾰ̓ρξοιτο",
            "d2": "ᾰ̓ρξοισθον",
            "d3": "ᾰ̓ρξοίσθην",
            "p1": "ᾰ̓ρξοίμεθᾰ",
            "p2": "ᾰ̓ρξοισθε",
            "p3": "ᾰ̓ρξοιντο"
          }
        },
        "passive": {
          "indicative": {
            "s1": "ᾰ̓ρχθήσομαι",
            "s2": "ᾰ̓ρχθήσῃ",
            "s3": "ᾰ̓ρχθήσεται",
            "d2": "ᾰ̓ρχθήσεσθον",
            "d3": "ᾰ̓ρχθήσεσθον",
            "p1": "ᾰ̓ρχθησόμεθᾰ",
            "p2": "ᾰ̓ρχθήσεσθε",
            "p3": "ᾰ̓ρχθήσονται"
          },
          "optative": {
            "s1": "ᾰ̓ρχθησοίμην",
            "s2": "ᾰ̓ρχθήσοιο",
            "s3": "ᾰ̓ρχθήσοιτο",
            "d2": "ᾰ̓ρχθήσοισθον",
            "d3": "ᾰ̓ρχθησοίσθην",
            "p1": "ᾰ̓ρχθησοίμεθᾰ",
            "p2": "ᾰ̓ρχθήσοισθε",
            "p3": "ᾰ̓ρχθήσοιντο"
          }
        },
        "infinitive": {
          "active": "ᾰ̓ρξειν",
          "middle": "ᾰ̓ρξεσθαι",
          "passive": "ᾰ̓ρχθήσεσθαι"
        },
        "participle": {
          "m": {
            "active": "ᾰ̓ρξων",
            "middle": "ᾰ̓ρξόμενος",
            "passive": "ᾰ̓ρχθησόμενος"
          },
          "f": {
            "active": "ᾰ̓ρξουσᾰ",
            "middle": "ᾰ̓ρξομένη",
            "passive": "ᾰ̓ρχθησομένη"
          },
          "n": {
            "active": "ᾰ̓ρξον",
            "middle": "ᾰ̓ρξόμενον",
            "passive": "ᾰ̓ρχθησόμενον"
          }
        }
      }
    }
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B2%CE%BF%CF%85%CE%BB%CE%B5%CF%8D%CF%89
    "type": "verb",
    "ground": "βουλεύειν",
    "passport": "βουλεύω",
    "dutch": "beraadslagen, besluiten",
    "explicitConjugations": {
      "present": {
        "active": {
          "indicative": {
            "s1": "βουλεύω",
            "s2": "βουλεύεις",
            "s3": "βουλεύει",
            "d2": "βουλεύετον",
            "d3": "βουλεύετον",
            "p1": "βουλεύομεν",
            "p2": "βουλεύετε",
            "p3": "βουλεύουσῐ(ν)"
          },
          "subjunctive": {
            "s1": "βουλεύω",
            "s2": "βουλεύῃς",
            "s3": "βουλεύῃ",
            "d2": "βουλεύητον",
            "d3": "βουλεύητον",
            "p1": "βουλεύωμεν",
            "p2": "βουλεύητε",
            "p3": "βουλεύωσῐ(ν)"
          },
          "optative": {
            "s1": "βουλεύοιμῐ",
            "s2": "βουλεύοις",
            "s3": "βουλεύοι",
            "d2": "βουλεύοιτον",
            "d3": "βουλευοίτην",
            "p1": "βουλεύοιμεν",
            "p2": "βουλεύοιτε",
            "p3": "βουλεύοιεν"
          },
          "imperative": {
            "s1": "",
            "s2": "βούλευε",
            "s3": "βουλευέτω",
            "d2": "βουλεύετον",
            "d3": "βουλευέτων",
            "p1": "",
            "p2": "βουλεύετε",
            "p3": "βουλευόντων"
          }
        },
        "middlePassive": {
          "indicative": {
            "s1": "βουλεύομαι",
            "s2": "βουλεύῃ,βουλεύει",
            "s3": "βουλεύεται",
            "d2": "βουλεύεσθον",
            "d3": "βουλεύεσθον",
            "p1": "βουλευόμεθᾰ",
            "p2": "βουλεύεσθε",
            "p3": "βουλεύονται"
          },
          "subjunctive": {
            "s1": "βουλεύωμαι",
            "s2": "βουλεύῃ",
            "s3": "βουλεύηται",
            "d2": "βουλεύησθον",
            "d3": "βουλεύησθον",
            "p1": "βουλευώμεθᾰ",
            "p2": "βουλεύησθε",
            "p3": "βουλεύωνται"
          },
          "optative": {
            "s1": "βουλευοίμην",
            "s2": "βουλεύοιο",
            "s3": "βουλεύοιτο",
            "d2": "βουλεύοισθον",
            "d3": "βουλευοίσθην",
            "p1": "βουλευοίμεθᾰ",
            "p2": "βουλεύοισθε",
            "p3": "βουλεύοιντο"
          },
          "imperative": {
            "s1": "",
            "s2": "βουλεύου",
            "s3": "βουλευέσθω",
            "d2": "βουλεύεσθον",
            "d3": "βουλευέσθων",
            "p1": "",
            "p2": "βουλεύεσθε",
            "p3": "βουλευέσθων"
          }
        },
        "infinitive": {
          "active": "βουλεύειν",
          "middle": "βουλεύεσθαι"
        },
        "participle": {
          "m": {
            "active": "βουλεύων",
            "middle": "βουλευόμενος"
          },
          "f": {
            "active": "βουλεύουσᾰ",
            "middle": "βουλευομένη"
          },
          "n": {
            "active": "βουλεῦον",
            "middle": "βουλευόμενον"
          }
        }
      },
      "imperfect": {
        "active": {
          "indicative": {
            "s1": "ἐβούλευον",
            "s2": "ἐβούλευες",
            "s3": "ἐβούλευε(ν)",
            "d2": "ἐβουλεύετον",
            "d3": "ἐβουλευέτην",
            "p1": "ἐβουλεύομεν",
            "p2": "ἐβουλεύετε",
            "p3": "ἐβούλευον"
          }
        },
        "middlePassive": {
          "indicative": {
            "s1": "ἐβουλευόμην",
            "s2": "ἐβουλεύου",
            "s3": "ἐβουλεύετο",
            "d2": "ἐβουλεύεσθον",
            "d3": "ἐβουλευέσθην",
            "p1": "ἐβουλευόμεθᾰ",
            "p2": "ἐβουλεύεσθε",
            "p3": "ἐβουλεύοντο"
          }
        }
      }
    }
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B4%CE%AD%CF%87%CE%BF%CE%BC%CE%B1%CE%B9
    "type": "verb",
    "ground": "δέχεσθαι",
    "passport": "δέχομαι",
    "dutch": "ontvangen",
    "explicitConjugations": {
      "present": {
        "middlePassive": {
          "indicative": {
            "s1": "δέχομαι",
            "s2": ["δέχῃ", "δέχει"],
            "s3": "δέχεται",
            "d2": "δέχεσθον",
            "d3": "δέχεσθον",
            "p1": "δεχόμεθᾰ",
            "p2": "δέχεσθε",
            "p3": "δέχονται"
          },
          "subjunctive": {
            "s1": "δέχωμαι",
            "s2": "δέχῃ",
            "s3": "δέχηται",
            "d2": "δέχησθον",
            "d3": "δέχησθον",
            "p1": "δεχώμεθᾰ",
            "p2": "δέχησθε",
            "p3": "δέχωνται"
          },
          "optative": {
            "s1": "δεχοίμην",
            "s2": "δέχοιο",
            "s3": "δέχοιτο",
            "d2": "δέχοισθον",
            "d3": "δεχοίσθην",
            "p1": "δεχοίμεθᾰ",
            "p2": "δέχοισθε",
            "p3": "δέχοιντο"
          },
          "imperative": {
            "s1": "",
            "s2": "δέχου",
            "s3": "δεχέσθω",
            "d2": "δέχεσθον",
            "d3": "δεχέσθων",
            "p1": "",
            "p2": "δέχεσθε",
            "p3": "δεχέσθων"
          }
        },
        "infinitive": {
          "active": "",
          "middle": "δέχεσθαι"
        },
        "participle": {
          "m": {
            "active": "",
            "middle": "δεχόμενος"
          },
          "f": {
            "active": "",
            "middle": "δεχομένη"
          },
          "n": {
            "active": "",
            "middle": "δεχόμενον"
          }
        }
      },
      "imperfect": {
        "middlePassive": {
          "indicative": {
            "s1": "ἐδεχόμην",
            "s2": "ἐδέχου",
            "s3": "ἐδέχετο",
            "d2": "ἐδέχεσθον",
            "d3": "ἐδεχέσθην",
            "p1": "ἐδεχόμεθᾰ",
            "p2": "ἐδέχεσθε",
            "p3": "ἐδέχοντο"
          }
        }
      }
    }
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%90%CF%81%CE%B3%CE%AC%CE%B6%CE%BF%CE%BC%CE%B1%CE%B9
    "type": "verb",
    "ground": "ἐργᾰζεσθαι",
    "passport": "ἐργᾰζομαι",
    "dutch": "werken",
    "explicitConjugations": {
      "present": {
        "middlePassive": {
          "indicative": {
            "s1": "ἐργᾰζομαι",
            "s2": ["ἐργᾰζῃ", "ἐργᾰζει"],
            "s3": "ἐργᾰζεται",
            "d2": "ἐργᾰζεσθον",
            "d3": "ἐργᾰζεσθον",
            "p1": "ἐργᾰζόμεθᾰ",
            "p2": "ἐργᾰζεσθε",
            "p3": "ἐργᾰζονται"
          },
          "subjunctive": {
            "s1": "ἐργᾰζωμαι",
            "s2": "ἐργᾰζῃ",
            "s3": "ἐργᾰζηται",
            "d2": "ἐργᾰζησθον",
            "d3": "ἐργᾰζησθον",
            "p1": "ἐργᾰζώμεθᾰ",
            "p2": "ἐργᾰζησθε",
            "p3": "ἐργᾰζωνται"
          },
          "optative": {
            "s1": "ἐργᾰζοίμην",
            "s2": "ἐργᾰζοιο",
            "s3": "ἐργᾰζοιτο",
            "d2": "ἐργᾰζοισθον",
            "d3": "ἐργᾰζοίσθην",
            "p1": "ἐργᾰζοίμεθᾰ",
            "p2": "ἐργᾰζοισθε",
            "p3": "ἐργᾰζοιντο"
          },
          "imperative": {
            "s1": "",
            "s2": "ἐργᾰζου",
            "s3": "ἐργᾰζέσθω",
            "d2": "ἐργᾰζεσθον",
            "d3": "ἐργᾰζέσθων",
            "p1": "",
            "p2": "ἐργᾰζεσθε",
            "p3": "ἐργᾰζέσθων"
          }
        },
        "infinitive": {
          "active": "",
          "middle": "ἐργᾰζεσθαι"
        },
        "participle": {
          "m": {
            "active": "",
            "middle": "ἐργᾰζόμενος"
          },
          "f": {
            "active": "",
            "middle": "ἐργᾰζομένη"
          },
          "n": {
            "active": "",
            "middle": "ἐργᾰζόμενον"
          }
        }
      }
    }
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BF%E1%BC%B4%CE%BF%CE%BC%CE%B1%CE%B9
    "type": "verb",
    "ground": "οἴεσθαι",
    "passport": "οἴομαι",
    "dutch": "denken, menen",
    "explicitConjugations": {
      "present": {
        "middlePassive": {
          "indicative": {
            "s1": "οἴομαι",
            "s2": ["οἴῃ", "οἴει"],
            "s3": "οἴεται",
            "d2": "οἴεσθον",
            "d3": "οἴεσθον",
            "p1": "οἰόμεθᾰ",
            "p2": "οἴεσθε",
            "p3": "οἴονται",
          },
        },
        "infinitive": {
          "middle": "οἴεσθαι",
        },
      },
      "imperfect": {
        "middlePassive": {
          "indicative": {
            "s1": "ᾠόμην",
            "s2": "ᾤου",
            "s3": "ᾤετο",
            "d2": "ᾤεσθον",
            "d3": "ᾠέσθην",
            "p1": "ᾠόμεθᾰ",
            "p2": "ᾤεσθε",
            "p3": "ᾤοντο"
          }
        }
      },
      "future": {
        "middle": {
          "indicative": {
            "s1": "οἰήσομαι",
            "s2": "οἰήσῃ,οἰήσει",
            "s3": "οἰήσεται",
            "d2": "οἰήσεσθον",
            "d3": "οἰήσεσθον",
            "p1": "οἰησόμεθᾰ",
            "p2": "οἰήσεσθε",
            "p3": "οἰήσονται"
          },
          "optative": {
            "s1": "οἰησοίμην",
            "s2": "οἰήσοιο",
            "s3": "οἰήσοιτο",
            "d2": "οἰήσοισθον",
            "d3": "οἰησοίσθην",
            "p1": "οἰησοίμεθᾰ",
            "p2": "οἰήσοισθε",
            "p3": "οἰήσοιντο"
          }
        },
        "infinitive": {
          "active": "",
          "middle": "οἰήσεσθαι",
          "passive": ""
        },
        "participle": {
          "m": {
            "active": "",
            "middle": "οἰησόμενος",
            "passive": ""
          },
          "f": {
            "active": "",
            "middle": "οἰησομένη",
            "passive": ""
          },
          "n": {
            "active": "",
            "middle": "οἰησόμενον",
            "passive": ""
          }
        }
      }
    }
  },
  {
    // https://en.wiktionary.org/wiki/%CF%80%CE%BF%CF%81%CE%B5%CF%8D%CF%89
    "type": "verb",
    "ground": "πορεύειν",
    "passport": "πορεύω",
    "dutch": "reizen, oprukken",
    "explicitConjugations": {
      "present": {
        "active": {
          "indicative": {
            "s1": "πορεύω",
            "s2": "πορεύεις",
            "s3": "πορεύει",
            "d2": "πορεύετον",
            "d3": "πορεύετον",
            "p1": "πορεύομεν",
            "p2": "πορεύετε",
            "p3": "πορεύουσῐ(ν)"
          },
          "subjunctive": {
            "s1": "πορεύω",
            "s2": "πορεύῃς",
            "s3": "πορεύῃ",
            "d2": "πορεύητον",
            "d3": "πορεύητον",
            "p1": "πορεύωμεν",
            "p2": "πορεύητε",
            "p3": "πορεύωσῐ(ν)"
          },
          "optative": {
            "s1": "πορεύοιμῐ",
            "s2": "πορεύοις",
            "s3": "πορεύοι",
            "d2": "πορεύοιτον",
            "d3": "πορευοίτην",
            "p1": "πορεύοιμεν",
            "p2": "πορεύοιτε",
            "p3": "πορεύοιεν"
          },
          "imperative": {
            "s1": "",
            "s2": "πόρευε",
            "s3": "πορευέτω",
            "d2": "πορεύετον",
            "d3": "πορευέτων",
            "p1": "",
            "p2": "πορεύετε",
            "p3": "πορευόντων"
          }
        },
        "middlePassive": {
          "indicative": {
            "s1": "πορεύομαι",
            "s2": "πορεύῃ,πορεύει",
            "s3": "πορεύεται",
            "d2": "πορεύεσθον",
            "d3": "πορεύεσθον",
            "p1": "πορευόμεθᾰ",
            "p2": "πορεύεσθε",
            "p3": "πορεύονται"
          },
          "subjunctive": {
            "s1": "πορεύωμαι",
            "s2": "πορεύῃ",
            "s3": "πορεύηται",
            "d2": "πορεύησθον",
            "d3": "πορεύησθον",
            "p1": "πορευώμεθᾰ",
            "p2": "πορεύησθε",
            "p3": "πορεύωνται"
          },
          "optative": {
            "s1": "πορευοίμην",
            "s2": "πορεύοιο",
            "s3": "πορεύοιτο",
            "d2": "πορεύοισθον",
            "d3": "πορευοίσθην",
            "p1": "πορευοίμεθᾰ",
            "p2": "πορεύοισθε",
            "p3": "πορεύοιντο"
          },
          "imperative": {
            "s1": "",
            "s2": "πορεύου",
            "s3": "πορευέσθω",
            "d2": "πορεύεσθον",
            "d3": "πορευέσθων",
            "p1": "",
            "p2": "πορεύεσθε",
            "p3": "πορευέσθων"
          }
        },
        "infinitive": {
          "active": "πορεύειν",
          "middle": "πορεύεσθαι"
        },
        "participle": {
          "m": {
            "active": "πορεύων",
            "middle": "πορευόμενος"
          },
          "f": {
            "active": "πορεύουσᾰ",
            "middle": "πορευομένη"
          },
          "n": {
            "active": "πορεῦον",
            "middle": "πορευόμενον"
          }
        }
      },
      "imperfect": {
        "middlePassive": {
          "indicative": {
            "s1": "ἐπορευόμην",
            "s2": "ἐπορεύου",
            "s3": "ἐπορεύετο",
            "d2": "ἐπορεύεσθον",
            "d3": "ἐπορευέσθην",
            "p1": "ἐπορευόμεθᾰ",
            "p2": "ἐπορεύεσθε",
            "p3": "ἐπορεύοντο"
          }
        }
      },
      "future": {
        "middle": {
          "indicative": {
            "s1": "πορεύσομαι",
            "s2": "πορεύσῃ,πορεύσει",
            "s3": "πορεύσεται",
            "d2": "πορεύσεσθον",
            "d3": "πορεύσεσθον",
            "p1": "πορευσόμεθᾰ",
            "p2": "πορεύσεσθε",
            "p3": "πορεύσονται"
          },
          "optative": {
            "s1": "πορευσοίμην",
            "s2": "πορεύσοιο",
            "s3": "πορεύσοιτο",
            "d2": "πορεύσοισθον",
            "d3": "πορευσοίσθην",
            "p1": "πορευσοίμεθᾰ",
            "p2": "πορεύσοισθε",
            "p3": "πορεύσοιντο"
          }
        },
        "passive": {
          "indicative": {
            "s1": "πορευθήσομαι",
            "s2": "πορευθήσῃ",
            "s3": "πορευθήσεται",
            "d2": "πορευθήσεσθον",
            "d3": "πορευθήσεσθον",
            "p1": "πορευθησόμεθᾰ",
            "p2": "πορευθήσεσθε",
            "p3": "πορευθήσονται"
          },
          "optative": {
            "s1": "πορευθησοίμην",
            "s2": "πορευθήσοιο",
            "s3": "πορευθήσοιτο",
            "d2": "πορευθήσοισθον",
            "d3": "πορευθησοίσθην",
            "p1": "πορευθησοίμεθᾰ",
            "p2": "πορευθήσοισθε",
            "p3": "πορευθήσοιντο"
          }
        },
        "infinitive": {
          "active": "",
          "middle": "πορεύσεσθαι",
          "passive": "πορευθήσεσθαι"
        },
        "participle": {
          "m": {
            "active": "",
            "middle": "πορευσόμενος",
            "passive": "πορευθησόμενος"
          },
          "f": {
            "active": "",
            "middle": "πορευσομένη",
            "passive": "πορευθησομένη"
          },
          "n": {
            "active": "",
            "middle": "πορευσόμενον",
            "passive": "πορευθησόμενον"
          }
        }
      }
    }
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BA%CF%81%CE%AF%CE%BD%CF%89
    "type": "verb",
    "ground": "κρῑνειν",
    "passport": "κρῑνω",
    "dutch": "onderscheiden, oordelen",
    "explicitConjugations": {
      "present": {
        "active": {
          "indicative": {
            "s1": "κρῑνω",
            "s2": "κρῑνεις",
            "s3": "κρῑνει",
            "d2": "κρῑνετον",
            "d3": "κρῑνετον",
            "p1": "κρῑνομεν",
            "p2": "κρῑνετε",
            "p3": "κρῑνουσῐ(ν)"
          },
          "subjunctive": {
            "s1": "κρῑνω",
            "s2": "κρῑνῃς",
            "s3": "κρῑνῃ",
            "d2": "κρῑνητον",
            "d3": "κρῑνητον",
            "p1": "κρῑνωμεν",
            "p2": "κρῑνητε",
            "p3": "κρῑνωσῐ(ν)"
          },
          "optative": {
            "s1": "κρῑνοιμῐ",
            "s2": "κρῑνοις",
            "s3": "κρῑνοι",
            "d2": "κρῑνοιτον",
            "d3": "κρῑνοίτην",
            "p1": "κρῑνοιμεν",
            "p2": "κρῑνοιτε",
            "p3": "κρῑνοιεν"
          },
          "imperative": {
            "s1": "",
            "s2": "κρῖνε",
            "s3": "κρῑνέτω",
            "d2": "κρῑνετον",
            "d3": "κρῑνέτων",
            "p1": "",
            "p2": "κρῑνετε",
            "p3": "κρῑνόντων"
          }
        },
        "middlePassive": {
          "indicative": {
            "s1": "κρῑνομαι",
            "s2": "κρῑνῃ,κρῑνει",
            "s3": "κρῑνεται",
            "d2": "κρῑνεσθον",
            "d3": "κρῑνεσθον",
            "p1": "κρῑνόμεθᾰ",
            "p2": "κρῑνεσθε",
            "p3": "κρῑνονται"
          },
          "subjunctive": {
            "s1": "κρῑνωμαι",
            "s2": "κρῑνῃ",
            "s3": "κρῑνηται",
            "d2": "κρῑνησθον",
            "d3": "κρῑνησθον",
            "p1": "κρῑνώμεθᾰ",
            "p2": "κρῑνησθε",
            "p3": "κρῑνωνται"
          },
          "optative": {
            "s1": "κρῑνοίμην",
            "s2": "κρῑνοιο",
            "s3": "κρῑνοιτο",
            "d2": "κρῑνοισθον",
            "d3": "κρῑνοίσθην",
            "p1": "κρῑνοίμεθᾰ",
            "p2": "κρῑνοισθε",
            "p3": "κρῑνοιντο"
          },
          "imperative": {
            "s1": "",
            "s2": "κρῑνου",
            "s3": "κρῑνέσθω",
            "d2": "κρῑνεσθον",
            "d3": "κρῑνέσθων",
            "p1": "",
            "p2": "κρῑνεσθε",
            "p3": "κρῑνέσθων"
          }
        },
        "infinitive": {
          "active": "κρῑνειν",
          "middle": "κρῑνεσθαι"
        },
        "participle": {
          "m": {
            "active": "κρῑνων",
            "middle": "κρῑνόμενος"
          },
          "f": {
            "active": "κρῑνουσᾰ",
            "middle": "κρῑνομένη"
          },
          "n": {
            "active": "κρῖνον",
            "middle": "κρῑνόμενον"
          }
        }
      },
      "imperfect": {
        "active": {
          "indicative": {
            "s1": "ἔκρῑνον",
            "s2": "ἔκρῑνες",
            "s3": "ἔκρῑνε(ν)",
            "d2": "ἐκρῑνετον",
            "d3": "ἐκρῑνέτην",
            "p1": "ἐκρῑνομεν",
            "p2": "ἐκρῑνετε",
            "p3": "ἔκρῑνον"
          }
        },
        "middlePassive": {
          "indicative": {
            "s1": "ἐκρῑνόμην",
            "s2": "ἐκρῑνου",
            "s3": "ἐκρῑνετο",
            "d2": "ἐκρῑνεσθον",
            "d3": "ἐκρῑνέσθην",
            "p1": "ἐκρῑνόμεθᾰ",
            "p2": "ἐκρῑνεσθε",
            "p3": "ἐκρῑνοντο"
          }
        }
      }
    }
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BC%CE%AD%CE%BB%CE%BB%CF%89
    "type": "verb",
    "ground": "μέλλειν",
    "passport": "μέλλω",
    "dutch": "dralen, van plan zijn (te)",
    "explicitConjugations": {
      "present": {
        "active": {
          "indicative": {
            "s1": "μέλλω",
            "s2": "μέλλεις",
            "s3": "μέλλει",
            "d2": "μέλλετον",
            "d3": "μέλλετον",
            "p1": "μέλλομεν",
            "p2": "μέλλετε",
            "p3": "μέλλουσῐ(ν)"
          },
          "subjunctive": {
            "s1": "μέλλω",
            "s2": "μέλλῃς",
            "s3": "μέλλῃ",
            "d2": "μέλλητον",
            "d3": "μέλλητον",
            "p1": "μέλλωμεν",
            "p2": "μέλλητε",
            "p3": "μέλλωσῐ(ν)"
          },
          "optative": {
            "s1": "μέλλοιμῐ",
            "s2": "μέλλοις",
            "s3": "μέλλοι",
            "d2": "μέλλοιτον",
            "d3": "μελλοίτην",
            "p1": "μέλλοιμεν",
            "p2": "μέλλοιτε",
            "p3": "μέλλοιεν"
          },
          "imperative": {
            "s1": "",
            "s2": "μέλλε",
            "s3": "μελλέτω",
            "d2": "μέλλετον",
            "d3": "μελλέτων",
            "p1": "",
            "p2": "μέλλετε",
            "p3": "μελλόντων"
          }
        },
        "middlePassive": {
          "indicative": {
            "s1": "μέλλομαι",
            "s2": "μέλλῃ,μέλλει",
            "s3": "μέλλεται",
            "d2": "μέλλεσθον",
            "d3": "μέλλεσθον",
            "p1": "μελλόμεθᾰ",
            "p2": "μέλλεσθε",
            "p3": "μέλλονται"
          },
          "subjunctive": {
            "s1": "μέλλωμαι",
            "s2": "μέλλῃ",
            "s3": "μέλληται",
            "d2": "μέλλησθον",
            "d3": "μέλλησθον",
            "p1": "μελλώμεθᾰ",
            "p2": "μέλλησθε",
            "p3": "μέλλωνται"
          },
          "optative": {
            "s1": "μελλοίμην",
            "s2": "μέλλοιο",
            "s3": "μέλλοιτο",
            "d2": "μέλλοισθον",
            "d3": "μελλοίσθην",
            "p1": "μελλοίμεθᾰ",
            "p2": "μέλλοισθε",
            "p3": "μέλλοιντο"
          },
          "imperative": {
            "s1": "",
            "s2": "μέλλου",
            "s3": "μελλέσθω",
            "d2": "μέλλεσθον",
            "d3": "μελλέσθων",
            "p1": "",
            "p2": "μέλλεσθε",
            "p3": "μελλέσθων"
          }
        },
        "infinitive": {
          "active": "μέλλειν",
          "middle": "μέλλεσθαι"
        },
        "participle": {
          "m": {
            "active": "μέλλων",
            "middle": "μελλόμενος"
          },
          "f": {
            "active": "μέλλουσᾰ",
            "middle": "μελλομένη"
          },
          "n": {
            "active": "μέλλον",
            "middle": "μελλόμενον"
          }
        }
      },
      "future": {
        "active": {
          "indicative": {
            "s1": "μελλήσω",
            "s2": "μελλήσεις",
            "s3": "μελλήσει",
            "d2": "μελλήσετον",
            "d3": "μελλήσετον",
            "p1": "μελλήσομεν",
            "p2": "μελλήσετε",
            "p3": "μελλήσουσῐ(ν)"
          },
          "optative": {
            "s1": "μελλήσοιμῐ",
            "s2": "μελλήσοις",
            "s3": "μελλήσοι",
            "d2": "μελλήσοιτον",
            "d3": "μελλησοίτην",
            "p1": "μελλήσοιμεν",
            "p2": "μελλήσοιτε",
            "p3": "μελλήσοιεν"
          }
        },
        "middle": {
          "indicative": {
            "s1": "μελλήσομαι",
            "s2": "μελλήσῃ,μελλήσει",
            "s3": "μελλήσεται",
            "d2": "μελλήσεσθον",
            "d3": "μελλήσεσθον",
            "p1": "μελλησόμεθᾰ",
            "p2": "μελλήσεσθε",
            "p3": "μελλήσονται"
          },
          "optative": {
            "s1": "μελλησοίμην",
            "s2": "μελλήσοιο",
            "s3": "μελλήσοιτο",
            "d2": "μελλήσοισθον",
            "d3": "μελλησοίσθην",
            "p1": "μελλησοίμεθᾰ",
            "p2": "μελλήσοισθε",
            "p3": "μελλήσοιντο"
          }
        },
        "infinitive": {
          "active": "μελλήσειν",
          "middle": "μελλήσεσθαι",
          "passive": ""
        },
        "participle": {
          "m": {
            "active": "μελλήσων",
            "middle": "μελλησόμενος",
            "passive": ""
          },
          "f": {
            "active": "μελλήσουσᾰ",
            "middle": "μελλησομένη",
            "passive": ""
          },
          "n": {
            "active": "μελλῆσον",
            "middle": "μελλησόμενον",
            "passive": ""
          }
        }
      }
    }
  },
  {
    // https://en.wiktionary.org/wiki/%CF%86%CE%B7%CE%BC%CE%AF
    "type": "verb",
    "ground": "φᾰναι",
    "passport": "φημῐ",
    "dutch": "zeggen, beweren",
    "explicitConjugations": {
      "present": {
        "active": {
          "indicative": {
            "s1": "φημῐ",
            "s2": "φῄς",
            "s3": "φησῐ/φησῐν",
            "d2": "φᾰτόν",
            "d3": "φᾰτόν",
            "p1": "φᾰμέν",
            "p2": "φᾰτέ",
            "p3": "φᾱσῐ/φᾱσῐν"
          },
          "subjunctive": {
            "s1": "φῶ",
            "s2": "φῇς",
            "s3": "φῇ",
            "d2": "φῆτον",
            "d3": "φῆτον",
            "p1": "φῶμεν",
            "p2": "φῆτε",
            "p3": "φῶσῐ(ν)"
          },
          "optative": {
            "s1": "φαίην",
            "s2": "φαίης",
            "s3": "φαίη",
            "d2": "φαῖτον,φαίητον",
            "d3": "φαίτην,φαιήτην",
            "p1": "φαῖμεν,φαίημεν",
            "p2": "φαῖτε,φαίητε",
            "p3": "φαῖεν,φαίησᾰν"
          },
          "imperative": {
            "s1": "",
            "s2": "φᾰθῐ/φᾰθῐ",
            "s3": "φᾰτω",
            "d2": "φᾰτον",
            "d3": "φᾰτων",
            "p1": "",
            "p2": "φᾰτε",
            "p3": "φᾰντων"
          }
        },
        "infinitive": {
          "active": "φᾰναι",
          "middle": ""
        },
        "participle": {
          "m": {
            "active": "φᾱς",
            "middle": ""
          },
          "f": {
            "active": "φᾶσᾰ",
            "middle": ""
          },
          "n": {
            "active": "φᾰν",
            "middle": ""
          }
        }
      },
      "imperfect": {
        "active": {
          "indicative": {
            "s1": "ἔφην",
            "s2": "ἔφης/ἔφησθᾰ",
            "s3": "ἔφη(ν)",
            "d2": "ἔφᾰτον",
            "d3": "ἐφᾰτην",
            "p1": "ἔφᾰμεν",
            "p2": "ἔφᾰτε",
            "p3": "ἔφᾰσᾰν/ἔφᾰν"
          }
        }
      },
      "future": {
        "active": {
          "indicative": {
            "s1": "φήσω",
            "s2": "φήσεις",
            "s3": "φήσει",
            "d2": "φήσετον",
            "d3": "φήσετον",
            "p1": "φήσομεν",
            "p2": "φήσετε",
            "p3": "φήσουσῐ(ν)"
          },
          "optative": {
            "s1": "φήσοιμῐ",
            "s2": "φήσοις",
            "s3": "φήσοι",
            "d2": "φήσοιτον",
            "d3": "φησοίτην",
            "p1": "φήσοιμεν",
            "p2": "φήσοιτε",
            "p3": "φήσοιεν"
          }
        },
        "infinitive": {
          "active": "φήσειν",
          "middle": "",
          "passive": ""
        },
        "participle": {
          "m": {
            "active": "φήσων",
            "middle": "",
            "passive": ""
          },
          "f": {
            "active": "φήσουσᾰ",
            "middle": "",
            "passive": ""
          },
          "n": {
            "active": "φῆσον",
            "middle": "",
            "passive": ""
          }
        }
      }
    }
  },
  // {
  //   type: "verb",
  //   dutch: "",
  //   ground: "",
  //   passport: "",
  //   explicitConjugations: {
  //     active: {
  //       indicative: {
  //         present: {
  //           "s1": "",
  //           "s2": "",
  //           "s3": "",
  //           "d2": "",
  //           "d3": "",
  //           "p1": "",
  //           "p2": "",
  //           "p3": "",
  //         },
  //       },
  //     },
  //   },
  // },
];

export const searchGreekVerb = searchWord(GREEK_VERBS, "Greek verb", "ground");