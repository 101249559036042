import _ from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LatinAdjectiveFull from "../components/latin/LatinAdjectiveFull.tsx";
import LatinDefaultFull from "../components/latin/LatinDefaultFull.tsx";
import LatinIndefinitePronounFull from "../components/latin/LatinIndefinitePronounFull.tsx";
import LatinNounFull from "../components/latin/LatinNounFull.tsx";
import LatinPluralAdjectiveFull from "../components/latin/LatinPluralAdjectiveFull.tsx";
import LatinVerbFull from "../components/latin/LatinVerbFull.tsx";
import { nominative } from "../data/latin/LatinNoun.tsx";
import { LatinWord, toDutchType } from "../data/latin/LatinWord.tsx";
import { assertNever } from "../utils/assertNever.tsx";
import "./Practice.scss";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

function LatinQuestionBody({ word }: { word: LatinWord }): React.ReactNode {
  switch (word.type) {
    case "noun": {
      return <div>{nominative(word)}</div>;
    }
    case "adjective": {
      return <div>{word.latin}</div>;
    }
    case "fixedAdjective": {
      return <div>{word.latin}</div>;
    }
    case "pluralAdjective": {
      return <div>{word.latin}</div>;
    }
    case "conjugatedVerb": {
      return <div>{word.latin}</div>;
    }
    case "adverb": {
      return <div>{word.latin}</div>;
    }
    case "conjunction": {
      return <div>{word.latin}</div>;
    }
    case "negation": {
      return <div>{word.latin}</div>
    }
    case "verb": {
      return <div>{word.infinitive}</div>;
    }
    case "passiveVerb": {
      return <div>{word.latin}</div>
    }
    case "countingWord": {
      return <div>{word.latin}</div>;
    }
    case "questionParticle": {
      return <div>{word.latin}</div>;
    }
    case "indefinitePronoun": {
      return <div>{word.latin}</div>;
    }
    case "interjection": {
      return <div>{word.latin}</div>;
    }
    case "personalPronoun": {
      return <div>{word.latin}</div>;
    }
    case "possessivePronoun": {
      return <div>{word.latin}</div>;
    }
    case "interrogativePronoun": {
      return <div>{word.latin}</div>;
    }
    case "demonstrativePronoun": {
      return <div>{word.latin}</div>;
    }
    case "prepositionAccusative": {
      return <div>{word.latin} + <em>acc.</em></div>;
    }
    case "prepositionAblative": {
      return <div>{word.latin} + <em>abl.</em></div>;
    }
    case "toFixIrregularWord": {
      return <div>{word.latin}</div>;
    }
    default:
      return assertNever(word);
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

function LatinAnswerBody({ word }: { word: LatinWord }): React.ReactNode {
  switch (word.type) {
    case "noun": {
      return <LatinNounFull noun={word} />;
    }
    case "adjective": {
      return <LatinAdjectiveFull word={word} />;
    }
    case "fixedAdjective": {
      return <LatinDefaultFull word={word} />;
    }
    case "pluralAdjective": {
      return <LatinPluralAdjectiveFull word={word} />;
    }
    case "conjugatedVerb": {
      return <LatinDefaultFull word={word} />;
    }
    case "adverb": {
      return <LatinDefaultFull word={word} />;
    }
    case "conjunction": {
      return <LatinDefaultFull word={word} />;
    }
    case "negation": {
      return <LatinDefaultFull word={word} />;
    }
    case "verb": {
      return <LatinVerbFull verb={word} />;
    }
    case "passiveVerb": {
      return <LatinDefaultFull word={word} />;
    }
    case "countingWord": {
      return <LatinDefaultFull word={word} />;
    }
    case "questionParticle": {
      return <LatinDefaultFull word={word} />;
    }
    case "indefinitePronoun": {
      return <LatinIndefinitePronounFull pronoun={word} />;
    }
    case "interjection": {
      return <LatinDefaultFull word={word} />;
    }
    case "personalPronoun": {
      return <LatinDefaultFull word={word} />;
    }
    case "possessivePronoun": {
      return <LatinDefaultFull word={word} />;
    }
    case "interrogativePronoun": {
      return <LatinDefaultFull word={word} />;
    }
    case "demonstrativePronoun": {
      return <LatinDefaultFull word={word} />;
    }
    case "prepositionAccusative": {
      return <LatinDefaultFull word={word} />;
    }
    case "prepositionAblative": {
      return <LatinDefaultFull word={word} />;
    }
    case "toFixIrregularWord": {
      return <LatinDefaultFull word={word} />;
    }
    default:
      return assertNever(word);
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface LatinResultsBodyProps {
  correct: number;
  total: number;
}

function LatinResultsBody({ correct, total }: LatinResultsBodyProps): React.ReactNode {
  if (total === 0) {
    return "Je deed niets, maar wel zonder fouten!"
  }
  if (correct / total < 0.5) {
    return "Stultus es!";
  }
  if (correct !== total) {
    return "Melius facere potes!";
  }
  return "Optime! Tu es Cicero!"
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

type LatinPracticeState = "swirlIn" | "question" | "answer" | "swirlOut";

// const isSwirling = (state: CardState) => (state === "swirlIn") || (state === "swirlOut");
const isNotSwirling = (state: LatinPracticeState) =>
  (state === "question") || (state === "answer");
// const showQuestionSide = (state: CardState) => (state === "swirlIn") || (state === "question");
const showAnswerSide = (state: LatinPracticeState) =>
  (state === "answer") || (state === "swirlOut");

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// Transitions take 250 msec.
const FLIP_CARD_TRANSITION_DURATION = 250;

interface LatinPracticeProps<W extends LatinWord> {
  vocabulary: W[];
  words: number[];
  answerBodyFn?: (word: W) => React.ReactNode;
}

export function LatinPractice<W extends LatinWord>({ vocabulary, words, answerBodyFn }: LatinPracticeProps<W>) {
  const params = useParams();
  const [ state, setState ] = useState<LatinPracticeState>("swirlIn");
  const [ testWords, setTestWords ] = useState<W[]>([]);
  const [ currentIndex, setCurrentIndex ] = useState<number>(0);
  const [ wrongIndices, setWrongIndices ] = useState<number[]>([]);

  // Reshuffle all words that need to be trained and start from scratch.
  const reshuffleAll = () => {
    // TODO: safety!
    // console.log("reshuffleAll");
    setTestWords(_(vocabulary).slice().pullAt(words).shuffle().value());
    setCurrentIndex(0);
    setWrongIndices([]);
    setState("swirlIn");
  }

  // Reshuffle all wrong words and start from scratch.
  const reshuffleWrong = () => {
    // console.log("reshuffleWrong");
    setTestWords(_(testWords).at(wrongIndices).shuffle().value());
    setCurrentIndex(0);
    setWrongIndices([]);
    setState("swirlIn");
  }

  const bumpIndex = () => {
    // console.log("bumpIndex");
    setCurrentIndex(currentIndex + 1);
  }

  useEffect(reshuffleAll, [ params ]);

  // Swirl in effect.
  useEffect(() => {
    if (state !== "swirlIn") return;
    // console.log("Swirl in effect");
    const timer = setTimeout(() => {
      // console.log("Swirl in effect: timed code");
      setState("question");
    }, FLIP_CARD_TRANSITION_DURATION);
    return () => {
      clearTimeout(timer);
    };
  }, [ state ]);

  // Swirl out effect.
  useEffect(() => {
    if (state !== "swirlOut") return;
    // console.log("Swirl out effect");
    const timer = setTimeout(() => {
      // console.log("Swirl out effect: timed code");
      setState("swirlIn");
    }, FLIP_CARD_TRANSITION_DURATION);
    return () => {
      clearTimeout(timer);
    };
  }, [ state ]);

  if (currentIndex > testWords.length) {
    return <div>Dit zou nooit mogen gebeuren...</div>;
  }

  if (currentIndex === testWords.length) {
    const correct = testWords.length - wrongIndices.length;
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="results">
          <div className="card">
            <div className="card-header">
              Resultaat: {correct}/{testWords.length}
            </div>
            <div className="card-body">
              <LatinResultsBody correct={correct} total={testWords.length} />
            </div>
            <div className="card-footer">
              <div className="d-flex justify-content-between">
                <button className={`btn btn-outline-warning ${wrongIndices.length === 0 ? "disabled" : ""}`}
                        onClick={reshuffleWrong}>
                  Enkel fouten
                </button>
                <button className="btn btn-outline-success"
                        onClick={reshuffleAll}>
                  Alles opnieuw
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const handleQuestionCardClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    if (isNotSwirling(state) && (state === "question")) {
      setState("answer");
    }
  }

  const handleAnswerCardCorrectClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    if (isNotSwirling(state) && (state === "answer")) {
      bumpIndex();
      setState("swirlOut");
    }
  }

  const handleAnswerCardWrongClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    if (isNotSwirling(state) && (state === "answer")) {
      setWrongIndices([ ...wrongIndices, currentIndex ]);
      bumpIndex();
      setState("swirlOut");
    }
  }

  const swirl = state === "swirlOut" ? "swirl-out" : state === "swirlIn" ? "swirl-in" : "swirl-none";
  const flip = showAnswerSide(state) ? "flipped" : "";
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className={`flip-card ${swirl}`}>
        <div className={`inner ${flip}`}>
          <div className="front">
            <div className="card shadow-lg"
                 onClick={handleQuestionCardClick}>
              <div className="card-header">
                <div className="d-flex justify-content-between align-items-baseline">
                  <span className="word-type" />
                  <span className="word-index">{currentIndex + 1}/{testWords.length}</span>
                </div>
              </div>
              <div className="card-body">
                <LatinQuestionBody word={testWords[currentIndex]} />
              </div>
            </div>
          </div>
          <div className="back">
            <div className="card shadow-lg">
              <div className="card-header">
                <div className="d-flex justify-content-between align-items-baseline">
                  <span className="word-type">{toDutchType(testWords[currentIndex])}</span>
                  <span className="word-index">{currentIndex + 1}/{testWords.length}</span>
                </div>
              </div>
              <div className="card-body">
                <LatinAnswerBody word={testWords[currentIndex]} />
                {answerBodyFn?.(testWords[currentIndex])}
              </div>
              <div className="card-footer">
                <div className="d-flex justify-content-between">
                  <button className="btn btn-outline-danger"
                          onClick={handleAnswerCardWrongClick}>
                    Falsus
                  </button>
                  <button className="btn btn-outline-success"
                          onClick={handleAnswerCardCorrectClick}>
                    Verus
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}