import { Language } from "../data/Language";
import "./T.scss";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface TProps {
  l: Language;
  children: string;
}

export function T({ l, children }: TProps) {
  return <span className={`text-${l}`}>{children}</span>;
}

export function La({ children }: { children: string }) {
  return <T l="la">{children}</T>;
}

export function Gr({ children }: { children: string }) {
  return <T l="gr">{children}</T>;
}