import _ from "lodash";
import { assertNever } from "../../../utils/assertNever.tsx";
import { Vocabulary } from "../../Vocabulary.tsx";
import { Example } from "../../WordBase.tsx";
import { LatinNoun, LatinNounDeclension, LatinNounSex, safeDecline } from "../LatinNoun.tsx";
import { LatinWord } from "../LatinWord.tsx";
import { searchLatinAdjective, searchLatinPluralAdjective } from "../shared/LatinAdjectives.tsx";
import { searchLatinAdverb } from "../shared/LatinAdverbs.tsx";
import { searchLatinConjunction } from "../shared/LatinConjunction.tsx";
import { searchLatinNegation } from "../shared/LatinNegations.tsx";
import { searchLatinNoun } from "../shared/LatinNouns.tsx";
import { searchLatinPrepositionAblative, searchLatinPrepositionAccusative } from "../shared/LatinPrepositions.tsx";
import { searchLatinDemonstrativePronoun, searchLatinIndefinitePronoun, searchLatinPersonalPronoun, searchLatinPossessivePronoun, } from "../shared/LatinPronouns.tsx";
import { searchLatinToFixIrregularWord } from "../shared/LatinToFixes.tsx";
import { searchLatinConjugatedVerb, searchLatinVerb } from "../shared/LatinVerbs.tsx";
import { searchLatinInterjection } from "../shared/LatinInterjection.tsx";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export interface Extras {
  _2023_pegasus_novus_1: {
    examples: Example[];
    notes: string[];
  };
}

function _search<T, K>(f: (root: K) => T, root: K, examples: Example[], notes: string[]): T & Extras {
  return {
    ...f(root),
    _2023_pegasus_novus_1: {
      examples,
      notes,
    },
  };
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export function nounToKlass({ declension, }: LatinNoun): LatinNoun["klass"] {
  switch (declension) {
    case "1":
    case "2":
    case "2irr":
      return 1;
    case "2er":
      return "1er";
    case "2r":
      return "1r";
    case "3cons":
    case "3mixed":
    case "3irr":
      return 2;
    case "4":
    case "5":
      return 3;
    default:
      assertNever(declension);
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const NOUN_MODELS: { [D in LatinNounDeclension]: Record<LatinNounSex<D>, string> } = {
  "1": {
    "f": "rosa",
  },
  "2": {
    "m": "avus",
    "n": "dōnum",
  },
  "2irr": {
    "m": "avus",
    "n": "dōnum",
  },
  "2er": {
    "m": "avus",
    "n": "dōnum",
  },
  "2r": {
    "m": "avus",
    "n": "dōnum",
  },
  "3cons": {
    "m": "dux",
    "f": "māter",
    "n": "corpus",
  },
  "3mixed": {
    "m": "dux",
    "f": "māter",
    "n": "corpus",
  },
  "3irr": {
    "f": "māter",
  },
  "4": {
    "m": "fructus",
    "f": "manus",
    "n": "?",
  },
  "5": {
    "f": "rēs",
  },
};

export function getModelNoun(noun: LatinNoun): string {
  const d = safeDecline(NOUN_MODELS, noun);
  if (_.isArray(d)) {
    return d.join(" ");
  } else {
    return "";
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const LATIN_2023_PEGASUS_NOVUS_1_VOCABULARY: Vocabulary<"la", LatinWord & Extras> = {
  language: "la",
  vocabulary: [
    _search(searchLatinNoun, "amīcus", [
      {
        foreign: "Servī quoque laetī sunt, quod ibi bonōs amīcōs vidēre possunt.",
        dutch: "De slaven zijn ook vrolijk, omdat zij daar goede vrienden kunnen zien.",
        religious: false,
      },
    ], []),
    _search(searchLatinNoun, "deus", [], []),
    _search(searchLatinNoun, "dominus", [
      {
        foreign: "In cōmoediā saepe callidī servī et īrātī dominī sunt.",
        dutch: "In een komedie vind je dikwijls sluwe slaven en woedende meesters.",
        religious: false,
      }, {
        foreign: "Sevēra domina saepe clāmat, nam servae nōn pārent.",
        dutch: "De strenge meesteres roept dikwijls, want de slavinnen gehoorzamen niet.",
        religious: false,
      }, {
        foreign: "Dominus autem īrātus nōn est, servī enim audiunt et bene labōrant.",
        dutch: "Maar de meester is niet woedend, want de slaven luisteren en werken goed.",
        religious: false,
      }, {
        foreign: "Post spectāculum dominus dominaque, servī servaeque domum properant.",
        dutch: "Na het schouwspel haasten de meester en de meesteres, de slaven en de slavinnen zich naar huis.",
        religious: false,
      },
    ], []),
    _search(searchLatinNoun, "equus", [], []),
    _search(searchLatinNoun, "fīlius", [
      {
        foreign: "Dum pater abest, Philolachēs, fīlius eius, pecūniam paternam absūmit.",
        dutch: "Terwijl de vader afwezig is, verkwist Philolachēs, zijn zoon, het geld van de (zijn) vader.",
        religious: false,
      }, {
        foreign: "Ideō Philolachēs, fīlius tuus, iam prīdem ēmigrāvit.",
        dutch: "Daarom is Philolachēs, uw zoon, al eerder verhuisd.",
        religious: false,
      }, {
        foreign: "Intereā domī fīlius pecūniam paternam absūmere nōn dēsinit.",
        dutch: "Intussen thuis is de zoon niet opgehouden met het geld van de vader te verkwisten.",
        religious: false,
      }, {
        foreign: "Augustus, prīmus prīnceps Rōmānus, ūnam fīliam Iūliam nōmine habet.",
        dutch: "Augustus, de eerste Romeinse keizer, had één dochter met de naam Julia.",
        religious: false,
      },
    ], []),
    _search(searchLatinNoun, "servus", [
      {
        foreign: "Servī servaeque dormiunt.",
        dutch: "De slaven en slavinnen slapen.",
        religious: false,
      }, {
        foreign: "Tum servus Trānio appāret.",
        dutch: "Op dat moment verschijnt de slaaf Trānio.",
        religious: false,
      }, {
        foreign: "Servī quoque laetī sunt, quod ibi bonōs amīcōs vidēre possunt.",
        dutch: "De slaven zijn ook vrolijk, omdat zij daar goede vrienden kunnen zien.",
        religious: false,
      }, {
        foreign: "In cōmoediā saepe callidī servī et īrātī dominī sunt.",
        dutch: "In een komedie vind je dikwijls sluwe slaven en woedende meesters.",
        religious: false,
      }, {
        foreign: "Nunc autem servus nōn callidus,sed stultus in scaenā appāret.",
        dutch: "Maar nu verschijnt de slaaf niet sluw, maar dwaas in de scène.",
        religious: false,
      }, {
        foreign: "Stultus servus āctor optimus est.",
        dutch: "De dwaze slaaf is een zeer goede acteur.",
        religious: false,
      }, {
        foreign: "Sevēra domina saepe clāmat, nam servae nōn pārent.",
        dutch: "De strenge meesteres roept dikwijls, want de slavinnen gehoorzamen niet.",
        religious: false,
      }, {
        foreign: "Post spectāculum dominus dominaque, servī servaeque domum properant.",
        dutch: "Na het schouwspel haasten de meester en de meesteres, de slaven en de slavinnen zich naar huis.",
        religious: false,
      }, {
        foreign: "Fidēlis veterānus: 'Virī dīvitēs servōs habent,' inquit, 'quī terga sua strigilī dēstringant. Ego tergum mūrō affricō, quod servum nōn habeō. Pauper enim sum.'",
        dutch: "De trouwe veteraan zegt: 'Rijke mannen hebben slaven die hun rug met een huidkrabber afkrabben. Ik wrijf mijn rug tegen de muur, omdat ik geen slaaf heb. Want ik ben arm.'",
        religious: false,
      }, {
        foreign: "Prīnceps misericordiā commōtus: 'Cūrās dēpōnere potes,' inquit, 'crās tū quoque servum habēbis, quī tergum tuum dēstringat.'",
        dutch: "De keizer, bewogen door medelijden, zegt: 'Je kunt je zorgen neerleggen, morgen zul jij ook een slaaf hebben, die jouw rug afkrabt.'",
        religious: false,
      }, {
        foreign: "Hadriānus autem rogat: 'Num et vōs servum cupitis? Inter vōs terga dēstringere dēbētis.'",
        dutch: "Maar Hadrianus vraagt: 'Jullie verlangen toch niet een slaaf? Jullie moeten de ruggen onder elkaar afkrabben.'",
        religious: false,
      },
    ], []),
    _search(searchLatinNoun, "aqua", [], []),
    _search(searchLatinNoun, "familia", [
      {
        "foreign": "Tōta familia domī quiētē dormit.",
        "dutch": "Geheel de familie slaapt rustig thuis.",
        "religious": false
      },
      {
        "foreign": "Hodiē tōta familia in theātrum properat.",
        "dutch": "Vandaag haast de gehele familie zich naar het theater.",
        "religious": false
      },
      {
        "foreign": "Pater quoque gaudet quod familia nunc ūnā aedificium intrāre potest.",
        "dutch": "Ook vader is blij omdat de familie nu samen het gebouw kan binnengaan.",
        "religious": false
      },
      {
        "foreign": "Nōbilis familia ruīnam emit et theātrum antīquum in lātam arcem convertit.",
        "dutch": "Een adellijke familie koopt de ruïne en verandert het oude theater in een uitgestrekte burcht.",
        "religious": false
      },
      {
        "foreign": "Tōta familia in theātrum properat, nam omnēs spectācula magnifica amant.",
        "dutch": "Het gehele gezin haast zich naar het theater, want allen houden van prachtige schouwspelen.",
        "religious": false
      },
      {
        "foreign": "Hodiē tōta familia in theātrō est.",
        "dutch": "Vandaag is de gehele familie in het theater.",
        "religious": false
      }
    ], []),
    _search(searchLatinNoun, "via", [], []),
    _search(searchLatinNoun, "monumentum", [
      {
        "foreign": "In forō statuae, templa, monumenta sunt.",
        "dutch": "Op het Romeins marktplein zijn standbeelden, tempels, monumenten.",
        "religious": false
      }
    ], []),
    _search(searchLatinNoun, "templum", [
      {
        foreign: "In forō statuae, templa, monumenta sunt.",
        dutch: "Op het Romeins marktplein zijn standbeelden, tempels, monumenten.",
        religious: false,
      }, {
        foreign: "Sub lūnā statuae templaque splendent.",
        dutch: "Onder de maan glanzen standbeelden en tempels.",
        religious: false,
      },
    ], []),
    _search(searchLatinNoun, "vīnum", [], []),
    _search(searchLatinNoun, "pater", [
      {
        foreign: "Dum pater abest, Philolachēs, fīlius eius, pecūniam paternam absūmit.",
        dutch: "Terwijl de vader afwezig is, verkwist Philolachēs, zijn zoon, het geld van de (zijn) vader.",
        religious: false,
      }, {
        foreign: "Sed tribus annīs post pater ex imprōvīsō redit.",
        dutch: "Maar drie jaar later keert de vader onverwachts terug.",
        religious: false,
      }, {
        foreign: "Pater venit!",
        dutch: "(De) vader komt!",
        religious: false,
      }, {
        foreign: "Intereā domī fīlius pecūniam paternam absūmere nōn dēsinit.",
        dutch: "Intussen thuis is de zoon niet opgehouden met het geld van de vader te verkwisten.",
        religious: false,
      }, {
        foreign: "'Cūr theātrum nōndum intrās, pater?', Mārcus impatiēns rogat.",
        dutch: "'Waarom ga jij het theater nog niet binnen, vader?', vraagt Mārcus ongeduldig.",
        religious: false,
      }, {
        foreign: "Pater respondet: 'Māter et avus quoque veniunt, sed avus lentius ambulat.",
        dutch: "De vader antwoordt: 'Moeder en grootvader komen ook, maar grootvader stapt trager.",
        religious: false,
      }, {
        foreign: "Pater quoque gaudet quod familia nunc ūnā aedificium intrāre potest.",
        dutch: "Ook vader is blij omdat de familie nu samen het gebouw kan binnengaan.",
        religious: false,
      }, {
        foreign: "In scaenā Philolachēs laetus est quod pater abest.",
        dutch: "Op de scène is Philolaches vrolijk omdat zijn vader afwezig is.",
        religious: false,
      },
    ], []),
    _search(searchLatinNoun, "senex", [
      {
        "foreign": "Senex ibi dormit.",
        "dutch": "De oude man slaapt daar.",
        "religious": false
      },
      {
        "foreign": "Senēs ibi dormiunt.",
        "dutch": "De oude mannen slapen daar.",
        "religious": false
      },
      {
        "foreign": "Mātrēs dē līberīs narrant, iuvenēs senēsque gaudent quod prīnceps ipse adest.",
        "dutch": "De moeders vertellen over (de/hun) kinderen, de jongemannen en de oude mannen zijn blij omdat de keizer zelf aanwezig is.",
        "religious": false
      },
      {
        "foreign": "Paucīs diēbus post prīnceps iterum thermās intrat et multōs senēs videt, quī terga mūrō affricant.",
        "dutch": "Enkele dagen later gaat de keizer opnieuw de thermen binnen en ziet hij veel oude mannen, die de rug tegen de muur wrijven.",
        "religious": false
      }
    ], []),
    _search(searchLatinNoun, "virgō", [], []),
    _search(searchLatinNoun, "vōx", [
      {
        foreign: "Vōcēs audiō!",
        dutch: "Ik hoor stemmen!",
        religious: false,
      },
    ], []),
    _search(searchLatinNoun, "flūmen", [
      {
        foreign: "Tiberis flūmen lentē in mare fluit.",
        dutch: "De Tiber rivier stroomt traag naar zee.",
        religious: false,
      }, {
        foreign: "Prope flūmen Tiberim Augustus magnificum theātrum aedificārī iubet.",
        dutch: "Augustus laat een prachtig theater bouwen dichtbij de Tiber rivier.",
        religious: false,
      },
    ], []),
    _search(searchLatinNoun, "sīdus", [
      {
        "foreign": "Nunc sīdera appārent.",
        "dutch": "Nu verschijnen er sterrebeelden.",
        "religious": false
      },
      {
        "foreign": "Claudia respondet: 'Multa sīdera videō.'",
        "dutch": "Claudia antwoordt: 'Ik zie veel sterren.'",
        "religious": false
      },
      {
        "foreign": "Mārcus sēcum cōgitat: 'Sīdera nunc spectāmus. Dormīre nōlumus.'",
        "dutch": "Mārcus denkt bij zichzelf: 'Wij kijken nu sterren. Wij willen niet slapen.'",
        "religious": false
      }
    ], []),
    _search(searchLatinNoun, "fēmina", [], []),
    _search(searchLatinNoun, "canis", [], []),
    _search(searchLatinNoun, "iuvenis", [
      {
        "foreign": "Mārcellus optimus pulcherque iuvenis est et Iūliam uxōrem dūcit.",
        "dutch": "Marcellus is een zeer knappe jongeman en huwt Julia.",
        "religious": false
      },
      {
        "foreign": "Intereā multī iuvenēs in theātrō iam sedent, nam āctōrēs fābulam laetam agunt.",
        "dutch": "Intussen zitten al veel jongemannen in het theater, want de acteurs voeren een vrolijk toneelstuk op.",
        "religious": false
      },
      {
        "foreign": "Mātrēs dē līberīs narrant, iuvenēs senēsque gaudent quod prīnceps ipse adest.",
        "dutch": "De moeders vertellen over (de/hun) kinderen, de jongemannen en de oude mannen zijn blij omdat de keizer zelf aanwezig is.",
        "religious": false
      }
    ], []),
    _search(searchLatinNoun, "mercātor", [
      {
        "foreign": "In forō iūdicēs et mercātōrēs quoque sunt.",
        "dutch": "Op het Romeins marktplein zijn ook rechters en handelaars.",
        "religious": false
      }
    ], []),
    _search(searchLatinNoun, "mīles", [
      {
        foreign: "Mīlītēs enim vigilant.",
        dutch: "Want de soldaten waken.",
        religious: false,
      }, {
        foreign: "Mīles tergum mūrō affricat.",
        dutch: "De soldaat wrijft de rug tegen een muur.",
        religious: false,
      }, {
        foreign: "Hadriānus mīrātus mīlitem rogat: 'Cūr tergum mūrō affricās?'",
        dutch: "Hadrianus, verwonderd, vraagt de soldaat: 'Waarom wrijf jij de rug tegen een muur?'",
        religious: false,
      }, {
        foreign: "Ibi imperātōrī Camillō mīlitibusque eōs ostendit.",
        dutch: "Daar toont hij hen aan de opperbevelhebber Camillus en aan de soldaten.",
        religious: false,
      },
    ], []),
    _search(searchLatinNoun, "senātor", [
      {
        "foreign": "Albius Claudius Pulcher senātor est.",
        "dutch": "Albius Claudius is senator.",
        "religious": false
      },
      {
        "foreign": "Senātōrēs in forō aut in cūriā sunt.",
        "dutch": "De senatoren zijn op het Romeins marktplein of in de Romeinse senaat.",
        "religious": false
      },
      {
        "foreign": "Decem senātōrēs nōndum in cūriā sunt.",
        "dutch": "Tien senatoren zijn nog niet in het Romeinse senaatsgebouw.",
        "religious": false
      },
      {
        "foreign": "Senātōrēs nōn iam in cūriā sunt.",
        "dutch": "De senatoren zijn niet meer in het Romeins senaatsgebouw.",
        "religious": false
      }
    ], []),
    _search(searchLatinNoun, "nox", [
      {
        foreign: "Ecce, iam nox est.",
        dutch: "Kijk, het is al nacht.",
        religious: false,
      }, {
        foreign: "Nox est.",
        dutch: "Het is nacht.",
        religious: false,
      },
    ], []),
    _search(searchLatinNoun, "caput", [], []),
    {
      type: "conjugatedVerb",
      latin: "est",
      dutch: "(hij, zij, het, er) is",
      _2023_pegasus_novus_1: {
        examples: [
          {
            foreign: "Quid est, Trānio?",
            dutch: "Wat is er, Trānio?",
            religious: false,
          }, {
            foreign: "Clausa iānua est interdiū.",
            dutch: "De deur is overdag gesloten.",
            religious: false,
          }, {
            foreign: "Nōnne quis intus est?",
            dutch: "Er is toch wel iemand binnen?",
            religious: false,
          }, {
            foreign: "Iam fīnis est.",
            dutch: "Het is al het einde.",
            religious: false,
          }, {
            foreign: "Nox est.",
            dutch: "Het is nacht.",
            religious: false,
          },
        ],
        notes: [],
      },
    },
    {
      type: "conjugatedVerb",
      latin: "sunt",
      dutch: "(zij, er) zijn",
      _2023_pegasus_novus_1: {
        examples: [
          {
            foreign: "Spectātōrēs laetī sunt et domum properāmus.",
            dutch: "De toeschouwers zijn vrolijk en wij haasten ons naar huis.",
            religious: false,
          }, {
            foreign: "Rōmae tria theātra sunt.",
            dutch: "In Rome zijn er drie theaters.",
            religious: false,
          },
        ],
        notes: [],
      },
    },
    _search(searchLatinAdverb, "ibi", [
      {
        foreign: "Ibi legit aut dormit.",
        dutch: "Daar leest hij of slaapt hij.",
        religious: false,
      }, {
        foreign: "Ibi optimam fābulam spectāmus.",
        dutch: "Daar bekijken wij een zeer goed toneelstuk.",
        religious: false,
      }, {
        foreign: "Senex ibi dormit.",
        dutch: "De oude man slaapt daar.",
        religious: false,
      }, {
        foreign: "Senēs ibi dormiunt.",
        dutch: "De oude mannen slapen daar.",
        religious: false,
      }, {
        foreign: "Servī quoque laetī sunt, quod ibi bonōs amīcōs vidēre possunt.",
        dutch: "De slaven zijn ook vrolijk, omdat zij daar goede vrienden kunnen zien.",
        religious: false,
      }, {
        foreign: "Ibi mulierēs appellat et virōs interrogat.",
        dutch: "Daar spreekt hij de vrouwen aan en ondervraagt hij de mannen.",
        religious: false,
      }, {
        foreign: "Ibi imperātōrī Camillō mīlitibusque eōs ostendit.",
        dutch: "Daar toont hij hen aan de opperbevelhebber Camillus en aan de soldaten.",
        religious: false,
      },
    ], []),
    _search(searchLatinAdverb, "saepe", [
      {
        foreign: "Clēmēns saepe in hortō labōrat.",
        dutch: "Clēmēns werkt dikwijls in de tuin.",
        religious: false,
      }, {
        foreign: "Hīc saepe sedeō.",
        dutch: "Ik zit hier dikwijls.",
        religious: false,
      }, {
        foreign: "Hīc saepe sedēmus.",
        dutch: "Wij zitten hier dikwijls.",
        religious: false,
      }, {
        foreign: "In cōmoediā saepe callidī servī et īrātī dominī sunt.",
        dutch: "In een komedie vind je dikwijls sluwe slaven en woedende meesters.",
        religious: false,
      }, {
        foreign: "Sevēra domina saepe clāmat, nam servae nōn pārent.",
        dutch: "De strenge meesteres roept dikwijls, want de slavinnen gehoorzamen niet.",
        religious: false,
      }, {
        foreign: "Hadriānus prīnceps saepe in thermās adit.",
        dutch: "Keizer Hadrianus gaat dikwijls naar de thermen.",
        religious: false,
      },
    ], []),
    _search(searchLatinConjunction, "aut", [
      {
        foreign: "Senātōrēs in forō aut in cūriā sunt.",
        dutch: "De senatoren zijn op het Romeins marktplein of in de Romeinse senaat.",
        religious: false,
      },
    ], []),
    _search(searchLatinConjunction, "et", [], []),
    _search(searchLatinConjunction, "-que", [
      {
        foreign: "Sub lūnā statuae templaque splendent.",
        dutch: "Onder de maan glanzen standbeelden en tempels.",
        religious: false,
      }, {
        foreign: "Servī servaeque dormiunt.",
        dutch: "De slaven en slavinnen slapen.",
        religious: false,
      }, {
        foreign: "Māter līberōs audit clāmatque: 'Cūr nōn tacētis?'",
        dutch: "De moeder van de kinderen hoort en roept: 'Waarom zijn jullie niet stil?'",
        religious: false,
      }, {
        foreign: "Post spectāculum dominus dominaque, servī servaeque domum properant.",
        dutch: "Na het schouwspel haasten de meester en de meesteres, de slaven en de slavinnen zich naar huis.",
        religious: false,
      }, {
        foreign: "Mātrēs dē līberīs narrant, iuvenēs senēsque gaudent quod prīnceps ipse adest.",
        dutch: "De moeders vertellen over (de/hun) kinderen, de jongemannen en de oude mannen zijn blij omdat de keizer zelf aanwezig is.",
        religious: false,
      },
    ], []),
    _search(searchLatinConjunction, "sed", [
      {
        foreign: "Dura lex, sed lex.",
        dutch: "De wet is streng, maar het is de wet (de strenge wet, maar de wet).",
        religious: true,
      }, {
        foreign: "Sed libera nos a malo.",
        dutch: "Maar bevrijdt ons van het kwade.",
        religious: true,
      }, {
        foreign: "Sed tribus annīs post pater ex imprōvīsō redit.",
        dutch: "Maar drie jaar later keert de vader onverwachts terug.",
        religious: false,
      }, {
        foreign: "Theopropidēs iānuam pultat, sed quid hoc est?",
        dutch: "Theopropidēs klopt op de deur, maar wat is dit?",
        religious: false,
      }, {
        foreign: "Pater respondet: 'Māter et avus quoque veniunt, sed avus lentius ambulat.",
        dutch: "De vader antwoordt: 'Moeder en grootvader komen ook, maar grootvader stapt trager.",
        religious: false,
      }, {
        foreign: "Plūrimī spectātōrēs iam in theātrō sedent, sed citō vacuās sēdēs vidēmus.",
        dutch: "In het theater zitten al zeer veel toeschouwers, maar we zien gauw lege zitplaatsen.",
        religious: false,
      }, {
        foreign: "Māter Scrībōnia est, sed Augustus cum uxōre dīvortium fēcit.",
        dutch: "De moeder is Scribonia, maar Augustus was gescheiden van de echtgenote.",
        religious: false,
      }, {
        foreign: "Nunc autem servus nōn callidus,sed stultus in scaenā appāret.",
        dutch: "Maar nu verschijnt de slaaf niet sluw, maar dwaas in de scène.",
        religious: false,
      },
    ], []),
    _search(searchLatinNoun, "avus", [
      {
        foreign: "Pater respondet: 'Māter et avus quoque veniunt, sed avus lentius ambulat.",
        dutch: "De vader antwoordt: 'Moeder en grootvader komen ook, maar grootvader stapt trager.",
        religious: false,
      }, {
        foreign: "Tandem appārent māter et avus. 'Grrr... Cūr nōn properātis?', sēcum cōgitat Mārcus.",
        dutch: "Eindelijk verschijnen moeder en grootvader. 'Grrr... Waarom haasten jullie jezelf niet?', denkt Mārcus bij zichzelf.",
        religious: false,
      },
    ], []),
    _search(searchLatinNoun, "rosa", [], []),
    _search(searchLatinNoun, "dōnum", [], []),
    _search(searchLatinNoun, "dux", [], []),
    _search(searchLatinNoun, "māter", [
      {
        foreign: "Māter līberōs audit clāmatque: 'Cūr nōn tacētis?'",
        dutch: "De moeder van de kinderen hoort en roept: 'Waarom zijn jullie niet stil?'",
        religious: false,
      }, {
        foreign: "Pater respondet: 'Māter et avus quoque veniunt, sed avus lentius ambulat.",
        dutch: "De vader antwoordt: 'Moeder en grootvader komen ook, maar grootvader stapt trager.",
        religious: false,
      }, {
        foreign: "Tandem appārent māter et avus. 'Grrr... Cūr nōn properātis?', sēcum cōgitat Mārcus.",
        dutch: "Eindelijk verschijnen moeder en grootvader. 'Grrr... Waarom haasten jullie jezelf niet?', denkt Mārcus bij zichzelf.",
        religious: false,
      }, {
        foreign: "Māter Scrībōnia est, sed Augustus cum uxōre dīvortium fēcit.",
        dutch: "De moeder is Scribonia, maar Augustus was gescheiden van de echtgenote.",
        religious: false,
      }, {
        foreign: "Mātrēs dē līberīs narrant, iuvenēs senēsque gaudent quod prīnceps ipse adest.",
        dutch: "De moeders vertellen over (de/hun) kinderen, de jongemannen en de oude mannen zijn blij omdat de keizer zelf aanwezig is.",
        religious: false,
      },
    ], []),
    _search(searchLatinNoun, "corpus", [], []),
    _search(searchLatinAdverb, "iam", [
      {
        foreign: "Avus iam senex est.",
        dutch: "De grootvader is al een oude man.",
        religious: false,
      }, {
        foreign: "Ecce, iam nox est.",
        dutch: "Kijk, het is al nacht.",
        religious: false,
      }, {
        foreign: "Ideō Philolachēs, fīlius tuus, iam prīdem ēmigrāvit.",
        dutch: "Daarom is Philolachēs, uw zoon, al eerder verhuisd.",
        religious: false,
      }, {
        foreign: "Iam fīnis est.",
        dutch: "Het is al het einde.",
        religious: false,
      }, {
        foreign: "Plūrimī spectātōrēs iam in theātrō sedent, sed citō vacuās sēdēs vidēmus.",
        dutch: "In het theater zitten al zeer veel toeschouwers, maar we zien gauw lege zitplaatsen.",
        religious: false,
      }, {
        foreign: "Maximē maeret Augustus, nam prīnceps Mārcellum iam successōrem habēbat.",
        dutch: "Augustus treurt erg, want de keizer had Marcellus al als opvolger.",
        religious: false,
      }, {
        foreign: "Praecō assiduē clāmat: 'Multī āctōrēs in urbe sunt! Āctōrēs iam in theātrō sunt! Albius Claudius Pulcher fābulam optimam dat!'",
        dutch: "De omroeper roept onafgebroken: 'Er zijn veel acteurs in de stad! De acteurs zijn al in het theater! Albius Claudius Pulcher laat het beste toneelstuk opvoeren!'",
        religious: false,
      }, {
        foreign: "Multī spectātōrēs iam in magnō theātrō sedent.",
        dutch: "Veel toeschouwers zitten al in het grote theater.",
        religious: false,
      }, {
        foreign: "Intereā multī iuvenēs in theātrō iam sedent, nam āctōrēs fābulam laetam agunt.",
        dutch: "Intussen zitten al veel jongemannen in het theater, want de acteurs voeren een vrolijk toneelstuk op.",
        religious: false,
      },
    ], []),
    {
      type: "adverb",
      latin: "interdum",
      dutch: "soms",
      _2023_pegasus_novus_1: {
        examples: [
          {
            foreign: "Interdum in sēde sedet.",
            dutch: "Soms zit hij in de zetel.",
            religious: false,
          }, {
            foreign: "In lūdō magister multās rēs docet, interdum puerīs fābulam narrat.",
            dutch: "Op school onderwijst de meester veel zaken, soms vertelt hij de jongens een verhaal.",
            religious: false,
          },
        ],
        notes: [],
      },
    },
    {
      type: "adverb",
      latin: "quoque",
      dutch: "ook",
      _2023_pegasus_novus_1: {
        examples: [
          {
            foreign: "In forō iūdicēs et mercātōrēs quoque sunt.",
            dutch: "Op het Romeins marktplein zijn ook rechters en handelaars.",
            religious: false,
          }, {
            foreign: "Pater respondet: 'Māter et avus quoque veniunt, sed avus lentius ambulat.",
            dutch: "De vader antwoordt: 'Moeder en grootvader komen ook, maar grootvader stapt trager.",
            religious: false,
          }, {
            foreign: "Pater quoque gaudet quod familia nunc ūnā aedificium intrāre potest.",
            dutch: "Ook vader is blij omdat de familie nu samen het gebouw kan binnengaan.",
            religious: false,
          }, {
            foreign: "Albius senātor quoque est.",
            dutch: "Albius is ook een senator.",
            religious: false,
          }, {
            foreign: "Servī quoque laetī sunt, quod ibi bonōs amīcōs vidēre possunt.",
            dutch: "De slaven zijn ook vrolijk, omdat zij daar goede vrienden kunnen zien.",
            religious: false,
          }, {
            foreign: "Prīnceps misericordiā commōtus: 'Cūrās dēpōnere potes,' inquit, 'crās tū quoque servum habēbis, quī tergum tuum dēstringat.'",
            dutch: "De keizer, bewogen door medelijden, zegt: 'Je kunt je zorgen neerleggen, morgen zul jij ook een slaaf hebben, die jouw rug afkrabt.'",
            religious: false,
          },
        ],
        notes: [
          "Het bijwoord quoque staat normaal achter het woord waar het bij hoort.",
        ],
      },
    },
    _search(searchLatinConjunction, "enim", [
      {
        foreign: "Mīlītēs enim vigilant.",
        dutch: "Want de soldaten waken.",
        religious: false,
      }, {
        foreign: "HOC EST ENIM CORPUS MEUM.",
        dutch: "WANT DIT IS MIJN LICHAAM.",
        religious: true,
      }, {
        foreign: "HIC EST ENIM CALIX SANGUINIS MEI.",
        dutch: "WANT DIT IS DE KELK VAN MIJN BLOED.",
        religious: true,
      }, {
        foreign: "Patientiam servō: certē enim in hōc theātrō vacuās sēdēs invenīmus.'",
        dutch: "Ik bewaar de kalmte: want we vinden zeker vrije zitplaatsen in dit theater.'",
        religious: false,
      }, {
        foreign: "Servae enim saepe in culīnā labōrant.",
        dutch: "Want de slavinnen werken dikwijls in de keuken.",
        religious: false,
      }, {
        foreign: "Dominus autem īrātus nōn est, servī enim audiunt et bene labōrant.",
        dutch: "Maar de meester is niet woedend, want de slaven luisteren en werken goed.",
        religious: false,
      }, {
        foreign: "Fidēlis veterānus: 'Virī dīvitēs servōs habent,' inquit, 'quī terga sua strigilī dēstringant. Ego tergum mūrō affricō, quod servum nōn habeō. Pauper enim sum.'",
        dutch: "De trouwe veteraan zegt: 'Rijke mannen hebben slaven die hun rug met een huidkrabber afkrabben. Ik wrijf mijn rug tegen de muur, omdat ik geen slaaf heb. Want ik ben arm.'",
        religious: false,
      }, {
        foreign: "Miserī puerī maximē timent, in magnīs enim perīculīs sunt.",
        dutch: "De ongelukkige jongens zijn zeer bang, want ze zijn in grote gevaren.",
        religious: false,
      },
    ], [
      "Het voegwoord enim staat in het Latijn nooit op de eerste plaats in de zin.",
    ]),
    _search(searchLatinNoun, "cūria", [
      {
        "foreign": "Senātōrēs in forō aut in cūriā sunt.",
        "dutch": "De senatoren zijn op het Romeins marktplein of in de Romeinse senaat.",
        "religious": false
      },
      {
        "foreign": "Decem senātōrēs nōndum in cūriā sunt.",
        "dutch": "Tien senatoren zijn nog niet in het Romeinse senaatsgebouw.",
        "religious": false
      },
      {
        "foreign": "Senātōrēs nōn iam in cūriā sunt.",
        "dutch": "De senatoren zijn niet meer in het Romeins senaatsgebouw.",
        "religious": false
      }
    ], []),
    _search(searchLatinNoun, "fābula", [
      {
        "foreign": "Ibi optimam fābulam spectāmus.",
        "dutch": "Daar bekijken wij een zeer goed toneelstuk.",
        "religious": false
      },
      {
        "foreign": "Hodiē in theātrō Pompeiī fābulam 'Mōstellāria' spectāmus.",
        "dutch": "Vandaag bekijken we het toneelstuk 'Mōstellāria' in het theater van Pompeius.",
        "religious": false
      },
      {
        "foreign": "Praecō assiduē clāmat: 'Multī āctōrēs in urbe sunt! Āctōrēs iam in theātrō sunt! Albius Claudius Pulcher fābulam optimam dat!'",
        "dutch": "De omroeper roept onafgebroken: 'Er zijn veel acteurs in de stad! De acteurs zijn al in het theater! Albius Claudius Pulcher laat het beste toneelstuk opvoeren!'",
        "religious": false
      },
      {
        "foreign": "Intereā multī iuvenēs in theātrō iam sedent, nam āctōrēs fābulam laetam agunt.",
        "dutch": "Intussen zitten al veel jongemannen in het theater, want de acteurs voeren een vrolijk toneelstuk op.",
        "religious": false
      },
      {
        "foreign": "Omnēs novam fābulam spectāre cupiunt.",
        "dutch": "Allen verlangen het nieuwe toneelstuk te bekijken.",
        "religious": false
      },
      {
        "foreign": "In lūdō magister multās rēs docet, interdum puerīs fābulam narrat.",
        "dutch": "Op school onderwijst de meester veel zaken, soms vertelt hij de jongens een verhaal.",
        "religious": false
      }
    ], []),
    _search(searchLatinNoun, "lūna", [
      {
        "foreign": "Sub lūnā statuae templaque splendent.",
        "dutch": "Onder de maan glanzen standbeelden en tempels.",
        "religious": false
      }
    ], []),
    _search(searchLatinNoun, "forum", [
      {
        foreign: "Senātōrēs in forō aut in cūriā sunt.",
        dutch: "De senatoren zijn op het Romeins marktplein of in de Romeinse senaat.",
        religious: false,
      }, {
        foreign: "Decem senātōrēs nōndum in cūriā sunt.",
        dutch: "Tien senatoren zijn nog niet in het Romeinse senaatsgebouw.",
        religious: false,
      }, {
        foreign: "In forō statuae, templa, monumenta sunt.",
        dutch: "Op het Romeins marktplein zijn standbeelden, tempels, monumenten.",
        religious: false,
      }, {
        foreign: "In forō iūdicēs et mercātōrēs quoque sunt.",
        dutch: "Op het Romeins marktplein zijn ook rechters en handelaars.",
        religious: false,
      }, {
        foreign: "Subitō magnus clāmor in forō est.",
        dutch: "Plotseling is ('klinkt') er groot ('veel') geroep in het forum.",
        religious: false,
      },
    ], []),
    _search(searchLatinNoun, "iūdex", [
      {
        "foreign": "In forō iūdicēs et mercātōrēs quoque sunt.",
        "dutch": "Op het Romeins marktplein zijn ook rechters en handelaars.",
        "religious": false
      }
    ], []),
    _search(searchLatinNoun, "onus", [], []),
    _search(searchLatinNoun, "tempus", [
      {
        foreign: "Ex eō tempore tōtum theātrum ruīna est.",
        dutch: "Het hele theater is vanaf dat moment een ruïne.",
        religious: false,
      },
    ], []),
    _search(searchLatinNegation, "nōn", [
      {
        foreign: "Intereā domī fīlius pecūniam paternam absūmere nōn dēsinit.",
        dutch: "Intussen thuis is de zoon niet opgehouden met het geld van de vader te verkwisten.",
        religious: false,
      }, {
        foreign: "Māter līberōs audit clāmatque: 'Cūr nōn tacētis?'",
        dutch: "De moeder van de kinderen hoort en roept: 'Waarom zijn jullie niet stil?'",
        religious: false,
      }, {
        foreign: "Tandem appārent māter et avus. 'Grrr... Cūr nōn properātis?', sēcum cōgitat Mārcus.",
        dutch: "Eindelijk verschijnen moeder en grootvader. 'Grrr... Waarom haasten jullie jezelf niet?', denkt Mārcus bij zichzelf.",
        religious: false,
      }, {
        foreign: "Nunc autem servus nōn callidus,sed stultus in scaenā appāret.",
        dutch: "Maar nu verschijnt de slaaf niet sluw, maar dwaas in de scène.",
        religious: false,
      }, {
        foreign: "Sevēra domina saepe clāmat, nam servae nōn pārent.",
        dutch: "De strenge meesteres roept dikwijls, want de slavinnen gehoorzamen niet.",
        religious: false,
      }, {
        foreign: "Dominus autem īrātus nōn est, servī enim audiunt et bene labōrant.",
        dutch: "Maar de meester is niet woedend, want de slaven luisteren en werken goed.",
        religious: false,
      },
    ], []),
    _search(searchLatinAdverb, "semper", [
      {
        foreign: "Ideō precor beatam Mariam semper Virginem, ...",
        dutch: "Daarom smeek ik de heilige Maria, altijd Maagd, ...",
        religious: true,
      },
    ], []),
    _search(searchLatinNoun, "statua", [
      {
        "foreign": "In forō statuae, templa, monumenta sunt.",
        "dutch": "Op het Romeins marktplein zijn standbeelden, tempels, monumenten.",
        "religious": false
      },
      {
        "foreign": "Sub lūnā statuae templaque splendent.",
        "dutch": "Onder de maan glanzen standbeelden en tempels.",
        "religious": false
      },
      {
        "foreign": "In scaenā magnificās statuās et columnās altās vidēmus.",
        "dutch": "Op de scene zien we prachtige standbeelden en hoge zuilen.",
        "religious": false
      }
    ], []),
    _search(searchLatinNoun, "eques", [], []),
    _search(searchLatinVerb, "labōrāre", [
      {
        "foreign": "Nōn iam in culīnā labōrant.",
        "dutch": "Ze werken niet meer in de keuken.",
        "religious": false
      },
      {
        "foreign": "Cūr in hortō labōrās?",
        "dutch": "Waarom werk jij in de tuin?",
        "religious": false
      },
      {
        "foreign": "Cūr in hortō labōrātis?",
        "dutch": "Waarom werken jullie in de tuin?",
        "religious": false
      },
      {
        "foreign": "Tandem appārent māter et avus. 'Grrr... Cūr nōn properātis?', sēcum cōgitat Mārcus.",
        "dutch": "Eindelijk verschijnen moeder en grootvader. 'Grrr... Waarom haasten jullie jezelf niet?', denkt Mārcus bij zichzelf.",
        "religious": false
      },
      {
        "foreign": "Dominus autem īrātus nōn est, servī enim audiunt et bene labōrant.",
        "dutch": "Maar de meester is niet woedend, want de slaven luisteren en werken goed.",
        "religious": false
      }
    ], []),
    _search(searchLatinVerb, "appārēre", [
      {
        "foreign": "Nunc sīdera appārent.",
        "dutch": "Nu verschijnen er sterrebeelden.",
        "religious": false
      },
      {
        "foreign": "Tum servus Trānio appāret.",
        "dutch": "Op dat moment verschijnt de slaaf Trānio.",
        "religious": false
      },
      {
        "foreign": "Tandem appārent māter et avus. 'Grrr... Cūr nōn properātis?', sēcum cōgitat Mārcus.",
        "dutch": "Eindelijk verschijnen moeder en grootvader. 'Grrr... Waarom haasten jullie jezelf niet?', denkt Mārcus bij zichzelf.",
        "religious": false
      },
      {
        "foreign": "Nunc autem servus nōn callidus, sed stultus in scaenā appāret.",
        "dutch": "Maar nu verschijnt de slaaf niet sluw, maar dwaas in de scène.",
        "religious": false
      }
    ], []),
    _search(searchLatinVerb, "dormīre", [
      {
        foreign: "Tōta familia domī quiētē dormit.",
        dutch: "Geheel de familie slaapt rustig thuis.",
        religious: false,
      }, {
        foreign: "Servī servaeque dormiunt.",
        dutch: "De slaven en slavinnen slapen.",
        religious: false,
      }, {
        foreign: "Senex ibi dormit.",
        dutch: "De oude man slaapt daar.",
        religious: false,
      }, {
        foreign: "Senēs ibi dormiunt.",
        dutch: "De oude mannen slapen daar.",
        religious: false,
      }, {
        foreign: "Mārcus et Claudia nōndum dormiunt.",
        dutch: "Mārcus en Claudia slapen nog niet.",
        religious: false,
      }, {
        foreign: "Mārcus sēcum cōgitat: 'Sīdera nunc spectāmus. Dormīre nōlumus.'",
        dutch: "Mārcus denkt bij zichzelf: 'Wij kijken nu sterren. Wij willen niet slapen.'",
        religious: false,
      }, {
        foreign: "Dominus autem īrātus nōn est, servī enim audiunt et bene labōrant.",
        dutch: "Maar de meester is niet woedend, want de slaven luisteren en werken goed.",
        religious: false,
      },
    ], []),
    {
      type: "adverb",
      latin: "domī",
      dutch: "thuis",
      _2023_pegasus_novus_1: {
        examples: [
          {
            foreign: "Tōta familia domī quiētē dormit.",
            dutch: "Geheel de familie slaapt rustig thuis.",
            religious: false,
          }, {
            foreign: "Intereā domī fīlius pecūniam paternam absūmere nōn dēsinit.",
            dutch: "Intussen thuis is de zoon niet opgehouden met het geld van de vader te verkwisten.",
            religious: false,
          },
        ],
        notes: ["De locatief van domus."]
      },
    },
    _search(searchLatinAdverb, "etiam", [
      {
        foreign: "Ante domum etiam Argus vigilat!",
        dutch: "Vóór het huis waakt ook Argus!",
        religious: false,
      }, {
        foreign: "Crucifixus etiam pro nobis.",
        dutch: "Hij werd ook voor ons gekruisigd.",
        religious: true,
      }, {
        foreign: "Etiam inter bellum magister līberōs docēre et cum eīs in silvā ambulāre nōn dēsinit.",
        dutch: "Ook tijdens de oorlog houdt de meester niet op de kinderen te onderwijzen en met hen in het bos te wandelen.",
        religious: false,
      },
    ], []),
    {
      type: "adverb",
      latin: "nōndum",
      dutch: "nog niet",
      _2023_pegasus_novus_1: {
        examples: [
          {
            foreign: "Decem senātōrēs nōndum in cūriā sunt.",
            dutch: "Tien senatoren zijn nog niet in het Romeinse senaatsgebouw.",
            religious: false,
          }, {
            foreign: "Mārcus et Claudia nōndum dormiunt.",
            dutch: "Mārcus en Claudia slapen nog niet.",
            religious: false,
          }, {
            foreign: "'Cūr theātrum nōndum intrās, pater?', Mārcus impatiēns rogat.",
            dutch: "'Waarom ga jij het theater nog niet binnen, vader?', vraagt Mārcus ongeduldig.",
            religious: false,
          },
        ],
        notes: [],
      },
    },
    {
      type: "adverb",
      latin: "nōn iam",
      dutch: "niet meer",
      _2023_pegasus_novus_1: {
        examples: [
          {
            foreign: "Senātōrēs nōn iam in cūriā sunt.",
            dutch: "De senatoren zijn niet meer in het Romeins senaatsgebouw.",
            religious: false,
          }, {
            foreign: "Nōn iam in culīnā labōrant.",
            dutch: "Ze werken niet meer in de keuken.",
            religious: false,
          },
        ],
        notes: [],
      },
    },
    {
      type: "adverb",
      latin: "nunc",
      dutch: "nu",
      _2023_pegasus_novus_1: {
        examples: [
          {
            foreign: "Hīc et nunc!",
            dutch: "Hier en nu! (Rust en focus op het heden: jezelf niet verliezen in afleiding of opwinden over zorgen.)",
            religious: true,
          }, {
            foreign: "Nunc sīdera appārent.",
            dutch: "Nu verschijnen er sterrebeelden.",
            religious: false,
          }, {
            foreign: "Mārcus sēcum cōgitat: 'Sīdera nunc spectāmus. Dormīre nōlumus.'",
            dutch: "Mārcus denkt bij zichzelf: 'Wij kijken nu sterren. Wij willen niet slapen.'",
            religious: false,
          }, {
            foreign: "Pater quoque gaudet quod familia nunc ūnā aedificium intrāre potest.",
            dutch: "Ook vader is blij omdat de familie nu samen het gebouw kan binnengaan.",
            religious: false,
          }, {
            foreign: "Nunc in altō aedificiō XIV mīlia spectātōrum fābulās spectāre possunt.",
            dutch: "Veertienduizend toeschouwers kunnen nu in het hoge gebouw toneelstukken bekijken.",
            religious: false,
          }, {
            foreign: "Nunc autem servus nōn callidus, sed stultus in scaenā appāret.",
            dutch: "Maar nu verschijnt de slaaf niet sluw, maar dwaas in de scène.",
            religious: false,
          },
        ],
        notes: [],
      },
    },
    _search(searchLatinNoun, "theātrum", [
      {
        "foreign": "Hodiē tōta familia in theātrum properat.",
        "dutch": "Vandaag haast de gehele familie zich naar het theater.",
        "religious": false
      },
      {
        "foreign": "Rōmae tria theātra sunt.",
        "dutch": "In Rome zijn er drie theaters.",
        "religious": false
      },
      {
        "foreign": "Hodiē in theātrō Pompeiī fābulam 'Mōstellāria' spectāmus.",
        "dutch": "Vandaag bekijken we het toneelstuk 'Mōstellāria' in het theater van Pompeius.",
        "religious": false
      },
      {
        "foreign": "'Cūr theātrum nōndum intrās, pater?', Mārcus impatiēns rogat.",
        "dutch": "'Waarom ga jij het theater nog niet binnen, vader?', vraagt Mārcus ongeduldig.",
        "religious": false
      },
      {
        "foreign": "Patientiam servō: certē enim in hōc theātrō vacuās sēdēs invenīmus.'",
        "dutch": "Ik bewaar de kalmte: want we vinden zeker vrije zitplaatsen in dit theater.'",
        "religious": false
      },
      {
        "foreign": "Plūrimī spectātōrēs iam in theātrō sedent, sed citō vacuās sēdēs vidēmus.",
        "dutch": "In het theater zitten al zeer veel toeschouwers, maar we zien gauw lege zitplaatsen.",
        "religious": false
      },
      {
        "foreign": "Prope flūmen Tiberim Augustus magnificum theātrum aedificārī iubet.",
        "dutch": "Augustus laat een prachtig theater bouwen dichtbij de Tiber rivier.",
        "religious": false
      },
      {
        "foreign": "Maestus prīnceps theātrum novum Mārcellō mortuō dēvovet.",
        "dutch": "De treurige keizer draagt het nieuwe theater aan de dode Marcellus op.",
        "religious": false
      },
      {
        "foreign": "Rōmānī multōs lapidēs ē theātrō Mārcellī auferunt, quod pontem antīquum renovāre volunt.",
        "dutch": "De Romeinen nemen veel stenen weg uit theater van Marcellus, omdat ze de oude brug willen hernieuwen.",
        "religious": false
      },
      {
        "foreign": "Ex eō tempore tōtum theātrum ruīna est.",
        "dutch": "Het hele theater is vanaf dat moment een ruïne.",
        "religious": false
      },
      {
        "foreign": "Nōbilis familia ruīnam emit et theātrum antīquum in lātam arcem convertit.",
        "dutch": "Een adellijke familie koopt de ruïne en verandert het oude theater in een uitgestrekte burcht.",
        "religious": false
      },
      {
        "foreign": "Praecō assiduē clāmat: 'Multī āctōrēs in urbe sunt! Āctōrēs iam in theātrō sunt! Albius Claudius Pulcher fābulam optimam dat!'",
        "dutch": "De omroeper roept onafgebroken: 'Er zijn veel acteurs in de stad! De acteurs zijn al in het theater! Albius Claudius Pulcher laat het beste toneelstuk opvoeren!'",
        "religious": false
      },
      {
        "foreign": "Tōta familia in theātrum properat, nam omnēs spectācula magnifica amant.",
        "dutch": "Het gehele gezin haast zich naar het theater, want allen houden van prachtige schouwspelen.",
        "religious": false
      },
      {
        "foreign": "Multī spectātōrēs iam in magnō theātrō sedent.",
        "dutch": "Veel toeschouwers zitten al in het grote theater.",
        "religious": false
      },
      {
        "foreign": "Theātrum magnum aedificium est.",
        "dutch": "Het theater is een groot (belangrijk) gebouw.",
        "religious": false
      },
      {
        "foreign": "Intereā multī iuvenēs in theātrō iam sedent, nam āctōrēs fābulam laetam agunt.",
        "dutch": "Intussen zitten al veel jongemannen in het theater, want de acteurs voeren een vrolijk toneelstuk op.",
        "religious": false
      },
      {
        "foreign": "Hodiē tōta familia in theātrō est.",
        "dutch": "Vandaag is de gehele familie in het theater.",
        "religious": false
      }
    ], []),
    _search(searchLatinNoun, "mulier", [
      {
        foreign: "Benedicta tu in mulieribus,",
        dutch: "Gezegend zijt gij onder (alle) vrouwen,",
        religious: true,
      }, {
        foreign: "Ibi mulierēs appellat et virōs interrogat.",
        dutch: "Daar spreekt hij de vrouwen aan en ondervraagt hij de mannen.",
        religious: false,
      },
    ], []),
    _search(searchLatinNoun, "uxor", [
      {
        foreign: "Māter Scrībōnia est, sed Augustus cum uxōre dīvortium fēcit.",
        dutch: "De moeder is Scribonia, maar Augustus was gescheiden van de echtgenote.",
        religious: false,
      }, {
        foreign: "Mārcellus optimus pulcherque iuvenis est et Iūliam uxōrem dūcit.",
        dutch: "Marcellus is een zeer knappe jongeman en huwt Julia.",
        religious: false,
      },
    ], []),
    _search(searchLatinVerb, "cōgitāre", [
      {
        foreign: "Mārcus sēcum cōgitat: 'Sīdera nunc spectāmus. Dormīre nōlumus.'",
        dutch: "Mārcus denkt bij zichzelf: 'Wij kijken nu sterren. Wij willen niet slapen.'",
        religious: false,
      }, {
        foreign: "Tandem appārent māter et avus. 'Grrr... Cūr nōn properātis?', sēcum cōgitat Mārcus.",
        dutch: "Eindelijk verschijnen moeder en grootvader. 'Grrr... Waarom haasten jullie jezelf niet?', denkt Mārcus bij zichzelf.",
        religious: false,
      },
    ], []),
    _search(searchLatinVerb, "dare", [
      {
        "foreign": "Magnum plausum damus.",
        "dutch": "Wij geven een groot applaus.",
        "religious": false
      },
      {
        "foreign": "Praecō assiduē clāmat: 'Multī āctōrēs in urbe sunt! Āctōrēs iam in theātrō sunt! Albius Claudius Pulcher fābulam optimam dat!'",
        "dutch": "De omroeper roept onafgebroken: 'Er zijn veel acteurs in de stad! De acteurs zijn al in het theater! Albius Claudius Pulcher laat het beste toneelstuk opvoeren!'",
        "religious": false
      }
    ], []),
    _search(searchLatinVerb, "narrāre", [
      {
        "foreign": "Mātrēs dē līberīs narrant, iuvenēs senēsque gaudent quod prīnceps ipse adest.",
        "dutch": "De moeders vertellen over (de/hun) kinderen, de jongemannen en de oude mannen zijn blij omdat de keizer zelf aanwezig is.",
        "religious": false
      },
      {
        "foreign": "In lūdō magister multās rēs docet, interdum puerīs fābulam narrat.",
        "dutch": "Op school onderwijst de meester veel zaken, soms vertelt hij de jongens een verhaal.",
        "religious": false
      }
    ], []),
    _search(searchLatinVerb, "properāre", [
      {
        "foreign": "Hodiē tōta familia in theātrum properat.",
        "dutch": "Vandaag haast de gehele familie zich naar het theater.",
        "religious": false
      },
      {
        "foreign": "Spectātōrēs laetī sunt et domum properāmus.",
        "dutch": "De toeschouwers zijn vrolijk en wij haasten ons naar huis.",
        "religious": false
      },
      {
        "foreign": "Tandem appārent māter et avus. 'Grrr... Cūr nōn properātis?', sēcum cōgitat Mārcus.",
        "dutch": "Eindelijk verschijnen moeder en grootvader. 'Grrr... Waarom haasten jullie jezelf niet?', denkt Mārcus bij zichzelf.",
        "religious": false
      },
      {
        "foreign": "Tōta familia in theātrum properat, nam omnēs spectācula magnifica amant.",
        "dutch": "Het gehele gezin haast zich naar het theater, want allen houden van prachtige schouwspelen.",
        "religious": false
      },
      {
        "foreign": "Post spectāculum dominus dominaque, servī servaeque domum properant.",
        "dutch": "Na het schouwspel haasten de meester en de meesteres, de slaven en de slavinnen zich naar huis.",
        "religious": false
      }
    ], []),
    _search(searchLatinVerb, "rogāre", [
      {
        foreign: "Mārcus rogat: 'Claudia, quid vidēs?'",
        dutch: "Mārcus vraagt: 'Claudia, wat zie jij?'",
        religious: false,
      }, {
        foreign: "'Cūr theātrum nōndum intrās, pater?', Mārcus impatiēns rogat.",
        dutch: "'Waarom ga jij het theater nog niet binnen, vader?', vraagt Mārcus ongeduldig.",
        religious: false,
      }, {
        foreign: "Hadriānus mīrātus mīlitem rogat: 'Cūr tergum mūrō affricās?'",
        dutch: "Hadrianus, verwonderd, vraagt de soldaat: 'Waarom wrijf jij de rug tegen een muur?'",
        religious: false,
      }, {
        foreign: "Hadriānus autem rogat: 'Num et vōs servum cupitis? Inter vōs terga dēstringere dēbētis.'",
        dutch: "Maar Hadrianus vraagt: 'Jullie verlangen toch niet een slaaf? Jullie moeten de ruggen onder elkaar afkrabben.'",
        religious: false,
      },
    ], []),
    _search(searchLatinVerb, "spectāre", [
      {
        "foreign": "Ibi optimam fābulam spectāmus.",
        "dutch": "Daar bekijken wij een zeer goed toneelstuk.",
        "religious": false
      },
      {
        "foreign": "Caelum spectant.",
        "dutch": "Zij bekijken de hemel.",
        "religious": false
      },
      {
        "foreign": "Mārcus sēcum cōgitat: 'Sīdera nunc spectāmus. Dormīre nōlumus.'",
        "dutch": "Mārcus denkt bij zichzelf: 'Wij kijken nu sterren. Wij willen niet slapen.'",
        "religious": false
      },
      {
        "foreign": "Hodiē in theātrō Pompeiī fābulam 'Mōstellāria' spectāmus.",
        "dutch": "Vandaag bekijken we het toneelstuk 'Mōstellāria' in het theater van Pompeius.",
        "religious": false
      },
      {
        "foreign": "Nunc in altō aedificiō XIV mīlia spectātōrum fābulās spectāre possunt.",
        "dutch": "Veertienduizend toeschouwers kunnen nu in het hoge gebouw toneelstukken bekijken.",
        "religious": false
      },
      {
        "foreign": "Omnēs novam fābulam spectāre cupiunt.",
        "dutch": "Allen verlangen het nieuwe toneelstuk te bekijken.",
        "religious": false
      }
    ], []),
    _search(searchLatinVerb, "stāre", [], []),
    _search(searchLatinVerb, "gaudēre", [
      {
        "foreign": "Pater quoque gaudet quod familia nunc ūnā aedificium intrāre potest.",
        "dutch": "Ook vader is blij omdat de familie nu samen het gebouw kan binnengaan.",
        "religious": false
      },
      {
        "foreign": "Mātrēs dē līberīs narrant, iuvenēs senēsque gaudent quod prīnceps ipse adest.",
        "dutch": "De moeders vertellen over (de/hun) kinderen, de jongemannen en de oude mannen zijn blij omdat de keizer zelf aanwezig is.",
        "religious": false
      }
    ], []),
    _search(searchLatinVerb, "habēre", [
      {
        foreign: "Habemus eum pro amico.",
        dutch: "Wij beschouwen hem als vriend.",
        religious: false,
      }, {
        foreign: "Augustus, prīmus prīnceps Rōmānus, ūnam fīliam Iūliam nōmine habet.",
        dutch: "Augustus, de eerste Romeinse keizer, had één dochter met de naam Julia.",
        religious: false,
      }, {
        foreign: "Fidēlis veterānus: 'Virī dīvitēs servōs habent,' inquit, 'quī terga sua strigilī dēstringant. Ego tergum mūrō affricō, quod servum nōn habeō. Pauper enim sum.'",
        dutch: "De trouwe veteraan zegt: 'Rijke mannen hebben slaven die hun rug met een huidkrabber afkrabben. Ik wrijf mijn rug tegen de muur, omdat ik geen slaaf heb. Want ik ben arm.'",
        religious: false,
      }, {
        foreign: "Prīnceps misericordiā commōtus: 'Cūrās dēpōnere potes,' inquit, 'crās tū quoque servum habēbis, quī tergum tuum dēstringat.'",
        dutch: "De keizer, bewogen door medelijden, zegt: 'Je kunt je zorgen neerleggen, morgen zul jij ook een slaaf hebben, die jouw rug afkrabt.'",
        religious: false,
      },
    ], []),
    _search(searchLatinVerb, "respondēre", [
      {
        foreign: "Nēmō iānuam aperit aut respondet.",
        dutch: "Niemand opent de deur of antwoordt.",
        religious: false,
      }, {
        foreign: "Claudia respondet: 'Multa sīdera videō.'",
        dutch: "Claudia antwoordt: 'Ik zie veel sterren.'",
        religious: false,
      }, {
        foreign: "Pater respondet: 'Māter et avus quoque veniunt, sed avus lentius ambulat.",
        dutch: "De vader antwoordt: 'Moeder en grootvader komen ook, maar grootvader stapt trager.",
        religious: false,
      },
    ], []),
    _search(searchLatinVerb, "sedēre", [
      {
        "foreign": "Hīc saepe sedeō.",
        "dutch": "Ik zit hier dikwijls.",
        "religious": false
      },
      {
        "foreign": "Hīc saepe sedēmus.",
        "dutch": "Wij zitten hier dikwijls.",
        "religious": false
      },
      {
        "foreign": "Plūrimī spectātōrēs iam in theātrō sedent, sed citō vacuās sēdēs vidēmus.",
        "dutch": "In het theater zitten al zeer veel toeschouwers, maar we zien gauw lege zitplaatsen.",
        "religious": false
      },
      {
        "foreign": "Multī spectātōrēs iam in magnō theātrō sedent.",
        "dutch": "Veel toeschouwers zitten al in het grote theater.",
        "religious": false
      },
      {
        "foreign": "Intereā multī iuvenēs in theātrō iam sedent, nam āctōrēs fābulam laetam agunt.",
        "dutch": "Intussen zitten al veel jongemannen in het theater, want de acteurs voeren een vrolijk toneelstuk op.",
        "religious": false
      }
    ], []),
    _search(searchLatinVerb, "vidēre", [
      {
        "foreign": "Eum vīdī!",
        "dutch": "Ik heb hem gezien!",
        "religious": false
      },
      {
        "foreign": "Mārcus rogat: 'Claudia, quid vidēs?'",
        "dutch": "Mārcus vraagt: 'Claudia, wat zie jij?'",
        "religious": false
      },
      {
        "foreign": "Claudia respondet: 'Multa sīdera videō.'",
        "dutch": "Claudia antwoordt: 'Ik zie veel sterren.'",
        "religious": false
      },
      {
        "foreign": "Plūrimī spectātōrēs iam in theātrō sedent, sed citō vacuās sēdēs vidēmus.",
        "dutch": "In het theater zitten al zeer veel toeschouwers, maar we zien gauw lege zitplaatsen.",
        "religious": false
      },
      {
        "foreign": "Servī quoque laetī sunt, quod ibi bonōs amīcōs vidēre possunt.",
        "dutch": "De slaven zijn ook vrolijk, omdat zij daar goede vrienden kunnen zien.",
        "religious": false
      },
      {
        "foreign": "In scaenā magnificās statuās et columnās altās vidēmus.",
        "dutch": "Op de scene zien we prachtige standbeelden en hoge zuilen.",
        "religious": false
      },
      {
        "foreign": "Quōdam diē prīnceps rōbustum veterānum videt.",
        "dutch": "Op een dag ziet de keizer een gespierde veteraan.",
        "religious": false
      },
      {
        "foreign": "Paucīs diēbus post prīnceps iterum thermās intrat et multōs senēs videt, quī terga mūrō affricant.",
        "dutch": "Enkele dagen later gaat de keizer opnieuw de thermen binnen en ziet hij veel oude mannen, die de rug tegen de muur wrijven.",
        "religious": false
      }
    ], []),
    _search(searchLatinVerb, "aperīre", [
      {
        "foreign": "Nēmō iānuam aperit aut respondet.",
        "dutch": "Niemand opent de deur of antwoordt.",
        "religious": false
      },
      {
        "foreign": "Iānuam aperīre dēbētis!",
        "dutch": "Julie moeten de deur openen!",
        "religious": false
      }
    ], []),
    _search(searchLatinVerb, "venīre", [
      {
        "foreign": "Pater venit!",
        "dutch": "(De) vader komt!",
        "religious": false
      },
      {
        "foreign": "Pater respondet: 'Māter et avus quoque veniunt, sed avus lentius ambulat.",
        "dutch": "De vader antwoordt: 'Moeder en grootvader komen ook, maar grootvader stapt trager.",
        "religious": false
      }
    ], []),
    _search(searchLatinVerb, "esse", [
      {
        foreign: "Ipsum esse subsistens",
        dutch: "'Het zijn zelf dat op zichzelf bestaat' (filosofische definitie die St. Thomas van Aquino aan YHWH geeft)",
        religious: true,
      },
    ], []),
    {
      type: "adverb",
      latin: "cūr?",
      dutch: "waarom?",
      _2023_pegasus_novus_1: {
        examples: [
          {
            foreign: "Cūr in hortō labōrās?",
            dutch: "Waarom werk jij in de tuin?",
            religious: false,
          }, {
            foreign: "Cūr in hortō labōrātis?",
            dutch: "Waarom werken jullie in de tuin?",
            religious: false,
          }, {
            foreign: "Māter līberōs audit clāmatque: 'Cūr nōn tacētis?'",
            dutch: "De moeder van de kinderen hoort en roept: 'Waarom zijn jullie niet stil?'",
            religious: false,
          }, {
            foreign: "'Cūr theātrum nōndum intrās, pater?', Mārcus impatiēns rogat.",
            dutch: "'Waarom ga jij het theater nog niet binnen, vader?', vraagt Mārcus ongeduldig.",
            religious: false,
          }, {
            foreign: "Hadriānus mīrātus mīlitem rogat: 'Cūr tergum mūrō affricās?'",
            dutch: "Hadrianus, verwonderd, vraagt de soldaat: 'Waarom wrijf jij de rug tegen een muur?'",
            religious: false,
          },
        ],
        notes: [],
      },
    },
    _search(searchLatinAdverb, "domum", [
      {
        foreign: "Spectātōrēs laetī sunt et domum properāmus.",
        dutch: "De toeschouwers zijn vrolijk en wij haasten ons naar huis.",
        religious: false,
      }, {
        foreign: "Post spectāculum dominus dominaque, servī servaeque domum properant.",
        dutch: "Na het schouwspel haasten de meester en de meesteres, de slaven en de slavinnen zich naar huis.",
        religious: false,
      },
    ], []),
    _search(searchLatinAdverb, "ideō", [
      {
        foreign: "Ideō precor beatam Mariam semper Virginem, ...",
        dutch: "Daarom smeek ik de heilige Maria, altijd Maagd, ...",
        religious: true,
      }, {
        foreign: "Ideō Philolachēs, fīlius tuus, iam prīdem ēmigrāvit.",
        dutch: "Daarom is Philolachēs, uw zoon, al eerder verhuisd.",
        religious: false,
      },
    ], []),
    {
      type: "adverb",
      latin: "intereā",
      dutch: "intussen",
      _2023_pegasus_novus_1: {
        examples: [
          {
            foreign: "Intereā domī fīlius pecūniam paternam absūmere nōn dēsinit.",
            dutch: "Intussen thuis is de zoon niet opgehouden met het geld van de vader te verkwisten.",
            religious: false,
          }, {
            foreign: "Intereā multī iuvenēs in theātrō iam sedent, nam āctōrēs fābulam laetam agunt.",
            dutch: "Intussen zitten al veel jongemannen in het theater, want de acteurs voeren een vrolijk toneelstuk op.",
            religious: false,
          },
        ],
        notes: [],
      },
    },
    _search(searchLatinNoun, "pecūnia", [
      {
        "foreign": "Dum pater abest, Philolachēs, fīlius eius, pecūniam paternam absūmit.",
        "dutch": "Terwijl de vader afwezig is, verkwist Philolachēs, zijn zoon, het geld van de (zijn) vader.",
        "religious": false
      },
      {
        "foreign": "Intereā domī fīlius pecūniam paternam absūmere nōn dēsinit.",
        "dutch": "Intussen thuis is de zoon niet opgehouden met het geld van de vader te verkwisten.",
        "religious": false
      }
    ], []),
    _search(searchLatinNoun, "aedificium", [
      {
        "foreign": "Pater quoque gaudet quod familia nunc ūnā aedificium intrāre potest.",
        "dutch": "Ook vader is blij omdat de familie nu samen het gebouw kan binnengaan.",
        "religious": false
      },
      {
        "foreign": "Nunc in altō aedificiō XIV mīlia spectātōrum fābulās spectāre possunt.",
        "dutch": "Veertienduizend toeschouwers kunnen nu in het hoge gebouw toneelstukken bekijken.",
        "religious": false
      },
      {
        "foreign": "Theātrum magnum aedificium est.",
        "dutch": "Het theater is een groot (belangrijk) gebouw.",
        "religious": false
      }
    ], []),
    _search(searchLatinNoun, "spectāculum", [
      {
        "foreign": "'Spectāculum ineat!', Mārcus clāmat.",
        "dutch": "'Het schouwspel mag beginnen!', roept Mārcus.",
        "religious": false
      },
      {
        "foreign": "Nunc in altō aedificiō XIV mīlia spectātōrum fābulās spectāre possunt.",
        "dutch": "Veertienduizend toeschouwers kunnen nu in het hoge gebouw toneelstukken bekijken.",
        "religious": false
      },
      {
        "foreign": "Tōta familia in theātrum properat, nam omnēs spectācula magnifica amant.",
        "dutch": "Het gehele gezin haast zich naar het theater, want allen houden van prachtige schouwspelen.",
        "religious": false
      },
      {
        "foreign": "Post spectāculum dominus dominaque, servī servaeque domum properant.",
        "dutch": "Na het schouwspel haasten de meester en de meesteres, de slaven en de slavinnen zich naar huis.",
        "religious": false
      }
    ], []),
    _search(searchLatinNoun, "fīnis", [
      {
        "foreign": "Iam fīnis est.",
        "dutch": "Het is al het einde.",
        "religious": false
      }
    ], []),
    _search(searchLatinNoun, "iter", [
      {
        "foreign": "Theopropidēs longum iter in Aegyptum facit.",
        "dutch": "Theopropidēs maakt een lange rijs naar Egypte.",
        "religious": false
      }
    ], []),
    _search(searchLatinAdjective, "laet", [
      {
        foreign: "Spectātōrēs laetī sunt et domum properāmus.",
        dutch: "De toeschouwers zijn vrolijk en wij haasten ons naar huis.",
        religious: false,
      }, {
        foreign: "Servī quoque laetī sunt, quod ibi bonōs amīcōs vidēre possunt.",
        dutch: "De slaven zijn ook vrolijk, omdat zij daar goede vrienden kunnen zien.",
        religious: false,
      }, {
        foreign: "Intereā multī iuvenēs in theātrō iam sedent, nam āctōrēs fābulam laetam agunt.",
        dutch: "Intussen zitten al veel jongemannen in het theater, want de acteurs voeren een vrolijk toneelstuk op.",
        religious: false,
      }, {
        foreign: "In scaenā Philolachēs laetus est quod pater abest.",
        dutch: "Op de scène is Philolaches vrolijk omdat zijn vader afwezig is.",
        religious: false,
      },
    ], []),
    _search(searchLatinAdjective, "lāt", [], []),
    _search(searchLatinAdjective, "long", [
      {
        foreign: "Theopropidēs longum iter in Aegyptum facit.",
        dutch: "Theopropidēs maakt een lange rijs naar Egypte.",
        religious: false,
      }, {
        foreign: "Nōbilis familia ruīnam emit et theātrum antīquum in lātam arcem convertit.",
        dutch: "Een adellijke familie koopt de ruïne en verandert het oude theater in een uitgestrekte burcht.",
        religious: false,
      },
    ], []),
    _search(searchLatinAdjective, "magn", [
      {
        foreign: "Magnum plausum damus.",
        dutch: "Wij geven een groot applaus.",
        religious: false,
      }, {
        foreign: "Subitō magnus clāmor in forō est.",
        dutch: "Plotseling is ('klinkt') er groot ('veel') geroep in het forum.",
        religious: false,
      }, {
        foreign: "Multī spectātōrēs iam in magnō theātrō sedent.",
        dutch: "Veel toeschouwers zitten al in het grote theater.",
        religious: false,
      }, {
        foreign: "Theātrum magnum aedificium est.",
        dutch: "Het theater is een groot (belangrijk) gebouw.",
        religious: false,
      }, {
        foreign: "Miserī puerī maximē timent, in magnīs enim perīculīs sunt.",
        dutch: "De ongelukkige jongens zijn zeer bang, want ze zijn in grote gevaren.",
        religious: false,
      },
    ], []),
    _search(searchLatinAdjective, "optim", [
      {
        "foreign": "Ibi optimam fābulam spectāmus.",
        "dutch": "Daar bekijken wij een zeer goed toneelstuk.",
        "religious": false
      },
      {
        "foreign": "Mārcellus optimus pulcherque iuvenis est et Iūliam uxōrem dūcit.",
        "dutch": "Marcellus is een zeer knappe jongeman en huwt Julia.",
        "religious": false
      },
      {
        "foreign": "Praecō assiduē clāmat: 'Multī āctōrēs in urbe sunt! Āctōrēs iam in theātrō sunt! Albius Claudius Pulcher fābulam optimam dat!'",
        "dutch": "De omroeper roept onafgebroken: 'Er zijn veel acteurs in de stad! De acteurs zijn al in het theater! Albius Claudius Pulcher laat het beste toneelstuk opvoeren!'",
        "religious": false
      },
      {
        "foreign": "Stultus servus āctor optimus est.",
        "dutch": "De dwaze slaaf is een zeer goede acteur.",
        "religious": false
      }
    ], []),
    _search(searchLatinAdjective, "timid", [
      {
        "foreign": "Pater mōnstra timet et timidus fugit.",
        "dutch": "De vader vreest het monster en vlucht bang weg.",
        "religious": false
      }
    ], []),
    _search(searchLatinVerb, "clāmāre", [
      {
        foreign: "Ad te clāmamus, exsules filii Hevae;",
        dutch: "Tot u roepen wij, bannelingen, kinderen van Eva;",
        religious: true,
      }, {
        foreign: "'Spectāculum ineat!', Mārcus clāmat.",
        dutch: "'Het schouwspel mag beginnen!', roept Mārcus.",
        religious: false,
      }, {
        foreign: "Subitō magnus clāmor in forō est.",
        dutch: "Plotseling is ('klinkt') er groot ('veel') geroep in het forum.",
        religious: false,
      }, {
        foreign: "Praecō assiduē clāmat: 'Multī āctōrēs in urbe sunt! Āctōrēs iam in theātrō sunt! Albius Claudius Pulcher fābulam optimam dat!'",
        dutch: "De omroeper roept onafgebroken: 'Er zijn veel acteurs in de stad! De acteurs zijn al in het theater! Albius Claudius Pulcher laat het beste toneelstuk opvoeren!'",
        religious: false,
      }, {
        foreign: "Sevēra domina saepe clāmat, nam servae nōn pārent.",
        dutch: "De strenge meesteres roept dikwijls, want de slavinnen gehoorzamen niet.",
        religious: false,
      },
    ], []),
    _search(searchLatinVerb, "invenīre", [
      {
        "foreign": "Patientiam servō: certē enim in hōc theātrō vacuās sēdēs invenīmus.'",
        "dutch": "Ik bewaar de kalmte: want we vinden zeker vrije zitplaatsen in dit theater.'",
        "religious": false
      }
    ], []),
    _search(searchLatinAdverb, "tum of tunc", [
      {
        foreign: "Tum servus Trānio appāret.",
        dutch: "Op dat moment verschijnt de slaaf Trānio.",
        religious: false,
      },
    ], []),
    _search(searchLatinConjunction, "dum", [
      {
        foreign: "Dum pater abest, Philolachēs, fīlius eius, pecūniam paternam absūmit.",
        dutch: "Terwijl de vader afwezig is, verkwist Philolachēs, zijn zoon, het geld van de (zijn) vader.",
        religious: false,
      },
    ], []),
    _search(searchLatinVerb, "amāre", [
      {
        foreign: "Tōta familia in theātrum properat, nam omnēs spectācula magnifica amant.",
        dutch: "Het gehele gezin haast zich naar het theater, want allen houden van prachtige schouwspelen.",
        religious: false,
      },
    ], []),
    _search(searchLatinVerb, "monēre", [], []),
    _search(searchLatinVerb, "audīre", [
      {
        foreign: "Vōcēs audiō!",
        dutch: "Ik hoor stemmen!",
        religious: false,
      }, {
        foreign: "Māter līberōs audit clāmatque: 'Cūr nōn tacētis?'",
        dutch: "De moeder van de kinderen hoort en roept: 'Waarom zijn jullie niet stil?'",
        religious: false,
      }, {
        foreign: "Dominus autem īrātus nōn est, servī enim audiunt et bene labōrant.",
        dutch: "Maar de meester is niet woedend, want de slaven luisteren en werken goed.",
        religious: false,
      },
    ], []),
    _search(searchLatinAdjective, "alt", [
      {
        "foreign": "Nunc in altō aedificiō XIV mīlia spectātōrum fābulās spectāre possunt.",
        "dutch": "Veertienduizend toeschouwers kunnen nu in het hoge gebouw toneelstukken bekijken.",
        "religious": false
      },
      {
        "foreign": "In scaenā magnificās statuās et columnās altās vidēmus.",
        "dutch": "Op de scene zien we prachtige standbeelden en hoge zuilen.",
        "religious": false
      }
    ], []),
    _search(searchLatinAdjective, "magnific", [
      {
        "foreign": "Prope flūmen Tiberim Augustus magnificum theātrum aedificārī iubet.",
        "dutch": "Augustus laat een prachtig theater bouwen dichtbij de Tiber rivier.",
        "religious": false
      },
      {
        "foreign": "Tōta familia in theātrum properat, nam omnēs spectācula magnifica amant.",
        "dutch": "Het gehele gezin haast zich naar het theater, want allen houden van prachtige schouwspelen.",
        "religious": false
      },
      {
        "foreign": "In scaenā magnificās statuās et columnās altās vidēmus.",
        "dutch": "Op de scene zien we prachtige standbeelden en hoge zuilen.",
        "religious": false
      }
    ], []),
    {
      type: "pluralAdjective",
      latin: "multī",
      root: "mult",
      dutch: "veel",
      klass: 1,
      _2023_pegasus_novus_1: {
        examples: [
          {
            foreign: "Claudia respondet: 'Multa sīdera videō.'",
            dutch: "Claudia antwoordt: 'Ik zie veel sterren.'",
            religious: false,
          }, {
            foreign: "Rōmānī multōs lapidēs ē theātrō Mārcellī auferunt, quod pontem antīquum renovāre volunt.",
            dutch: "De Romeinen nemen veel stenen weg uit theater van Marcellus, omdat ze de oude brug willen hernieuwen.",
            religious: false,
          }, {
            foreign: "Praecō assiduē clāmat: 'Multī āctōrēs in urbe sunt! Āctōrēs iam in theātrō sunt! Albius Claudius Pulcher fābulam optimam dat!'",
            dutch: "De omroeper roept onafgebroken: 'Er zijn veel acteurs in de stad! De acteurs zijn al in het theater! Albius Claudius Pulcher laat het beste toneelstuk opvoeren!'",
            religious: false,
          }, {
            foreign: "Multī spectātōrēs iam in magnō theātrō sedent.",
            dutch: "Veel toeschouwers zitten al in het grote theater.",
            religious: false,
          }, {
            foreign: "Intereā multī iuvenēs in theātrō iam sedent, nam āctōrēs fābulam laetam agunt.",
            dutch: "Intussen zitten al veel jongemannen in het theater, want de acteurs voeren een vrolijk toneelstuk op.",
            religious: false,
          }, {
            foreign: "Paucīs diēbus post prīnceps iterum thermās intrat et multōs senēs videt, quī terga mūrō affricant.",
            dutch: "Enkele dagen later gaat de keizer opnieuw de thermen binnen en ziet hij veel oude mannen, die de rug tegen de muur wrijven.",
            religious: false,
          }, {
            foreign: "In lūdō magister multās rēs docet, interdum puerīs fābulam narrat.",
            dutch: "Op school onderwijst de meester veel zaken, soms vertelt hij de jongens een verhaal.",
            religious: false,
          },
        ],
        notes: [],
      },
    },
    _search(searchLatinAdjective, "nov", [
      {
        foreign: "Maestus prīnceps theātrum novum Mārcellō mortuō dēvovet.",
        dutch: "De treurige keizer draagt het nieuwe theater aan de dode Marcellus op.",
        religious: false,
      }, {
        foreign: "Omnēs novam fābulam spectāre cupiunt.",
        dutch: "Allen verlangen het nieuwe toneelstuk te bekijken.",
        religious: false,
      },
    ], []),
    _search(searchLatinAdjective, "Rōmān", [
      {
        foreign: "Augustus, prīmus prīnceps Rōmānus, ūnam fīliam Iūliam nōmine habet.",
        dutch: "Augustus, de eerste Romeinse keizer, had één dochter met de naam Julia.",
        religious: false,
      },
    ], []),
    _search(searchLatinAdverb, "subitō", [
      {
        foreign: "Subitō autem Mārcellus, XX annōs nātus, post gravem morbum mortuus est.",
        dutch: "Maar Marcellus is na zware ziekte plotseling gestorven, 20 jaar oud.",
        religious: false,
      }, {
        foreign: "Subitō magnus clāmor in forō est.",
        dutch: "Plotseling is ('klinkt') er groot ('veel') geroep in het forum.",
        religious: false,
      },
    ], []),
    _search(searchLatinAdjective, "antīqu", [
      {
        "foreign": "Rōmānī multōs lapidēs ē theātrō Mārcellī auferunt, quod pontem antīquum renovāre volunt.",
        "dutch": "De Romeinen nemen veel stenen weg uit theater van Marcellus, omdat ze de oude brug willen hernieuwen.",
        "religious": false
      },
      {
        "foreign": "Nōbilis familia ruīnam emit et theātrum antīquum in lātam arcem convertit.",
        "dutch": "Een adellijke familie koopt de ruïne en verandert het oude theater in een uitgestrekte burcht.",
        "religious": false
      }
    ], []),
    _search(searchLatinAdjective, "mal", [], []),
    _search(searchLatinAdjective, "tōt", [
      {
        foreign: "Tōta familia domī quiētē dormit.",
        dutch: "Geheel de familie slaapt rustig thuis.",
        religious: false,
      }, {
        foreign: "tōtus tuus",
        dutch: "geheel de uwe (persoonlijk motto van Johannes Paulus II)",
        religious: true,
      }, {
        foreign: "Tōtus tuus ego sum, et omnia mea tua sunt. Accipiō te in mea omnia. Praebe mihi cor tuum, Maria.",
        dutch: "Ik ben geheel de Uwe, en alles wat ik heb is van U. Ik verwelkom U in al mijn zaken en zorgen. Toon mij Uw hart, O Maria.",
        religious: true,
      }, {
        foreign: "Hodiē tōta familia in theātrum properat.",
        dutch: "Vandaag haast de gehele familie zich naar het theater.",
        religious: false,
      }, {
        foreign: "Ex eō tempore tōtum theātrum ruīna est.",
        dutch: "Het hele theater is vanaf dat moment een ruïne.",
        religious: false,
      }, {
        foreign: "Tōta familia in theātrum properat, nam omnēs spectācula magnifica amant.",
        dutch: "Het gehele gezin haast zich naar het theater, want allen houden van prachtige schouwspelen.",
        religious: false,
      }, {
        foreign: "Hodiē tōta familia in theātrō est.",
        dutch: "Vandaag is de gehele familie in het theater.",
        religious: false,
      },
    ], []),
    _search(searchLatinConjunction, "nam", [
      {
        foreign: "Maximē maeret Augustus, nam prīnceps Mārcellum iam successōrem habēbat.",
        dutch: "Augustus treurt erg, want de keizer had Marcellus al als opvolger.",
        religious: false,
      }, {
        foreign: "Tōta familia in theātrum properat, nam omnēs spectācula magnifica amant.",
        dutch: "Het gehele gezin haast zich naar het theater, want allen houden van prachtige schouwspelen.",
        religious: false,
      }, {
        foreign: "Intereā multī iuvenēs in theātrō iam sedent, nam āctōrēs fābulam laetam agunt.",
        dutch: "Intussen zitten al veel jongemannen in het theater, want de acteurs voeren een vrolijk toneelstuk op.",
        religious: false,
      }, {
        foreign: "Sevēra domina saepe clāmat, nam servae nōn pārent.",
        dutch: "De strenge meesteres roept dikwijls, want de slavinnen gehoorzamen niet.",
        religious: false,
      },
    ], []),
    _search(searchLatinConjunction, "quod", [
      {
        foreign: "Rōmānī multōs lapidēs ē theātrō Mārcellī auferunt, quod pontem antīquum renovāre volunt.",
        dutch: "De Romeinen nemen veel stenen weg uit theater van Marcellus, omdat ze de oude brug willen hernieuwen.",
        religious: false,
      }, {
        foreign: "Servī quoque laetī sunt, quod ibi bonōs amīcōs vidēre possunt.",
        dutch: "De slaven zijn ook vrolijk, omdat zij daar goede vrienden kunnen zien.",
        religious: false,
      }, {
        foreign: "In scaenā Philolachēs laetus est quod pater abest.",
        dutch: "Op de scène is Philolaches vrolijk omdat zijn vader afwezig is.",
        religious: false,
      }, {
        foreign: "Mātrēs dē līberīs narrant, iuvenēs senēsque gaudent quod prīnceps ipse adest.",
        dutch: "De moeders vertellen over (de/hun) kinderen, de jongemannen en de oude mannen zijn blij omdat de keizer zelf aanwezig is.",
        religious: false,
      }, {
        foreign: "Fidēlis veterānus: 'Virī dīvitēs servōs habent,' inquit, 'quī terga sua strigilī dēstringant. Ego tergum mūrō affricō, quod servum nōn habeō. Pauper enim sum.'",
        dutch: "De trouwe veteraan zegt: 'Rijke mannen hebben slaven die hun rug met een huidkrabber afkrabben. Ik wrijf mijn rug tegen de muur, omdat ik geen slaaf heb. Want ik ben arm.'",
        religious: false,
      },
    ], []),
    _search(searchLatinAdjective, "bon", [
      {
        foreign: "Servī quoque laetī sunt, quod ibi bonōs amīcōs vidēre possunt.",
        dutch: "De slaven zijn ook vrolijk, omdat zij daar goede vrienden kunnen zien.",
        religious: false,
      },
    ], []),
    _search(searchLatinAdjective, "īrāt", [
      {
        foreign: "In cōmoediā saepe callidī servī et īrātī dominī sunt.",
        dutch: "In een komedie vind je dikwijls sluwe slaven en woedende meesters.",
        religious: false,
      }, {
        foreign: "Dominus autem īrātus nōn est, servī enim audiunt et bene labōrant.",
        dutch: "Maar de meester is niet woedend, want de slaven luisteren en werken goed.",
        religious: false,
      },
    ], []),
    _search(searchLatinAdjective, "stult", [
      {
        "foreign": "Nunc autem servus nōn callidus, sed stultus in scaenā appāret.",
        "dutch": "Maar nu verschijnt de slaaf niet sluw, maar dwaas in de scène.",
        "religious": false
      },
      {
        "foreign": "Stultus servus āctor optimus est.",
        "dutch": "De dwaze slaaf is een zeer goede acteur.",
        "religious": false
      }
    ], []),
    {
      type: "adverb",
      latin: "bene",
      dutch: "goed",
      _2023_pegasus_novus_1: {
        examples: [
          {
            foreign: "Dominus autem īrātus nōn est, servī enim audiunt et bene labōrant.",
            dutch: "Maar de meester is niet woedend, want de slaven luisteren en werken goed.",
            religious: false,
          },
        ],
        notes: [],
      },
    },
    {
      type: "countingWord",
      latin: "ūnus",
      conjugations: ["ūnus", "ūna", "ūnum"],
      dutch: "één; enig",
      _2023_pegasus_novus_1: {
        examples: [
          {
            foreign: "Augustus, prīmus prīnceps Rōmānus, ūnam fīliam Iūliam nōmine habet.",
            dutch: "Augustus, de eerste Romeinse keizer, had één dochter met de naam Julia.",
            religious: false,
          },
        ],
        notes: [],
      },
    },
    {
      type: "countingWord",
      latin: "duo",
      conjugations: ["duo", "duae", "duo"],
      dutch: "twee",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    {
      type: "countingWord",
      latin: "trēs",
      conjugations: ["trēs", "tria", "trium"],
      dutch: "drie",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    {
      type: "countingWord",
      latin: "quattuor",
      dutch: "vier",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    {
      type: "countingWord",
      latin: "quīnque",
      dutch: "vijf",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    {
      type: "countingWord",
      latin: "sex",
      dutch: "zes",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    {
      type: "countingWord",
      latin: "septem",
      dutch: "zeven",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    {
      type: "countingWord",
      latin: "octō",
      dutch: "acht",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    {
      type: "countingWord",
      latin: "novem",
      dutch: "negen",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    {
      type: "countingWord",
      latin: "decem",
      dutch: "tien",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    {
      type: "countingWord",
      latin: "centum",
      dutch: "honderd",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    {
      type: "countingWord",
      latin: "mīlle",
      dutch: "duizend",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    {
      type: "countingWord",
      latin: "... mīlia (mīl-ium, o)",
      dutch: "...duizend",
      _2023_pegasus_novus_1: {
        examples: [
          {
            foreign: "Nunc in altō aedificiō XIV mīlia spectātōrum fābulās spectāre possunt.",
            dutch: "Veertienduizend toeschouwers kunnen nu in het hoge gebouw toneelstukken bekijken.",
            religious: false,
          },
        ],
        notes: [],
      },
    },
    _search(searchLatinNoun, "mūrus", [
      {
        "foreign": "Mīles tergum mūrō affricat.",
        "dutch": "De soldaat wrijft de rug tegen de muur.",
        "religious": false
      },
      {
        "foreign": "Hadriānus mīrātus mīlitem rogat: 'Cūr tergum mūrō affricās?'",
        "dutch": "Hadrianus, verwonderd, vraagt de soldaat: 'Waarom wrijf jij de rug tegen een muur?'",
        "religious": false
      },
      {
        "foreign": "Fidēlis veterānus: 'Virī dīvitēs servōs habent,' inquit, 'quī terga sua strigilī dēstringant. Ego tergum mūrō affricō, quod servum nōn habeō. Pauper enim sum.'",
        "dutch": "De trouwe veteraan zegt: 'Rijke mannen hebben slaven die hun rug met een huidkrabber afkrabben. Ik wrijf mijn rug tegen de muur, omdat ik geen slaaf heb. Want ik ben arm.'",
        "religious": false
      },
      {
        "foreign": "Paucīs diēbus post prīnceps iterum thermās intrat et multōs senēs videt, quī terga mūrō affricant.",
        "dutch": "Enkele dagen later gaat de keizer opnieuw de thermen binnen en ziet hij veel oude mannen, die de rug tegen de muur wrijven.",
        "religious": false
      }
    ], []),
    _search(searchLatinNoun, "cūra", [
      {
        "foreign": "Prīnceps misericordiā commōtus: 'Cūrās dēpōnere potes,' inquit, 'crās tū quoque servum habēbis, quī tergum tuum dēstringat.'",
        "dutch": "De keizer, bewogen door medelijden, zegt: 'Je kunt je zorgen neerleggen, morgen zul jij ook een slaaf hebben, die jouw rug afkrabt.'",
        "religious": false
      }
    ], []),
    _search(searchLatinNoun, "tergum", [
      {
        "foreign": "Mīles tergum mūrō affricat.",
        "dutch": "De soldaat wrijft de rug tegen de muur.",
        "religious": false
      },
      {
        "foreign": "Hadriānus mīrātus mīlitem rogat: 'Cūr tergum mūrō affricās?'",
        "dutch": "Hadrianus, verwonderd, vraagt de soldaat: 'Waarom wrijf jij de rug tegen een muur?'",
        "religious": false
      },
      {
        "foreign": "Fidēlis veterānus: 'Virī dīvitēs servōs habent,' inquit, 'quī terga sua strigilī dēstringant. Ego tergum mūrō affricō, quod servum nōn habeō. Pauper enim sum.'",
        "dutch": "De trouwe veteraan zegt: 'Rijke mannen hebben slaven die hun rug met een huidkrabber afkrabben. Ik wrijf mijn rug tegen de muur, omdat ik geen slaaf heb. Want ik ben arm.'",
        "religious": false
      },
      {
        "foreign": "Paucīs diēbus post prīnceps iterum thermās intrat et multōs senēs videt, quī terga mūrō affricant.",
        "dutch": "Enkele dagen later gaat de keizer opnieuw de thermen binnen en ziet hij veel oude mannen, die de rug tegen de muur wrijven.",
        "religious": false
      }
    ], []),
    _search(searchLatinNoun, "prīnceps", [
      {
        "foreign": "Pater: 'Prīnceps', inquit, 'thermās nunc intrat.'",
        "dutch": "Vader zegt: 'De keizer gaat nu de thermen binnen.'",
        "religious": false
      },
      {
        "foreign": "Augustus, prīmus prīnceps Rōmānus, ūnam fīliam Iūliam nōmine habet.",
        "dutch": "Augustus, de eerste Romeinse keizer, had één dochter met de naam Julia.",
        "religious": false
      },
      {
        "foreign": "Maximē maeret Augustus, nam prīnceps Mārcellum iam successōrem habēbat.",
        "dutch": "Augustus treurt erg, want de keizer had Marcellus al als opvolger.",
        "religious": false
      },
      {
        "foreign": "Maestus prīnceps theātrum novum Mārcellō mortuō dēvovet.",
        "dutch": "De treurige keizer draagt het nieuwe theater aan de dode Marcellus op.",
        "religious": false
      },
      {
        "foreign": "Hadriānus prīnceps saepe in thermās adit.",
        "dutch": "Keizer Hadrianus gaat dikwijls naar de thermen.",
        "religious": false
      },
      {
        "foreign": "Mātrēs dē līberīs narrant, iuvenēs senēsque gaudent quod prīnceps ipse adest.",
        "dutch": "De moeders vertellen over (de/hun) kinderen, de jongemannen en de oude mannen zijn blij omdat de keizer zelf aanwezig is.",
        "religious": false
      },
      {
        "foreign": "Quōdam diē prīnceps rōbustum veterānum videt.",
        "dutch": "Op een dag ziet de keizer een gespierde veteraan.",
        "religious": false
      },
      {
        "foreign": "Prīnceps misericordiā commōtus: 'Cūrās dēpōnere potes,' inquit, 'crās tū quoque servum habēbis, quī tergum tuum dēstringat.'",
        "dutch": "De keizer, bewogen door medelijden, zegt: 'Je kunt je zorgen neerleggen, morgen zul jij ook een slaaf hebben, die jouw rug afkrabt.'",
        "religious": false
      },
      {
        "foreign": "Paucīs diēbus post prīnceps iterum thermās intrat et multōs senēs videt, quī terga mūrō affricant.",
        "dutch": "Enkele dagen later gaat de keizer opnieuw de thermen binnen en ziet hij veel oude mannen, die de rug tegen de muur wrijven.",
        "religious": false
      }
    ], []),
    _search(searchLatinVerb, "appellāre", [
      {
        "foreign": "Ibi mulierēs appellat et virōs interrogat.",
        "dutch": "Daar spreekt hij de vrouwen aan en ondervraagt hij de mannen.",
        "religious": false
      }
    ], []),
    _search(searchLatinVerb, "interrogāre", [
      {
        "foreign": "Ibi mulierēs appellat et virōs interrogat.",
        "dutch": "Daar spreekt hij de vrouwen aan en ondervraagt hij de mannen.",
        "religious": false
      }
    ], []),
    _search(searchLatinVerb, "intrāre", [
      {
        foreign: "Pater: 'Prīnceps', inquit, 'thermās nunc intrat.'",
        dutch: "Vader zegt: 'De keizer gaat nu de thermen binnen.'",
        religious: false,
      }, {
        foreign: "Paucīs diēbus post prīnceps iterum thermās intrat et multōs senēs videt, quī terga mūrō affricant.",
        dutch: "Enkele dagen later gaat de keizer opnieuw de thermen binnen en ziet hij veel oude mannen, die de rug tegen de muur wrijven.",
        religious: false,
      },
    ], []),
    _search(searchLatinVerb, "ōrnāre", [], []),
    _search(searchLatinVerb, "dēbēre", [
      {
        foreign: "Hadriānus autem rogat: 'Num et vōs servum cupitis? Inter vōs terga dēstringere dēbētis.'",
        dutch: "Maar Hadrianus vraagt: 'Jullie verlangen toch niet een slaaf? Jullie moeten de ruggen onder elkaar afkrabben.'",
        religious: false,
      },
    ], []),
    _search(searchLatinConjugatedVerb, "inquit", [
      {
        foreign: "Pater: 'Prīnceps', inquit, 'thermās nunc intrat.'",
        dutch: "Vader zegt: 'De keizer gaat nu de thermen binnen.'",
        religious: false,
      }, {
        foreign: "Fidēlis veterānus: 'Virī dīvitēs servōs habent,' inquit, 'quī terga sua strigilī dēstringant. Ego tergum mūrō affricō, quod servum nōn habeō. Pauper enim sum.'",
        dutch: "De trouwe veteraan zegt: 'Rijke mannen hebben slaven die hun rug met een huidkrabber afkrabben. Ik wrijf mijn rug tegen de muur, omdat ik geen slaaf heb. Want ik ben arm.'",
        religious: false,
      }, {
        foreign: "Prīnceps misericordiā commōtus: 'Cūrās dēpōnere potes,' inquit, 'crās tū quoque servum habēbis, quī tergum tuum dēstringat.'",
        dutch: "De keizer, bewogen door medelijden, zegt: 'Je kunt je zorgen neerleggen, morgen zul jij ook een slaaf hebben, die jouw rug afkrabt.'",
        religious: false,
      },
    ], [
      "Het werkwoord inquit staat in het Latijn altijd in de mededeling.",
    ]),
    {
      type: "adverb",
      latin: "iterum",
      dutch: "opnieuw",
      _2023_pegasus_novus_1: {
        examples: [
          {
            foreign: "Paucīs diēbus post prīnceps iterum thermās intrat et multōs senēs videt, quī terga mūrō affricant.",
            dutch: "Enkele dagen later gaat de keizer opnieuw de thermen binnen en ziet hij veel oude mannen, die de rug tegen de muur wrijven.",
            religious: false,
          },
        ],
        notes: [],
      },
    },
    _search(searchLatinAdverb, "sīc", [
      {
        foreign: "Sīc Hadriānus prīnceps cupiditātem eōrum lūdit.",
        dutch: "Zo bespot keizer Hadrianus hun hebzucht.",
        religious: false,
      },
    ], []),
    _search(searchLatinConjunction, "autem", [
      {
        foreign: "Hadriānus autem rogat: ...",
        dutch: "Maar Hadrianus vraagt: ...",
        religious: false,
      }, {
        foreign: "Subitō autem Mārcellus, XX annōs nātus, post gravem morbum mortuus est.",
        dutch: "Maar Marcellus is na zware ziekte plotseling gestorven, 20 jaar oud.",
        religious: false,
      }, {
        foreign: "Nunc autem servus nōn callidus, sed stultus in scaenā appāret.",
        dutch: "Maar nu verschijnt de slaaf niet sluw, maar dwaas in de scène.",
        religious: false,
      }, {
        foreign: "Dominus autem īrātus nōn est, servī enim audiunt et bene labōrant.",
        dutch: "Maar de meester is niet woedend, want de slaven luisteren en werken goed.",
        religious: false,
      }, {
        foreign: "Hadriānus autem rogat: 'Num et vōs servum cupitis? Inter vōs terga dēstringere dēbētis.'",
        dutch: "Maar Hadrianus vraagt: 'Jullie verlangen toch niet een slaaf? Jullie moeten de ruggen onder elkaar afkrabben.'",
        religious: false,
      }, {
        foreign: "Quōdam diē autem magister longius solitō ambulat et puerōs per agrōs in castra Rōmānōrum dūcit.",
        dutch: "Maar op een dag wandelt de meester langer dan gewoonlijk en leidt hij de jongens door de velden naar het kamp van de Romeinen.",
        religious: false,
      },
    ], [
      "Het voegwoord autem staat in het Latijn nooit op de eerste plaats in de zin.",
    ]),
    {
      type: "questionParticle",
      latin: "-ne?",
      dutch: "(wordt niet vertaald)",
      _2023_pegasus_novus_1: {
        examples: [
          {
            foreign: "Avusne quoque in thermīs est?",
            dutch: "Is grootvader ook in de thermen?",
            religious: false,
          },
        ],
        notes: [
          "Het vraagpartikel -ne wordt aan het eerste woord van een ja-nee-vraag gehecht.",
        ],
      },
      // toForeign: false,
    },
    _search(searchLatinVerb, "abesse", [
      {
        "foreign": "In scaenā Philolachēs laetus est quod pater abest.",
        "dutch": "Op de scène is Philolaches vrolijk omdat zijn vader afwezig is.",
        "religious": false
      },
      {
        "foreign": "Dum pater abest, Philolachēs, fīlius eius, pecūniam paternam absūmit.",
        "dutch": "Terwijl de vader afwezig is, verkwist Philolachēs, zijn zoon, het geld van de (zijn) vader.",
        "religious": false
      }
    ], []),
    _search(searchLatinVerb, "adesse", [
      {
        "foreign": "Mātrēs dē līberīs narrant, iuvenēs senēsque gaudent quod prīnceps ipse adest.",
        "dutch": "De moeders vertellen over (de/hun) kinderen, de jongemannen en de oude mannen zijn blij omdat de keizer zelf aanwezig is.",
        "religious": false
      }
    ], []),
    _search(searchLatinVerb, "superesse", [], []),
    _search(searchLatinVerb, "posse", [
      {
        foreign: "Nunc in altō aedificiō XIV mīlia spectātōrum fābulās spectāre possunt.",
        dutch: "Veertienduizend toeschouwers kunnen nu in het hoge gebouw toneelstukken bekijken.",
        religious: false,
      }, {
        foreign: "Servī quoque laetī sunt, quod ibi bonōs amīcōs vidēre possunt.",
        dutch: "De slaven zijn ook vrolijk, omdat zij daar goede vrienden kunnen zien.",
        religious: false,
      }, {
        foreign: "Prīnceps misericordiā commōtus: 'Cūrās dēpōnere potes,' inquit, 'crās tū quoque servum habēbis, quī tergum tuum dēstringat.'",
        dutch: "De keizer, bewogen door medelijden, zegt: 'Je kunt je zorgen neerleggen, morgen zul jij ook een slaaf hebben, die jouw rug afkrabt.'",
        religious: false,
      },
    ], []),
    _search(searchLatinAdjective, "calid", [], []),
    _search(searchLatinAdjective, "cert", [], []),
    _search(searchLatinAdjective, "dign", [
      {
        "foreign": "Dignum et iūstum est.",
        "dutch": "Dat is passend en rechtvaardig.",
        "religious": true
      }
    ], []),
    _search(searchLatinAdjective, "fess", [], []),
    _search(searchLatinAdjective, "frīgid", [], []),
    _search(searchLatinAdjective, "Graec", [], []),
    _search(searchLatinAdjective, "grāt", [], []),
    _search(searchLatinAdjective, "iūcund", [], []),
    _search(searchLatinAdjective, "iūst", [
      {
        "foreign": "Dignum et iūstum est.",
        "dutch": "Dat is passend en rechtvaardig.",
        "religious": true
      }
    ], []),
    _search(searchLatinAdjective, "sān", [], []),
    _search(searchLatinVerb, "dolēre", [], []),
    _search(searchLatinNoun, "annus", [
      {
        "foreign": "Subitō autem Mārcellus, XX annōs nātus, post gravem morbum mortuus est.",
        "dutch": "Maar Marcellus is na zware ziekte plotseling gestorven, 20 jaar oud.",
        "religious": false
      }
    ], []),
    _search(searchLatinNoun, "hortus", [], []),
    _search(searchLatinNoun, "taurus", [], []),
    _search(searchLatinNoun, "īnsula", [], []),
    _search(searchLatinNoun, "rēgia", [], []),
    _search(searchLatinNoun, "caelum", [], []),
    _search(searchLatinNoun, "mōnstrum", [], []),
    _search(searchLatinNoun, "perīculum", [
      {
        "foreign": "Miserī puerī maximē timent, in magnīs enim perīculīs sunt.",
        "dutch": "De ongelukkige jongens zijn zeer bang, want ze zijn in grote gevaren.",
        "religious": false
      }
    ], []),
    _search(searchLatinNoun, "homō", [], []),
    _search(searchLatinNoun, "rēx", [], []),
    _search(searchLatinNoun, "urbs", [
      {
        foreign: "Praecō assiduē clāmat: 'Multī āctōrēs in urbe sunt! Āctōrēs iam in theātrō sunt! Albius Claudius Pulcher fābulam optimam dat!'",
        dutch: "De omroeper roept onafgebroken: 'Er zijn veel acteurs in de stad! De acteurs zijn al in het theater! Albius Claudius Pulcher laat het beste toneelstuk opvoeren!'",
        religious: false,
      }, {
        foreign: "Cottīdiē magister et puerī ex urbe exeunt et in silvā ambulant.",
        dutch: "Dagelijks gaan de meester en de jongens uit de stad weg en wandelen in het bos.",
        religious: false,
      }, {
        foreign: "Imperātōrī dīcit: 'Hōs puerōs tibi trādō. Parentēs eōrum nōbiles et dīvitēs sunt. Accipe eōs obsidēs et urbs in potestāte vestrā erit.'",
        dutch: "Hij zegt aan de opperbevelhebber: 'Ik lever deze jongens aan jou uit. Hun ouders zijn adellijk en rijk. Ontvang hen als gijzelaars en de stad zal in jullie macht zijn.'",
        religious: false,
      },
    ], []),
    _search(searchLatinVerb, "exspectāre", [], []),
    _search(searchLatinVerb, "habitāre", [], []),
    _search(searchLatinVerb, "parāre", [], []),
    _search(searchLatinVerb, "postulāre", [], []),
    _search(searchLatinVerb, "servāre", [], []),
    _search(searchLatinVerb, "vocāre", [], []),
    _search(searchLatinVerb, "manēre", [], []),
    _search(searchLatinVerb, "possidēre", [], []),
    _search(searchLatinVerb, "rīdēre", [], []),
    _search(searchLatinVerb, "reperīre", [], []),
    _search(searchLatinVerb, "scīre", [], []),
    _search(searchLatinVerb, "nescīre", [], []),
    _search(searchLatinIndefinitePronoun, "nēmō", [], []),
    _search(searchLatinIndefinitePronoun, "nihil", [], []),
    _search(searchLatinAdverb, "maximē", [
      {
        foreign: "Maximē maeret Augustus, nam prīnceps Mārcellum iam successōrem habēbat.",
        dutch: "Augustus treurt erg, want de keizer had Marcellus al als opvolger.",
        religious: false,
      },
    ], []),
    _search(searchLatinConjunction, "sī", [], []),
    _search(searchLatinInterjection, "ecce!", [], []),
    _search(searchLatinNoun, "capillus", [], []),
    _search(searchLatinNoun, "porta", [], []),
    _search(searchLatinNoun, "prōvincia", [], []),
    _search(searchLatinNoun, "terra", [], []),
    _search(searchLatinNoun, "fōns", [], []),
    _search(searchLatinNoun, "pēs", [], []),
    _search(searchLatinVerb, "vigilāre", [], []),
    _search(searchLatinVerb, "terrēre", [], []),
    _search(searchLatinVerb, "timēre", [
      {
        "foreign": "Miserī puerī maximē timent, in magnīs enim perīculīs sunt.",
        "dutch": "De ongelukkige jongens zijn zeer bang, want ze zijn in grote gevaren.",
        "religious": false
      }
    ], []),
    _search(searchLatinAdverb, "prīmum of prīmō", [], []),
    _search(searchLatinAdverb, "deinde", [], []),
    _search(searchLatinAdverb, "dēnique", [], []),
    _search(searchLatinAdverb, "numquam", [], []),
    _search(searchLatinAdverb, "tandem", [], []),
    _search(searchLatinConjunction, "at", [], []),
    _search(searchLatinConjunction, "cum", [], []),
    _search(searchLatinAdjective, "sōl", [], []),
    _search(searchLatinAdjective, "superb", [], []),
    _search(searchLatinPersonalPronoun, "ego", [], []),
    _search(searchLatinPersonalPronoun, "tū", [], []),
    _search(searchLatinPersonalPronoun, "is, ea, id", [], []),
    _search(searchLatinPersonalPronoun, "nōs", [], []),
    _search(searchLatinPersonalPronoun, "vōs", [], []),
    _search(searchLatinPersonalPronoun, "iī, eae, ea", [], []),
    {
      type: "adverb",
      latin: "magis ",
      dutch: "meer",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    {
      type: "adverb",
      latin: "tantum",
      dutch: "zoveel; alleen maar",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    {
      type: "questionParticle",
      latin: "nōnne?",
      dutch: "toch wel?",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    {
      type: "questionParticle",
      latin: "num?",
      dutch: "toch niet?",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    _search(searchLatinNoun, "nūntius", [], []),
    _search(searchLatinNoun, "sapientia", [], []),
    _search(searchLatinNoun, "unda", [], []),
    _search(searchLatinNoun, "bellum", [
      {
        "foreign": "Etiam inter bellum magister līberōs docēre et cum eīs in silvā ambulāre nōn dēsinit.",
        "dutch": "Ook tijdens de oorlog houdt de meester niet op de kinderen te onderwijzen en met hen in het bos te wandelen.",
        "religious": false
      }
    ], []),
    _search(searchLatinNoun, "amor", [], []),
    _search(searchLatinNoun, "auctor", [], []),
    _search(searchLatinNoun, "frāter", [], []),
    _search(searchLatinNoun, "ignis", [], []),
    _search(searchLatinNoun, "Iuppiter", [], []),
    _search(searchLatinNoun, "mōns", [], []),
    _search(searchLatinNoun, "lūx", [], []),
    _search(searchLatinNoun, "soror", [], []),
    _search(searchLatinNoun, "Venus", [], []),
    _search(searchLatinNoun, "nōmen", [
      {
        foreign: "Augustus, prīmus prīnceps Rōmānus, ūnam fīliam Iūliam nōmine habet.",
        dutch: "Augustus, de eerste Romeinse keizer, had één dochter met de naam Julia.",
        religious: false,
      },
    ], []),
    _search(searchLatinNoun, "scelus", [], []),
    _search(searchLatinVerb, "adiuvāre", [], []),
    _search(searchLatinVerb, "cūrāre", [], []),
    _search(searchLatinVerb, "mōnstrāre", [], []),
    _search(searchLatinAdjective, "ali", [], []),
    {
      // TODO: deze is ook fout!
      type: "toFixIrregularWord",
      latin: "cēterī, cēterae, cētera",
      dutch: "de overige",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    {
      type: "conjunction",
      latin: "sīcut",
      dutch: "zoals",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    _search(searchLatinNoun, "umbra", [], []),
    _search(searchLatinAdjective, "clār", [], []),
    _search(searchLatinVerb, "aedificāre", [
      {
        foreign: "Prope flūmen Tiberim Augustus magnificum theātrum aedificārī iubet.",
        dutch: "Augustus laat een prachtig theater bouwen dichtbij de Tiber rivier.",
        religious: false,
      },
    ], []),
    {
      type: "possessivePronoun",
      latin: "eius",
      dutch: "zijn, haar",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: ["Omdat het Latijn geen persoonlijk voornaamwoord heeft voor de derde persoon, wordt een trucje gebruikt. De genitivus van is, ea, id wordt gebruikt om bezit aan te geven.",],
      },
    },
    {
      type: "possessivePronoun",
      latin: "eōrum, eārum",
      dutch: "hun",
      _2023_pegasus_novus_1: {
        examples: [
          {
            foreign: "Imperātōrī dīcit: 'Hōs puerōs tibi trādō. Parentēs eōrum nōbiles et dīvitēs sunt. Accipe eōs obsidēs et urbs in potestāte vestrā erit.'",
            dutch: "Hij zegt aan de opperbevelhebber: 'Ik lever deze jongens aan jou uit. Hun ouders zijn adellijk en rijk. Ontvang hen als gijzelaars en de stad zal in jullie macht zijn.'",
            religious: false,
          },
        ],
        notes: ["Omdat het Latijn geen persoonlijk voornaamwoord heeft voor de derde persoon, wordt een trucje gebruikt. De genitivus van is, ea, id wordt gebruikt om bezit aan te geven.",],
      },
    },
    _search(searchLatinPossessivePronoun, "meus, ~a, ~um", [], []),
    _search(searchLatinPossessivePronoun, "tuus, ~a, ~um", [
      {
        foreign: "Prīnceps misericordiā commōtus: 'Cūrās dēpōnere potes,' inquit, 'crās tū quoque servum habēbis, quī tergum tuum dēstringat.'",
        dutch: "De keizer, bewogen door medelijden, zegt: 'Je kunt je zorgen neerleggen, morgen zul jij ook een slaaf hebben, die jouw rug afkrabt.'",
        religious: false,
      },
    ], []),
    _search(searchLatinPossessivePronoun, "suus, ~a, ~um", [
      {
        foreign: "Fidēlis veterānus: 'Virī dīvitēs servōs habent,' inquit, 'quī terga sua strigilī dēstringant. Ego tergum mūrō affricō, quod servum nōn habeō. Pauper enim sum.'",
        dutch: "De trouwe veteraan zegt: 'Rijke mannen hebben slaven die hun rug met een huidkrabber afkrabben. Ik wrijf mijn rug tegen de muur, omdat ik geen slaaf heb. Want ik ben arm.'",
        religious: false,
      }, {
        foreign: "Nōbilēs cīvēs Faleriōrum līberōs suōs magistrō mandāre solent.",
        dutch: "De voorname burgers van Falerii zijn gewoon hun kinderen aan de meester toe te vertrouwen.",
        religious: false,
      },
    ], [
      "Als de bezitter onderwerp is van de zin, en benadrukt moet worden, kan het bezittelijk voornaamwoord suus gebruikt worden. Het bezittelijk voornaamwoord suus wordt gebruikt bij alle geslachten en getallen.",
    ]),
    _search(searchLatinNoun, "gladius", [], []),
    _search(searchLatinNoun, "hasta", [], []),
    _search(searchLatinNoun, "sagitta", [], []),
    _search(searchLatinAdjective, "parv", [], []),
    {
      type: "adverb",
      latin: "ita",
      dutch: "zo",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    _search(searchLatinAdverb, "tamen", [], []),
    _search(searchLatinConjunction, "itaque", [], [
      "Zowel een voegwoord als een bijwoord."
    ]),
    _search(searchLatinNoun, "cīvis", [
      {
        foreign: "Nōbilēs cīvēs Faleriōrum līberōs suōs magistrō mandāre solent.",
        dutch: "De voorname burgers van Falerii zijn gewoon hun kinderen aan de meester toe te vertrouwen.",
        religious: false,
      },
    ], []),
    _search(searchLatinNoun, "comes", [], []),
    _search(searchLatinNoun, "lapis", [
      {
        "foreign": "Rōmānī multōs lapidēs ē theātrō Mārcellī auferunt, quod pontem antīquum renovāre volunt.",
        "dutch": "De Romeinen nemen veel stenen weg uit theater van Marcellus, omdat ze de oude brug willen hernieuwen.",
        "religious": false
      }
    ], []),
    _search(searchLatinNoun, "mors", [], []),
    _search(searchLatinAdjective, "prīm", [
      {
        foreign: "Augustus, prīmus prīnceps Rōmānus, ūnam fīliam Iūliam nōmine habet.",
        dutch: "Augustus, de eerste Romeinse keizer, had één dochter met de naam Julia.",
        religious: false,
      },
    ], []),
    _search(searchLatinVerb, "capere", [], []),
    _search(searchLatinVerb, "accipere", [
      {
        "foreign": "Imperātōrī dīcit: 'Hōs puerōs tibi trādō. Parentēs eōrum nōbiles et dīvitēs sunt. Accipe eōs obsidēs et urbs in potestāte vestrā erit.'",
        "dutch": "Hij zegt aan de opperbevelhebber: 'Ik lever deze jongens aan jou uit. Hun ouders zijn adellijk en rijk. Ontvang hen als gijzelaars en de stad zal in jullie macht zijn.'",
        "religious": false
      }
    ], []),
    _search(searchLatinVerb, "incipere", [], []),
    _search(searchLatinVerb, "cupere", [
      {
        "foreign": "Omnēs novam fābulam spectāre cupiunt.",
        "dutch": "Allen verlangen het nieuwe toneelstuk te bekijken.",
        "religious": false
      },
      {
        "foreign": "Hadriānus autem rogat: 'Num et vōs servum cupitis? Inter vōs terga dēstringere dēbētis.'",
        "dutch": "Maar Hadrianus vraagt: 'Jullie verlangen toch niet een slaaf? Jullie moeten de ruggen onder elkaar afkrabben.'",
        "religious": false
      }
    ], []),
    _search(searchLatinVerb, "facere", [], []),
    _search(searchLatinVerb, "interficere", [], []),
    _search(searchLatinVerb, "fugere", [], []),
    _search(searchLatinVerb, "iacere", [], []),
    _search(searchLatinVerb, "abicere", [], []),
    _search(searchLatinVerb, "rapere", [], []),
    _search(searchLatinVerb, "corripere", [], []),
    _search(searchLatinVerb, "ēripere", [], []),
    _search(searchLatinVerb, "-spicere", [], []),
    _search(searchLatinVerb, "adspicere", [], []),
    _search(searchLatinVerb, "cōnspicere", [], []),
    _search(searchLatinVerb, "respicere", [], []),
    {
      type: "adverb",
      latin: "satis",
      dutch: "genoeg; nogal",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    _search(searchLatinAdverb, "statim", [], []),
    _search(searchLatinNoun, "arma", [], []),
    _search(searchLatinNoun, "castra", [], []),
    _search(searchLatinNoun, "parentēs", [
      {
        "foreign": "Imperātōrī dīcit: 'Hōs puerōs tibi trādō. Parentēs eōrum nōbiles et dīvitēs sunt. Accipe eōs obsidēs et urbs in potestāte vestrā erit.'",
        "dutch": "Hij zegt aan de opperbevelhebber: 'Ik lever deze jongens aan jou uit. Hun ouders zijn adellijk en rijk. Ontvang hen als gijzelaars en de stad zal in jullie macht zijn.'",
        "religious": false
      }
    ], []),
    _search(searchLatinVerb, "fīerī", [], []),
    _search(searchLatinVerb, "īre", [], []),
    _search(searchLatinVerb, "abīre", [], []),
    _search(searchLatinVerb, "adīre", [], []),
    _search(searchLatinVerb, "exīre", [
      {
        "foreign": "Cottīdiē magister et puerī ex urbe exeunt et in silvā ambulant.",
        "dutch": "Dagelijks gaan de meester en de jongens uit de stad weg en wandelen in het bos.",
        "religious": false
      }
    ], []),
    _search(searchLatinVerb, "inīre", [], []),
    _search(searchLatinVerb, "praeterīre", [], []),
    _search(searchLatinVerb, "redīre", [], []),
    _search(searchLatinVerb, "trānsīre", [], []),
    _search(searchLatinNoun, "silva", [
      {
        foreign: "Cottīdiē magister et puerī ex urbe exeunt et in silvā ambulant.",
        dutch: "Dagelijks gaan de meester en de jongens uit de stad weg en wandelen in het bos.",
        religious: false,
      }, {
        foreign: "Etiam inter bellum magister līberōs docēre et cum eīs in silvā ambulāre nōn dēsinit.",
        dutch: "Ook tijdens de oorlog houdt de meester niet op de kinderen te onderwijzen en met hen in het bos te wandelen.",
        religious: false,
      },
    ], []),
    _search(searchLatinNoun, "vīta", [], []),
    _search(searchLatinNoun, "leō", [], []),
    _search(searchLatinNoun, "pars", [], []),
    _search(searchLatinVerb, "necāre", [], []),
    _search(searchLatinVerb, "pugnāre", [], []),
    _search(searchLatinVerb, "tacēre", [], []),
    _search(searchLatinVerb, "tenēre", [], []),
    {
      type: "adverb",
      latin: "quārē?",
      dutch: "waarom?",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    {
      type: "adverb",
      latin: "ante(ā)",
      dutch: "vroeger",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    {
      type: "adverb",
      latin: "cottīdiē",
      dutch: "dagelijks",
      _2023_pegasus_novus_1: {
        examples: [
          {
            foreign: "Cottīdiē magister et puerī ex urbe exeunt et in silvā ambulant.",
            dutch: "Dagelijks gaan de meester en de jongens uit de stad weg en wandelen in het bos.",
            religious: false,
          },
        ],
        notes: [],
      },
    },
    {
      type: "adverb",
      latin: "crās",
      dutch: "morgen",
      _2023_pegasus_novus_1: {
        examples: [
          {
            foreign: "Prīnceps misericordiā commōtus: 'Cūrās dēpōnere potes,' inquit, 'crās tū quoque servum habēbis, quī tergum tuum dēstringat.'",
            dutch: "De keizer, bewogen door medelijden, zegt: 'Je kunt je zorgen neerleggen, morgen zul jij ook een slaaf hebben, die jouw rug afkrabt.'",
            religious: false,
          },
        ],
        notes: [],
      },
    },
    {
      type: "adverb",
      latin: "herī",
      dutch: "gisteren",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    {
      type: "adverb",
      latin: "hodiē",
      dutch: "vandaag",
      _2023_pegasus_novus_1: {
        examples: [
          {
            foreign: "Hodiē tōta familia in theātrō est.",
            dutch: "Vandaag is de gehele familie in het theater.",
            religious: false,
          },
        ],
        notes: [],
      },
    },
    {
      type: "adverb",
      latin: "māne",
      dutch: "'s morgens",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    {
      type: "adverb",
      latin: "post(eā)",
      dutch: "later",
      _2023_pegasus_novus_1: {
        examples: [
          {
            foreign: "Subitō autem Mārcellus, XX annōs nātus, post gravem morbum mortuus est.",
            dutch: "Maar Marcellus is na zware ziekte plotseling gestorven, 20 jaar oud.",
            religious: false,
          }, {
            foreign: "Paucīs diēbus post prīnceps iterum thermās intrat et multōs senēs videt, quī terga mūrō affricant.",
            dutch: "Enkele dagen later gaat de keizer opnieuw de thermen binnen en ziet hij veel oude mannen, die de rug tegen de muur wrijven.",
            religious: false,
          },
        ],
        notes: [],
      },
    },
    {
      type: "adverb",
      latin: "posterō diē",
      dutch: "de volgende dag",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    {
      type: "adverb",
      latin: "quōdam diē",
      dutch: "op een dag",
      _2023_pegasus_novus_1: {
        examples: [
          {
            foreign: "Quōdam diē prīnceps rōbustum veterānum videt.",
            dutch: "Op een dag ziet de keizer een gespierde veteraan.",
            religious: false,
          }, {
            foreign: "Quōdam diē autem magister longius solitō ambulat et puerōs per agrōs in castra Rōmānōrum dūcit.",
            dutch: "Maar op een dag wandelt de meester langer dan gewoonlijk en leidt hij de jongens door de velden naar het kamp van de Romeinen.",
            religious: false,
          },
        ],
        notes: [],
      },
    },
    _search(searchLatinNoun, "lēgātus", [], []),
    _search(searchLatinNoun, "lūdus", [
      {
        "foreign": "In lūdō magister multās rēs docet, interdum puerīs fābulam narrat.",
        "dutch": "Op school onderwijst de meester veel zaken, soms vertelt hij de jongens een verhaal.",
        "religious": false
      }
    ], []),
    _search(searchLatinNoun, "populus", [], []),
    _search(searchLatinNoun, "imperātor", [
      {
        "foreign": "Ibi imperātōrī Camillō mīlitibusque eōs ostendit.",
        "dutch": "Daar toont hij hen aan de opperbevelhebber Camillus en aan de soldaten.",
        "religious": false
      },
      {
        "foreign": "Imperātōrī dīcit: 'Hōs puerōs tibi trādō. Parentēs eōrum nōbiles et dīvitēs sunt. Accipe eōs obsidēs et urbs in potestāte vestrā erit.'",
        "dutch": "Hij zegt aan de opperbevelhebber: 'Ik lever deze jongens aan jou uit. Hun ouders zijn adellijk en rijk. Ontvang hen als gijzelaars en de stad zal in jullie macht zijn.'",
        "religious": false
      }
    ], []),
    _search(searchLatinNoun, "sōl", [], []),
    _search(searchLatinNoun, "legiō", [], []),
    _search(searchLatinNoun, "pāx", [], []),
    _search(searchLatinNoun, "iūs", [], []),
    _search(searchLatinNoun, "mūnus", [], []),
    _search(searchLatinAdjective, "nūd", [], []),
    _search(searchLatinVerb, "excitāre", [], []),
    _search(searchLatinVerb, "imperāre", [], []),
    _search(searchLatinVerb, "mandāre", [
      {
        "foreign": "Nōbilēs cīvēs Faleriōrum līberōs suōs magistrō mandāre solent.",
        "dutch": "De voorname burgers van Falerii zijn gewoon hun kinderen aan de meester toe te vertrouwen.",
        "religious": false
      }
    ], []),
    _search(searchLatinVerb, "portāre", [], []),
    _search(searchLatinVerb, "solēre", [
      {
        "foreign": "Nōbilēs cīvēs Faleriōrum līberōs suōs magistrō mandāre solent.",
        "dutch": "De voorname burgers van Falerii zijn gewoon hun kinderen aan de meester toe te vertrouwen.",
        "religious": false
      }
    ], []),
    _search(searchLatinVerb, "vincīre", [], []),
    {
      type: "adverb",
      latin: "ferē",
      dutch: "bijna; meestal",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    _search(searchLatinConjunction, "ubi", [], []),
    _search(searchLatinNoun, "puer", [
      {
        foreign: "In lūdō magister multās rēs docet, interdum puerīs fābulam narrat.",
        dutch: "Op school onderwijst de meester veel zaken, soms vertelt hij de jongens een verhaal.",
        religious: false,
      }, {
        foreign: "Cottīdiē magister et puerī ex urbe exeunt et in silvā ambulant.",
        dutch: "Dagelijks gaan de meester en de jongens uit de stad weg en wandelen in het bos.",
        religious: false,
      }, {
        foreign: "Quōdam diē autem magister longius solitō ambulat et puerōs per agrōs in castra Rōmānōrum dūcit.",
        dutch: "Maar op een dag wandelt de meester langer dan gewoonlijk en leidt hij de jongens door de velden naar het kamp van de Romeinen.",
        religious: false,
      }, {
        foreign: "Imperātōrī dīcit: 'Hōs puerōs tibi trādō. Parentēs eōrum nōbiles et dīvitēs sunt. Accipe eōs obsidēs et urbs in potestāte vestrā erit.'",
        dutch: "Hij zegt aan de opperbevelhebber: 'Ik lever deze jongens aan jou uit. Hun ouders zijn adellijk en rijk. Ontvang hen als gijzelaars en de stad zal in jullie macht zijn.'",
        religious: false,
      },
    ], []),
    _search(searchLatinNoun, "līberī", [
      {
        "foreign": "Mātrēs dē līberīs narrant, iuvenēs senēsque gaudent quod prīnceps ipse adest.",
        "dutch": "De moeders vertellen over (de/hun) kinderen, de jongemannen en de oude mannen zijn blij omdat de keizer zelf aanwezig is.",
        "religious": false
      },
      {
        "foreign": "Nōbilēs cīvēs Faleriōrum līberōs suōs magistrō mandāre solent.",
        "dutch": "De voorname burgers van Falerii zijn gewoon hun kinderen aan de meester toe te vertrouwen.",
        "religious": false
      },
      {
        "foreign": "Etiam inter bellum magister līberōs docēre et cum eīs in silvā ambulāre nōn dēsinit.",
        "dutch": "Ook tijdens de oorlog houdt de meester niet op de kinderen te onderwijzen en met hen in het bos te wandelen.",
        "religious": false
      }
    ], []),
    _search(searchLatinNoun, "vir", [
      {
        "foreign": "Ibi mulierēs appellat et virōs interrogat.",
        "dutch": "Daar spreekt hij de vrouwen aan en ondervraagt hij de mannen.",
        "religious": false
      },
      {
        "foreign": "Fidēlis veterānus: 'Virī dīvitēs servōs habent,' inquit, 'quī terga sua strigilī dēstringant. Ego tergum mūrō affricō, quod servum nōn habeō. Pauper enim sum.'",
        "dutch": "De trouwe veteraan zegt: 'Rijke mannen hebben slaven die hun rug met een huidkrabber afkrabben. Ik wrijf mijn rug tegen de muur, omdat ik geen slaaf heb. Want ik ben arm.'",
        "religious": false
      }
    ], []),
    _search(searchLatinNoun, "ager", [
      {
        "foreign": "Quōdam diē autem magister longius solitō ambulat et puerōs per agrōs in castra Rōmānōrum dūcit.",
        "dutch": "Maar op een dag wandelt de meester langer dan gewoonlijk en leidt hij de jongens door de velden naar het kamp van de Romeinen.",
        "religious": false
      }
    ], []),
    _search(searchLatinNoun, "liber", [], []),
    _search(searchLatinNoun, "magister", [
      {
        "foreign": "Nōbilēs cīvēs Faleriōrum līberōs suōs magistrō mandāre solent.",
        "dutch": "De voorname burgers van Falerii zijn gewoon hun kinderen aan de meester toe te vertrouwen.",
        "religious": false
      },
      {
        "foreign": "In lūdō magister multās rēs docet, interdum puerīs fābulam narrat.",
        "dutch": "Op school onderwijst de meester veel zaken, soms vertelt hij de jongens een verhaal.",
        "religious": false
      },
      {
        "foreign": "Cottīdiē magister et puerī ex urbe exeunt et in silvā ambulant.",
        "dutch": "Dagelijks gaan de meester en de jongens uit de stad weg en wandelen in het bos.",
        "religious": false
      },
      {
        "foreign": "Etiam inter bellum magister līberōs docēre et cum eīs in silvā ambulāre nōn dēsinit.",
        "dutch": "Ook tijdens de oorlog houdt de meester niet op de kinderen te onderwijzen en met hen in het bos te wandelen.",
        "religious": false
      },
      {
        "foreign": "Quōdam diē autem magister longius solitō ambulat et puerōs per agrōs in castra Rōmānōrum dūcit.",
        "dutch": "Maar op een dag wandelt de meester langer dan gewoonlijk en leidt hij de jongens door de velden naar het kamp van de Romeinen.",
        "religious": false
      }
    ], []),
    _search(searchLatinAdjective, "līber", [], []),
    _search(searchLatinAdjective, "miser", [
      {
        "foreign": "Miserī puerī maximē timent, in magnīs enim perīculīs sunt.",
        "dutch": "De ongelukkige jongens zijn zeer bang, want ze zijn in grote gevaren.",
        "religious": false
      }
    ], []),
    _search(searchLatinAdjective, "aegr", [], []),
    _search(searchLatinAdjective, "nigr", [], []),
    _search(searchLatinAdjective, "pulchr", [], []),
    _search(searchLatinAdjective, "sacr", [], []),
    _search(searchLatinPossessivePronoun, "noster, nostra, nostrum", [], []),
    _search(searchLatinPossessivePronoun, "vester, vestra, vestrum", [], []),
    _search(searchLatinNoun, "cibus", [], []),
    _search(searchLatinNoun, "glōria", [], []),
    _search(searchLatinNoun, "pugna", [], []),
    _search(searchLatinNoun, "victōria", [], []),
    _search(searchLatinNoun, "beneficium", [], []),
    _search(searchLatinNoun, "praemium", [], []),
    _search(searchLatinAdjective, "maxim", [
      {
        "foreign": "Miserī puerī maximē timent, in magnīs enim perīculīs sunt.",
        "dutch": "De ongelukkige jongens zijn zeer bang, want ze zijn in grote gevaren.",
        "religious": false
      }
    ], []),
    _search(searchLatinVerb, "laudāre", [], []),
    _search(searchLatinVerb, "nūntiāre", [], []),
    _search(searchLatinVerb, "optāre", [], []),
    _search(searchLatinVerb, "pārēre", [], []),
    _search(searchLatinVerb, "praebēre", [], []),
    {
      type: "interrogativePronoun",
      latin: "quis?",
      dutch: "wie?",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    {
      type: "interrogativePronoun",
      latin: "quid?",
      dutch: "wat?",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    _search(searchLatinNoun, "puella", [], []),
    _search(searchLatinAdjective, "nōt", [], []),
    _search(searchLatinAdjective, "vēr", [], []),
    _search(searchLatinVerb, "temptāre", [], []),
    _search(searchLatinVerb, "ārdēre", [], []),
    _search(searchLatinVerb, "iacēre", [], []),
    _search(searchLatinVerb, "placēre", [], [
      "Een heerlijke maaltijd zou me nu wel bevallen!"
    ]),
    {
      type: "adverb",
      latin: "citō",
      dutch: "snel",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    {
      type: "adverb",
      latin: "quō?",
      dutch: "waarheen?",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    {
      type: "conjunction",
      latin: "ergō",
      dutch: "dus",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    _search(searchLatinNoun, "oculus", [], []),
    _search(searchLatinNoun, "auxilium", [], []),
    _search(searchLatinNoun, "bracchium", [], []),
    _search(searchLatinNoun, "verbum", [], []),
    _search(searchLatinNoun, "auris", [], []),
    _search(searchLatinAdjective, "dūr", [], []),
    _search(searchLatinAdjective, "maest", [], []),
    _search(searchLatinAdjective, "vīv", [], []),
    _search(searchLatinVerb, "ōrāre", [], []),
    _search(searchLatinVerb, "maerēre", [], []),
    _search(searchLatinNoun, "locus", [], []),
    _search(searchLatinNoun, "rāmus", [], []),
    _search(searchLatinNoun, "causa", [], []),
    _search(searchLatinNoun, "cēna", [], []),
    _search(searchLatinNoun, "hōra", [], []),
    _search(searchLatinNoun, "vīlla", [], []),
    _search(searchLatinNoun, "cōnsilium", [], []),
    _search(searchLatinNoun, "pretium", [], []),
    _search(searchLatinNoun, "flōs", [], []),
    _search(searchLatinNoun, "arbor", [], []),
    _search(searchLatinAdjective, "plēn", [], []),
    _search(searchLatinVerb, "invītāre", [], []),
    _search(searchLatinVerb, "haerēre", [], []),
    _search(searchLatinNoun, "mēnsa", [], []),
    _search(searchLatinNoun, "nāvis", [], []),
    _search(searchLatinAdjective, "cupid", [], []),
    _search(searchLatinAdjective, "mīr", [], []),
    _search(searchLatinAdjective, "nūll", [], []),
    {
      type: "pluralAdjective",
      latin: "paucī",
      root: "pauc",
      dutch: "weinig; enkele",
      klass: 1,
      _2023_pegasus_novus_1: {
        examples: [
          {
            foreign: "Paucīs diēbus post prīnceps iterum thermās intrat et multōs senēs videt, quī terga mūrō affricant.",
            dutch: "Enkele dagen later gaat de keizer opnieuw de thermen binnen en ziet hij veel oude mannen, die de rug tegen de muur wrijven.",
            religious: false,
          },
        ],
        notes: [],
      },
    },
    _search(searchLatinVerb, "agere", [], []),
    _search(searchLatinVerb, "bibere", [], []),
    _search(searchLatinVerb, "cognōscere", [], []),
    _search(searchLatinVerb, "dēsinere", [
      {
        foreign: "Etiam inter bellum magister līberōs docēre et cum eīs in silvā ambulāre nōn dēsinit.",
        dutch: "Ook tijdens de oorlog houdt de meester niet op de kinderen te onderwijzen en met hen in het bos te wandelen.",
        religious: false,
      },
    ], []),
    _search(searchLatinVerb, "dīcere", [
      {
        foreign: "Imperātōrī dīcit: 'Hōs puerōs tibi trādō. Parentēs eōrum nōbiles et dīvitēs sunt. Accipe eōs obsidēs et urbs in potestāte vestrā erit.'",
        dutch: "Hij zegt aan de opperbevelhebber: 'Ik lever deze jongens aan jou uit. Hun ouders zijn adellijk en rijk. Ontvang hen als gijzelaars en de stad zal in jullie macht zijn.'",
        religious: false,
      },
    ], []),
    _search(searchLatinVerb, "emere", [], []),
    _search(searchLatinVerb, "intellegere", [], []),
    _search(searchLatinVerb, "legere", [], []),
    _search(searchLatinVerb, "ostendere", [
      {
        "foreign": "Ibi imperātōrī Camillō mīlitibusque eōs ostendit.",
        "dutch": "Daar toont hij hen aan de opperbevelhebber Camillus en aan de soldaten.",
        "religious": false
      }
    ], []),
    _search(searchLatinVerb, "quaerere", [], []),
    _search(searchLatinVerb, "sūmere", [], []),
    _search(searchLatinVerb, "vendere", [], []),
    _search(searchLatinVerb, "vīvere", [], []),
    _search(searchLatinNoun, "hospes", [], []),
    _search(searchLatinNoun, "pōns", [], []),
    _search(searchLatinNoun, "aestās", [], []),
    _search(searchLatinVerb, "audēre", [], []),
    _search(searchLatinVerb, "docēre", [
      {
        "foreign": "In lūdō magister multās rēs docet, interdum puerīs fābulam narrat.",
        "dutch": "Op school onderwijst de meester veel zaken, soms vertelt hij de jongens een verhaal.",
        "religious": false
      },
      {
        "foreign": "Etiam inter bellum magister līberōs docēre et cum eīs in silvā ambulāre nōn dēsinit.",
        "dutch": "Ook tijdens de oorlog houdt de meester niet op de kinderen te onderwijzen en met hen in het bos te wandelen.",
        "religious": false
      }
    ], []),
    _search(searchLatinVerb, "tegere", [], []),
    _search(searchLatinVerb, "metuere", [], []),
    _search(searchLatinVerb, "cōnsīdere", [], []),
    _search(searchLatinVerb, "dēpōnere", [
      {
        "foreign": "Prīnceps misericordiā commōtus: 'Cūrās dēpōnere potes,' inquit, 'crās tū quoque servum habēbis, quī tergum tuum dēstringat.'",
        "dutch": "De keizer, bewogen door medelijden, zegt: 'Je kunt je zorgen neerleggen, morgen zul jij ook een slaaf hebben, die jouw rug afkrabt.'",
        "religious": false
      }
    ], []),
    _search(searchLatinVerb, "discere", [], []),
    _search(searchLatinVerb, "dūcere", [
      {
        foreign: "Quōdam diē autem magister longius solitō ambulat et puerōs per agrōs in castra Rōmānōrum dūcit.",
        dutch: "Maar op een dag wandelt de meester langer dan gewoonlijk en leidt hij de jongens door de velden naar het kamp van de Romeinen.",
        religious: false,
      },
    ], []),
    _search(searchLatinVerb, "lūdere", [
      {
        "foreign": "Sīc Hadriānus prīnceps cupiditātem eōrum lūdit.",
        "dutch": "Zo bespot keizer Hadrianus hun hebzucht.",
        "religious": false
      }
    ], []),
    _search(searchLatinVerb, "mittere", [], []),
    _search(searchLatinVerb, "pōnere", [], []),
    _search(searchLatinVerb, "relinquere", [], []),
    _search(searchLatinVerb, "vertere", [], []),
    _search(searchLatinVerb, "vincere", [], []),
    _search(searchLatinVerb, "vīsere", [], []),
    _search(searchLatinVerb, "recipere", [], []),
    _search(searchLatinAdverb, "hīc", [], []),
    _search(searchLatinNoun, "īra", [], []),
    _search(searchLatinNoun, "poena", [], []),
    _search(searchLatinNoun, "studium", [], []),
    _search(searchLatinNoun, "famēs", [], []),
    _search(searchLatinNoun, "fraus", [], []),
    _search(searchLatinVerb, "iubēre", [], []),
    _search(searchLatinVerb, "surgere", [], []),
    _search(searchLatinVerb, "sentīre", [], []),
    _search(searchLatinNoun, "audācia", [], []),
    _search(searchLatinNoun, "cōnstantia", [], []),
    _search(searchLatinNoun, "silentium", [], []),
    _search(searchLatinNoun, "hiems", [], []),
    _search(searchLatinNoun, "virtūs", [], []),
    _search(searchLatinAdjective, "angust", [], []),
    _search(searchLatinAdjective, "tūt", [], []),
    _search(searchLatinVerb, "cadere", [], []),
    _search(searchLatinVerb, "currere", [], []),
    _search(searchLatinVerb, "petere", [], []),
    _search(searchLatinVerb, "poscere", [], []),
    _search(searchLatinVerb, "errāre", [], []),
    _search(searchLatinVerb, "flēre", [], []),
    _search(searchLatinVerb, "canere", [], []),
    _search(searchLatinVerb, "fingere", [], []),
    _search(searchLatinVerb, "fluere", [], []),
    _search(searchLatinAdverb, "ubi?", [], []),
    _search(searchLatinPrepositionAccusative, "ad", [
      {
        foreign: "Domina amīcās ad trīclīnium dūcit.",
        dutch: "De meesteres leidt haar vriendinnen naar de eetkamer.",
        religious: false,
      }, {
        foreign: "Domina līberōs ad flūmen invenit.",
        dutch: "De meesteres vindt haar kinderen bij de rivier.",
        religious: false,
      },
    ], []),
    _search(searchLatinPrepositionAccusative, "ante", [], []),
    _search(searchLatinPrepositionAccusative, "apud", [], []),
    _search(searchLatinPrepositionAccusative, "inter", [], []),
    _search(searchLatinPrepositionAccusative, "per", [], []),
    _search(searchLatinPrepositionAccusative, "post", [], []),
    _search(searchLatinPrepositionAccusative, "praeter", [], []),
    _search(searchLatinPrepositionAblative, "cum", [
      {
        foreign: "Cum amīcō iter faciō.",
        dutch: "Ik maak een tocht met een vriend.",
        religious: false,
      }, {
        foreign: "Cum iter faciō, gaudeō.",
        dutch: "Wanneer ik een tocht maak, ben ik blij.",
        religious: false,
      },
    ], []),
    _search(searchLatinPrepositionAblative, "dē", [], []),
    _search(searchLatinPrepositionAblative, "ē of ex", [], []),
    _search(searchLatinPrepositionAblative, "sine", [], []),
    _search(searchLatinPrepositionAblative, "sub", [], []),
    _search(searchLatinPrepositionAccusative, "in", [], []),
    _search(searchLatinPrepositionAblative, "in", [], []),
    _search(searchLatinNoun, "saxum", [], []),
    _search(searchLatinNoun, "celeritās", [], []),
    _search(searchLatinAdjective, "hūmān", [], []),
    _search(searchLatinVerb, "līberāre", [], []),
    _search(searchLatinVerb, "volāre", [], []),
    _search(searchLatinVerb, "caedere", [], []),
    _search(searchLatinVerb, "dēscendere", [], []),
    _search(searchLatinVerb, "haurīre", [], []),
    _search(searchLatinVerb, "dēicere", [], []),
    _search(searchLatinPersonalPronoun, "sē", [], []),
    _search(searchLatinAdverb, "ūnā", [], []),
    _search(searchLatinNoun, "cōpia", [], []),
    _search(searchLatinNoun, "sermō", [], []),
    _search(searchLatinNoun, "vātēs", [], []),
    _search(searchLatinNoun, "voluntās", [], []),
    _search(searchLatinAdjective, "aure", [], []),
    _search(searchLatinAdjective, "medi", [], []),
    _search(searchLatinAdjective, "obscūr", [], []),
    _search(searchLatinAdjective, "brev", [], []),
    _search(searchLatinAdjective, "mortāl", [], []),
    _search(searchLatinAdjective, "nōbil", [], []),
    _search(searchLatinAdjective, "omn", [], []),
    _search(searchLatinPluralAdjective, "omni", [], []),
    _search(searchLatinAdjective, "trīst", [], []),
    _search(searchLatinAdjective, "audāc", [], []),
    _search(searchLatinAdjective, "dīvit", [], []),
    _search(searchLatinAdjective, "ferōc", [], []),
    _search(searchLatinAdjective, "ingent", [], []),
    _search(searchLatinAdjective, "sapient", [], []),
    _search(searchLatinAdjective, "celer", [], []),
    _search(searchLatinVerb, "incitāre", [], []),
    _search(searchLatinVerb, "complēre", [], []),
    {
      type: "adverb",
      latin: "adhūc",
      dutch: "tot nu toe; nog altijd",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    {
      type: "adverb",
      latin: "diū",
      dutch: "lange tijd",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    _search(searchLatinAdjective, "fort", [], []),
    _search(searchLatinNoun, "praeda", [], []),
    _search(searchLatinAdjective, "crūdēl", [], []),
    _search(searchLatinAdjective, "difficil", [], []),
    _search(searchLatinAdjective, "facil", [], []),
    _search(searchLatinAdjective, "grav", [], []),
    _search(searchLatinAdjective, "lev", [], []),
    _search(searchLatinAdjective, "simil", [], []),
    _search(searchLatinAdjective, "ūtil", [], []),
    _search(searchLatinAdjective, "atrōc", [], []),
    _search(searchLatinAdjective, "fēlīc", [], []),
    _search(searchLatinAdjective, "pauper", [], []),
    _search(searchLatinAdjective, "veter", [], []),
    _search(searchLatinAdjective, "ācr", [], []),
    _search(searchLatinNoun, "minister", [], []),
    _search(searchLatinNoun, "fortūna", [], []),
    _search(searchLatinNoun, "ars", [], []),
    _search(searchLatinNoun, "asinus", [], []),
    _search(searchLatinNoun, "aurum", [], []),
    _search(searchLatinNoun, "collis", [], []),
    _search(searchLatinNoun, "hostis", [], []),
    _search(searchLatinNoun, "lībertās", [], []),
    _search(searchLatinNoun, "vestis", [], []),
    _search(searchLatinNoun, "animal", [], []),
    _search(searchLatinVerb, "dubitāre", [], []),
    _search(searchLatinVerb, "impōnere", [], []),
    _search(searchLatinVerb, "ferre", [], []),
    _search(searchLatinVerb, "afferre", [], []),
    _search(searchLatinVerb, "auferre", [], []),
    _search(searchLatinVerb, "offerre", [], []),
    _search(searchLatinVerb, "referre", [], []),
    _search(searchLatinVerb, "trānsferre", [], []),
    _search(searchLatinVerb, "velle", [], []),
    _search(searchLatinVerb, "mālle", [], []),
    _search(searchLatinVerb, "nōlle", [], []),
    {
      type: "adverb",
      latin: "mox",
      dutch: "weldra",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    _search(searchLatinNoun, "dolus", [], []),
    _search(searchLatinNoun, "calor", [], []),
    _search(searchLatinNoun, "avis", [], []),
    _search(searchLatinNoun, "carmen", [], []),
    _search(searchLatinNoun, "frīgus", [], []),
    _search(searchLatinNoun, "mare", [], []),
    _search(searchLatinNoun, "moenia", [], []),
    _search(searchLatinAdjective, "aetern", [], []),
    _search(searchLatinVerb, "expugnāre", [], []),
    {
      type: "adverb",
      latin: "aliter",
      dutch: "anders",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    {
      type: "adverb",
      latin: "facile",
      dutch: "gemakkelijk",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    {
      type: "adverb",
      latin: "multum (of multō)",
      dutch: "veel",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    _search(searchLatinConjunction, "ac (of atque)", [], []),
    _search(searchLatinNoun, "āla", [], []),
    _search(searchLatinNoun, "cōpiae", [], []),
    _search(searchLatinNoun, "custōs", [], []),
    _search(searchLatinNoun, "arx", [], []),
    _search(searchLatinAdjective, "ēgregi", [], []),
    _search(searchLatinAdjective, "secund", [], []),
    _search(searchLatinAdjective, "tacit", [], []),
    _search(searchLatinVerb, "cēdere", [], []),
    _search(searchLatinVerb, "discēdere", [], []),
    _search(searchLatinVerb, "colere", [], []),
    _search(searchLatinVerb, "custōdīre", [], []),
    {
      type: "adverb",
      latin: "interim",
      dutch: "intussen",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    _search(searchLatinNoun, "animus", [], []),
    _search(searchLatinNoun, "medicus", [], []),
    _search(searchLatinNoun, "fera", [], []),
    _search(searchLatinNoun, "fuga", [], []),
    _search(searchLatinNoun, "lingua", [], []),
    _search(searchLatinNoun, "vulnus", [], []),
    {
      type: "pluralAdjective",
      latin: "cūnctī",
      root: "cūnct",
      dutch: "alle",
      klass: 1,
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    _search(searchLatinAdjective, "lent", [], []),
    _search(searchLatinAdjective, "quiēt", [], []),
    _search(searchLatinAdjective, "saev", [], []),
    _search(searchLatinVerb, "movēre", [], []),
    _search(searchLatinVerb, "accēdere", [], []),
    _search(searchLatinVerb, "comprehendere", [], []),
    _search(searchLatinVerb, "dēdūcere", [], []),
    _search(searchLatinVerb, "dīmittere", [], []),
    _search(searchLatinDemonstrativePronoun, "ipse, ipsa, ipsum; ipsīus", [], []),
    _search(searchLatinDemonstrativePronoun, "is, ea, id", [], []),
    _search(searchLatinAdverb, "procul", [], []),
    _search(searchLatinPrepositionAblative, "ā of ab", [], []),
    _search(searchLatinNoun, "ventus", [], []),
    _search(searchLatinNoun, "exemplum", [], []),
    _search(searchLatinNoun, "tempestās", [], []),
    _search(searchLatinNoun, "lītus", [], []),
    _search(searchLatinVerb, "cōgere", [], []),
    _search(searchLatinVerb, "frangere", [], []),
    _search(searchLatinVerb, "reddere", [], []),
    _search(searchLatinVerb, "excipere", [], []),
    _search(searchLatinConjunction, "nec of neque", [], []),
    {
      type: "conjunction",
      latin: "nec ... nec of neque ... neque",
      dutch: "noch ... noch",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    _search(searchLatinNoun, "digitus", [], []),
    _search(searchLatinNoun, "dextra", [], []),
    _search(searchLatinNoun, "scūtum", [], []),
    _search(searchLatinNoun, "pellis", [], []),
    _search(searchLatinNoun, "regiō", [], []),
    _search(searchLatinNoun, "pecus", [], []),
    _search(searchLatinVerb, "vulnerāre", [], []),
    _search(searchLatinVerb, "gerere", [], []),
    _search(searchLatinVerb, "induere", [], []),
    _search(searchLatinVerb, "trahere", [], []),
    _search(searchLatinVerb, "fīnīre", [], []),
    {
      type: "passiveVerb",
      latin: "vidērī (videor)",
      dutch: "schijnen",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    _search(searchLatinConjunction, "quia", [
      {
        foreign: "quia peccavi nimis",
        dutch: "(om)dat ik zwaar gezondigd heb",
        religious: true,
      },
    ], []),
    {
      type: "conjunction",
      latin: "ut",
      dutch: "zoals",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    _search(searchLatinAdjective, "infer", [], []),
    _search(searchLatinVerb, "appropinquāre", [], []),
    _search(searchLatinVerb, "dēspērāre", [], []),
    _search(searchLatinVerb, "spērāre", [
      {
        "foreign": "spēra in Deo",
        "dutch": "hoop op God",
        "religious": true
      }
    ], []),
    _search(searchLatinVerb, "obsidēre", [], []),
    _search(searchLatinVerb, "prōmittere", [], []),
    _search(searchLatinVerb, "scrībere", [], []),
    {
      type: "adverb",
      latin: "ōlim",
      dutch: "eens",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    _search(searchLatinNoun, "victor", [], []),
    _search(searchLatinAdjective, "alter", [], []),
    _search(searchLatinToFixIrregularWord, "alter ..., alter ...", [], []),
    {
      type: "pluralAdjective",
      latin: "plūrimī",
      root: "plūrim",
      dutch: "zeer veel",
      klass: 1,
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    _search(searchLatinAdjective, "tant", [], []),
    _search(searchLatinVerb, "vexāre", [], []),
    _search(searchLatinVerb, "exercēre", [], []),
    _search(searchLatinNoun, "initium", [], []),
    _search(searchLatinNoun, "iūdicium", [], []),
    _search(searchLatinNoun, "rēgnum", [], []),
    _search(searchLatinNoun, "signum", [], []),
    _search(searchLatinNoun, "gēns", [], []),
    _search(searchLatinNoun, "sēdēs", [], []),
    _search(searchLatinNoun, "certāmen", [], []),
    _search(searchLatinNoun, "pectus", [], []),
    _search(searchLatinNoun, "currus", [], []),
    _search(searchLatinNoun, "cursus", [], []),
    _search(searchLatinNoun, "metus", [], []),
    _search(searchLatinNoun, "fidēs", [], []),
    _search(searchLatinNoun, "spēs", [], []),
    {
      type: "pluralAdjective",
      latin: "singulī",
      root: "singul",
      dutch: "elk één; afzonderlijk",
      klass: 1,
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    _search(searchLatinAdjective, "dulc", [], []),
    {
      type: "fixedAdjective",
      latin: "tot",
      dutch: "zoveel",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    _search(searchLatinVerb, "certāre", [], []),
    _search(searchLatinVerb, "pervenīre", [], []),
    _search(searchLatinAdverb, "simul", [], []),
    _search(searchLatinConjunction, "quamquam", [], []),
    _search(searchLatinNoun, "fructus", [], []),
    _search(searchLatinNoun, "rēs", [], []),
    _search(searchLatinNoun, "domus", [], ["Mixed 4+2"]),
    _search(searchLatinNoun, "manus", [], []),
    _search(searchLatinNoun, "cāsus", [], []),
    _search(searchLatinNoun, "ēventus", [], []),
    _search(searchLatinNoun, "exercitus", [], []),
    _search(searchLatinNoun, "magistrātus", [], []),
    _search(searchLatinNoun, "passus", [], []),
    _search(searchLatinNoun, "senātus", [], []),
    _search(searchLatinNoun, "tumultus", [], []),
    _search(searchLatinNoun, "vultus", [], []),
    _search(searchLatinNoun, "diēs", [], [
      "Mixed m/f",
    ]),
    _search(searchLatinNoun, "faciēs", [], []),
    _search(searchLatinAdjective, "aliēn", [], []),
    _search(searchLatinAdjective, "dext", [], []),
    _search(searchLatinAdjective, "rēgi", [], []),
    _search(searchLatinAdjective, "familiār", [], []),
    _search(searchLatinAdjective, "turp", [], []),
    _search(searchLatinVerb, "alere", [], []),
    _search(searchLatinVerb, "committere", [], [
      "Hij begaat een zware misdaad."
    ]),
    _search(searchLatinVerb, "fallere", [], [
      "De fout is me ontgaan"
    ]),
    _search(searchLatinVerb, "pellere", [], []),
    _search(searchLatinVerb, "statuere", [], []),
    _search(searchLatinVerb, "trādere", [], []),
    _search(searchLatinNoun, "īnferī", [], []),
    _search(searchLatinNoun, "marītus", [], []),
    _search(searchLatinNoun, "morbus", [], []),
    _search(searchLatinNoun, "mēnsis", [], []),
    _search(searchLatinNoun, "aetās", [], []),
    _search(searchLatinVerb, "suādēre", [], []),
    _search(searchLatinVerb, "carpere", [], []),
    _search(searchLatinVerb, "claudere", [], []),
    _search(searchLatinVerb, "crēscere", [], []),
    _search(searchLatinVerb, "occīdere", [], []),
    _search(searchLatinVerb, "prōcēdere", [], []),
    _search(searchLatinVerb, "tangere", [], []),
    _search(searchLatinVerb, "prōdesse", [], []),
    _search(searchLatinVerb, "perīre", [], []),
    {
      type: "adverb",
      latin: "quandō?",
      dutch: "wanneer?",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    {
      type: "adverb",
      latin: "rūrsus",
      dutch: "opnieuw",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    _search(searchLatinNoun, "somnium", [], []),
    _search(searchLatinNoun, "imber", [], []),
    _search(searchLatinAdjective, "tāl", [], []),
    _search(searchLatinDemonstrativePronoun, "īdem, eadem, idem; eiusdem", [], []),
    _search(searchLatinAdverb, "haud", [], []),
    {
      type: "adverb",
      latin: "inde",
      dutch: "vandaar; daarna; daarom",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    {
      type: "adverb",
      latin: "plērumque",
      dutch: "meestal",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
    _search(searchLatinDemonstrativePronoun, "hic, haec, hoc; huius", [], []),
    _search(searchLatinDemonstrativePronoun, "iste, ista, istud; istīus", [], []),
    _search(searchLatinDemonstrativePronoun, "ille, illa, illud; illīus", [], []),
    _search(searchLatinNoun, "epistula", [], []),
    {
      type: "adverb",
      latin: "praetereā",
      dutch: "bovendien",
      _2023_pegasus_novus_1: {
        examples: [],
        notes: [],
      },
    },
  ],
};