import { searchWord } from "../../WordBase";
import { LatinPrepositionAblative, LatinPrepositionAccusative } from "../LatinWord";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const LATIN_PREPOSITION_ABLATIVE: Array<LatinPrepositionAblative> = [
  {
    type: "prepositionAblative",
    latin: "ā of ab",
    dutch: "van; door",
  },
  {
    type: "prepositionAblative",
    latin: "cum",
    dutch: "met",
  },
  {
    type: "prepositionAblative",
    latin: "dē",
    dutch: "van; over",
  },
  {
    type: "prepositionAblative",
    latin: "ē of ex",
    dutch: "uit; vanaf",
  },
  {
    type: "prepositionAblative",
    latin: "prō",
    dutch: "voor; in plaats van",
  },
  {
    type: "prepositionAblative",
    latin: "sine",
    dutch: "zonder",
  },
  {
    type: "prepositionAblative",
    latin: "in",
    dutch: "in; op",
  },
  {
    type: "prepositionAblative",
    latin: "sub",
    dutch: "onder; omstreeks",
  },
  // {
  //   type: "prepositionAblative",
  //   latin: "",
  //   dutch: "",
  // },
];

export const searchLatinPrepositionAblative = searchWord(LATIN_PREPOSITION_ABLATIVE, "Latin preposition (+ abl)", "latin");

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const LATIN_PREPOSITION_ACCUSATIVE: Array<LatinPrepositionAccusative> = [
  {
    type: "prepositionAccusative",
    latin: "ad",
    dutch: "naar; tot; (tot) bij",
  },
  {
    type: "prepositionAccusative",
    latin: "ante",
    dutch: "vóór",
  },
  {
    type: "prepositionAccusative",
    latin: "apud",
    dutch: "bij",
  },
  {
    type: "prepositionAccusative",
    latin: "circum",
    dutch: "rondom",
  },
  {
    type: "prepositionAccusative",
    latin: "inter",
    dutch: "tussen; tijdens",
  },
  {
    type: "prepositionAccusative",
    latin: "ob",
    dutch: "tegenover; wegens",
  },
  {
    type: "prepositionAccusative",
    latin: "per",
    dutch: "door; gedurende",
  },
  {
    type: "prepositionAccusative",
    latin: "post",
    dutch: "achter; na",
  },
  {
    type: "prepositionAccusative",
    latin: "praeter",
    dutch: "voorbij; behalve",
  },
  {
    type: "prepositionAccusative",
    latin: "trāns",
    dutch: "over",
  },
  {
    type: "prepositionAccusative",
    latin: "in",
    dutch: "naar; tegen",
  },
  {
    type: "prepositionAccusative",
    latin: "sub",
    dutch: "tot onder; omstreeks",
  },
  {
    type: "prepositionAccusative",
    latin: "contrā",
    dutch: "tegenover",
  },
  {
    type: "prepositionAccusative",
    latin: "intrā",
    dutch: "binnen",
  },
  {
    type: "prepositionAccusative",
    latin: "propter",
    dutch: "wegens",
  },
  // {
  //   type: "prepositionAccusative",
  //   latin: "",
  //   dutch: "",
  // },
];

export const searchLatinPrepositionAccusative = searchWord(LATIN_PREPOSITION_ACCUSATIVE, "Latin preposition (+ acc)", "latin");