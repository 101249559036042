import { Optional } from "../../../utils/Optional";
import { Vocabulary } from "../../Vocabulary";
import { searchGreekAdjective } from "../GreekAdjectives";
import { searchGreekAdverb } from "../GreekAdverbs";
import { searchGreekConjunction } from "../GreekConjunctions";
import { searchGreekImpersonalVerb } from "../GreekImpersonalVerb";
import { searchGreekNoun } from "../GreekNouns";
import { searchGreekNumeral } from "../GreekNumeral";
import { searchGreekParticle } from "../GreekParticles";
import { searchGreekPrefix } from "../GreekPrefix";
import { searchGreekPrepositionAccusative, searchGreekPrepositionDative, searchGreekPrepositionGenitive } from "../GreekPrepositions";
import { searchGreekInterrogativePronouns } from "../GreekPronouns";
import { searchGreekSuffix } from "../GreekSuffixes";
import { searchGreekVerb } from "../GreekVerbs";
import { GreekWord } from "../GreekWord";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface Greek2024NeaThalassa1Example {
  greek: string;
  translation: string;
  tag: "chatgpt";
}

interface Greek2024NeaThalassa1Extras {
  _2024_nea_thalassa_1: {
    section: string;
    logicalIndex: number;
    page: number;
    examples: Greek2024NeaThalassa1Example[];
  };
}

function _search<T>(
  section: string,
  logicalIndex: number,
  page: number,
  f: (root: string) => T,
  root: string,
  examples: Greek2024NeaThalassa1Example[],
  extras?: Optional<T>
): T & Greek2024NeaThalassa1Extras {
  return {
    ...f(root),
    ...extras ?? {},
    _2024_nea_thalassa_1: {
      section,
      logicalIndex,
      page,
      examples,
    },
  };
}

export const GREEK_2024_NEA_THALASSA_1_VOCABULARY: Vocabulary<"gr", GreekWord & Greek2024NeaThalassa1Extras> = {
  language: "gr",
  vocabulary: [
    _search("1",   1, 1, searchGreekNoun, "φων", []),
    _search("1",   2, 1, searchGreekNoun, "ψυχ", []),
    _search("1",   3, 1, searchGreekNoun, "βί", []),
    _search("1",   4, 1, searchGreekNoun, "χρόν", []),
    _search("1",   5, 1, searchGreekVerb, "γρἀφειν", []),
    _search("2",   6, 1, searchGreekVerb, "λὐειν", []),
    _search("2",   7, 1, searchGreekVerb, "εἶναι", []),
    _search("2",   8, 1, searchGreekNoun, "ἄνθρωπ", []),
    _search("2",   9, 1, searchGreekNoun, "δούλ", []),
    _search("2",  10, 1, searchGreekNoun, "ἴππ", []),
    _search("2",  11, 1, searchGreekNoun, "φῐ́λ", []),
    _search("2",  12, 1, searchGreekNoun, "δῆμ", []),
    _search("2",  13, 1, searchGreekNoun, "λόγ", []),
    _search("2",  14, 1, searchGreekNoun, "οἶκ", []),
    _search("2",  15, 1, searchGreekNoun, "πόλεμ", []),
    _search("2",  16, 1, searchGreekNoun, "στρᾰτηγ", []),
    _search("2",  17, 1, searchGreekInterrogativePronouns, "τί", []),
    _search("2",  17, 1, searchGreekAdverb, "τί", []),
    _search("2",  18, 1, searchGreekVerb, "ᾰ̓κούειν", []),
    _search("2",  19, 1, searchGreekVerb, "βλέπειν", []),
    _search("2",  20, 1, searchGreekVerb, "ἐσθῐ́ειν", []),
    _search("2",  21, 1, searchGreekVerb, "ἔχειν", []),
    _search("2",  22, 1, searchGreekVerb, "λέγειν", []),
    _search("2",  23, 1, searchGreekVerb, "πέμπειν", []),
    _search("2",  24, 1, searchGreekVerb, "πῑ́νειν", []),
    _search("2",  25, 1, searchGreekVerb, "τρέφειν", []),
    _search("2",  26, 1, searchGreekPrepositionAccusative, "εἰς", []),
    _search("2",  27, 1, searchGreekPrepositionDative, "ἐν", []),
    _search("2",  28, 1, searchGreekAdverb, "τότε", []),
    _search("2",  29, 1, searchGreekAdverb, "καί", [
      {
        greek: "Τὸ τέκνον καί μαθεῖν θέλει.",
        translation: "Het kind wil ook leren.",
        tag: "chatgpt",
      },
    ]),
    _search("2",  29, 2, searchGreekConjunction, "καί", []),
    _search("2",  30, 2, searchGreekConjunction, "δέ", [
      {
        greek: "Ὁ βίος δέ καλός.",
        translation: "Het leven, echter, is mooi.",
        tag: "chatgpt",
      },
    ]),
    _search("2",  31, 2, searchGreekConjunction, "οὖν", []),
    _search("2",  32, 2, searchGreekParticle, "οὐ / οὐκ / οὐχ", []),
    _search("2",  33, 2, searchGreekNoun, "θε", []),
    _search("2",  34, 2, searchGreekNoun, "οἶν", []),
    _search("2",  35, 2, searchGreekNoun, "οὐρᾰν", []),
    _search("2",  36, 2, searchGreekVerb, "ᾰ̓́γειν", []),
    _search("2",  37, 2, searchGreekPrepositionGenitive, "ἐκ / ἐξ", []),
    _search("2",  38, 2, searchGreekVerb, "εὑρῐ́σκειν", []),
    _search("2",  39, 2, searchGreekVerb, "χαίρειν", []),
    _search("2",  40, 2, searchGreekConjunction, "ἀλλά", []),
    _search("2",  41, 2, searchGreekConjunction, "οὔτε ... οὔτε", []),
    _search("2",  42, 2, searchGreekConjunction, "ὅτε", []),
    _search("3",  43, 2, searchGreekNoun, "τῑμ", []),
    _search("3",  44, 2, searchGreekNoun, "δῶρ", []),
    _search("3",  45, 2, searchGreekNoun, "ἔργ", []),
    _search("3",  46, 2, searchGreekAdjective, "ᾰ̓γᾰθός", []),
    _search("3",  47, 2, searchGreekAdjective, "κᾰλός", []),
    _search("3",  48, 2, searchGreekAdjective, "σοφός", []),
    _search("3",  49, 2, searchGreekParticle, "ἦ", []),
    _search("3",  50, 2, searchGreekConjunction, "γᾰ́ρ", []),
    _search("3",  51, 2, searchGreekConjunction, "ἤ", [
      {
        greek: "Ὁ πᾰτήρ ἤ ἡ μήτηρ.",
        translation: "De vader of de moeder.",
        tag: "chatgpt",
      },
    ]),
    _search("3",  52, 2, searchGreekConjunction, "μέν ... δέ", []),
    _search("3",  53, 2, searchGreekConjunction, "μέντοι", []),
    _search("3",  54, 2, searchGreekConjunction, "ὅπως", []),
    _search("3",  55, 2, searchGreekConjunction, "τε", []),
    _search("3",  56, 2, searchGreekNoun, "εἰρήν", []),
    _search("3",  57, 2, searchGreekNoun, "τέχν", []),
    _search("3",  58, 2, searchGreekNoun, "ἥλῐ", []),
    _search("3",  59, 2, searchGreekNoun, "κῐ́νδῡν", []),
    _search("3",  60, 2, searchGreekNoun, "λῐ́θ", []),
    _search("3",  61, 2, searchGreekNoun, "πολέμῐ", []),
    _search("3",  62, 2, searchGreekNoun, "τόπ", []),
    _search("3",  63, 3, searchGreekNoun, "ᾰ̓́κρ", []),
    _search("3",  64, 3, searchGreekNoun, "τέκν", []),
    _search("3",  65, 3, searchGreekAdjective, "δῠνᾰτός", []),
    _search("3",  66, 3, searchGreekAdjective, "ἕκᾰστος", []),
    _search("3",  67, 3, searchGreekAdjective, "μέγῐστος", []),
    _search("3",  68, 3, searchGreekAdjective, "ξένος", []),
    _search("3",  69, 3, searchGreekAdjective, "ὀλῐ́γος", []),
    _search("3",  70, 3, searchGreekAdjective, "πολλοί", []),
    _search("3",  71, 3, searchGreekAdjective, "χᾰλεπός", []),
    _search("3",  72, 3, searchGreekAdjective, "χρήσῐμος", []),
    _search("3",  73, 3, searchGreekVerb, "ἀποκτείνειν", []),
    _search("3",  74, 3, searchGreekVerb, "βαίνειν", []),
    _search("3",  75, 3, searchGreekPrefix, "ἀνα-", []),
    _search("3",  76, 3, searchGreekPrefix, "κατα-", []),
    _search("3",  77, 3, searchGreekVerb, "θαυμᾰ́ζειν", []),
    _search("3",  78, 3, searchGreekVerb, "πᾰρέχειν", []),
    _search("3",  79, 3, searchGreekVerb, "φαίνειν", []),
    _search("3",  80, 3, searchGreekVerb, "φέρειν", []),
    _search("3",  81, 3, searchGreekVerb, "φεύγειν", []),
    _search("3",  82, 3, searchGreekImpersonalVerb, "ἔξεστι(ν)", []),
    _search("3",  83, 3, searchGreekAdverb, "εὖ", []),
    _search("3",  84, 3, searchGreekAdverb, "νῦν", []),
    _search("3",  85, 3, searchGreekConjunction, "ὅτι", []),
    _search("3",  86, 3, searchGreekNoun, "ᾰ̓ρχ", []),
    _search("3",  87, 3, searchGreekNoun, "γ", []),
    _search("3",  89, 3, searchGreekVerb, "ἐθέλειν", []),
    _search("3",  90, 3, searchGreekVerb, "ὀνομᾰ́ζειν", []),
    _search("3",  91, 3, searchGreekVerb, "σῴζειν", []),
    _search("3",  92, 3, searchGreekNumeral, "δῠ́ο", []),
    _search("3",  93, 3, searchGreekPrepositionGenitive, "ἀντί", []),
    _search("3",  94, 3, searchGreekPrepositionGenitive, "περῐ́", []),
    _search("3",  95, 3, searchGreekAdverb, "ἐκεῖ", []),
    _search("3",  96, 3, searchGreekAdverb, "μᾰ́λῐστᾰ", [
      {
        greek: "Ναί, μᾰ́λῐστᾰ.",
        translation: "Ja, zeker.",
        tag: "chatgpt",
      },
      {
        greek: "Ὁ στρᾰτηγός μᾰ́λῐστᾰ σοφός.",
        translation: "De generaal is heel wijs.",
        tag: "chatgpt",
      },
    ]),
    _search("3",  97, 3, searchGreekParticle, "διότι", []),
    _search("3",  97, 3, searchGreekConjunction, "δῐότῐ", []),
    _search("3",  98, 3, searchGreekNoun, "νῑ́κ", []),
    _search("3",  99, 3, searchGreekAdjective, "πρῶτος", []),
    _search("3", 100, 4, searchGreekVerb, "ἥκειν", []),
    _search("3", 101, 4, searchGreekAdverb, "αὐτίκα", []),
    _search("3", 102, 4, searchGreekAdverb, "πολῠ́", []),
    _search("3", 103, 4, searchGreekConjunction, "εἰ", []),
    _search("4", 104, 4, searchGreekVerb, "βᾰ́λλειν", []),
    _search("4", 105, 4, searchGreekPrepositionGenitive, "ῠ̔πό", []),
    _search("4", 106, 4, searchGreekNoun, "ᾰ̓δελφ", []),
    _search("4", 107, 4, searchGreekVerb, "λείπειν", []),
    _search("4", 108, 4, searchGreekVerb, "τῐ́κτειν", []),
    _search("4", 109, 4, searchGreekNoun, "τῠ́χ", []),
    _search("4", 110, 4, searchGreekVerb, "δῐᾰφέρειν", []),
    _search("4", 111, 4, searchGreekAdverb, "μάλα", []),
    _search("4", 112, 4, searchGreekAdjective, "κᾰκός", []),
    _search("4", 113, 4, searchGreekVerb, "ᾰ̓νᾰγῐγνώσκειν", []),
    _search("4", 114, 4, searchGreekVerb, "λᾰμβᾰ́νειν", []),
    _search("4", 115, 4, searchGreekPrepositionAccusative, "μετᾰ́", []),
    _search("4", 116, 4, searchGreekAdverb, "ἔνθα", []),
    _search("4", 117, 4, searchGreekAdverb, "οὐκέτι", []),
    _search("4", 118, 4, searchGreekVerb, "πρᾱ́ττειν", []),
    _search("4", 119, 4, searchGreekConjunction, "ὥστε", []),
    _search("5", 120, 4, searchGreekAdverb, "ἀεί", []),
    _search("5", 121, 4, searchGreekNoun, "θῠ́ρ", []),
    _search("5", 122, 4, searchGreekAdjective, "νέος", []),
    _search("5", 123, 4, searchGreekNoun, "πολῑ́τ", []),
    _search("5", 124, 4, searchGreekNoun, "ὁδ", []),
    _search("5", 125, 4, searchGreekAdjective, "δῐ́καιος", []),
    _search("5", 126, 4, searchGreekAdjective, "μᾰκρός", []),
    _search("5", 127, 4, searchGreekAdjective, "μῑκρός", []),
    _search("5", 128, 4, searchGreekNoun, "μᾰ́χ", []),
    _search("5", 129, 4, searchGreekAdjective, "ᾰ̓́λλος", []),
    _search("5", 130, 4, searchGreekPrepositionGenitive, "ἀπό", []),
    _search("5", 105, 5, searchGreekPrepositionDative, "ῠ̔πό", []),
    _search("5", 131, 5, searchGreekPrepositionAccusative, "ἐπί", []),
    _search("5", 131, 5, searchGreekPrepositionGenitive, "ἐπί", []),
    _search("5", 131, 5, searchGreekPrepositionDative, "ἐπί", []),
    _search("5", 132, 5, searchGreekPrepositionAccusative, "παρά", []),
    _search("5", 132, 5, searchGreekPrepositionGenitive, "παρά", []),
    _search("5", 132, 5, searchGreekPrepositionDative, "παρά", []),
    _search("5", 133, 5, searchGreekPrepositionAccusative, "πρός", []),
    _search("5", 133, 5, searchGreekPrepositionGenitive, "πρός", []),
    _search("5", 133, 5, searchGreekPrepositionDative, "πρός", []),
    _search("5", 134, 5, searchGreekAdverb, "ποῦ", []),
    _search("5", 135, 5, searchGreekNoun, "ἡμέρ", []),
    _search("5", 136, 5, searchGreekNoun, "οἰκῐ́", []),
    _search("5", 137, 5, searchGreekNoun, "δεσπότ", []),
    _search("5", 138, 5, searchGreekNoun, "κρῐτ", []),
    _search("5", 139, 5, searchGreekAdjective, "ᾰ̓́ξῐος", []),
    _search("5", 140, 5, searchGreekAdjective, "ἐλεύθερος", []),
    _search("5",  88, 5, searchGreekAdjective, "ἱερός", []),
    _search("5", 141, 5, searchGreekVerb, "γιγνώσκειν", []),
    _search("5", 142, 5, searchGreekNoun, "ποτᾰμ", []),
    _search("5", 143, 5, searchGreekNoun, "δένδρ", []),
    _search("5", 144, 5, searchGreekVerb, "μένειν", []),
    _search("5", 145, 5, searchGreekPrepositionGenitive, "ὑπέρ", []),
    _search("5", 115, 5, searchGreekPrepositionGenitive, "μετά", []),
    _search("5", 146, 5, searchGreekNoun, "ᾰ̓λήθει", []),
    _search("5", 147, 5, searchGreekAdjective, "ῥᾴδῐος", []),
    _search("5", 148, 5, searchGreekVerb, "δῐᾰφθείρειν", []),
    _search("5", 149, 5, searchGreekPrepositionDative, "σύν", []),
    _search("5", 150, 5, searchGreekConjunction, "ὥσπερ", []),
    _search("5", 151, 5, searchGreekNoun, "χώρ", []),
    _search("5", 152, 5, searchGreekNoun, "ποιητ", []),
    _search("5", 153, 5, searchGreekNoun, "νόμ", []),
    _search("5", 154, 5, searchGreekPrepositionGenitive, "πρό", []),
    _search("5", 155, 5, searchGreekPrepositionAccusative, "διά", []),
    _search("5", 155, 5, searchGreekPrepositionGenitive, "διά", []),
    _search("5", 156, 5, searchGreekAdverb, "εἰκότως", []),
    _search("5", 157, 5, searchGreekAdverb, "οὔποτε", []),
    _search("5", 157, 5, searchGreekAdverb, "οὐδέποτε", []),
    _search("6", 158, 6, searchGreekVerb, "αἰσθάνεσθαι", []),
    _search("6", 159, 6, searchGreekVerb, "ἀποκρίνεσθαι", []),
    _search("6", 160, 6, searchGreekVerb, "βούλεσθαι", []),
    _search("6", 161, 6, searchGreekVerb, "γίγνεσθαι", []),
    _search("6", 162, 6, searchGreekVerb, "μᾰχεσθαι", []),
    _search("6", 163, 6, searchGreekVerb, "παύειν", [], {
      dutch: "doen ophouden, doen stoppen"
    }),
    _search("6", 163, 6, searchGreekVerb, "παύειν", [], {
      ground: "παύεσθαι",
      passport: "παύομαι (med.)",
      dutch: "ophouden, stoppen",
    }),
    _search("6", 164, 6, searchGreekVerb, "πείθειν", []),
    _search("6", 164, 6, searchGreekVerb, "πείθειν", [], {
      ground: "πείθεσθαι",
      passport: "πείθομαι (med.)",
      dutch: "geloven, gehoorzamen",
    }),
    _search("6", 79, 6, searchGreekVerb, "φαίνειν", [], {
      ground: "φαίνεσθαι",
      passport: "φαίνομαι (med.)",
      dutch: "(+inf.) schijnen, blijken",
    }),
    _search("6", 165, 6, searchGreekVerb, "ἰέναι", [], {
      passport: "ἔρχομαι (onr. ww.)",
    }),
    _search("6", 166, 6, searchGreekSuffix, "-περ", []),
    _search("6", 167, 6, searchGreekNoun, "στρᾰτῐώτ", []),
    _search("6", 168, 6, searchGreekVerb, "ᾰ̓ρχειν", [], {
      passport: "ᾰ̓ρχω (+ gen.)",
    }),
    _search("6", 168, 6, searchGreekVerb, "ᾰ̓ρχειν", [], {
      ground: "ᾰ̓ρχεσθαι",
      passport: "ᾰ̓ρχομαι (med.)",
      dutch: "beginnen",
    }),
    _search("6", 169, 6, searchGreekVerb, "βουλεύειν", []),
    _search("6", 169, 6, searchGreekVerb, "βουλεύειν", [], {
      ground: "βουλεύεσθαι",
      passport: "βουλεύομαι (med.)",
      dutch: "overwegen, (bij zichzelf) besluiten",
    }),
    _search("6", 170, 6, searchGreekVerb, "δέχεσθαι", []),
    _search("6", 171, 6, searchGreekVerb, "ἐργᾰζεσθαι", []),
    _search("6", 172, 6, searchGreekVerb, "οἴεσθαι", []),
    _search("6", 173, 6, searchGreekVerb, "πορεύειν", [], {
      ground: "πορεύεσθαι",
      passport: "πορεύομαι",
      dutch: "reizen, oprukken",
    }),
    _search("6", 174, 6, searchGreekPrefix, "ἀπ-", []),
    _search("6", 175, 6, searchGreekAdjective, "μόνος", []),
    _search("6", 176, 6, searchGreekVerb, "κρῑνειν", []),
    _search("6", 177, 6, searchGreekAdverb, "πολλάκις", []),
    _search("6", 178, 6, searchGreekNoun, "στρᾰτῐ", []),
    _search("6", 179, 6, searchGreekVerb, "μέλλειν", [], {
      passport: "μέλλω (+ inf.)",
    }),
    _search("6", 180, 6, searchGreekVerb, "φᾰναι", []),
  ],
};