import { LatinWord, toDutchTitle, toForeignTitle } from "../../data/latin/LatinWord";
import "../WordFull.scss";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface LatinDefaultFullProps {
  word: LatinWord;
}

function LatinDefaultFull({ word }: LatinDefaultFullProps) {
  return (
    <div className="word-full">
      <div className="word-full-title">
        {toForeignTitle(word)}
      </div>
      <div className="word-full-translation">
        {toDutchTitle(word)}
      </div>
    </div>
  );
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export default LatinDefaultFull;