import { searchWord } from "../WordBase";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export interface GreekPrefix {
  type: "prefix";  // "voorvoegsel"
  ground: string;
  dutch: string;
}

export const GREEK_PREFIXES: GreekPrefix[] = [
  {
    type: "prefix",
    ground: "ἀνα-",
    dutch: "naar omhoog",
  },
  {
    type: "prefix",
    ground: "κατα-",
    dutch: "naar omlaag, naar beneden",
  },
  {
    type: "prefix",
    ground: "ἀπ-",
    dutch: "weg van",
  },
  // {
  //   type: "prefix",
  //   ground: "",
  //   dutch: "",
  // },
];

export const searchGreekPrefix = searchWord(GREEK_PREFIXES, "Greek prefix", "ground");