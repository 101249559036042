import { LatinNegation } from "../LatinWord";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const LATIN_NEGATIONS: Array<LatinNegation> = [
  {
    type: "negation",
    latin: "nōn",
    dutch: "niet",
  },
];

export function searchLatinNegation(latin: string): LatinNegation {
  const results = LATIN_NEGATIONS.filter((negation) => negation.latin === latin);
  if (results.length !== 1) {
    console.error(results);
    throw new Error(`No single Latin negation found for: ${latin}`);
  }
  return results[0];
}