import { getModelNoun } from "../../data/latin/2023/PegasusNovus1.tsx";
import {
  ablP,
  ablS,
  accP,
  accS,
  datP,
  datS,
  genP,
  genS,
  LatinNoun,
  nominative,
  nomP,
  nomS,
  vocP,
  vocS
} from "../../data/latin/LatinNoun.tsx";
import { SEX_TITLE } from "../../data/WordBase.tsx";
import { merge } from "../../utils/merge.tsx";
import "../WordFull.scss";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

interface LatinNounFullProps {
  noun: LatinNoun;
}

function LatinNounFull({ noun }: LatinNounFullProps) {
  const { dutch, sex } = noun;

  return (
    <div className="word-full">
      <div className="word-full-title">
        {nominative(noun)} ({SEX_TITLE[sex]})
        <span className="word-full-model float-end">{getModelNoun(noun)}</span>
      </div>
      <div className="word-full-conjugation">
        <table className="table table-sm">
          <tbody>
          <tr>
            <th className="declension">Nominatief</th>
            <td>{merge(nomS(noun))}</td>
            <td>{merge(nomP(noun))}</td>
          </tr>
          <tr>
            <th className="declension">Accusatief</th>
            <td>{merge(accS(noun))}</td>
            <td>{merge(accP(noun))}</td>
          </tr>
          <tr>
            <th className="declension">Genitief</th>
            <td>{merge(genS(noun))}</td>
            <td>{merge(genP(noun))}</td>
          </tr>
          <tr>
            <th className="declension">Datief</th>
            <td>{merge(datS(noun))}</td>
            <td>{merge(datP(noun))}</td>
          </tr>
          <tr>
            <th className="declension">Ablatief</th>
            <td>{merge(ablS(noun))}</td>
            <td>{merge(ablP(noun))}</td>
          </tr>
          <tr>
            <th className="declension">Vocatief</th>
            <td>{merge(vocS(noun))}</td>
            <td>{merge(vocP(noun))}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div className="word-full-translation">
        {dutch}
      </div>
    </div>
  );
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export default LatinNounFull;