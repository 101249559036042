import * as rt from "runtypes";
import { searchWord, WordFinal } from "../WordBase";
import { Optional } from "../../utils/Optional";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const GreekDeclinedNumeral = rt.Record({
  nomS: WordFinal,
  genS: WordFinal,
  datS: WordFinal,
  accS: WordFinal,
  vocS: WordFinal,
  nomD: WordFinal,
  genD: WordFinal,
  datD: WordFinal,
  accD: WordFinal,
  vocD: WordFinal,
  nomP: WordFinal,
  genP: WordFinal,
  datP: WordFinal,
  accP: WordFinal,
  vocP: WordFinal,
});
export type GreekDeclinedNumeral = rt.Static<typeof GreekDeclinedNumeral>;

export interface GreekNumeral {
  type: "numeral";  // "hoofdtelwoord"
  ground: string;
  dutch: string;
  explicitDeclensions?: Optional<GreekDeclinedNumeral>;
}

export const GREEK_NUMERALS: GreekNumeral[] = [
  {
    // https://en.wiktionary.org/wiki/%CE%B4%CF%8D%CE%BF
    type: "numeral",
    ground: "δῠ́ο",
    dutch: "twee",
    explicitDeclensions: {
      nomD: "δῠ́ο",
      genD: "δῠ́ο",
      datD: ["δῠοῖν", "δῠεῖν"],
      accD: "δῠῶν",
      vocD: ["δῠοῖν", "δῠεῖν"],
      nomP: ["δῠσῐ́", "δῠσῐ́ν", "δῠοῖσῐ"],
      genP: "δῠ́ο",
      datP: "δῠ́ο",
      accP: "δῠ́ο",
      vocP: "δῠ́ο",
    },
  },
  // {
  //   type: "numeral",
  //   ground: "",
  //   dutch: "",
  // },
];

export const searchGreekNumeral = searchWord(GREEK_NUMERALS, "Greek numeral", "ground");