import * as rt from "runtypes";
import { searchWord, Sex, WordFinal } from "../WordBase";
import { Optional } from "../../utils/Optional";

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export type GreekNounDeclension = "1" | "2";

export type GreekNounSex<D extends GreekNounDeclension> =
  D extends "1" ? ("m" | "f") & Sex :
    D extends "2" ? ("m" | "f" | "n") & Sex:
      never;

export const GreekDeclinedNoun = rt.Record({
  nomS: WordFinal,
  genS: WordFinal,
  datS: WordFinal,
  accS: WordFinal,
  vocS: WordFinal,
  nomD: WordFinal,
  genD: WordFinal,
  datD: WordFinal,
  accD: WordFinal,
  vocD: WordFinal,
  nomP: WordFinal,
  genP: WordFinal,
  datP: WordFinal,
  accP: WordFinal,
  vocP: WordFinal,
});
export type GreekDeclinedNoun = rt.Static<typeof GreekDeclinedNoun>;

export interface GreekNounBase<D extends GreekNounDeclension> {
  type: "noun";
  root: string;
  ground: string;
  passport: string;
  dutch: string;
  declension: D;
  explicitDeclensions?: Optional<GreekDeclinedNoun>;
  hasSingular: boolean;
  hasDual?: boolean;
  hasPlural: boolean;
  sex: GreekNounSex<D>;
}

export type GreekNoun =
  | GreekNounBase<"1">
  | GreekNounBase<"2">;

export const GREEK_NOUNS: GreekNoun[] = [
  {
    // https://en.wiktionary.org/wiki/%CF%86%CF%89%CE%BD%CE%AE
    type: "noun",
    root: "φων",
    ground: "ἡ φωνή",
    passport: "φωνῆς",
    dutch: "de stem, de klank",
    declension: "1",
    explicitDeclensions: {
      nomS: "ἡ φωνή",
      genS: "τῆς φωνῆς",
      datS: "τῇ φωνῇ",
      accS: "τὴν φωνήν",
      vocS: "φωνή",
      nomD: "τὼ φωνᾱ́",
      genD: "τοῖν φωναῖν",
      datD: "τοῖν φωναῖν",
      accD: "τὼ φωνᾱ́",
      vocD: "φωνᾱ́",
      nomP: "αἱ φωναί",
      genP: "τῶν φωνῶν",
      datP: "ταῖς φωναῖς",
      accP: "τᾱ̀ς φωνᾱ́ς",
      vocP: "φωναί",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "f",
  },
  {
    // https://en.wiktionary.org/wiki/%CF%88%CF%85%CF%87%CE%AE
    type: "noun",
    root: "ψυχ",
    ground: "ἡ ψυχή",
    passport: "ψυχῆς",
    dutch: "de ziel",
    declension: "1",
    explicitDeclensions: {
      nomS: "ἡ ψῡχή",
      genS: "τῆς ψῡχῆς",
      datS: "τῇ ψῡχῇ",
      accS: "τὴν ψῡχήν",
      vocS: "ψῡχή",
      nomD: "τὼ ψῡχᾱ́",
      genD: "τοῖν ψῡχαῖν",
      datD: "τοῖν ψῡχαῖν",
      accD: "τὼ ψῡχᾱ́",
      vocD: "ψῡχᾱ́",
      nomP: "αἱ ψῡχαί",
      genP: "τῶν ψῡχῶν",
      datP: "ταῖς ψῡχαῖς",
      accP: "τᾱ̀ς ψῡχᾱ́ς",
      vocP: "ψῡχαί",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "f",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B2%CE%AF%CE%BF%CF%82
    type: "noun",
    root: "βί",
    ground: "ὁ βίος",
    passport: "βίου",
    dutch: "het leven",
    declension: "2",
    explicitDeclensions: {
      nomS: "ὁ βῐ́ος",
      genS: "τοῦ βῐ́ου",
      datS: "τῷ βῐ́ῳ",
      accS: "τὸν βῐ́ον",
      vocS: "βῐ́ε",
      nomD: "τὼ βῐ́ω",
      genD: "τοῖν βῐ́οιν",
      datD: "τοῖν βῐ́οιν",
      accD: "τὼ βῐ́ω",
      vocD: "βῐ́ω",
      nomP: "οἱ βῐ́οι",
      genP: "τῶν βῐ́ων",
      datP: "τοῖς βῐ́οις",
      accP: "τοὺς βῐ́ους",
      vocP: "βῐ́οι",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "m",
  },
  {
    // https://en.wiktionary.org/wiki/%CF%87%CF%81%CF%8C%CE%BD%CE%BF%CF%82
    type: "noun",
    root: "χρόν",
    ground: "ὁ χρόνος",
    passport: "χρὀνου",
    dutch: "de tijd",
    declension: "2",
    explicitDeclensions: {
      nomS: "ὁ χρόνος",
      genS: "τοῦ χρόνου",
      datS: "τῷ χρόνῳ",
      accS: "τὸν χρόνον",
      vocS: "χρόνε",
      nomD: "τὼ χρόνω",
      genD: "τοῖν χρόνοιν",
      datD: "τοῖν χρόνοιν",
      accD: "τὼ χρόνω",
      vocD: "χρόνω",
      nomP: "οἱ χρόνοι",
      genP: "τῶν χρόνων",
      datP: "τοῖς χρόνοις",
      accP: "τοὺς χρόνους",
      vocP: "χρόνοι",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "m",
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%84%CE%BD%CE%B8%CF%81%CF%89%CF%80%CE%BF%CF%82
    type: "noun",
    root: "ἄνθρωπ",
    ground: "ὁ ἄνθρωπος",
    passport: "άνθρωπου",
    dutch: "de mens",
    declension: "2",
    explicitDeclensions: {
      nomS: "ὁ, ἡ ἄνθρωπος",
      genS: "τοῦ, τῆς ἀνθρώπου",
      datS: "τῷ, τῇ ἀνθρώπῳ",
      accS: "τὸν, τὴν ἄνθρωπον",
      vocS: "ἄνθρωπε",
      nomD: "τὼ ἀνθρώπω",
      genD: "τοῖν ἀνθρώποιν",
      datD: "τοῖν ἀνθρώποιν",
      accD: "τὼ ἀνθρώπω",
      vocD: "ἀνθρώπω",
      nomP: "οἱ, αἱ ἄνθρωποι",
      genP: "τῶν ἀνθρώπων",
      datP: "τοῖς, ταῖς ἀνθρώποις",
      accP: "τοὺς, τᾱ̀ς ἀνθρώπους",
      vocP: "ἄνθρωποι",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "m",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B4%CE%BF%E1%BF%A6%CE%BB%CE%BF%CF%82
    type: "noun",
    root: "δούλ",
    ground: "ὁ δοῦλος",
    passport: "δοὐλου",
    dutch: "de slaaf",
    declension: "2",
    explicitDeclensions: {
      nomS: "ὁ δοῦλος",
      genS: "τοῦ δούλου",
      datS: "τῷ δούλῳ",
      accS: "τὸν δοῦλον",
      vocS: "δοῦλε",
      nomD: "τὼ δούλω",
      genD: "τοῖν δούλοιν",
      datD: "τοῖν δούλοιν",
      accD: "τὼ δούλω",
      vocD: "δούλω",
      nomP: "οἱ δοῦλοι",
      genP: "τῶν δούλων",
      datP: "τοῖς δούλοις",
      accP: "τοὺς δούλους",
      vocP: "δοῦλοι",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "m",
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%B5%CF%80%CF%80%CE%BF%CF%82
    type: "noun",
    root: "ἴππ",
    ground: "ὁ ἵππος",
    passport: "ἵππου",
    dutch: "het paard",
    declension: "2",
    explicitDeclensions: {
      nomS: "ὁ, ἡ ῐ̔́ππος",
      genS: "τοῦ, τῆς ῐ̔́ππου",
      datS: "τῷ, τῇ ῐ̔́ππῳ",
      accS: "τὸν, τὴν ῐ̔́ππον",
      vocS: "ῐ̔́ππε",
      nomD: "τὼ ῐ̔́ππω",
      genD: "τοῖν ῐ̔́πποιν",
      datD: "τοῖν ῐ̔́πποιν",
      accD: "τὼ ῐ̔́ππω",
      vocD: "ῐ̔́ππω",
      nomP: "οἱ, αἱ ῐ̔́πποι",
      genP: "τῶν ῐ̔́ππων",
      datP: "τοῖς, ταῖς ῐ̔́πποις",
      accP: "τοὺς, τᾱ̀ς ῐ̔́ππους",
      vocP: "ῐ̔́πποι",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "m",
  },
  {
    // https://en.wiktionary.org/wiki/%CF%86%CE%AF%CE%BB%CE%BF%CF%82
    type: "noun",
    root: "φῐ́λ",
    ground: "ὁ φῐ́λος",
    passport: "φῐ́λου",
    dutch: "de vriend",
    declension: "2",
    explicitDeclensions: {
      nomS: "ὁ φῐ́λος",
      genS: "τοῦ φῐ́λου",
      datS: "τῷ φῐ́λῳ",
      accS: "τὸν φῐ́λον",
      vocS: "φῐ́λε",
      nomD: "τὼ φῐ́λω",
      genD: "τοῖν φῐ́λοιν",
      datD: "τοῖν φῐ́λοιν",
      accD: "τὼ φῐ́λω",
      vocD: "φῐ́λω",
      nomP: "οἱ φῐ́λοι",
      genP: "τῶν φῐ́λων",
      datP: "τοῖς φῐ́λοις",
      accP: "τοὺς φῐ́λους",
      vocP: "φῐ́λοι",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "m",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B4%E1%BF%86%CE%BC%CE%BF%CF%82
    type: "noun",
    root: "δῆμ",
    ground: "ὁ δῆμος",
    passport: "δήμου",
    dutch: "het volk",
    declension: "2",
    explicitDeclensions: {
      nomS: "ὁ δῆμος",
      genS: "τοῦ δήμου",
      datS: "τῷ δήμῳ",
      accS: "τὸν δῆμον",
      vocS: "δῆμε",
      nomD: "τὼ δήμω",
      genD: "τοῖν δήμοιν",
      datD: "τοῖν δήμοιν",
      accD: "τὼ δήμω",
      vocD: "δήμω",
      nomP: "οἱ δῆμοι",
      genP: "τῶν δήμων",
      datP: "τοῖς δήμοις",
      accP: "τοὺς δήμους",
      vocP: "δῆμοι",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "m",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BB%CF%8C%CE%B3%CE%BF%CF%82
    type: "noun",
    root: "λόγ",
    ground: "ὁ λόγος",
    passport: "λόγου",
    dutch: "het woord, het verhaal, de redenering",
    declension: "2",
    explicitDeclensions: {
      nomS: "ὁ λόγος",
      genS: "τοῦ λόγου",
      datS: "τῷ λόγῳ",
      accS: "τὸν λόγον",
      vocS: "λόγε",
      nomD: "τὼ λόγω",
      genD: "τοῖν λόγοιν",
      datD: "τοῖν λόγοιν",
      accD: "τὼ λόγω",
      vocD: "λόγω",
      nomP: "οἱ λόγοι",
      genP: "τῶν λόγων",
      datP: "τοῖς λόγοις",
      accP: "τοὺς λόγους",
      vocP: "λόγοι",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "m",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BF%E1%BC%B6%CE%BA%CE%BF%CF%82
    type: "noun",
    root: "οἶκ",
    ground: "ὁ οἶκος",
    passport: "οἴκου",
    dutch: "het huis",
    declension: "2",
    explicitDeclensions: {
      nomS: "ὁ οἶκος",
      genS: "τοῦ οἴκου",
      datS: "τῷ οἴκῳ",
      accS: "τὸν οἶκον",
      vocS: "οἶκε",
      nomD: "τὼ οἴκω",
      genD: "τοῖν οἴκοιν",
      datD: "τοῖν οἴκοιν",
      accD: "τὼ οἴκω",
      vocD: "οἴκω",
      nomP: "οἱ οἶκοι",
      genP: "τῶν οἴκων",
      datP: "τοῖς οἴκοις",
      accP: "τοὺς οἴκους",
      vocP: "οἶκοι",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "m",
  },
  {
    // https://en.wiktionary.org/wiki/%CF%80%CF%8C%CE%BB%CE%B5%CE%BC%CE%BF%CF%82
    type: "noun",
    root: "πόλεμ",
    ground: "ὁ πόλεμος",
    passport: "πολέμου",
    dutch: "de oorlog",
    declension: "2",
    explicitDeclensions: {
      nomS: "ὁ πόλεμος",
      genS: "τοῦ πολέμου",
      datS: "τῷ πολέμῳ",
      accS: "τὸν πόλεμον",
      vocS: "πόλεμε",
      nomD: "τὼ πολέμω",
      genD: "τοῖν πολέμοιν",
      datD: "τοῖν πολέμοιν",
      accD: "τὼ πολέμω",
      vocD: "πολέμω",
      nomP: "οἱ πόλεμοι",
      genP: "τῶν πολέμων",
      datP: "τοῖς πολέμοις",
      accP: "τοὺς πολέμους",
      vocP: "πόλεμοι",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "m",
  },
  {
    // https://en.wiktionary.org/wiki/%CF%83%CF%84%CF%81%CE%B1%CF%84%CE%B7%CE%B3%CF%8C%CF%82
    type: "noun",
    root: "στρᾰτηγ",
    ground: "ὁ στρᾰτηγός",
    passport: "στρᾰτηγοῦ",
    dutch: "de aanvoerder, de generaal",
    declension: "2",
    explicitDeclensions: {
      nomS: "ὁ στρᾰτηγός",
      genS: "τοῦ στρᾰτηγοῦ",
      datS: "τῷ στρᾰτηγῷ",
      accS: "τὸν στρᾰτηγόν",
      vocS: "στρᾰτηγέ",
      nomD: "τὼ στρᾰτηγώ",
      genD: "τοῖν στρᾰτηγοῖν",
      datD: "τοῖν στρᾰτηγοῖν",
      accD: "τὼ στρᾰτηγώ",
      vocD: "στρᾰτηγώ",
      nomP: "οἱ στρᾰτηγοί",
      genP: "τῶν στρᾰτηγῶν",
      datP: "τοῖς στρᾰτηγοῖς",
      accP: "τοὺς στρᾰτηγούς",
      vocP: "στρᾰτηγοί",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "m",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B8%CE%B5%CF%8C%CF%82
    type: "noun",
    root: "θε",
    ground: "ὁ θεός",
    passport: "θεοῦ",
    dutch: "de god",
    declension: "2",
    explicitDeclensions: {
      nomS: "ὁ θεός",
      genS: "τοῦ θεοῦ",
      datS: "τῷ θεῷ",
      accS: "τὸν θεόν",
      vocS: "θεέ",
      nomD: "τὼ θεώ",
      genD: "τοῖν θεοῖν",
      datD: "τοῖν θεοῖν",
      accD: "τὼ θεώ",
      vocD: "θεώ",
      nomP: "οἱ θεοί",
      genP: "τῶν θεῶν",
      datP: "τοῖς θεοῖς",
      accP: "τοὺς θεούς",
      vocP: "θεοί",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "m",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BF%E1%BC%B6%CE%BD%CE%BF%CF%82
    type: "noun",
    root: "οἶν",
    ground: "ὁ οἶνος",
    passport: "οἴνου",
    dutch: "de wijn",
    declension: "2",
    explicitDeclensions: {
      nomS: "ὁ οἶνος",
      genS: "τοῦ οἴνου",
      datS: "τῷ οἴνῳ",
      accS: "τὸν οἶνον",
      vocS: "οἶνε",
      nomD: "τὼ οἴνω",
      genD: "τοῖν οἴνοιν",
      datD: "τοῖν οἴνοιν",
      accD: "τὼ οἴνω",
      vocD: "οἴνω",
      nomP: "οἱ οἶνοι",
      genP: "τῶν οἴνων",
      datP: "τοῖς οἴνοις",
      accP: "τοὺς οἴνους",
      vocP: "οἶνοι",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "m",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BF%E1%BD%90%CF%81%CE%B1%CE%BD%CF%8C%CF%82
    type: "noun",
    root: "οὐρᾰν",
    ground: "ὁ οὐρᾰνός",
    passport: "οὐρᾰνοῦ",
    dutch: "de hemel",
    declension: "2",
    explicitDeclensions: {
      nomS: "ὁ οὐρᾰνός",
      genS: "τοῦ οὐρᾰνοῦ",
      datS: "τῷ οὐρᾰνῷ",
      accS: "τὸν οὐρᾰνόν",
      vocS: "οὐρᾰνέ",
      nomD: "τὼ οὐρᾰνώ",
      genD: "τοῖν οὐρᾰνοῖν",
      datD: "τοῖν οὐρᾰνοῖν",
      accD: "τὼ οὐρᾰνώ",
      vocD: "οὐρᾰνώ",
      nomP: "οἱ οὐρᾰνοί",
      genP: "τῶν οὐρᾰνῶν",
      datP: "τοῖς οὐρᾰνοῖς",
      accP: "τοὺς οὐρᾰνούς",
      vocP: "οὐρᾰνοί",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "m",
  },
  {
    // https://en.wiktionary.org/wiki/%CF%84%CE%B9%CE%BC%CE%AE
    type: "noun",
    root: "τῑμ",
    ground: "ἡ τῑμή",
    passport: "τῑμῆς",
    dutch: "de eer, het eerbewijs",
    declension: "1",
    explicitDeclensions: {
      nomS: "ἡ τῑμή",
      genS: "τῆς τῑμῆς",
      datS: "τῇ τῑμῇ",
      accS: "τὴν τῑμήν",
      vocS: "τῑμή",
      nomD: "τὼ τῑμᾱ́",
      genD: "τοῖν τῑμαῖν",
      datD: "τοῖν τῑμαῖν",
      accD: "τὼ τῑμᾱ́",
      vocD: "τῑμᾱ́",
      nomP: "αἱ τῑμαί",
      genP: "τῶν τῑμῶν",
      datP: "ταῖς τῑμαῖς",
      accP: "τᾱ̀ς τῑμᾱ́ς",
      vocP: "τῑμαί",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "f",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B4%E1%BF%B6%CF%81%CE%BF%CE%BD
    type: "noun",
    root: "δῶρ",
    ground: "τὸ δῶρον",
    passport: "δώρου",
    dutch: "het geschenk",
    declension: "2",
    explicitDeclensions: {
      nomS: "τὸ δῶρον",
      genS: "τοῦ δώρου",
      datS: "τῷ δώρῳ",
      accS: "τὸ δῶρον",
      vocS: "δῶρον",
      nomD: "τὼ δώρω",
      genD: "τοῖν δώροιν",
      datD: "τοῖν δώροιν",
      accD: "τὼ δώρω",
      vocD: "δώρω",
      nomP: "τᾰ̀ δῶρᾰ",
      genP: "τῶν δώρων",
      datP: "τοῖς δώροις",
      accP: "τᾰ̀ δῶρᾰ",
      vocP: "δῶρᾰ",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "n",
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%94%CF%81%CE%B3%CE%BF%CE%BD
    type: "noun",
    root: "ἔργ",
    ground: "τὸ ἔργον",
    passport: "ἔργου",
    dutch: "het werk",
    declension: "2",
    explicitDeclensions: {
      nomS: "τὸ ἔργον",
      genS: "τοῦ ἔργου",
      datS: "τῷ ἔργῳ",
      accS: "τὸ ἔργον",
      vocS: "ἔργον",
      nomD: "τὼ ἔργω",
      genD: "τοῖν ἔργοιν",
      datD: "τοῖν ἔργοιν",
      accD: "τὼ ἔργω",
      vocD: "ἔργω",
      nomP: "τᾰ̀ ἔργᾰ",
      genP: "τῶν ἔργων",
      datP: "τοῖς ἔργοις",
      accP: "τᾰ̀ ἔργᾰ",
      vocP: "ἔργᾰ",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "n",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B5%E1%BC%B0%CF%81%CE%AE%CE%BD%CE%B7
    type: "noun",
    root: "εἰρήν",
    ground: "ἡ εἰρήνη",
    passport: "εἰρήνης",
    dutch: "de vrede",
    declension: "1",
    explicitDeclensions: {
      nomS: "ἡ εἰρήνη",
      genS: "τῆς εἰρήνης",
      datS: "τῇ εἰρήνῃ",
      accS: "τὴν εἰρήνην",
      vocS: "εἰρήνη",
      nomD: "τὼ εἰρήνᾱ",
      genD: "τοῖν εἰρήναιν",
      datD: "τοῖν εἰρήναιν",
      accD: "τὼ εἰρήνᾱ",
      vocD: "εἰρήνᾱ",
      nomP: "αἱ εἰρῆναι",
      genP: "τῶν εἰρηνῶν",
      datP: "ταῖς εἰρήναις",
      accP: "τᾱ̀ς εἰρήνᾱς",
      vocP: "εἰρῆναι",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "f",
  },
  {
    // https://en.wiktionary.org/wiki/%CF%84%CE%AD%CF%87%CE%BD%CE%B7
    type: "noun",
    root: "τέχν",
    ground: "ἡ τέχνη",
    passport: "τέχνης",
    dutch: "de vaardigheid, de kunst",
    declension: "1",
    explicitDeclensions: {
      nomS: "ἡ τέχνη",
      genS: "τῆς τέχνης",
      datS: "τῇ τέχνῃ",
      accS: "τὴν τέχνην",
      vocS: "τέχνη",
      nomD: "τὼ τέχνᾱ",
      genD: "τοῖν τέχναιν",
      datD: "τοῖν τέχναιν",
      accD: "τὼ τέχνᾱ",
      vocD: "τέχνᾱ",
      nomP: "αἱ τέχναι",
      genP: "τῶν τεχνῶν",
      datP: "ταῖς τέχναις",
      accP: "τᾱ̀ς τέχνᾱς",
      vocP: "τέχναι",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "f",
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%A5%CE%BB%CE%B9%CE%BF%CF%82
    type: "noun",
    root: "ἥλῐ",
    ground: "ὁ ἥλῐος",
    passport: "ἡλῐ́ου",
    dutch: "de zon",
    declension: "2",
    explicitDeclensions: {
      nomS: "ὁ ἥλῐος",
      genS: "τοῦ ἡλῐ́ου",
      datS: "τῷ ἡλῐ́ῳ",
      accS: "τὸν ἥλῐον",
      vocS: "ἥλῐε",
    },
    hasSingular: true,
    hasDual: false,
    hasPlural: false,
    sex: "m",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BA%CE%AF%CE%BD%CE%B4%CF%85%CE%BD%CE%BF%CF%82
    type: "noun",
    root: "κῐ́νδῡν",
    ground: "ὁ κῐ́νδῡνος",
    passport: "κῐνδῡ́νου",
    dutch: "het gevaar",
    declension: "2",
    explicitDeclensions: {
      nomS: "ὁ κῐ́νδῡνος",
      genS: "τοῦ κῐνδῡ́νου",
      datS: "τῷ κῐνδῡ́νῳ",
      accS: "τὸν κῐ́νδῡνον",
      vocS: "κῐ́νδῡνε",
      nomD: "τὼ κῐνδῡ́νω",
      genD: "τοῖν κῐνδῡ́νοιν",
      datD: "τοῖν κῐνδῡ́νοιν",
      accD: "τὼ κῐνδῡ́νω",
      vocD: "κῐνδῡ́νω",
      nomP: "οἱ κῐ́νδῡνοι",
      genP: "τῶν κῐνδῡ́νων",
      datP: "τοῖς κῐνδῡ́νοις",
      accP: "τοὺς κῐνδῡ́νους",
      vocP: "κῐ́νδῡνοι",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "m",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BB%CE%AF%CE%B8%CE%BF%CF%82
    type: "noun",
    root: "λῐ́θ",
    ground: "ὁ λῐ́θος",
    passport: "λῐ́θου",
    dutch: "de steen",
    declension: "2",
    explicitDeclensions: {
      nomS: "ὁ λῐ́θος",
      genS: "τοῦ λῐ́θου",
      datS: "τῷ λῐ́θῳ",
      accS: "τὸν λῐ́θον",
      vocS: "λῐ́θε",
      nomD: "τὼ λῐ́θω",
      genD: "τοῖν λῐ́θοιν",
      datD: "τοῖν λῐ́θοιν",
      accD: "τὼ λῐ́θω",
      vocD: "λῐ́θω",
      nomP: "οἱ λῐ́θοι",
      genP: "τῶν λῐ́θων",
      datP: "τοῖς λῐ́θοις",
      accP: "τοὺς λῐ́θους",
      vocP: "λῐ́θοι",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "m",
  },
  {
    // https://en.wiktionary.org/wiki/%CF%80%CE%BF%CE%BB%CE%AD%CE%BC%CE%B9%CE%BF%CF%82
    type: "noun",
    root: "πολέμῐ",
    ground: "ὁ πολέμῐος",
    passport: "πολεμῐ́ου",
    dutch: "de vijand",
    declension: "2",
    explicitDeclensions: {
      nomS: "ὁ πολέμῐος",
      genS: "τοῦ πολεμῐ́ου",
      datS: "τῷ πολεμῐ́ῳ",
      accS: "τὸν πολέμῐον",
      vocS: "πολέμῐε",
      nomD: "τὼ πολεμῐ́ω",
      genD: "τοῖν πολεμῐ́οιν",
      datD: "τοῖν πολεμῐ́οιν",
      accD: "τὼ πολεμῐ́ω",
      vocD: "πολεμῐ́ω",
      nomP: "οἱ πολέμῐοι",
      genP: "τῶν πολεμῐ́ων",
      datP: "τοῖς πολεμῐ́οις",
      accP: "τοὺς πολεμῐ́ους",
      vocP: "πολέμῐοι",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "m",
  },
  {
    // https://en.wiktionary.org/wiki/%CF%84%CF%8C%CF%80%CE%BF%CF%82
    type: "noun",
    root: "τόπ",
    ground: "ὁ τόπος",
    passport: "τόπου",
    dutch: "de plaats",
    declension: "2",
    explicitDeclensions: {
      nomS: "ὁ τόπος",
      genS: "τοῦ τόπου",
      datS: "τῷ τόπῳ",
      accS: "τὸν τόπον",
      vocS: "τόπε",
      nomD: "τὼ τόπω",
      genD: "τοῖν τόποιν",
      datD: "τοῖν τόποιν",
      accD: "τὼ τόπω",
      vocD: "τόπω",
      nomP: "οἱ τόποι",
      genP: "τῶν τόπων",
      datP: "τοῖς τόποις",
      accP: "τοὺς τόπους",
      vocP: "τόποι",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "m",
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%84%CE%BA%CF%81%CE%BF%CE%BD
    type: "noun",
    root: "ᾰ̓́κρ",
    ground: "τὸ ᾰ̓́κρον",
    passport: "ᾰ̓́κρου",
    dutch: "de top",
    declension: "2",
    explicitDeclensions: {
      nomS: "τὸ ᾰ̓́κρον",
      genS: "τοῦ ᾰ̓́κρου",
      datS: "τῷ ᾰ̓́κρῳ",
      accS: "τὸ ᾰ̓́κρον",
      vocS: "ᾰ̓́κρον",
      nomD: "τὼ ᾰ̓́κρω",
      genD: "τοῖν ᾰ̓́κροιν",
      datD: "τοῖν ᾰ̓́κροιν",
      accD: "τὼ ᾰ̓́κρω",
      vocD: "ᾰ̓́κρω",
      nomP: "τᾰ̀ ᾰ̓́κρᾰ",
      genP: "τῶν ᾰ̓́κρων",
      datP: "τοῖς ᾰ̓́κροις",
      accP: "τᾰ̀ ᾰ̓́κρᾰ",
      vocP: "ᾰ̓́κρᾰ",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "n",
  },
  {
    // https://en.wiktionary.org/wiki/%CF%84%CE%AD%CE%BA%CE%BD%CE%BF%CE%BD
    type: "noun",
    root: "τέκν",
    ground: "τὸ τέκνον",
    passport: "τέκνου",
    dutch: "het kind (t.o.v. zijn ouders)",
    declension: "2",
    explicitDeclensions: {
      nomS: "τὸ τέκνον",
      genS: "τοῦ τέκνου",
      datS: "τῷ τέκνῳ",
      accS: "τὸ τέκνον",
      vocS: "τέκνον",
      nomD: "τὼ τέκνω",
      genD: "τοῖν τέκνοιν",
      datD: "τοῖν τέκνοιν",
      accD: "τὼ τέκνω",
      vocD: "τέκνω",
      nomP: "τᾰ̀ τέκνᾰ",
      genP: "τῶν τέκνων",
      datP: "τοῖς τέκνοις",
      accP: "τᾰ̀ τέκνᾰ",
      vocP: "τέκνᾰ",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "n",
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%80%CF%81%CF%87%CE%AE
    type: "noun",
    root: "ᾰ̓ρχ",
    ground: "ἡ ᾰ̓ρχή",
    passport: "ᾰ̓ρχῆς",
    dutch: "het begin, de heerschappij",
    declension: "1",
    explicitDeclensions: {
      nomS: "ἡ ᾰ̓ρχή",
      nomD: "τὼ ᾰ̓ρχᾱ́",
      nomP: "αἱ ᾰ̓ρχαί",
      genS: "τῆς ᾰ̓ρχῆς",
      genD: "τοῖν ᾰ̓ρχαῖν",
      genP: "τῶν ᾰ̓ρχῶν",
      datS: "τῇ ᾰ̓ρχῇ",
      datD: "τοῖν ᾰ̓ρχαῖν",
      datP: "ταῖς ᾰ̓ρχαῖς",
      accS: "τὴν ᾰ̓ρχήν",
      accD: "τὼ ᾰ̓ρχᾱ́",
      accP: "τᾱ̀ς ᾰ̓ρχᾱ́ς",
      vocS: "ᾰ̓ρχή",
      vocD: "ᾰ̓ρχᾱ́",
      vocP: "ᾰ̓ρχαί",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "f",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B3%E1%BF%86
    type: "noun",
    root: "γ",
    ground: "ἡ γῆ",
    passport: "γῆς",
    dutch: "de aarde, het land",
    declension: "1",
    explicitDeclensions: {
      nomS: "ἡ γῆ",
      nomD: "τὼ γᾶ",
      nomP: ["αἱ γαῖ", "αἱ γέαι"],
      genS: "τῆς γῆς",
      genD: "τοῖν γαῖν",
      genP: ["τῶν γῶν", "τῶν γεῶν"],
      datS: "τῇ γῇ",
      datD: "τοῖν γαῖν",
      datP: "ταῖς γεαῖς",
      accS: "τὴν γῆν",
      accD: "τὼ γᾶ",
      accP: ["τᾱ̀ς γᾶς", "τᾱ̀ς γέᾱς"],
      vocS: "γῆ",
      vocD: "γᾶ",
      vocP: ["γαῖ", "γέαι"],
    },
    hasSingular: true,
    hasPlural: true,
    sex: "f",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BD%CE%AF%CE%BA%CE%B7
    type: "noun",
    root: "νῑ́κ",
    ground: "ἡ νῑ́κη",
    passport: "νῑ́κης",
    dutch: "de overwinning",
    declension: "1",
    explicitDeclensions: {
      nomS: "ἡ νῑ́κη",
      nomD: "τὼ νῑ́κᾱ",
      nomP: "αἱ νῖκαι",
      genS: "τῆς νῑ́κης",
      genD: "τοῖν νῑ́καιν",
      genP: "τῶν νῑκῶν",
      datS: "τῇ νῑ́κῃ",
      datD: "τοῖν νῑ́καιν",
      datP: "ταῖς νῑ́καις",
      accS: "τὴν νῑ́κην",
      accD: "τὼ νῑ́κᾱ",
      accP: "τᾱ̀ς νῑ́κᾱς",
      vocS: "νῑ́κη",
      vocD: "νῑ́κᾱ",
      vocP: "νῖκαι",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "f",
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%80%CE%B4%CE%B5%CE%BB%CF%86%CF%8C%CF%82
    type: "noun",
    root: "ᾰ̓δελφ",
    ground: "ὁ ᾰ̓δελφός",
    passport: "ᾰ̓δελφοῦ",
    dutch: "de broer",
    declension: "2",
    explicitDeclensions: {
      nomS: "ὁ ᾰ̓δελφός",
      nomD: "τὼ ᾰ̓δελφώ",
      nomP: "οἱ ᾰ̓δελφοί",
      genS: "τοῦ ᾰ̓δελφοῦ",
      genD: "τοῖν ᾰ̓δελφοῖν",
      genP: "τῶν ᾰ̓δελφῶν",
      datS: "τῷ ᾰ̓δελφῷ",
      datD: "τοῖν ᾰ̓δελφοῖν",
      datP: "τοῖς ᾰ̓δελφοῖς",
      accS: "τὸν ᾰ̓δελφόν",
      accD: "τὼ ᾰ̓δελφώ",
      accP: "τοὺς ᾰ̓δελφούς",
      vocS: ["ᾰ̓δελφέ", "ᾰ̓́δελφε"],
      vocD: "ᾰ̓δελφώ",
      vocP: "ᾰ̓δελφοί",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "m",
  },
  {
    // https://en.wiktionary.org/wiki/%CF%84%CF%8D%CF%87%CE%B7
    type: "noun",
    root: "τῠ́χ",
    ground: "ἡ τῠ́χη",
    passport: "τῠ́χης",
    dutch: "het lot, het toeval",
    declension: "1",
    explicitDeclensions: {
      nomS: "ἡ τῠ́χη",
      nomD: "τὼ τῠ́χᾱ",
      nomP: "αἱ τῠ́χαι",
      genS: "τῆς τῠ́χης",
      genD: "τοῖν τῠ́χαιν",
      genP: "τῶν τῠχῶν",
      datS: "τῇ τῠ́χῃ",
      datD: "τοῖν τῠ́χαιν",
      datP: "ταῖς τῠ́χαις",
      accS: "τὴν τῠ́χην",
      accD: "τὼ τῠ́χᾱ",
      accP: "τᾱ̀ς τῠ́χᾱς",
      vocS: "τῠ́χη",
      vocD: "τῠ́χᾱ",
      vocP: "τῠ́χαι",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "f",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B8%CF%8D%CF%81%CE%B1
    type: "noun",
    root: "θῠ́ρ",
    ground: "ἡ θῠ́ρᾱ",
    passport: "θῠ́ρᾱς",
    dutch: "de deur",
    declension: "1",
    explicitDeclensions: {
      nomS: "ἡ θῠ́ρᾱ",
      nomD: "τὼ θῠ́ρᾱ",
      nomP: "αἱ θῠ́ραι",
      genS: "τῆς θῠ́ρᾱς",
      genD: "τοῖν θῠ́ραιν",
      genP: "τῶν θῠρῶν",
      datS: "τῇ θῠ́ρᾳ",
      datD: "τοῖν θῠ́ραιν",
      datP: "ταῖς θῠ́ραις",
      accS: "τὴν θῠ́ρᾱν",
      accD: "τὼ θῠ́ρᾱ",
      accP: "τᾱ̀ς θῠ́ρᾱς",
      vocS: "θῠ́ρᾱ",
      vocD: "θῠ́ρᾱ",
      vocP: "θῠ́ραι",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "f",
  },
  {
    // https://en.wiktionary.org/wiki/%CF%80%CE%BF%CE%BB%CE%AF%CF%84%CE%B7%CF%82
    type: "noun",
    root: "πολῑ́τ",
    ground: "ὁ πολῑ́της",
    passport: "πολῑ́του",
    dutch: "de burger",
    declension: "1",
    explicitDeclensions: {
      nomS: "ὁ πολῑ́της",
      nomD: "τὼ πολῑ́τᾱ",
      nomP: "οἱ πολῖται",
      genS: "τοῦ πολῑ́του",
      genD: "τοῖν πολῑ́ταιν",
      genP: "τῶν πολῑτῶν",
      datS: "τῷ πολῑ́τῃ",
      datD: "τοῖν πολῑ́ταιν",
      datP: "τοῖς πολῑ́ταις",
      accS: "τὸν πολῑ́την",
      accD: "τὼ πολῑ́τᾱ",
      accP: "τοὺς πολῑ́τᾱς",
      vocS: "πολῖτᾰ",
      vocD: "πολῑ́τᾱ",
      vocP: "πολῖται",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "m",
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BD%81%CE%B4%CF%8C%CF%82
    type: "noun",
    root: "ὁδ",
    ground: "ἡ ὁδός",
    passport: "ὁδοῦ",
    dutch: "de weg, de reis",
    declension: "2",
    explicitDeclensions: {
      nomS: "ἡ ὁδός",
      nomD: "τὼ ὁδώ",
      nomP: "αἱ ὁδοί",
      genS: "τῆς ὁδοῦ",
      genD: "τοῖν ὁδοῖν",
      genP: "τῶν ὁδῶν",
      datS: "τῇ ὁδῷ",
      datD: "τοῖν ὁδοῖν",
      datP: "ταῖς ὁδοῖς",
      accS: "τὴν ὁδόν",
      accD: "τὼ ὁδώ",
      accP: "τᾱ̀ς ὁδούς",
      vocS: "ὁδέ",
      vocD: "ὁδώ",
      vocP: "ὁδοί",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "f",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BC%CE%AC%CF%87%CE%B7
    type: "noun",
    root: "μᾰ́χ",
    ground: "ἡ μᾰ́χη",
    passport: "μᾰ́χης",
    dutch: "de strijd, het gevecht",
    declension: "1",
    explicitDeclensions: {
      nomS: "ἡ μᾰ́χη",
      nomD: "τὼ μᾰ́χᾱ",
      nomP: "αἱ μᾰ́χαι",
      genS: "τῆς μᾰ́χης",
      genD: "τοῖν μᾰ́χαιν",
      genP: "τῶν μᾰχῶν",
      datS: "τῇ μᾰ́χῃ",
      datD: "τοῖν μᾰ́χαιν",
      datP: "ταῖς μᾰ́χαις",
      accS: "τὴν μᾰ́χην",
      accD: "τὼ μᾰ́χᾱ",
      accP: "τᾱ̀ς μᾰ́χᾱς",
      vocS: "μᾰ́χη",
      vocD: "μᾰ́χᾱ",
      vocP: "μᾰ́χαι",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "f",
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%A1%CE%BC%CE%AD%CF%81%CE%B1
    type: "noun",
    root: "ἡμέρ",
    ground: "ἡ ἡμέρᾱ",
    passport: "ἡμέρᾱς",
    dutch: "de dag",
    declension: "1",
    explicitDeclensions: {
      nomS: "ἡ ἡμέρᾱ",
      nomD: "τὼ ἡμέρᾱ",
      nomP: "αἱ ἡμέραι",
      genS: "τῆς ἡμέρᾱς",
      genD: "τοῖν ἡμέραιν",
      genP: "τῶν ἡμερῶν",
      datS: "τῇ ἡμέρᾳ",
      datD: "τοῖν ἡμέραιν",
      datP: "ταῖς ἡμέραις",
      accS: "τὴν ἡμέρᾱν",
      accD: "τὼ ἡμέρᾱ",
      accP: "τᾱ̀ς ἡμέρᾱς",
      vocS: "ἡμέρᾱ",
      vocD: "ἡμέρᾱ",
      vocP: "ἡμέραι",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "f",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BF%E1%BC%B0%CE%BA%CE%AF%CE%B1
    type: "noun",
    root: "οἰκῐ́",
    ground: "ἡ οἰκῐ́ᾱ",
    passport: "οἰκῐ́ᾱς",
    dutch: "het huis",
    declension: "1",
    explicitDeclensions: {
      nomS: "ἡ οἰκῐ́ᾱ",
      nomD: "τὼ οἰκῐ́ᾱ",
      nomP: "αἱ οἰκῐ́αι",
      genS: "τῆς οἰκῐ́ᾱς",
      genD: "τοῖν οἰκῐ́αιν",
      genP: "τῶν οἰκῐῶν",
      datS: "τῇ οἰκῐ́ᾳ",
      datD: "τοῖν οἰκῐ́αιν",
      datP: "ταῖς οἰκῐ́αις",
      accS: "τὴν οἰκῐ́ᾱν",
      accD: "τὼ οἰκῐ́ᾱ",
      accP: "τᾱ̀ς οἰκῐ́ᾱς",
      vocS: "οἰκῐ́ᾱ",
      vocD: "οἰκῐ́ᾱ",
      vocP: "οἰκῐ́αι",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "f",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B4%CE%B5%CF%83%CF%80%CF%8C%CF%84%CE%B7%CF%82
    type: "noun",
    root: "δεσπότ",
    ground: "ὁ δεσπότης",
    passport: "δεσπότου",
    dutch: "de heer, de meester",
    declension: "1",
    explicitDeclensions: {
      nomS: "ὁ δεσπότης",
      nomD: "τὼ δεσπότᾱ",
      nomP: "οἱ δεσπόται",
      genS: "τοῦ δεσπότου",
      genD: "τοῖν δεσπόταιν",
      genP: "τῶν δεσποτῶν",
      datS: "τῷ δεσπότῃ",
      datD: "τοῖν δεσπόταιν",
      datP: "τοῖς δεσπόταις",
      accS: "τὸν δεσπότην",
      accD: "τὼ δεσπότᾱ",
      accP: "τοὺς δεσπότᾱς",
      vocS: "δέσποτᾰ",
      vocD: "δεσπότᾱ",
      vocP: "δεσπόται",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "m",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BA%CF%81%CE%B9%CF%84%CE%AE%CF%82
    type: "noun",
    root: "κρῐτ",
    ground: "ὁ κρῐτής",
    passport: "κρῐτοῦ",
    dutch: "de rechter, de scheidsrechter",
    declension: "1",
    explicitDeclensions: {
      nomS: "ὁ κρῐτής",
      nomD: "τὼ κρῐτᾱ́",
      nomP: "οἱ κρῐταί",
      genS: "τοῦ κρῐτοῦ",
      genD: "τοῖν κρῐταῖν",
      genP: "τῶν κρῐτῶν",
      datS: "τῷ κρῐτῇ",
      datD: "τοῖν κρῐταῖν",
      datP: "τοῖς κρῐταῖς",
      accS: "τὸν κρῐτήν",
      accD: "τὼ κρῐτᾱ́",
      accP: "τοὺς κρῐτᾱ́ς",
      vocS: "κρῐτᾰ́",
      vocD: "κρῐτᾱ́",
      vocP: "κρῐταί",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "m",
  },
  {
    // https://en.wiktionary.org/wiki/%CF%80%CE%BF%CF%84%CE%B1%CE%BC%CF%8C%CF%82
    type: "noun",
    root: "ποτᾰμ",
    ground: "ὁ ποτᾰμός",
    passport: "ποτᾰμοῦ",
    dutch: "de rivier",
    declension: "2",
    explicitDeclensions: {
      nomS: "ὁ ποτᾰμός",
      nomD: "τὼ ποτᾰμώ",
      nomP: "οἱ ποτᾰμοί",
      genS: "τοῦ ποτᾰμοῦ",
      genD: "τοῖν ποτᾰμοῖν",
      genP: "τῶν ποτᾰμῶν",
      datS: "τῷ ποτᾰμῷ",
      datD: "τοῖν ποτᾰμοῖν",
      datP: "τοῖς ποτᾰμοῖς",
      accS: "τὸν ποτᾰμόν",
      accD: "τὼ ποτᾰμώ",
      accP: "τοὺς ποτᾰμούς",
      vocS: "ποτᾰμέ",
      vocD: "ποτᾰμώ",
      vocP: "ποτᾰμοί",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "m",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%B4%CE%AD%CE%BD%CE%B4%CF%81%CE%BF%CE%BD
    type: "noun",
    root: "δένδρ",
    ground: "τὸ δένδρον",
    passport: "δένδρου",
    dutch: "de boom",
    declension: "2",
    explicitDeclensions: {
      nomS: "τὸ δένδρον",
      nomD: "τὼ δένδρω",
      nomP: "τᾰ̀ δένδρᾰ",
      genS: "τοῦ δένδρου",
      genD: "τοῖν δένδροιν",
      genP: "τῶν δένδρων",
      datS: "τῷ δένδρῳ",
      datD: "τοῖν δένδροιν",
      datP: "τοῖς δένδροις",
      accS: "τὸ δένδρον",
      accD: "τὼ δένδρω",
      accP: "τᾰ̀ δένδρᾰ",
      vocS: "δένδρον",
      vocD: "δένδρω",
      vocP: "δένδρᾰ",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "n",
  },
  {
    // https://en.wiktionary.org/wiki/%E1%BC%80%CE%BB%CE%AE%CE%B8%CE%B5%CE%B9%CE%B1
    type: "noun",
    root: "ᾰ̓λήθει",
    ground: "ἡ ᾰ̓λήθειᾰ",
    passport: "ᾰ̓ληθείᾱς",
    dutch: "de waarheid",
    declension: "1",
    explicitDeclensions: {
      nomS: "ἡ ᾰ̓λήθειᾰ",
      nomD: "τὼ ᾰ̓ληθείᾱ",
      nomP: "αἱ ᾰ̓λήθειαι",
      genS: "τῆς ᾰ̓ληθείᾱς",
      genD: "τοῖν ᾰ̓ληθείαιν",
      genP: "τῶν ᾰ̓ληθειῶν",
      datS: "τῇ ᾰ̓ληθείᾳ",
      datD: "τοῖν ᾰ̓ληθείαιν",
      datP: "ταῖς ᾰ̓ληθείαις",
      accS: "τὴν ᾰ̓λήθειᾰν",
      accD: "τὼ ᾰ̓ληθείᾱ",
      accP: "τᾱ̀ς ᾰ̓ληθείᾱς",
      vocS: "ᾰ̓λήθειᾰ",
      vocD: "ᾰ̓ληθείᾱ",
      vocP: "ᾰ̓λήθειαι",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "f",
  },
  {
    // https://en.wiktionary.org/wiki/%CF%87%CF%8E%CF%81%CE%B1
    type: "noun",
    root: "χώρ",
    ground: "ἡ χώρᾱ",
    passport: "χώρᾱς",
    dutch: "de landstreek",
    declension: "1",
    explicitDeclensions: {
      nomS: "ἡ χώρᾱ",
      nomD: "τὼ χώρᾱ",
      nomP: "αἱ χῶραι",
      genS: "τῆς χώρᾱς",
      genD: "τοῖν χώραιν",
      genP: "τῶν χωρῶν",
      datS: "τῇ χώρᾳ",
      datD: "τοῖν χώραιν",
      datP: "ταῖς χώραις",
      accS: "τὴν χώρᾱν",
      accD: "τὼ χώρᾱ",
      accP: "τᾱ̀ς χώρᾱς",
      vocS: "χώρᾱ",
      vocD: "χώρᾱ",
      vocP: "χῶραι",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "f",
  },
  {
    // https://en.wiktionary.org/wiki/%CF%80%CE%BF%CE%B9%CE%B7%CF%84%CE%AE%CF%82
    type: "noun",
    root: "ποιητ",
    ground: "ὁ ποιητής",
    passport: "ποιητοῦ",
    dutch: "de dichter",
    declension: "1",
    explicitDeclensions: {
      nomS: "ὁ ποιητής",
      nomD: "τὼ ποιητᾱ́",
      nomP: "οἱ ποιηταί",
      genS: "τοῦ ποιητοῦ",
      genD: "τοῖν ποιηταῖν",
      genP: "τῶν ποιητῶν",
      datS: "τῷ ποιητῇ",
      datD: "τοῖν ποιηταῖν",
      datP: "τοῖς ποιηταῖς",
      accS: "τὸν ποιητήν",
      accD: "τὼ ποιητᾱ́",
      accP: "τοὺς ποιητᾱ́ς",
      vocS: "ποιητᾰ́",
      vocD: "ποιητᾱ́",
      vocP: "ποιηταί",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "m",
  },
  {
    // https://en.wiktionary.org/wiki/%CE%BD%CF%8C%CE%BC%CE%BF%CF%82
    type: "noun",
    root: "νόμ",
    ground: "ὁ νόμος",
    passport: "νόμου",
    dutch: "het gebruik, de gewoonte, de wet",
    declension: "2",
    explicitDeclensions: {
      nomS: "ὁ νόμος",
      nomD: "τὼ νόμω",
      nomP: "οἱ νόμοι",
      genS: "τοῦ νόμου",
      genD: "τοῖν νόμοιν",
      genP: "τῶν νόμων",
      datS: "τῷ νόμῳ",
      datD: "τοῖν νόμοιν",
      datP: "τοῖς νόμοις",
      accS: "τὸν νόμον",
      accD: "τὼ νόμω",
      accP: "τοὺς νόμους",
      vocS: "νόμε",
      vocD: "νόμω",
      vocP: "νόμοι",
    },
    hasSingular: true,
    hasPlural: true,
    sex: "f",
  },
  // https://en.wiktionary.org/wiki/%CF%83%CF%84%CF%81%CE%B1%CF%84%CE%B9%CF%8E%CF%84%CE%B7%CF%82
  {
    "type": "noun",
    "root": "στρᾰτῐώτ",
    "ground": "ὁ στρᾰτῐώτης",
    "passport": "στρᾰτῐώτου",
    "dutch": "de soldaat",
    "declension": "1",
    "explicitDeclensions": {
      nomS: "ὁ στρᾰτῐώτης",
      nomD: "τὼ στρᾰτῐώτᾱ",
      nomP: "οἱ στρᾰτῐῶται",
      genS: "τοῦ στρᾰτῐώτου",
      genD: "τοῖν στρᾰτῐώταιν",
      genP: "τῶν στρᾰτῐωτῶν",
      datS: "τῷ στρᾰτῐώτῃ",
      datD: "τοῖν στρᾰτῐώταιν",
      datP: "τοῖς στρᾰτῐώταις",
      accS: "τὸν στρᾰτῐώτην",
      accD: "τὼ στρᾰτῐώτᾱ",
      accP: "τοὺς στρᾰτῐώτᾱς",
      vocS: "στρᾰτῐῶτᾰ",
      vocD: "στρᾰτῐώτᾱ",
      vocP: "στρᾰτῐῶται",
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "m"
  },
  {
    // https://en.wiktionary.org/wiki/%CF%83%CF%84%CF%81%CE%B1%CF%84%CE%B9%CE%AC
    "type": "noun",
    "root": "στρᾰτῐ",
    "ground": "ἡ στρᾰτῐᾱ",
    "passport": "στρᾰτῐᾶς",
    "dutch": "het leger",
    "declension": "1",
    "explicitDeclensions": {
      "nomS": "ἡ στρᾰτῐᾱ",
      "nomD": "τὼ στρᾰτῐᾱ",
      "nomP": "αἱ στρᾰτῐαί",
      "genS": "τῆς στρᾰτῐᾶς",
      "genD": "τοῖν στρᾰτῐαῖν",
      "genP": "τῶν στρᾰτῐῶν",
      "datS": "τῇ στρᾰτῐᾷ",
      "datD": "τοῖν στρᾰτῐαῖν",
      "datP": "ταῖς στρᾰτῐαῖς",
      "accS": "τὴν στρᾰτῐᾱν",
      "accD": "τὼ στρᾰτῐᾱ",
      "accP": "τᾱ̀ς στρᾰτῐᾱς̀",
      "vocS": "στρᾰτῐᾱ",
      "vocD": "στρᾰτῐᾱ",
      "vocP": "στρᾰτῐαί"
    },
    "hasSingular": true,
    "hasPlural": true,
    "sex": "f"
  },
  // {
  //   type: "noun",
  //   root: "",
  //   ground: "",
  //   passport: "",
  //   dutch: "",
  //   declension: "",
  //   explicitDeclensions: {
      // nomS: "",
      // nomD: "",
      // nomP: "",
      // genS: "",
      // genD: "",
      // genP: "",
      // datS: "",
      // datD: "",
      // datP: "",
      // accS: "",
      // accD: "",
      // accP: "",
      // vocS: "",
      // vocD: "",
      // vocP: "",
  //   },
  //   hasSingular: true,
  //   hasPlural: true,
  //   sex: "",
  // },
];

export const searchGreekNoun = searchWord(GREEK_NOUNS, "Greek noun", "root");